/** @jsx jsx */
import React from 'react';
import { jsx, css, CSSObject } from '@emotion/core';
import StyledImageDialog from './StyledImageDialog';
import { IThemeV2Type } from '@cb/types/entities/theme';

const styles: CSSObject = {
  cursor: 'pointer',

  '&:focus': {
    boxShadow: '0px 0px 0px var(--form-control-focus-ring-width) var(--form-control-focus-ring-color)',
  },

  '&:focus-visible': {
    outline: 'none',
  },
};

const StyledDocImage = ({
  imgElement,
  themeV2,
}: {
  imgElement: HTMLImageElement;
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode'; generatedCSSClassname: string };
}) => {
  const [showModal, setShowModal] = React.useState(false);

  const handleClick = () => setShowModal(true);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLImageElement>) => {
    if (e.key === 'Enter') handleClick();
  };
  return (
    <React.Fragment>
      <div onClick={handleClick} onKeyDown={handleKeyDown} css={css(styles)} tabIndex={0} role="button">
        <img src={imgElement.src} alt={imgElement.alt} />
      </div>

      <StyledImageDialog
        themeV2={themeV2}
        src={imgElement.src}
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
    </React.Fragment>
  );
};

export default StyledDocImage;
