import type { Exception } from '@sentry/react';
import { useEffect, useState } from 'react';

import { CmdButton, CmdSearchInput, cmdToast } from '@commandbar/design-system/cmd';
import { Page } from '@commandbar/design-system/components/antd';
import { Modal } from '@commandbar/design-system/components/antd';
import { PlusCircle, Trash04 } from '@commandbar/design-system/icons/react';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import * as Organization from '@commandbar/internal/middleware/organization';
import type { IOrganizationType } from '@commandbar/internal/middleware/types';
import { useAppContext } from 'editor/src/AppStateContext';
import { useReportEvent } from '../../../hooks/useEventReporting';
import { IntegrationActionButtons, Section, SectionContent, SectionTitle } from '../../styles';
import { useIntercomOAuth } from '../non-help-center/IntercomOAuth';
import SlackOAuth from '../non-help-center/SlackOAuth';
import type { IntegrationSpec } from '../shared/types';
import { onIntegrationError } from '../shared/utils';
import HelpCenterIntegrationSection from './HelpCenterIntegrationSection';
import IntegrationCard from './IntegrationCard';
import NonHelpCenterIntegrationSection from './NonHelpCenterIntegrationSection';

const IntegrationsList = ({ organization }: { organization: IOrganizationType }) => {
  const { reportEvent } = useReportEvent();

  const [activeIntegrations, setActiveIntegrations] = useState<Record<string, any> | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const organizationId = String(organization.id);
  const { flags } = useAppContext();

  const authorizeIntercom = useIntercomOAuth();

  useEffect(() => {
    Organization.readInternal(organizationId).then(async ({ integrations }) => {
      setActiveIntegrations(integrations);

      const query = new URLSearchParams(window.location.search);
      if (!!query.get('error')) {
        cmdToast.error(`${query.get('error')} If this issue persists, please reach out to us at support@command.ai.`);
      }
    });
  }, []);

  const deleteIntegrationFromOrg = (integration: Pick<IntegrationSpec, 'slug' | 'name'>) => {
    const remainingIntegrations = { ...activeIntegrations };
    delete remainingIntegrations[integration.slug];

    Organization.updateInternal({
      id: organizationId,
      integrations: remainingIntegrations,
    })
      .then(({ integrations }) => {
        setActiveIntegrations(integrations);
      })
      .catch(() => {
        cmdToast.error(
          `Failed to delete ${integration.name} integration. If this issue persists, please reach out to us at support@command.ai.`,
        );
      });
  };

  return (
    <Page
      style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
      title="Integrations"
      headerChildren={
        <CmdSearchInput
          value={searchTerm}
          placeholder="Search integrations"
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: '350px' }}
        />
      }
    >
      <HelpCenterIntegrationSection
        searchTerm={searchTerm}
        noSearchResult={
          ![
            'segment',
            'heap',
            'algolia',
            'amplitude',
            'logrocket',
            'mixpanel',
            'posthog',
            'rudderstack',
            'slack',
          ].filter((term) => term.includes(searchTerm.toLowerCase())).length
        }
      />

      <NonHelpCenterIntegrationSection
        sectionTitle="Analytics"
        activeIntegrations={activeIntegrations}
        integrations={[
          {
            name: 'Segment Source',
            slug: 'segment',
            imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/segment.svg',
            description: 'Connect Command AI analytics to Segment.',
            route: '/segment',
          },
          ...(flags['segment-webhook-destination']
            ? [
                {
                  name: 'Segment Webhook Destination',
                  slug: 'segment_webhook',
                  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/segment.svg',
                  description: 'Send data from Segment to Command AI server-side.',
                  route: '/segment-webhook',
                },
              ]
            : []),
          {
            name: 'Heap',
            slug: 'heap',
            imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/heap-logo.svg',
            description: 'Configure your Heap integration.',
            route: '/heap',
          },
          {
            name: 'HubSpot',
            slug: 'hubspot',
            imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/hubspot-logo.svg',
            description: 'Configure your HubSpot integration.',
            route: '/hubspot',
          },

          ...(flags['amplitude-audience-integration']
            ? [
                {
                  name: 'Amplitude',
                  slug: 'amplitude',
                  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/amplitude.svg',
                  description: 'Configure your Amplitude integration',
                  route: '/amplitude',
                },
              ]
            : []),
        ].map((integration) => ({ ...integration, handleDelete: deleteIntegrationFromOrg }))}
        searchTerm={searchTerm}
      />

      <NonHelpCenterIntegrationSection
        sectionTitle="Copilot handoff"
        activeIntegrations={activeIntegrations}
        integrations={[
          {
            name: 'Zendesk',
            slug: 'sunshine',
            imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/zendesk-logo.svg',
            description: 'Handoff Copilot chats to Zendesk agents.',
            route: '/zendesk-handoff',
          },
          {
            name: 'Intercom',
            slug: 'intercom',
            imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/intercom-logo.svg',
            description: 'Handoff Copilot chats to Intercom agents.',
            route: '/intercom-handoff',
            onConnect: authorizeIntercom,
            handleDelete: deleteIntegrationFromOrg,
            configurable: false,
          },
          {
            name: 'Freshchat',
            slug: 'freshchat',
            imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/freshchat-logo.svg',
            description: 'Handoff Copilot chats to Freshchat agents.',
            route: '/freshchat-handoff',
            handleDelete: deleteIntegrationFromOrg,
          },
        ]}
        searchTerm={searchTerm}
      />

      <NonHelpCenterIntegrationSection
        sectionTitle="Search"
        activeIntegrations={activeIntegrations}
        integrations={[
          {
            name: 'Algolia',
            slug: 'algolia',
            imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/algolia.svg',
            description: 'Use Command AI as a frontend for Algolia search.',
            route: '/algolia',
          },
        ].map((integration) => ({ ...integration, handleDelete: deleteIntegrationFromOrg }))}
        searchTerm={searchTerm}
      />

      {'slack'.includes(searchTerm.toLowerCase()) && (
        <Section>
          <SectionTitle>Utilities</SectionTitle>
          <SectionContent>
            <IntegrationCard
              integration={{
                name: 'Slack',
                slug: 'slack',
                imgURL: 'https://staticassets.commandbar.com/integrations/slack-wordmark.svg',
                description: "Command AI's Slack App delivers instant notifications for new survey responses.",
              }}
              isActive={!!activeIntegrations?.slack}
            >
              <IntegrationActionButtons>
                {activeIntegrations?.slack ? (
                  <CmdButton
                    size="lg"
                    fullWidth
                    onClick={(_) => {
                      Modal.confirm({
                        title: (
                          <div>
                            <p>Are you sure you want to disconnect Slack?</p>
                            <p>
                              This will remove all Slack data from CommandBar. This will remove the Slack app from your
                              teams workspace.
                            </p>
                          </div>
                        ),
                        onOk: async () => {
                          try {
                            await axiosInstance.post('integrations/slack/uninstall_slack_app', {
                              organization: organization.id,
                            });
                            Organization.readInternal(organizationId).then(async ({ integrations }) => {
                              setActiveIntegrations(integrations);
                            });
                            cmdToast.success('Slack integration removed from your teams workspace.');
                          } catch (err) {
                            onIntegrationError(err as Exception, 'slack');
                          }
                          reportEvent('integration card clicked', {
                            segment: true,
                            highlight: true,
                            slack: true,
                            payloadMessage: 'Slack removed',
                            eventProps: {
                              name: 'Slack',
                            },
                          });
                        },
                        cancelText: 'Cancel',
                      });
                    }}
                  >
                    <Trash04 />
                    Delete
                  </CmdButton>
                ) : (
                  <SlackOAuth>
                    <CmdButton
                      fullWidth
                      size="lg"
                      onClick={() => {
                        reportEvent('integration card clicked', {
                          segment: true,
                          highlight: true,
                          slack: true,
                          payloadMessage: 'Slack',
                          eventProps: {
                            name: 'Slack',
                          },
                        });
                      }}
                    >
                      <PlusCircle />
                      Connect
                    </CmdButton>
                  </SlackOAuth>
                )}
              </IntegrationActionButtons>
            </IntegrationCard>
          </SectionContent>
        </Section>
      )}
    </Page>
  );
};

export default IntegrationsList;
