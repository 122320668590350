import { AnalyticsV2 } from './client';
import { getSDK } from '@commandbar/internal/client/globals';
import { _analytics } from '@commandbar/internal/client/symbols';
import { CBStore, HelpHubDoc } from '@commandbar/commandbar/shared/store/global-store';
import { CBEventSource } from './schema';
import { Option } from '@commandbar/commandbar/products/spotlight/types';
import type { Action } from '@cb/types/entities/command/actions';
import type {
  IAIMessageType,
  IChecklist,
  IChecklistItem,
  IExperienceHitType,
  INudgeType,
  IUserMessageType,
} from '@commandbar/internal/middleware/types';
import { NudgeStepButton, SurveyResponseEvent } from '../EventHandler';
import type { IDecideResponseType, IUserRemoteProperties } from '@cb/types/entities/endUser';
import { getNudgeStep } from '@commandbar/internal/util/nudges';
import { serializeObject } from './serializers';

const getClient = (): AnalyticsV2 | undefined => {
  const sdk = getSDK();
  return sdk?.[_analytics];
};

export const Track = {
  identify: (userProperties?: IUserRemoteProperties) => {
    // Values for user property and ID are added via automatic enrichments
    getClient()?.trackCBEvent?.(
      'c:identify',
      {},
      {
        end_user_properties: userProperties,
      },
      true,
    );
  },
  decide: (decideResult: IDecideResponseType, userProperties: IUserRemoteProperties) => {
    getClient()?.trackCBEvent?.(
      'c:decide',
      {},
      { decide: decideResult || undefined, end_user_properties: userProperties },
      true,
    );
  },
  internal: {
    sdkMethodCalled: (method: string, options?: any) => {
      getClient()?.trackCBEvent?.('c:internal:sdk_method_called', { method, options });
    },
  },
  // NOTE: The conditional chaining on `trackCBEvent` is needed because of some very old spotlight tests that only partially initialize the SDK
  spotlight: {
    /**
     * Fired whenever Spotlight is opened.
     * @param source The source of the event.
     */
    opened: (source?: CBEventSource) => {
      getClient()?.trackCBEvent?.(
        'c:spotlight_opened',
        {},
        {
          source,
        },
      );
    },

    /**
     * Fired whenever Spotlight is closed.
     * @param inputText The text that was in the search bar when Spotlight was closed.
     */
    closed: (inputText: string) => {
      getClient()?.trackCBEvent?.('c:spotlight_closed', {
        inputText,
      });
    },

    /**
     * Fired whenever input is typed into the search bar in Spotlight. These are debounced to once every 500ms.
     */
    searchInput: (inputText: string) => {
      getClient()?.trackCBEvent?.('c:spotlight_search_input', {
        inputText,
      });
    },

    /**
     * Fired whenever a search is executed in Spotlight.
     */
    searchExecuted: () => {
      getClient()?.trackCBEvent?.('c:spotlight_search_executed', {});
    },

    /**
     * Fired whenever a search result is clicked in Spotlight.
     * @param option The search result that was clicked.
     * @param inputText The text that was in the search bar when the result was clicked.
     */
    searchResultClicked: (option: Option, inputText: string) => {
      getClient()?.trackCBEvent?.('c:spotlight_search_result_clicked', {
        inputText,
        option: serializeObject(option),
      });
    },
  },

  nudge: {
    /**
     * Properties that will be included on all nudge events.
     */
    _getCommonProperties: (nudge: INudgeType, stepIndex: number) => {
      const nudgeStep = getNudgeStep(nudge, stepIndex);
      const isLastStep = stepIndex === nudge.steps.length - 1;

      return {
        nudgeType: nudge.type || 'unknown',
        nudgeId: nudge.id,
        nudgeStepId: nudgeStep?.id,
        isLastStep,
      };
    },

    /**
     * Fired whenever a nudge is viewed.
     * @param nudge The nudge that was viewed.
     * @param nudgeStep The step of the nudge that was viewed.
     * @param source The source that triggered the event, if applicable.
     */
    viewed: (nudge: INudgeType, stepIndex: number, source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('c:nudge_viewed', Track.nudge._getCommonProperties(nudge, stepIndex), {
        source,
      });
    },

    /**
     * Fired whenever a nudge is dismissed.
     * @param nudge The nudge that was dismissed.
     * @param nudgeStep The step of the nudge that was dismissed.
     */
    dismissed: (nudge: INudgeType, stepIndex: number) => {
      getClient()?.trackCBEvent?.('c:nudge_dismissed', Track.nudge._getCommonProperties(nudge, stepIndex));
    },

    /**
     * Fired whenever a nudge is snoozed.
     * @param nudge The nudge that was snoozed.
     * @param nudgeStep The step of the nudge that was snoozed.
     */
    snoozed: (nudge: INudgeType, stepIndex: number) => {
      getClient()?.trackCBEvent?.('c:nudge_snoozed', Track.nudge._getCommonProperties(nudge, stepIndex));
    },

    /**
     * Fired whenever the CTA of a nudge is clicked.
     * @param nudge The nudge that was clicked.
     * @param nudgeStep The step of the nudge that was clicked.
     */
    ctaClicked: (nudge: INudgeType, stepIndex: number, button: NudgeStepButton | undefined) => {
      getClient()?.trackCBEvent?.('c:nudge_cta_clicked', {
        ...Track.nudge._getCommonProperties(nudge, stepIndex),
        button: button ? serializeObject(button) : undefined,
      });
    },

    /**
     * Fired whenever a survey is submitted.
     * @param nudge The nudge that the survey was submitted for.
     * @param nudgeStep The step of the nudge that the survey was submitted for.
     * @param response The response to the survey.
     */
    surveySubmitted: (nudge: INudgeType, stepIndex: number, response: SurveyResponseEvent['response']) => {
      getClient()?.trackCBEvent?.('c:nudge_survey_submitted', {
        ...Track.nudge._getCommonProperties(nudge, stepIndex),
        response: {
          type: response.type,
          value: response.value,
          max: 'max' in response ? response.max : undefined,
        },
      });
    },
  },

  checklist: {
    /**
     * Properties that will be included on all nudge events.
     */
    _getCommonProperties: (checklist: IChecklist, item?: IChecklistItem, hasIncompleteItems?: boolean) => {
      // NOTE: We use the same properties as nudges because we are moving towards treating checklists as a specific type of nudge.
      return {
        nudgeType: 'checklist',
        nudgeId: checklist.id,
        nudgeStepId: item?.id,
        hasIncompleteItems,
      };
    },

    /**
     * Fired whenever a checklist is viewed.
     * @param checklist The checklist that was viewed.
     * @param source The source that triggered the event, if applicable.
     */
    viewed: (checklist: IChecklist, source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('c:nudge_viewed', Track.checklist._getCommonProperties(checklist), {
        source,
      });
    },

    /**
     * Fired whenever a checklist is dismissed, or when the 'Done' button is clicked and all steps are completed/skipped.
     * @param checklist The checklist that was dismissed.
     */
    dismissed: (checklist: IChecklist, hasIncompleteItems: boolean) => {
      getClient()?.trackCBEvent?.(
        'c:nudge_dismissed',
        Track.checklist._getCommonProperties(checklist, undefined, hasIncompleteItems),
      );
    },

    /**
     * Fired whenever all the items of a checklist have been skipped or completed.
     * @param checklist The checklist that was completed.
     */
    completed: (checklist: IChecklist) => {
      getClient()?.trackCBEvent?.('c:nudge_checklist_completed', Track.checklist._getCommonProperties(checklist));
    },

    /**
     * Fired whenever a checklist item is skipped.
     * @param checklist The checklist that was dismissed.
     * @param item The item that was skipped.
     */
    itemSkipped: (checklist: IChecklist, item: IChecklistItem, hasIncompleteItems: boolean) => {
      getClient()?.trackCBEvent?.(
        'c:nudge_checklist_item_skipped',
        Track.checklist._getCommonProperties(checklist, item, hasIncompleteItems),
      );
    },

    /**
     * Fired whenever a checklist item is completed.
     * @param checklist The checklist that was dismissed.
     * @param item The item that was completed.
     * @param hasIncompleteItems Whether there are any incomplete items left in the checklist.
     */
    itemCompleted: (checklist: IChecklist, item: IChecklistItem, hasIncompleteItems: boolean) => {
      getClient()?.trackCBEvent?.(
        'c:nudge_checklist_item_completed',
        Track.checklist._getCommonProperties(checklist, item, hasIncompleteItems),
      );
    },

    /**
     * Fired whenever the CTA of a checklist is clicked.
     * @param checklist The checklist that was clicked.
     * @param item The item that was clicked.
     */
    ctaClicked: (checklist: IChecklist, item: IChecklistItem) => {
      getClient()?.trackCBEvent?.('c:nudge_cta_clicked', {
        ...Track.checklist._getCommonProperties(checklist, item),
      });
    },
  },

  copilot: {
    /**
     * Properties that will be included on all Copilot events.
     * @returns The properties to include in the event.
     */
    _getCommonProperties: (chatId: string) => {
      return {
        chatId,
      };
    },

    /**
     * Fired whenever Copilot is opened.
     * @param source The source of the event.
     */
    viewed: (source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('c:copilot_viewed', {}, { source });
    },

    /**
     * Fired whenever a Copilot chat is started.
     * @param chatId The ID of the chat that was started.
     * @param source The source of the event.
     */
    chatStarted: (chatId: string, source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('c:copilot_chat_started', Track.copilot._getCommonProperties(chatId), {
        source,
      });
    },

    /**
     * Fired whenever a Copilot chat is restarted.
     * @param chatId The ID of the chat that was restarted.
     */
    chatRestarted: (chatId: string) => {
      getClient()?.trackCBEvent?.('c:copilot_chat_restarted', Track.copilot._getCommonProperties(chatId));
    },

    /**
     * Fired whenever a Copilot chat is ended.
     * @param chatId The ID of the chat that was ended.
     * @param message The message that was sent to the chat.
     */
    chatMessageSent: (chatId: string, message: IUserMessageType | IAIMessageType) => {
      getClient()?.trackCBEvent?.('c:copilot_chat_message_sent', {
        ...Track.copilot._getCommonProperties(chatId),
        message: serializeObject(message),
      });
    },

    /**
     * Fired whenever the feedback button is clicked in Copilot.
     * @param chatId The ID of the chat that the feedback was given for.
     * @param messageId The ID of the message that the feedback was given for.
     * @param feedback The feedback that was given. -1 for negative feedback, 1 for positive feedback.
     */
    chatMessageFeedback: (chatId: string, messageId: string, feedback: -1 | 1) => {
      getClient()?.trackCBEvent?.('c:copilot_chat_message_feedback', {
        ...Track.copilot._getCommonProperties(chatId),
        messageId,
        feedback: feedback.toString(),
      });
    },

    /**
     * Fired whenever a suggested follow-up is clicked in Copilot.
     * @param copilot The current state of Copilot when the follow-up was clicked.
     */
    followUpClicked: (chatId: string, followUp: string) => {
      getClient()?.trackCBEvent?.('c:copilot_follow_up_clicked', {
        ...Track.copilot._getCommonProperties(chatId),
        followUp,
      });
    },

    /**
     * Fired whenever a source is clicked in a Copilot message.
     * @param copilot The current state of Copilot when the source was clicked.
     */
    sourceClicked: (chatId: string, messageId: string, href: string) => {
      getClient()?.trackCBEvent?.('c:copilot_source_clicked', {
        ...Track.copilot._getCommonProperties(chatId),
        messageId,
        href,
      });
    },

    /**
     * Fired whenever a link is clicked in a Copilot message.
     * @param copilot The current state of Copilot when the link was clicked.
     */
    linkClicked: (chatId: string, messageId: string, href: string) => {
      getClient()?.trackCBEvent?.('c:copilot_link_clicked', {
        ...Track.copilot._getCommonProperties(chatId),
        messageId,
        href,
      });
    },

    /**
     * Fired whenever an experience suggestion is clicked in Copilot.
     * @param copilot The current state of Copilot when the message was scrolled.
     */
    experienceClicked: (chatId: string, experience: any) => {
      getClient()?.trackCBEvent?.('c:copilot_experience_clicked', {
        ...Track.copilot._getCommonProperties(chatId),
        experience,
      });
    },
  },

  helphub: {
    /**
     * Fired whenever HelpHub is viewed.
     * @param source The source of the event.
     * @returns void
     */
    viewed: (source?: CBEventSource) => {
      getClient()?.trackCBEvent?.(
        'c:helphub_viewed',
        {},
        {
          source,
        },
      );
    },

    /**
     * Fired whenever input is typed into the search bar in HelpHub. These are debounced to once every 500ms.
     * @returns void
     */
    searchInput: (inputText: string) => {
      getClient()?.trackCBEvent?.('c:helphub_search_input', {
        inputText,
      });
    },

    /**
     * Fired whenever a search is executed in HelpHub.
     * @returns void
     */
    searchExecuted: (inputText: string) => {
      getClient()?.trackCBEvent?.('c:helphub_search_executed', {
        inputText,
      });
    },

    /**
     * Fired whenever a search result is clicked in HelpHub.
     * @param target The search result that was clicked.
     * @returns void
     */
    searchResultClicked: (result: HelpHubDoc | IExperienceHitType) => {
      getClient()?.trackCBEvent?.('c:helphub_search_result_clicked', {
        result: serializeObject(result),
      });
    },

    /**
     * Fired whenever a suggested query is clicked in HelpHub.
     * @param query The suggested query that was clicked.
     * @returns void
     */
    suggestedQueryClicked: (query: string) => {
      getClient()?.trackCBEvent?.('c:helphub_suggested_query_clicked', {
        query,
      });
    },

    /**
     * Fired whenever the link to a document in HelpHub is copied.
     * @param href The URL of the document that was copied.
     * @returns void
     */
    docLinkCopied: (href: string) => {
      getClient()?.trackCBEvent?.('c:helphub_doc_link_copied', {
        href,
      });
    },

    /**
     * Fired whenever an item in a recommendation set is clicked in HelpHub.
     * @param recommendation The recommendation that was clicked.
     */
    recommendationClicked: (recommendation: Action) => {
      getClient()?.trackCBEvent?.('c:helphub_recommendation_clicked', {
        recommendation: serializeObject(recommendation),
      });
    },

    /**
     * Fired whenever an item in the additional resources section is clicked in HelpHub.
     * @param resource The additional resource that was clicked.
     */
    additionalResourceClicked: (resource: Action) => {
      getClient()?.trackCBEvent?.('c:helphub_additional_resource_clicked', {
        resource: serializeObject(resource),
      });
    },
  },

  content: {
    _getCommonProperties: (helpHubState: CBStore['helpHub']) => {
      return {
        docType: helpHubState.hubDoc?.type,
        docId: helpHubState.hubDoc?.command.id,
      };
    },

    /**
     * Fired whenever a piece of content is viewed. As of right now, this is only used for HelpHub.
     * @param helpHubState The current state of HelpHub when the content was viewed. This includes the current document.
     * @param source The source of where the document was opened from - if applicable.
     */
    viewed: (helpHubState: CBStore['helpHub'], source?: CBEventSource) => {
      getClient()?.trackCBEvent?.('c:content_viewed', Track.content._getCommonProperties(helpHubState), {
        source,
      });
    },

    /**
     * Fired whenever a piece of content is closed or navigated away from. As of right now, this is only used for HelpHub.
     * @param helpHubState The current state of HelpHub when the content was closed. This includes the current document.
     */
    closed: (helpHubState: CBStore['helpHub']) => {
      getClient()?.trackCBEvent?.('c:content_closed', Track.content._getCommonProperties(helpHubState));
    },

    /**
     * Fired whenever a link in a piece of content is clicked.
     * @param helpHubState The current state of HelpHub when the link was clicked. This includes the current document.
     */
    linkClicked: (helpHubState: CBStore['helpHub'], href: string) => {
      getClient()?.trackCBEvent?.('c:content_link_clicked', {
        ...Track.content._getCommonProperties(helpHubState),
        href,
      });
    },

    /**
     * Fired whenever a piece of content is scrolled.
     * @param helpHubState The current state of HelpHub when the content was scrolled. This includes the current document.
     */
    scrolled: (helpHubState: CBStore['helpHub']) => {
      getClient()?.trackCBEvent?.('c:content_scrolled', Track.content._getCommonProperties(helpHubState));
    },

    /**
     * Fired whenever the play button of a video is clicked.
     * @param helpHubState The current state of HelpHub when the play button was clicked. This includes the current document.
     */
    playClicked: (helpHubState: CBStore['helpHub']) => {
      getClient()?.trackCBEvent?.('c:content_play_clicked', Track.content._getCommonProperties(helpHubState));
    },

    /**
     * Fired whenever the pause button of a video is clicked.
     * @param helpHubState The current state of HelpHub when the pause button was clicked. This includes the current document.
     * @todo Instrument this event
     */
    pauseClicked: (helpHubState: CBStore['helpHub']) => {
      getClient()?.trackCBEvent?.('c:content_pause_clicked', Track.content._getCommonProperties(helpHubState));
    },
  },
};
