/** @jsx jsx  */
import React, { useState, useEffect, useCallback } from 'react';
import { css, jsx } from '@emotion/core';
import { IThemeV2Type } from '@cb/types/entities/theme';
import Dialog from 'rc-dialog';
import StyledSecondaryIconButton from '../shared/StyledSecondaryIconButton';
import { XClose } from '@commandbar/design-system/icons/react';
import Z from '@commandbar/internal/client/Z';
import { isMobileDevice } from '@commandbar/internal/util/operatingSystem';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode'; generatedCSSClassname: string };
  src: string;
  visible: boolean;
  onClose: () => void;
};

const StyledImageDialog = ({ themeV2, src, visible, onClose }: Props) => {
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });

  const updateContainerDimensions = useCallback(() => {
    const padding = isMobileDevice() ? 56 : 80;
    setContainerDimensions({
      width: window.innerWidth - padding,
      height: window.innerHeight - padding,
    });
  }, []);

  useEffect(() => {
    updateContainerDimensions();
    window.addEventListener('resize', updateContainerDimensions);
    return () => window.removeEventListener('resize', updateContainerDimensions);
  }, [updateContainerDimensions]);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height });
    };
    img.src = src;
  }, [src]);

  const calculateDimensions = () => {
    const aspectRatio = imageDimensions.width / imageDimensions.height;
    let width = imageDimensions.width;
    let height = imageDimensions.height;

    if (width > containerDimensions.width) {
      width = containerDimensions.width;
      height = width / aspectRatio;
    }

    if (height > containerDimensions.height) {
      height = containerDimensions.height;
      width = height * aspectRatio;
    }

    return { width, height };
  };

  const { width, height } = calculateDimensions();

  return (
    <Dialog
      visible={visible}
      onClose={onClose}
      aria-modal="true"
      wrapClassName={`commandbar-nudge-modal ${themeV2.generatedCSSClassname}`}
      bodyStyle={{
        background: 'var(--background-primary)',
        borderRadius: '0px',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      style={{
        background: 'var(--background-primary)',
        border: 'none',
        borderRadius: '0px',
        margin: 'var(--layout-padding)',
        width: `${width}px`,
        height: `${height}px`,
        maxWidth: '100%',
        maxHeight: '100%',
      }}
      maskStyle={{ zIndex: Z.Z_MASK }}
      css={css`
        .rc-dialog-close {
          opacity: 1;
        }
        .rc-dialog-content {
          background: var(--background-primary);
          border-radius: 0;
          height: 100%;
        }
      `}
      closeIcon={
        <StyledSecondaryIconButton
          style={{
            position: 'fixed',
            top: 'var(--layout-padding)',
            right: 'var(--layout-padding)',
            color: 'var(--content-primary)',
          }}
          themeV2={themeV2}
          onClick={onClose}
        >
          <XClose />
        </StyledSecondaryIconButton>
      }
    >
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }}
        src={src}
        alt={src}
      />
    </Dialog>
  );
};

export default StyledImageDialog;
