import styled from '@emotion/styled';
import type { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import React, { RefCallback } from 'react';

import {
  CmdButton,
  CmdButtonTabs,
  CmdDivider,
  CmdDropdown,
  CmdLabel,
  CmdSwitch,
  CmdTooltip,
  cmdToast,
} from '@commandbar/design-system/cmd';
import { CB_COLORS, Dropdown, Select, Tooltip, type UploadProps } from '@commandbar/design-system/components/antd';
import {
  ArrowUpRight,
  BookClosed,
  HelpCircle,
  HelpSquare,
  ImagePlus,
  InfoCircle,
  Lightbulb04,
  Lightning01,
} from '@commandbar/design-system/icons/react';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import type {
  ElementInformation,
  INudgePinStepType,
  INudgeStepType,
  INudgeTooltipStepType,
  IOrganizationType,
} from '@commandbar/internal/middleware/types';
import { SvgThumbnail, Thumbnail } from 'editor/src/editor/components/DragUpload/ImageUploader';
import { OverlaySettingsSection, OverlaySettingsVerticalSection } from 'editor/src/editor/components/styled';
import { useAppContext } from 'editor/src/AppStateContext';
import { TargetElementClickRecorder } from '../../../components/TargetElementClickRecorder';
import { CaretDown } from '../../../helphub/shared';
import { OverlayContainer } from '../../styled';
import { DropdownRow, DropdownRowLabel } from '../../../styled';
import { NudgeDropdown } from '../NudgeDropdown';
import { FormSection, SettingsDropdown } from './PositionInputConfig';
import { PositionOffsetForm } from './PositionInputConfig/PositionOffsetForm';
import { TextInput } from './PositionInputConfig/TextInput';
import * as S from './PositionInputConfig/styles';
import {
  BANNER_POSITION_LABEL,
  BannerFormFactorSelectInput,
  BannerPosition,
  FORM_FACTOR_INPUT_META,
  FormFactorSelectInput,
  FormFactorType,
  POSITION_LABEL,
  PopoverPosition,
  TNudgeOffset,
} from './metadata';

import { ReactComponent as RecordSelectorSvg } from '../../../../img/record_selector.svg';
import { ExperienceState } from '@commandbar/internal/client/extension/shared';
import { DESIGN_ROUTE, THEME_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { PlacementPreview } from '../PlacementPreview';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
`;

const StyledButton = styled.button<{ isSelected: boolean }>`
  pointer-events: auto;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #fff;
  color: #0d0e10;
  border: ${({ isSelected }) => (isSelected ? '1px solid transparent' : '1px solid rgba(0, 0, 0, 0.16)')};
  box-shadow: ${({ isSelected }) =>
    isSelected
      ? '0px -1px 3px 0px rgba(0, 0, 0, 0.08) inset, 0px -1px 1px 0px rgba(0, 0, 0, 0.03) inset, 0px 0px 0px 3px rgba(39, 84, 238, 0.16), 0px 0px 0px 1px #2754EE inset'
      : '0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)'};
  transition: border, box-shadow 300ms ease;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${CB_COLORS.neutral500};

  &:hover {
    color: ${CB_COLORS.neutral1000};
  }
`;

const ButtonContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ disabled }) => (disabled ? '#d9d9d9' : 'rgba(10, 10, 15, 0.24)')};
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : CB_COLORS.white)};
`;

const CaretContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  padding: 6px;
  border-radius: 0px 4px 4px 0px;
  border: none;
  border-left: 1px solid ${CB_COLORS.neutral100};
  color: ${CB_COLORS.neutral500};

  &:hover {
    color: ${CB_COLORS.neutral1000};
  }
`;

const OverlayGroupLabel = styled.span`
  margin-bottom: 11px;

  color: #797c85;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

interface IPositionInput {
  type: FormFactorType;
  onChange: (position: PopoverPosition) => void;
  onFormFactorBannerPositionChange: (position: BannerPosition) => void;
  positionValue: PopoverPosition | BannerPosition;
  onAnchorChange: (anchors: ElementInformation) => void;
  anchorValue: ElementInformation;
  onToggleIsOpenByDefault: () => void;
  isOpenByDefault: boolean;
  onToggleIsShowingMask: () => void;
  isShowingMask: boolean;
  onOffsetChange: (offset: TNudgeOffset) => void;
  offsetValue: TNudgeOffset;
  onAdvanceTriggerChange: (anchors: string) => void;
  advanceTriggerValue: string;
  marker: INudgeTooltipStepType['form_factor']['marker'];
  onMarkerChange: (marker: INudgeTooltipStepType['form_factor']['marker']) => void;
  showOn: INudgeTooltipStepType['form_factor']['show_on'];
  onShowOnChange: (showOn: INudgeTooltipStepType['form_factor']['show_on']) => void;
  markerPosition: INudgeTooltipStepType['form_factor']['marker']['positioning']['position'];
  onMarkerPositionChange: (
    markerPosition: INudgeTooltipStepType['form_factor']['marker']['positioning']['position'],
  ) => void;
  pinPosition: INudgePinStepType['form_factor']['position'];
  onPinPositionChange: (pinPosition: INudgePinStepType['form_factor']['position']) => void;
  pinAlignment: NonNullable<INudgePinStepType['form_factor']['alignment']>;
  onPinAlignmentChange: (pinPosition: NonNullable<INudgePinStepType['form_factor']['alignment']>) => void;
  onStartClickRecorder?: (type: ExperienceState['field'], skipPrompt?: boolean) => void;
  recorderDisabled: boolean;
  layout: NonNullable<INudgeStepType['form_factor']['layout']>;
  onLayoutChange: (layout: NonNullable<INudgeStepType['form_factor']['layout']>) => void;
  closeOnClickOutside?: boolean;
  toggleCloseOnClickOutside: () => void;
  disableLayoutSelection: boolean;
  placement: (INudgeStepType & { form_factor: { type: 'banner' } })['form_factor']['placement'] | undefined;
  onPlacementChange: (
    placement: (INudgeStepType & { form_factor: { type: 'banner' } })['form_factor']['placement'],
  ) => void;
}

export const FormFactorPositionInput = ({
  type,
  onChange,
  positionValue,
  anchorValue,
  onAnchorChange,
  isOpenByDefault,
  onToggleIsOpenByDefault,
  isShowingMask,
  onToggleIsShowingMask,
  onOffsetChange,
  offsetValue,
  onAdvanceTriggerChange,
  advanceTriggerValue,
  showOn,
  onShowOnChange,
  marker,
  onMarkerChange,
  markerPosition,
  onMarkerPositionChange,
  pinPosition,
  onPinPositionChange,
  pinAlignment,
  onPinAlignmentChange,
  onStartClickRecorder,
  recorderDisabled,
  layout,
  onLayoutChange,
  closeOnClickOutside,
  toggleCloseOnClickOutside,
  disableLayoutSelection,
  placement,
  onPlacementChange,
  onFormFactorBannerPositionChange,
}: IPositionInput) => {
  const metadata = FORM_FACTOR_INPUT_META[type];
  const { flags } = useAppContext();

  if (metadata.type === 'select') {
    return (
      <>
        {type === 'banner' ? (
          <NudgeDropdown
            onChange={onFormFactorBannerPositionChange}
            value={positionValue as BannerPosition}
            options={[metadata.options as BannerFormFactorSelectInput['options']]}
            placement="bottomRight"
            gap="8px"
          >
            <div style={{ display: 'flex', gap: '6px' }}>
              <IconContainer>
                <RecordSelectorSvg width={12} />
              </IconContainer>
              {BANNER_POSITION_LABEL[positionValue as BannerPosition]}
            </div>
          </NudgeDropdown>
        ) : (
          <React.Fragment>
            <NudgeDropdown
              onChange={onChange}
              value={positionValue as PopoverPosition}
              options={[metadata.options as FormFactorSelectInput['options']]}
              placement="bottomRight"
              gap="8px"
            >
              <div style={{ display: 'flex', gap: '6px' }}>
                <IconContainer>
                  <RecordSelectorSvg width={12} />
                </IconContainer>
                {POSITION_LABEL[positionValue as PopoverPosition]}
              </div>
            </NudgeDropdown>

            {flags['release-themes-v2'] && (
              <SettingsDropdown
                dropdownOverlay={
                  <OverlaySettingsContainer>
                    <OverlaySettingsSection
                      style={{ flexDirection: 'column' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <SelectSettingsConfig
                        layout={layout}
                        onLayoutChange={onLayoutChange}
                        disabled={disableLayoutSelection}
                      />
                      {type === 'modal' && (
                        <DropdownRow style={{ padding: 0, marginTop: 12 }}>
                          <DropdownRowLabel style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                            Click outside to close
                            <CmdTooltip message="Dismiss the modal when the user clicks the mask overlay.">
                              <div className="flex">
                                <InfoCircle />
                              </div>
                            </CmdTooltip>
                          </DropdownRowLabel>
                          <CmdSwitch checked={closeOnClickOutside} onCheckedChange={toggleCloseOnClickOutside} />
                        </DropdownRow>
                      )}
                    </OverlaySettingsSection>
                  </OverlaySettingsContainer>
                }
              />
            )}
          </React.Fragment>
        )}

        {type === 'banner' && (
          <SettingsDropdown
            dropdownOverlay={<BannerPositionConfig placement={placement} onPlacementChange={onPlacementChange} />}
          />
        )}
      </>
    );
  }

  return (
    <Container className="gap-xs">
      <ButtonContainer>
        <TargetElementClickRecorder
          value={anchorValue}
          onValueChange={(selectors) => onAnchorChange(selectors)}
          onStartClickRecorder={onStartClickRecorder?.bind(this, 'form_factor.anchor')}
          recorderDisabled={recorderDisabled}
        />

        <Dropdown
          trigger={['click']}
          placement="bottomRight"
          overlay={
            <OverlayContainer width="250px">
              <FormSection
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <S.Col style={{ gap: '4px' }}>
                  <S.SectionHeader>CSS or XPath selector</S.SectionHeader>
                  <TextInput
                    textArea={true}
                    placeholder="CSS or XPath Selector..."
                    value={anchorValue.selector}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onAnchorChange({ ...anchorValue, selector: e.target.value });
                    }}
                  />
                </S.Col>
              </FormSection>

              <React.Fragment>
                <FormSection
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <S.Col style={{ gap: '4px' }}>
                    <S.SectionHeader>
                      Text
                      <Tooltip
                        content={`CommandBar will try to find the element by this text in case the selector above doesn't yield a result.`}
                      >
                        <InfoCircle width={16} height={16} />
                      </Tooltip>
                    </S.SectionHeader>
                    <TextInput
                      textArea={false}
                      placeholder="Element text..."
                      value={anchorValue.text}
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onAnchorChange({ ...anchorValue, text: e.target.value });
                      }}
                    />
                  </S.Col>
                </FormSection>

                <CmdDivider spacing="md" />
              </React.Fragment>

              {type === 'tooltip' && (
                <FormSection
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <S.Col>
                    <S.SectionHeader>Marker position</S.SectionHeader>
                    <Select
                      value={markerPosition}
                      onChange={onMarkerPositionChange}
                      options={[
                        {
                          label: 'Left',
                          value: 'left',
                        },
                        {
                          label: 'Right',
                          value: 'right',
                        },
                        ...(flags['enable-inline-tooltip-options']
                          ? [
                              {
                                label: 'Inline left',
                                value: 'inline_left',
                              },
                              {
                                label: 'Inline right',
                                value: 'inline_right',
                              },
                            ]
                          : []),
                      ]}
                    />
                  </S.Col>
                </FormSection>
              )}

              {type === 'pin' && (
                <FormSection
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <S.Col style={{ gap: '4px' }}>
                    <S.SectionHeader>Position</S.SectionHeader>
                    <Select
                      value={pinPosition ?? 'auto'}
                      onChange={onPinPositionChange}
                      options={[
                        {
                          label: 'Auto',
                          value: 'auto',
                        },
                        {
                          label: 'Top of target',
                          value: 'top',
                        },
                        {
                          label: 'Bottom of target',
                          value: 'bottom',
                        },
                        {
                          label: 'Left of target',
                          value: 'left',
                        },
                        {
                          label: 'Right of target',
                          value: 'right',
                        },
                      ]}
                    />

                    {pinPosition !== 'auto' && (
                      <>
                        <S.SectionHeader style={{ marginTop: '8px' }}>Alignment</S.SectionHeader>
                        <CmdButtonTabs
                          activeKey={pinAlignment}
                          onChange={(e) => onPinAlignmentChange(e as typeof pinAlignment)}
                          tabs={[
                            pinPosition === 'left' || pinPosition === 'right'
                              ? { label: 'Top', key: 'top' }
                              : { label: 'Left', key: 'left' },
                            { label: 'Center', key: 'center' },
                            pinPosition === 'left' || pinPosition === 'right'
                              ? { label: 'Bottom', key: 'bottom' }
                              : { label: 'Right', key: 'right' },
                          ]}
                          className="w-full"
                        />
                        {pinPosition && <PlacementPreview position={pinPosition} alignment={pinAlignment} />}
                      </>
                    )}
                  </S.Col>
                </FormSection>
              )}

              <FormSection>
                <PositionOffsetForm x={offsetValue.x} y={offsetValue.y} onChange={onOffsetChange} type={type} />
              </FormSection>
            </OverlayContainer>
          }
        >
          <CaretContainer onClick={(e) => e.stopPropagation()}>
            <CaretDown />
          </CaretContainer>
        </Dropdown>
      </ButtonContainer>

      <SettingsDropdown
        dropdownOverlay={
          type === 'pin' ? (
            <PinPositionConfig
              isOpenByDefault={isOpenByDefault}
              onToggleIsOpenByDefault={onToggleIsOpenByDefault}
              isShowingMask={isShowingMask}
              onToggleIsShowingMask={onToggleIsShowingMask}
              onAdvanceTriggerChange={onAdvanceTriggerChange}
              advanceTriggerValue={advanceTriggerValue}
              onStartClickRecorder={onStartClickRecorder?.bind(this, 'form_factor.advance_trigger')}
              layout={layout}
              onLayoutChange={onLayoutChange}
              disableLayoutSelection={disableLayoutSelection}
            />
          ) : (
            <TooltipPositionConfig
              showOn={showOn}
              onShowOnChange={onShowOnChange}
              marker={marker}
              onMarkerChange={onMarkerChange}
              layout={layout}
              onLayoutChange={onLayoutChange}
              disableLayoutSelection={disableLayoutSelection}
            />
          )
        }
      />
    </Container>
  );
};

const CustomOverlayContainer = styled(OverlayContainer)`
  width: 230px;
`;

const OverlaySettingsContainer = styled(OverlayContainer)`
  width: 256px;
`;

const TargetSelectorButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${({ disabled }) => (disabled ? '#d9d9d9' : 'rgba(10, 10, 15, 0.24)')};
  background: ${({ disabled }) => (disabled ? '#f5f5f5' : CB_COLORS.white)};
`;

const InlineLabel = styled.div`
  display: flex;
  align-items: center;

  div {
    transform: translate(6px, 2px);
    opacity: 0.5;
  }
`;

interface PinPositionConfigProps {
  onToggleIsOpenByDefault: () => void;
  isOpenByDefault: boolean;
  onToggleIsShowingMask: () => void;
  isShowingMask: boolean;
  onAdvanceTriggerChange: (anchors: string) => void;
  advanceTriggerValue: string;
  onStartClickRecorder?: (skipPrompt?: boolean) => void;
  layout: NonNullable<INudgeStepType['form_factor']['layout']>;
  onLayoutChange: (layout: NonNullable<INudgeStepType['form_factor']['layout']>) => void;
  disableLayoutSelection?: boolean;
}

const PinPositionConfig = ({
  onToggleIsOpenByDefault,
  isOpenByDefault,
  onToggleIsShowingMask,
  isShowingMask,
  onAdvanceTriggerChange,
  advanceTriggerValue,
  onStartClickRecorder,
  layout,
  onLayoutChange,
  disableLayoutSelection = false,
}: PinPositionConfigProps) => {
  const [isUsingAdvanceTrigger, setIsUsingAdvanceTrigger] = React.useState(!!advanceTriggerValue);
  const { flags } = useAppContext();

  return (
    <CustomOverlayContainer style={{ gap: 0 }}>
      <OverlaySettingsSection
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onToggleIsOpenByDefault();
        }}
      >
        Open by default
        <CmdSwitch checked={isOpenByDefault} onCheckedChange={onToggleIsOpenByDefault} />
      </OverlaySettingsSection>

      <OverlaySettingsSection
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onToggleIsShowingMask();
        }}
      >
        Show mask
        <CmdSwitch checked={isShowingMask} onCheckedChange={onToggleIsShowingMask} />
      </OverlaySettingsSection>

      {flags['release-themes-v2'] && (
        <>
          <CmdDivider spacing="md" />

          <OverlaySettingsSection
            style={{ flexDirection: 'column' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <SelectSettingsConfig layout={layout} onLayoutChange={onLayoutChange} disabled={disableLayoutSelection} />
          </OverlaySettingsSection>
        </>
      )}

      <CmdDivider spacing="md" />

      <OverlaySettingsSection
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isUsingAdvanceTrigger) {
            onAdvanceTriggerChange('');
          }
          setIsUsingAdvanceTrigger((prev) => !prev);
        }}
      >
        <InlineLabel>
          Advance trigger
          <Tooltip content={'Advance the tour when a specific element is clicked'}>
            <InfoCircle width={16} height={16} />
          </Tooltip>
        </InlineLabel>
        <CmdSwitch
          checked={isUsingAdvanceTrigger}
          onCheckedChange={(prev) => {
            if (isUsingAdvanceTrigger) {
              onAdvanceTriggerChange('');
            }
            setIsUsingAdvanceTrigger(() => !prev);
          }}
        />
      </OverlaySettingsSection>

      {isUsingAdvanceTrigger && (
        <OverlaySettingsVerticalSection>
          <TargetSelectorButton>
            <TargetElementClickRecorder
              value={{ selector: advanceTriggerValue, tag: '', attributes: {}, text: '' }}
              onValueChange={(elementInformation) => onAdvanceTriggerChange(elementInformation.selector)}
              onStartClickRecorder={onStartClickRecorder}
            />
          </TargetSelectorButton>
          <TextInput
            textArea={true}
            placeholder="CSS or XPath Selector..."
            value={advanceTriggerValue}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onAdvanceTriggerChange(e.target.value);
            }}
          />
        </OverlaySettingsVerticalSection>
      )}
    </CustomOverlayContainer>
  );
};

const StyledDragger = styled(Dragger)`
  &.ant-upload.ant-upload-drag .ant-upload-btn {
    padding: 0;
  }
`;

interface Props extends UploadProps {
  beforeUpload: (file: RcFile, FileList: RcFile[]) => boolean;
  supportedFilesHelperText?: string;
  loading?: boolean;
  source?: string;
}

const ImageUploader = (props: Props) => {
  const [isUploading, setIsUploading] = React.useState(!!props.loading);
  const passedValidation = React.useRef(false);

  React.useEffect(() => {
    setIsUploading(!!props.loading);
  }, [props.loading, setIsUploading]);

  const ref: RefCallback<HTMLDivElement> = React.useCallback((node) => {
    const firstChild = node?.firstElementChild;
    if (firstChild instanceof SVGElement) {
      firstChild.style.height = '32px';
      firstChild.style.width = '100%';
    }
  }, []);

  const renderThumbnail = () => {
    if (!props.source) return null;

    return props.source.startsWith('<svg') ? (
      <SvgThumbnail ref={ref} dangerouslySetInnerHTML={{ __html: props.source }} />
    ) : (
      <Thumbnail height="32px" src={props.source} />
    );
  };

  return (
    <div style={{ display: props?.source ? 'flex' : 'initial', gap: 4 }}>
      {renderThumbnail()}
      <StyledDragger
        style={{
          width: '100%',
          border: 'none',
        }}
        {...props}
        beforeUpload={(...args) => {
          if (props.beforeUpload) {
            passedValidation.current = !!props.beforeUpload(...args);

            if (passedValidation.current) {
              setIsUploading(true);
            }
          }
        }}
        onChange={(info) => {
          if (passedValidation.current) {
            if (props.onChange) {
              props.onChange(info);
            }
            if (info.file.status === 'done' || info.file.status === 'error') {
              setIsUploading(false);
            }
          }
        }}
        showUploadList={false}
      >
        <CmdButton style={{ width: '100%', minWidth: '150px' }} icon={<ImagePlus />} loading={isUploading}>
          {`${props?.source ? 'Replace' : 'Upload'} Image`}
        </CmdButton>
      </StyledDragger>
    </div>
  );
};

type IconName = (INudgeTooltipStepType & {
  form_factor: { marker: { type: 'icon' } };
})['form_factor']['marker']['icon'];

const ICONS: Array<{
  name: IconName;
  icon: JSX.Element;
}> = [
  {
    name: 'helpCircle',
    icon: <HelpCircle />,
  },
  {
    name: 'helpSquare',
    icon: <HelpSquare />,
  },
  {
    name: 'infoCircle',
    icon: <InfoCircle />,
  },
  {
    name: 'bookClosed',
    icon: <BookClosed />,
  },
  {
    name: 'lightBulb',
    icon: <Lightbulb04 />,
  },
  {
    name: 'lightning',
    icon: <Lightning01 />,
  },
];

type IconPickerProps<T = IconName> = {
  value: T;
  onChange: (value: T) => void;
};

const IconPicker = ({ value, onChange }: IconPickerProps) => (
  <Container>
    {ICONS.map(({ name, icon }) => (
      <StyledButton type="button" key={name} onClick={() => onChange(name)} isSelected={name === value}>
        {icon}
      </StyledButton>
    ))}
  </Container>
);

const Beacon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <circle opacity="0.1" cx="16" cy="16" r="13" fill="#7856FF" />
    <circle cx="16" cy="16" r="8" fill="#7856FF" />
  </svg>
);

const renderMarkerValueInput = (
  organizationId: IOrganizationType['id'],
  marker: (INudgeStepType & { form_factor: { type: 'tooltip' } })['form_factor']['marker'],
  onMarkerChange: (marker: (INudgeStepType & { form_factor: { type: 'tooltip' } })['form_factor']['marker']) => void,
  useThemesV2: boolean,
) => {
  switch (marker.type) {
    case 'beacon': {
      return (
        <div style={{ display: 'flex', gap: 4 }}>
          <Beacon />
          <CmdButton
            onClick={() => {
              const url = `${process.env.REACT_APP_DASHBOARD_URL}/editor${useThemesV2 ? THEME_ROUTE : DESIGN_ROUTE}`;
              window.open(url, '_blank');
            }}
            fullWidth
          >
            Edit theme <ArrowUpRight />
          </CmdButton>
        </div>
      );
    }
    case 'image': {
      const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg+xml'];
      return (
        <ImageUploader
          name="image"
          source={marker.source}
          accept={validFileTypes.join(',')}
          action="/presigned_s3/"
          customRequest={(e) => {
            const file = e.file as RcFile;

            if (file.type === 'image/svg+xml') {
              const fileToUpload = new File([file], file.name);

              const reader = new FileReader();

              reader.onload = (progressEvent) => {
                try {
                  if (progressEvent.target !== null) {
                    const dataURL = progressEvent.target.result;

                    if (typeof dataURL === 'string') {
                      if (file.type === 'image/svg+xml' && !dataURL.startsWith('<svg')) {
                        throw new Error('Please add a file that begins with <svg');
                      }

                      if (e.onSuccess) e.onSuccess({ ...file, url: dataURL });
                    }
                  }
                } catch (err) {
                  cmdToast.error('Invalid svg file');
                  if (e.onError) e.onError(err as any);
                }
              };

              reader.readAsText(fileToUpload);
            } else {
              axiosInstance
                .post(e.action, {
                  key: file.uid,
                })
                .then(({ data }) => {
                  const toUpload = new File([file], `${organizationId}/${file.uid}`);

                  fetch(data.presigned_url, {
                    method: 'put',
                    body: toUpload,
                  })
                    .then((response) => response)
                    .then((response) => {
                      if (response.ok && e.onSuccess) {
                        e.onSuccess({ ...file, url: data.object_url });
                      } else if (e.onError) {
                        e.onError(response as any);
                      }
                    })
                    .catch((err) => {
                      if (e.onError) {
                        e.onError(err);
                      }
                    });
                });
            }
          }}
          beforeUpload={(file) => {
            const isValidSize = file.size / 1024 / 1024 < 10;
            if (!isValidSize) {
              cmdToast.error('Image must smaller than 10MB.');
            }

            const isValidType = validFileTypes.includes(file.type);
            if (!isValidType) {
              cmdToast.error('Image must jpeg, png, svg, or gif.');
            }

            return isValidSize && isValidType;
          }}
          onChange={(e) => {
            const { status } = e.file;
            if (status === 'done') {
              onMarkerChange({
                ...marker,
                source: e.file.response.url,
              });
              cmdToast.success(`${e.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
              cmdToast.error(`${e.file.name} file upload failed.`);
            }
          }}
          maxCount={1}
          listType="picture"
          showUploadList={{
            showRemoveIcon: false,
          }}
          supportedFilesHelperText=".jpg, .png, .svg, or .gif supported"
        />
      );
    }
    case 'icon': {
      return (
        <IconPicker
          value={marker.icon}
          onChange={(value) =>
            onMarkerChange({
              ...marker,
              icon: value,
            })
          }
        />
      );
    }
  }
};

interface TooltipPositionConfigProps {
  showOn: (INudgeStepType & { form_factor: { type: 'tooltip' } })['form_factor']['show_on'];
  onShowOnChange: (showOn: (INudgeStepType & { form_factor: { type: 'tooltip' } })['form_factor']['show_on']) => void;
  onMarkerChange: (marker: (INudgeStepType & { form_factor: { type: 'tooltip' } })['form_factor']['marker']) => void;
  marker: (INudgeStepType & { form_factor: { type: 'tooltip' } })['form_factor']['marker'];
  layout: NonNullable<INudgeStepType['form_factor']['layout']>;
  onLayoutChange: (layout: NonNullable<INudgeStepType['form_factor']['layout']>) => void;
  disableLayoutSelection?: boolean;
}

const TooltipPositionConfig = ({
  showOn,
  onShowOnChange,
  marker,
  onMarkerChange,
  layout,
  onLayoutChange,
  disableLayoutSelection,
}: TooltipPositionConfigProps) => {
  const { organization, flags } = useAppContext();

  return (
    <OverlaySettingsContainer style={{ gap: 0 }}>
      {flags['release-themes-v2'] && (
        <>
          <OverlaySettingsSection
            style={{ flexDirection: 'column' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <SelectSettingsConfig layout={layout} onLayoutChange={onLayoutChange} disabled={disableLayoutSelection} />
          </OverlaySettingsSection>

          <CmdDivider spacing="md" />
        </>
      )}

      <OverlaySettingsSection
        style={{ flexDirection: 'column' }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        Marker
        <Select
          value={marker.type}
          onChange={(type) => {
            switch (type) {
              case 'beacon': {
                onMarkerChange({
                  ...marker,
                  type,
                });
                break;
              }
              case 'image': {
                onMarkerChange({
                  ...marker,
                  type,
                  source: '',
                });
                break;
              }
              case 'icon': {
                onMarkerChange({
                  ...marker,
                  type,
                  icon: 'infoCircle',
                });
                break;
              }
            }
          }}
          options={[
            {
              label: 'Beacon',
              value: 'beacon',
            },
            {
              label: 'Icon',
              value: 'icon',
            },
            {
              label: 'Image',
              value: 'image',
            },
          ]}
        />
      </OverlaySettingsSection>

      <OverlaySettingsSection
        style={{ flexDirection: 'column', paddingTop: 0 }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {renderMarkerValueInput(organization.id, marker, onMarkerChange, flags['release-themes-v2'])}
      </OverlaySettingsSection>

      <CmdDivider spacing="md" />

      <OverlaySettingsSection
        style={{ flexDirection: 'column' }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        Show on
        <Select
          options={[
            {
              label: 'Click',
              value: 'click',
            },
            {
              label: 'Hover',
              value: 'hover',
            },
          ]}
          value={showOn}
          onChange={onShowOnChange}
          defaultValue="hover"
        />
      </OverlaySettingsSection>
    </OverlaySettingsContainer>
  );
};

interface SelectSettingsConfigProps {
  layout: NonNullable<INudgeStepType['form_factor']['layout']>;
  onLayoutChange: (layout: NonNullable<INudgeStepType['form_factor']['layout']>) => void;
  disabled?: boolean;
}

const SelectSettingsConfig = ({ layout, onLayoutChange, disabled = false }: SelectSettingsConfigProps) => (
  <>
    <OverlayGroupLabel>Styling</OverlayGroupLabel>
    {disabled ? (
      <InlineLabel>
        Layout
        <Tooltip content="Layouts don’t support input blocks">
          <InfoCircle width={16} height={16} />
        </Tooltip>
      </InlineLabel>
    ) : (
      'Layout'
    )}
    <Select
      value={layout}
      onChange={onLayoutChange}
      options={[
        {
          label: 'Classic',
          value: 'classic',
        },
        {
          label: 'Horizontal split',
          value: 'horizontal',
        },
        {
          label: 'Vertical split',
          value: 'vertical',
        },
      ]}
      disabled={disabled}
    />
  </>
);

interface BannerPositionConfigProps {
  placement: (INudgeStepType & { form_factor: { type: 'banner' } })['form_factor']['placement'] | undefined;
  onPlacementChange: (
    placement: (INudgeStepType & { form_factor: { type: 'banner' } })['form_factor']['placement'],
  ) => void;
}

const BannerPositionConfig = ({ placement, onPlacementChange }: BannerPositionConfigProps) => {
  return (
    <OverlaySettingsContainer>
      <OverlaySettingsSection
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            width: '100%',
          }}
        >
          <CmdLabel tooltip="Position the banner inline with content (default), stick to top/bottom on scroll, or above content.">
            Placement
          </CmdLabel>
          <CmdDropdown.Menu>
            <CmdDropdown.SelectTrigger style={{ width: '100%' }}>{`${
              placement && placement[0].toUpperCase()
            }${placement?.slice(1)}`}</CmdDropdown.SelectTrigger>
            <CmdDropdown.Content style={{ width: '230px' }}>
              <CmdDropdown.RadioGroup
                onValueChange={(value) =>
                  onPlacementChange(
                    value as (INudgeStepType & { form_factor: { type: 'banner' } })['form_factor']['placement'],
                  )
                }
              >
                <CmdDropdown.RadioItem value="default">Default</CmdDropdown.RadioItem>
                <CmdDropdown.RadioItem value="sticky">Sticky</CmdDropdown.RadioItem>
                <CmdDropdown.RadioItem value="overlay">Overlay</CmdDropdown.RadioItem>
              </CmdDropdown.RadioGroup>
            </CmdDropdown.Content>
          </CmdDropdown.Menu>
        </div>
      </OverlaySettingsSection>
    </OverlaySettingsContainer>
  );
};
