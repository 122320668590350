import { Modal, StatusBadge } from '@commandbar/design-system/components/antd';
import type { LabeledAction } from '@cb/types/entities/command/actions';
import {
  DetailPreviewObjectType,
  IEditorCommandType,
  IEditorCommandTypeLite,
  IKeyword,
} from '@commandbar/internal/middleware/types';
import ContentSettingsDropdown from './ContentSettingsDropdown';
import { CmdButton, CmdLabel } from '@commandbar/design-system/cmd';
import { Trash04 } from '@commandbar/design-system/icons/react';
import { useReportEvent } from 'commandbar.com/src/hooks/useEventReporting';
import { ACTIONS_ROUTE } from '@commandbar/internal/proxy-editor/editor_routes';
import { useAppContext } from 'editor/src/AppStateContext';
import { getDaysSinceDate } from '@commandbar/internal/util/time';
import KeywordSettingsDropdown from './KeywordSettingsDropdown';

export const StatusRenderer = (is_live: boolean) => (
  <span style={{ display: 'flex', alignItems: 'center', minWidth: 45 }}>
    {is_live ? (
      <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="green" text="Published" />
    ) : (
      <StatusBadge style={{ display: 'flex', alignItems: 'center' }} color="orange" text="Unpublished" />
    )}
  </span>
);

export function iscommand(
  supposedCommand?: IEditorCommandTypeLite | IKeyword,
): supposedCommand is IEditorCommandTypeLite {
  return !!supposedCommand && 'text' in supposedCommand;
}

export function isKeyword(
  supposedKeyword: IEditorCommandType | IEditorCommandTypeLite | IKeyword | undefined,
): supposedKeyword is IKeyword {
  return !!supposedKeyword && 'keyword' in supposedKeyword;
}

export const LastUpdateRenderer = (
  modifiedDate: string,
  entry?: IEditorCommandTypeLite | IKeyword,
  openEditDrawer?: (id: number) => void,
) => {
  const daysSinceLastSync = getDaysSinceDate(modifiedDate);

  if (iscommand(entry)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{daysSinceLastSync ? `${daysSinceLastSync}d ago` : 'Today'}</span>
        {entry && openEditDrawer && (
          <div onClick={(e) => e.stopPropagation()}>
            <ContentSettingsDropdown command={entry} openEditDrawer={openEditDrawer} />
          </div>
        )}
      </div>
    );
  } else if (isKeyword(entry)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{daysSinceLastSync ? `${daysSinceLastSync}d ago` : 'Today'}</span>
        {entry && openEditDrawer && (
          <div onClick={(e) => e.stopPropagation()}>
            <KeywordSettingsDropdown keyword={entry} openEditDrawer={openEditDrawer} />
          </div>
        )}
      </div>
    );
  } else {
    return <span>{daysSinceLastSync ? `${daysSinceLastSync}d ago` : 'Today'}</span>;
  }

  return null;
};

export const DrawerFooterDeleteButton = ({
  onDelete,
  activeCommand,
  disabled,
}: {
  onDelete: () => void;
  activeCommand: IEditorCommandType;
  disabled?: boolean;
}) => {
  const { reportEvent } = useReportEvent();
  const {
    dispatch: { commands },
  } = useAppContext();

  return (
    <CmdButton
      disabled={disabled}
      variant="ghost"
      icon={<Trash04 />}
      onClick={() => {
        Modal.confirm({
          title: 'This will permanently delete this document. Are you sure you want to proceed?',
          onOk: async () => {
            await commands.delete(activeCommand);
            reportEvent('content deleted', {
              segment: true,
              highlight: true,
              slack: true,
              payloadMessage: `${activeCommand.id}`,
              eventProps: {
                id: activeCommand.id,
              },
            });
            onDelete();
          },
          cancelText: 'Cancel',
        });
      }}
    ></CmdButton>
  );
};

export const LegacyOptions = ({ id }: { id: number }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 12px',
        background: '#F3F4F5',
        borderRadius: '8px',
      }}
    >
      <CmdLabel tooltip="Edit legacy options that you have already set.">Legacy Options</CmdLabel>
      <CmdButton to={`/editor${ACTIONS_ROUTE}/${id}`}>View/Edit</CmdButton>
    </div>
  );
};

export const hasLegacyValue = (command: IEditorCommandType) => {
  const default_availability_expression =
    command.availability_expression.type === 'LITERAL' && command.availability_expression.value === true;
  const default_recommend_expression =
    command.recommend_expression.type === 'LITERAL' && command.recommend_expression.value === false;
  const default_show_preview = command.show_preview === false;
  const default_arguments = !Object.keys(command.arguments).includes('__html__');
  return !(
    default_availability_expression &&
    default_recommend_expression &&
    default_show_preview &&
    default_arguments
  );
};

export function isContentObject(
  o: string | DetailPreviewObjectType | DetailPreviewObjectType[] | (string | DetailPreviewObjectType)[],
): o is DetailPreviewObjectType[] {
  if (typeof o === 'string' || !Array.isArray(o) || !o[0] || typeof o[0] !== 'object') return false;
  return 'value' in o[0];
}

export function isLabeledAction(o: string | number | LabeledAction | undefined): o is LabeledAction {
  if (!o || typeof o === 'number' || typeof o === 'string') return false;
  return 'action' in o;
}
