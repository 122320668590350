import React from 'react';
import { CmdButton } from '../../button';
import { CmdTypography } from '../../typography';
import { RefreshCw01, EmptyGraph } from '@commandbar/design-system/icons/react';

interface CmdTableNoResultCtaProps {
  onClick: () => void;
  ctaText?: string;
  children?: ((defaultAction: React.ReactElement) => React.ReactElement) | React.ReactElement;
}

export const CmdTableNoResultCta = ({ children, onClick, ctaText }: CmdTableNoResultCtaProps): React.ReactElement => {
  const defaultAction = (
    <CmdButton icon={<RefreshCw01 />} onClick={onClick}>
      {ctaText || 'Retry'}
    </CmdButton>
  );

  return (
    <>
      <EmptyGraph height={43} width={59} />
      <span className="flex flex-col gap-sm">
        <CmdTypography.Body fontWeight="semi-bold">No data available yet</CmdTypography.Body>
        <span className="items-center">
          <CmdTypography.Body variant="secondary" fontWeight="medium">
            There is no data available yet to show here.
          </CmdTypography.Body>
          <CmdTypography.Body variant="secondary" fontWeight="medium">
            If this is a mistake, please{' '}
            <a className="text-blue800" href="mailto:support@command.ai">
              contact us.
            </a>
          </CmdTypography.Body>
        </span>
      </span>

      {typeof children === 'function' ? children(defaultAction) : children ?? defaultAction}
    </>
  );
};
