import * as t from 'io-ts';

import { pipe } from 'fp-ts/function';
import { fold } from 'fp-ts/Either';

import { PathReporter as reporter } from 'io-ts/PathReporter';

export function decodeThrowing<T, O, I>(validator: t.Type<T, O, I>, input: I): T {
  const result = validator.decode(input);
  return pipe(
    result,
    fold(
      (_errors: any) => {
        const messages = reporter.report(result);

        const _payload = {
          error: `io-ts error -> ${messages.join('; ').toString()}`,
          errorDetail: JSON.stringify(input),
          url: window.location.href,
        };

        // FIXME: add slack logging here

        throw new Error(`${messages.join('\n')}\nwhen parsing ${JSON.stringify(input, null, 2)}`);
      },
      (value) => value,
    ),
  );
}

// Apply a validator and get the result in a Promise
// Source: https://www.olioapps.com/blog/checking-types-real-world-typescript/
export function decodeToPromise<T, O, I>(validator: t.Type<T, O, I>, input: I): Promise<T> {
  const result = validator.decode(input);
  return pipe(
    result,
    fold(
      (_errors: any) => {
        const messages = reporter.report(result);

        const _payload = {
          error: `io-ts error -> ${messages.join('; ').toString()}`,
          errorDetail: JSON.stringify(input),
          url: window.location.href,
        };

        // FIXME: add slack logging here

        return Promise.reject(new Error(messages.join('\n')));
      },
      (value: any) => {
        return Promise.resolve(value);
      },
    ),
  );
}
