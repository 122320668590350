import debounce from 'lodash/debounce';

type Subscriber<T extends MessageType> = {
  identifier?: string;
  callback: (payload: MessagePayloads[T]) => void;
  debouncedCallback?: ReturnType<typeof debounce<(payload: MessagePayloads[T]) => void>>;
};

type DomMutationPayload = { mutationList: MutationRecord[] };

type MessagePayloads = {
  dom_mutation: DomMutationPayload;
};

type MessageType = keyof MessagePayloads;
interface SubscriberGroup<T extends MessageType> {
  subscribers: Subscriber<T>[];
}

export class MessageBus {
  private messageToSubscriberGroup: Map<MessageType, SubscriberGroup<any>>;

  constructor() {
    this.messageToSubscriberGroup = new Map();
  }

  // Register a subscriber with optional ID (for unsubscribing) and optional debounce timeout (no debounce if not provided)
  subscribe<T extends MessageType>(
    messageType: T,
    listener: Subscriber<T>['callback'],
    listenerId: string | undefined = undefined,
    debounceTimeout: number | undefined = undefined,
  ): void {
    let entry = this.messageToSubscriberGroup.get(messageType) as SubscriberGroup<T>;
    if (!entry) {
      entry = { subscribers: [] };
      this.messageToSubscriberGroup.set(messageType, entry);
    }

    const subscriber: Subscriber<T> = { identifier: listenerId, callback: listener };
    if (debounceTimeout !== undefined) {
      subscriber.debouncedCallback = debounce(listener, debounceTimeout);
    }
    entry.subscribers.push(subscriber);
  }

  // Publish a message, calling all subscribers for that subscribe to the corresponding message type
  publish<T extends MessageType>(messageType: T, payload?: MessagePayloads[T]): void {
    const entry = this.messageToSubscriberGroup.get(messageType) as SubscriberGroup<T>;
    if (!entry) return;

    entry.subscribers.forEach((subscriber) => {
      payload = payload || ({} as MessagePayloads[T]);
      try {
        if (subscriber.debouncedCallback) {
          subscriber.debouncedCallback(payload);
        } else {
          subscriber.callback(payload);
        }
      } catch (error) {
        console.error('Error in message subscriber:', error);
      }
    });
  }

  // Remove a specific subscriber for an event by ID
  unsubscribe<T extends MessageType>(messageType: T, subscriberIdentifier: string): void {
    const entry = this.messageToSubscriberGroup.get(messageType);
    if (!entry) return;

    const activeSubscribers: typeof entry.subscribers = [];

    for (const subscriber of entry.subscribers) {
      if (subscriber.identifier === subscriberIdentifier) {
        subscriber.debouncedCallback?.cancel();
      } else {
        activeSubscribers.push(subscriber);
      }
    }

    entry.subscribers = activeSubscribers;
  }
}
