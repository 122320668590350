import { AI_ANSWER_TYPE, FEEDBACK, SUGGESTION } from './stateOld';
import { CmdSelect, CmdTypography } from '@commandbar/design-system/cmd';
import styled from '@emotion/styled';
import { useAppContext } from 'editor/src/AppStateContext';
import EndUserChooser from 'editor/src/editor/components/EndUserChooser';

const filterLabels: {
  answer: Record<AI_ANSWER_TYPE, string>;
  feedback: Record<FEEDBACK, string>;
  suggestion: Record<SUGGESTION, string>;
} = {
  answer: {
    [AI_ANSWER_TYPE.ALL]: 'All',
    [AI_ANSWER_TYPE.GENERATED]: 'Generated',
    [AI_ANSWER_TYPE.FALLBACK]: 'Fallback',
    [AI_ANSWER_TYPE.ERROR]: 'Error',
  },
  feedback: {
    [FEEDBACK.ALL]: 'All',
    [FEEDBACK.POSITIVE]: 'Positive',
    [FEEDBACK.NEGATIVE]: 'Negative',
    [FEEDBACK.UNRATED]: 'Unrated',
    [FEEDBACK.BOTH]: 'Both',
  },
  suggestion: {
    [SUGGESTION.ALL]: 'All',
    [SUGGESTION.PAGE]: 'Page',
    [SUGGESTION.ACTION]: 'Action',
    [SUGGESTION.CHECKLIST]: 'Checklist',
    [SUGGESTION.NUDGE]: 'Nudge',
    [SUGGESTION.ANY]: 'Any',
  },
};

const FilterSelectionLabel: React.FC = ({ children }) => (
  <CmdSelect.SelectValue asChild>
    <span
      style={{
        fontSize: '14px',
        fontWeight: 500,
      }}
    >
      {children}
    </span>
  </CmdSelect.SelectValue>
);

const StyledSelectTrigger = styled(CmdSelect.SelectTrigger)`
  min-width: 136px;
  height: 32px;
  gap: 8px;
`;

interface FiltersOldProps {
  answerFilter: AI_ANSWER_TYPE;
  setAnswerFilter: (answer: AI_ANSWER_TYPE) => void;

  feedbackFilter: FEEDBACK;
  setFeedbackFilter: (feedback: FEEDBACK) => void;

  suggestionFilter: SUGGESTION;
  setSuggestionFilter: (suggestion: SUGGESTION) => void;

  tagFilter: string;
  setTagFilter: (tag: string) => void;

  userFilter: string[];
  setUserFilter: (filter: string[]) => void;
}

const FiltersOld = ({
  answerFilter,
  setAnswerFilter,
  feedbackFilter,
  setFeedbackFilter,
  suggestionFilter,
  setSuggestionFilter,
  tagFilter,
  setTagFilter,
  userFilter,
  setUserFilter,
}: FiltersOldProps) => {
  const { tags } = useAppContext();

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flexWrap: 'wrap' }}>
      <CmdSelect.Menu value={answerFilter} onValueChange={(value) => setAnswerFilter(value as AI_ANSWER_TYPE)}>
        <StyledSelectTrigger>
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CmdTypography.Body variant="primary">AI Answer: </CmdTypography.Body>
            <FilterSelectionLabel>{filterLabels.answer[answerFilter]}</FilterSelectionLabel>
          </div>
        </StyledSelectTrigger>

        <CmdSelect.SelectContent>
          {Object.values(AI_ANSWER_TYPE).map((value) => (
            <CmdSelect.SelectItem key={value} value={value}>
              {filterLabels.answer[value]}
            </CmdSelect.SelectItem>
          ))}
        </CmdSelect.SelectContent>
      </CmdSelect.Menu>

      <CmdSelect.Menu value={feedbackFilter} onValueChange={(value) => setFeedbackFilter(value as FEEDBACK)}>
        <StyledSelectTrigger>
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CmdTypography.Body variant="primary">Feedback: </CmdTypography.Body>
            <FilterSelectionLabel>{filterLabels.feedback[feedbackFilter]}</FilterSelectionLabel>
          </div>
        </StyledSelectTrigger>

        <CmdSelect.SelectContent>
          {Object.values(FEEDBACK)
            .filter((value) => value !== FEEDBACK.BOTH)
            .map((value) => (
              <CmdSelect.SelectItem key={value} value={value}>
                {filterLabels.feedback[value]}
              </CmdSelect.SelectItem>
            ))}
        </CmdSelect.SelectContent>
      </CmdSelect.Menu>

      <CmdSelect.Menu value={suggestionFilter} onValueChange={(value) => setSuggestionFilter(value as SUGGESTION)}>
        <StyledSelectTrigger>
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CmdTypography.Body variant="primary">Suggestion: </CmdTypography.Body>
            <FilterSelectionLabel>{filterLabels.suggestion[suggestionFilter]}</FilterSelectionLabel>
          </div>
        </StyledSelectTrigger>

        <CmdSelect.SelectContent>
          {Object.values(SUGGESTION).map((value) => (
            <CmdSelect.SelectItem key={value} value={value}>
              {filterLabels.suggestion[value]}
            </CmdSelect.SelectItem>
          ))}
        </CmdSelect.SelectContent>
      </CmdSelect.Menu>

      <CmdSelect.Menu
        value={tagFilter}
        onValueChange={(value) => {
          setTagFilter(value);
        }}
      >
        <StyledSelectTrigger>
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CmdTypography.Body variant="primary">Tags: </CmdTypography.Body>
            <FilterSelectionLabel>{tagFilter === 'all' ? 'All' : tagFilter}</FilterSelectionLabel>
          </div>

          <CmdSelect.SelectContent>
            <CmdSelect.SelectItem key={'all'} value={'all'}>
              {'All'}
            </CmdSelect.SelectItem>
            {tags.map((tag) => (
              <CmdSelect.SelectItem key={tag.name} value={tag.name}>
                {tag.name}
              </CmdSelect.SelectItem>
            ))}
          </CmdSelect.SelectContent>
        </StyledSelectTrigger>

        <EndUserChooser
          style={{
            width: 'unset',
            minWidth: '136px',
            height: '32px',
            gap: '8px',
          }}
          endUserSlugs={userFilter}
          onEndUserChange={(newEndUser) => {
            setUserFilter(
              userFilter.includes(newEndUser.slug)
                ? userFilter.filter((slug) => slug !== newEndUser.slug)
                : [...userFilter, newEndUser.slug],
            );
          }}
          placeholder="All"
          prefixText="Users:"
          prefixIcon={null}
          searchPlaceholder="Filter by user ID"
        />
      </CmdSelect.Menu>
    </div>
  );
};

export default FiltersOld;
