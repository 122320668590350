/*******************************************************************************/
/* Imports
/*******************************************************************************/

import React, { useMemo } from 'react';

import { Route, Switch, Redirect, Prompt, useHistory } from 'react-router-dom';

import '../style/Home.css';

import { IUserType } from '@commandbar/internal/middleware/types';

import { Profile } from '@commandbar/internal/middleware/profile';

import useStartCommandBar from '../hooks/useStartCommandBar';

import { Skeleton, Page, Layout } from '@commandbar/design-system/components/antd';

import DashboardBanner from './DashboardBanner';

import Dashboard from './dashboard';

// Analytics Pages
import AnalyticsStrikes from './analytics/dashboards/strikes/Dashboard';

import AnalyticsAnnouncementsDashboard from './analytics/dashboards/announcements/Dashboard';
import AnalyticsAnnouncementsDetailDashboard from './analytics/dashboards/announcements/DetailDashboard';
import AnalyticsChecklistsDashboard from './analytics/dashboards/checklists/Dashboard';
import AnalyticsChecklistsDetailDashboard from './analytics/dashboards/checklists/DetailDashboard';
import AnalyticsCopilotDashboard from './analytics/dashboards/copilot/Dashboard';
import AnalyticsCopilotDashboardOld from './analytics/dashboards/copilot/DashboardOld';
import AnalyticsHelpHubDashboard from './analytics/dashboards/helphub/Dashboard';
import AnalyticsProductToursDashboard from './analytics/dashboards/product-tours/Dashboard';
import AnalyticsProductToursDetailDashboard from './analytics/dashboards/product-tours/DetailDashboard';
import AnalyticsSpotlightDashboard from './analytics/dashboards/spotlight/Dashboard';
import AnalyticsSurveysDashboard from './analytics/dashboards/surveys/Dashboard';
import AnalyticsSurveysDetailDashboard from './analytics/dashboards/surveys/DetailDashboard';
import AnalyticsDeadendsDashboard from './analytics/dashboards/deadends/Dashboard';

// Health Pages
import Sidebar from './HomeSidebar';
import Billing from './billing/Billing';
import History from './History';

// Integration pages
import IdentityVerification from './IdentityVerification';
import SegmentIntegrationPage from './integrations/non-help-center/segment/SegmentIntegrationPage';
import HeapIntegrationPage from './integrations/non-help-center/heap/HeapIntegrationPage';
import AlgoliaIntegrationPage from './integrations/non-help-center/algolia/AlgoliaIntegrationPage';
import SourceDetailPage from './content/sources/SourceDetailPage';
import ShareDataWithCommandBarChromeExtension from './ShareDataWithCommandBarChromeExtension';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { useReportEvent } from '../hooks/useEventReporting';
import IntegrationsList from './integrations/integrations-page/IntegrationsList';
import Configure from './Configure';

import { InstallGuide, WebAppInstallGuide, NoCodeInstallGuide } from './InstallGuide';
import ContentPage from './content/ContentPage';
import HubSpotIntegrationPage from './integrations/non-help-center/hubspot/HubSpotIntegrationPage';
import IntegrationsContextProvider from './IntegrationsContextProvider';
import Editor from './editor/Editor';
import { MenuItem, getMenuItems } from './nav/menu_items';
import { Helmet } from 'react-helmet';
import { IntegrationPage } from './integrations/non-help-center/thin-analytics/Instructions';
import { Billing as BillingClient } from '@commandbar/internal/middleware/billing';
import useEditor from 'editor/src/editor/useEditor';
import { AppStateContext } from 'editor/src/AppStateContext';
import { Loading } from '../App';
import AnalyticsContextProvider from './analytics/AnalyticsContextProvider';
import { getEditorPath } from '../Router';
import { dispatchCustomEvent } from '@commandbar/internal/util/dispatchCustomEvent';
import SegmentWebhookIntegrationPage from './integrations/non-help-center/segment-webhook/SegmentWebhookIntegrationPage';
import AmplitudeIntegrationPage from './integrations/non-help-center/amplitude/ActivationPage';
import ZendeskIntegrationPage from './integrations/non-help-center/zendesk-handoff/IntegrationPage';
import FreshchatIntegrationPage from './integrations/non-help-center/freshchat/IntegrationPage';
import TeamMembersPage from './TeamMembersPage';
import AudiencePage from './audience/AudiencePage';
import PropertiesPage from './users/PropertiesPage';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { CmdToaster, cmdToast } from '@commandbar/design-system/cmd';
import UsersPage from './users/UsersPage';
const { Content } = Layout;

export const ANALYTICS_BASE_ROUTE = '/analytics';

export enum AnalyticsPage {
  ANNOUNCEMENTS = 'Announcements',
  CHECKLISTS = 'Checklists',
  COPILOT = 'Copilot',
  HELPHUB = 'HelpHub',
  TOURS = 'Tours',
  SPOTLIGHT = 'Spotlight',
  SURVEYS = 'Surveys',
  STRIKES = 'Strikes',
  DEADENDS = 'Deadends',
}

const formatRoute = (pageName: string) => {
  if (pageName === 'Tours') {
    return 'product-tours';
  }

  return pageName.toLowerCase();
};

export const getAnalyticsDashboardRoute = (page: AnalyticsPage): `${typeof ANALYTICS_BASE_ROUTE}/${string}` =>
  `${ANALYTICS_BASE_ROUTE}/${formatRoute(page)}`;
const getAnalyticsDetailRoute = (page: AnalyticsPage): `${ReturnType<typeof getAnalyticsDashboardRoute>}/:id` =>
  `${getAnalyticsDashboardRoute(page)}/:id`;

const getAnalyticsPageTitle = (path: string): AnalyticsPage | undefined =>
  Object.values(AnalyticsPage).find((page) => path.includes(formatRoute(page)));

/*******************************************************************************/

interface IProps {
  user: IUserType;
}

/*******************************************************************************/

const Home = (props: IProps) => {
  useStartCommandBar(props.user.email, props.user.hmac);
  const { reportEvent } = useReportEvent();
  const startFreeTrial = () => axiosInstance.post('billing/start-free-trial/');
  const editorState = useEditor(props.user, true);

  const dispatch = editorState ? editorState.dispatch : null,
    state = editorState ? editorState.state : null;
  const history = useHistory();
  const currentPathRef = React.useRef(history.location.pathname);
  const providerValue = useMemo(
    () =>
      dispatch && state
        ? {
            ...state,
            dispatch,
            isStudio: true,
          }
        : null,
    [dispatch, state],
  );

  React.useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (providerValue && location.pathname !== currentPathRef.current) {
        currentPathRef.current = location.pathname;
        providerValue.hasUnsavedChangesRef.current = false;
      }
      if (location.pathname.includes('/editor/')) {
        const editorPath = getEditorPath(`${location.pathname}${location.search}${location.hash}`);

        dispatchCustomEvent('updateEditorRoute', { type: 'route', route_replacement: `/${editorPath}`, action });
      } else {
        dispatchCustomEvent('updateEditorRoute', { type: 'route', route_replacement: `/blank`, action: 'POP' });
      }
    });

    return () => {
      unlisten();
    };
  }, [history, !!providerValue]);

  const organization = state?.organization;

  React.useEffect(() => {
    Profile.list().then((profiles) => {
      if (
        state?.organizationSettings?.user_seat_limit &&
        profiles.length >= state?.organizationSettings?.user_seat_limit
      ) {
        window.CommandBar.addMetadata('atMaxSeatLimit', true);
      }
    });
  }, [state?.organizationSettings]);

  React.useEffect(() => {
    window.CommandBar.addMetadata('organization', organization);
    window.CommandBar.addMetadata('user', props.user);
  }, [organization, props.user]);

  React.useEffect(() => {
    BillingClient.readProfile().then((profile) => {
      if (!profile.on_free_trial && profile.tier === 'Free' && !profile.free_trial_end_date) {
        startFreeTrial()
          .then((response) => {
            // On the backend we check for if the org was created in the past 30 days before activating the trial
            // This is a rare case, but in this case it is a noop and nothing should be shown to the org
            // See the FreeTrialView for me context
            if (response.data.activated) {
              cmdToast.success('Free trial started!');
              reportEvent('trial started', {
                segment: true,
                highlight: true,
                slack: false,
                payloadMessage: 'Free trial',
                eventProps: {
                  plan_name: 'Free trial',
                  previous_plan_name: '',
                },
              });
            }
          })
          .catch(() => {
            reportEvent('Error: Could not activate free trial', {
              segment: false,
              highlight: true,
              slack: true,
              payloadMessage: `Url: ${window.location.href}}; Organization: ${organization?.id}`,
            });
            cmdToast.error("Error starting free trial. Please contact support if you think that's wrong.");
          });
      }
    });
  }, []);

  function getEditorPageName(menuItems: MenuItem[]): string | null {
    const path = window.location.pathname;
    for (const item of menuItems) {
      // If the item is a link and the route matches
      if (item.type === 'link' && path.startsWith(item.route)) {
        return item.title;
      }
      // If the item is a group, recurse into its children
      if (item.type === 'group') {
        const title = getEditorPageName(item.children);
        if (title) {
          return title;
        }
      }
    }
    // Return null if no matching title is found
    return null;
  }

  const menuItems: MenuItem[] = React.useMemo(() => {
    if (!providerValue) return [];

    const items = getMenuItems(providerValue.flags, props.user).filter(
      (item) => organization?.releases_available || !(item.type === 'link' && item.route === '/editor/releases'),
    );

    return items;
  }, [providerValue?.flags]);

  /**********************************************************************/
  /* Render
  /**********************************************************************/

  if (!providerValue) return <Loading />;

  return (
    <Layout id="home">
      <AppStateContext.Provider value={providerValue}>
        <IntegrationsContextProvider organization={organization}>
          <AnalyticsContextProvider>
            {organization && <ShareDataWithCommandBarChromeExtension organization={organization} />}
            <CmdToaster />
            <Layout style={{ background: 'white', minHeight: '100vh' }}>
              <Sidebar menuItems={menuItems} />
              <Layout className="site-layout" style={{ background: 'white', height: '100vh' }}>
                <DashboardBanner key="dashboard-banner" />
                <Content style={{ display: 'flex' }}>
                  <Prompt
                    message={() => {
                      if (providerValue.hasUnsavedChangesRef.current) {
                        return '';
                      } else {
                        return true;
                      }
                    }}
                    when={hasRequiredRole(props.user, 'contributor')}
                  />
                  <Switch>
                    <Route path="/home">
                      <Dashboard user={props.user} />
                    </Route>

                    {/* -- Non help center integrations routes -- */}
                    <Route path="/integrations" exact>
                      {organization && <IntegrationsList organization={organization} />}
                    </Route>

                    <Route path={['/content', '/content/:page']} exact>
                      {organization && <ContentPage />}
                    </Route>

                    <Route path="/content/sources/:id">{organization && <SourceDetailPage />}</Route>

                    <Route path="/editor/*">
                      <React.Fragment>
                        <Helmet>
                          <title>{getEditorPageName(menuItems) || 'Editor'} | Command AI</title>
                        </Helmet>
                      </React.Fragment>
                    </Route>

                    <Route path="/integrations/segment" exact>
                      {organization && <SegmentIntegrationPage organization={organization} user={props.user} />}
                    </Route>
                    <Route path="/integrations/segment-webhook" exact>
                      {organization && <SegmentWebhookIntegrationPage />}
                    </Route>

                    <Route path="/integrations/heap" exact>
                      {organization && <HeapIntegrationPage organization={organization} user={props.user} />}
                    </Route>

                    <Route path="/integrations/hubspot" exact>
                      {organization && <HubSpotIntegrationPage organization={organization} />}
                    </Route>

                    {providerValue.flags['amplitude-audience-integration'] && (
                      <Route path="/integrations/amplitude" exact>
                        {organization && <AmplitudeIntegrationPage />}
                      </Route>
                    )}

                    <Route path="/integrations/zendesk-handoff" exact>
                      {organization && <ZendeskIntegrationPage />}
                    </Route>

                    <Route path="/integrations/freshchat-handoff" exact>
                      {organization && <FreshchatIntegrationPage />}
                    </Route>

                    <Route path="/integrations/algolia" exact>
                      {organization && <AlgoliaIntegrationPage organization={organization} user={props.user} />}
                    </Route>

                    <Route path="/integrations/:slug">
                      {organization && <IntegrationPage organizationId={organization.id} />}
                    </Route>

                    <Route path="/configure" exact>
                      <Configure />
                    </Route>

                    {/* Settings routes */}
                    <Route path="/billing" exact>
                      <Billing />
                    </Route>
                    <Route path="/team" exact>
                      <TeamMembersPage />
                    </Route>
                    <Route path="/identity-verification" exact>
                      {!organization ? (
                        <Page title="Identity Verification">
                          <Skeleton active />
                        </Page>
                      ) : (
                        <IdentityVerification organization={organization} />
                      )}
                    </Route>
                    <Route path="/history" exact>
                      {!organization ? <Skeleton active /> : <History />}
                    </Route>

                    {/* Install Guide routes */}
                    <Route path="/installation-guide" exact>
                      <InstallGuide />
                    </Route>
                    <Route path="/installation-guide/web-app">
                      <WebAppInstallGuide organizationUid={organization?.id || ''} />
                    </Route>
                    <Route path="/installation-guide/no-code">
                      <NoCodeInstallGuide organizationUid={organization?.id || ''} />
                    </Route>

                    <Route path="/audiences" exact>
                      <AudiencePage />
                    </Route>

                    <Route path="/properties" exact>
                      <PropertiesPage />
                    </Route>
                    <Route path="/users" exact>
                      <UsersPage />
                    </Route>

                    {/* Analytics Routes */}
                    <Route path={[`${ANALYTICS_BASE_ROUTE}/*`, ANALYTICS_BASE_ROUTE]}>
                      {({ location }) => (
                        <>
                          <Helmet>
                            <title>{getAnalyticsPageTitle(location.pathname) ?? 'Analytics'} | Command AI</title>
                          </Helmet>

                          <>
                            <Route path={getAnalyticsDashboardRoute(AnalyticsPage.STRIKES)} exact>
                              <AnalyticsStrikes />
                            </Route>

                            <Route path={getAnalyticsDashboardRoute(AnalyticsPage.ANNOUNCEMENTS)} exact>
                              <AnalyticsAnnouncementsDashboard />
                            </Route>

                            <Route path={getAnalyticsDetailRoute(AnalyticsPage.ANNOUNCEMENTS)} exact>
                              <AnalyticsAnnouncementsDetailDashboard />
                            </Route>

                            <Route path={getAnalyticsDashboardRoute(AnalyticsPage.CHECKLISTS)} exact>
                              <AnalyticsChecklistsDashboard />
                            </Route>

                            <Route path={getAnalyticsDetailRoute(AnalyticsPage.CHECKLISTS)} exact>
                              <AnalyticsChecklistsDetailDashboard />
                            </Route>

                            <Route path={getAnalyticsDashboardRoute(AnalyticsPage.COPILOT)} exact>
                              {providerValue.flags['release-copilot-analytics-v2'] ? (
                                <AnalyticsCopilotDashboard />
                              ) : (
                                <AnalyticsCopilotDashboardOld />
                              )}
                            </Route>

                            <Route path={getAnalyticsDashboardRoute(AnalyticsPage.HELPHUB)} exact>
                              <AnalyticsHelpHubDashboard />
                            </Route>

                            <Route path={getAnalyticsDashboardRoute(AnalyticsPage.TOURS)} exact>
                              <AnalyticsProductToursDashboard />
                            </Route>

                            {/* TODO: Is there a simpler way to handle this nested route? */}
                            <Route path={getAnalyticsDetailRoute(AnalyticsPage.TOURS)} exact>
                              <AnalyticsProductToursDetailDashboard />
                            </Route>

                            <Route path={getAnalyticsDashboardRoute(AnalyticsPage.SPOTLIGHT)} exact>
                              <AnalyticsSpotlightDashboard />
                            </Route>

                            <Route path={getAnalyticsDashboardRoute(AnalyticsPage.SURVEYS)} exact>
                              <AnalyticsSurveysDashboard />
                            </Route>

                            <Route path={getAnalyticsDetailRoute(AnalyticsPage.SURVEYS)} exact>
                              <AnalyticsSurveysDetailDashboard />
                            </Route>

                            <Route path={getAnalyticsDashboardRoute(AnalyticsPage.DEADENDS)} exact>
                              <AnalyticsDeadendsDashboard />
                            </Route>
                          </>
                        </>
                      )}
                    </Route>
                    <Route>
                      <Redirect to="/home" />
                    </Route>
                  </Switch>

                  <div style={{ width: '100%' }} hidden={!window.location.pathname.includes('/editor')}>
                    {organization && <Editor user={props.user} />}
                  </div>
                </Content>
              </Layout>
            </Layout>
          </AnalyticsContextProvider>
        </IntegrationsContextProvider>
      </AppStateContext.Provider>
    </Layout>
  );
};

export default Home;
