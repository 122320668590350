/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';

const Container = styled.span`
  position: absolute;
  background-color: var(--background-primary);
  border: 1px solid var(--border-primary);
  border-radius: 3px;
  transform: rotate(45deg);

  &.pin-arrow {
    width: 32px;
    height: 32px;

    &-top,
    &-top-start,
    &-top-end {
      left: -1px;
      bottom: -3px;
      top: unset;
      transform: skew(0deg, -36deg);
    }

    &-bottom,
    &-bottom-start,
    &-bottom-end {
      left: -1px;
      top: -3px;
      transform: skew(0deg, 36deg);
    }

    &-left,
    &-left-start,
    &-left-end {
      top: -1px;
      left: unset;
      right: -3px;
      transform: skew(-36deg, 0deg) rotate(0deg);
    }

    &-right,
    &-right-start,
    &-right-end {
      top: -1px;
      left: -3px;
      transform: skew(36deg, 0deg) rotate(0deg);
    }

    // Default
    top: -3px;
    left: -1px;
    transform: skew(0deg, 36deg);
  }

  &.tooltip-arrow {
    width: 24px;
    height: 24px;
    left: 0;
    right: 0;
    top: -8px;
    margin: 0 auto;
  }
`;

interface ArrowInterface {
  containerStyles?: React.CSSProperties;
  type?: 'pin' | 'tooltip';
}

const Arrow = (props: ArrowInterface) => {
  const { containerStyles, type } = props;
  return <Container className={`commandbar-nudge-arrow ${type}-arrow`} style={containerStyles} />;
};

export default Arrow;
