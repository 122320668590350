/** @jsx jsx  */
import { css, jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { emptyGlobalStore } from 'shared/store/global-store';
import { useStore } from 'shared/util/hooks/useStore';
import { AnimationTransition, builtinKeyframes } from 'shared/util/hooks/useDelayUnmount';
import { IAIMessageType } from '@commandbar/internal/middleware/types';
import { useStyles } from 'products/helphub/components/useStyles';

import FeedbackButtons from './FeedbackButtons';
import { Continuations } from '../Continuations';
import { BotMessageText } from './BotMessageText';
import { CB_COLORS } from '@commandbar/design-system/colors';
import { isAction } from '@cb/types/entities/command/actions';
import StyledChatContent from '@commandbar/internal/client/themesV2/components/copilot/StyledChatContent';
import BotMessageAction from './BotMessageAction';
import BotMessageFooter from './BotMessageFooter';
import StyledChatMessage from '@commandbar/internal/client/themesV2/components/copilot/StyledChatMessage';
import isEqual from 'lodash/isEqual';

export type BotMessageProps = {
  preview: boolean;
  isFirstMessage: boolean;
  isLastMessage: boolean;
  previousContinuations: Set<string>;
  message: IAIMessageType;
  isInitialBotMessage?: boolean;
  sendChat: (message: string) => void;
  isChatHistoryPreview: boolean;
  isFinishedStreaming: boolean;
  onFinishStreaming: () => void;
  previewOverrides?: { useThemeV2: boolean };
};

export const BotMessage: React.FC<BotMessageProps> = (props) => {
  const {
    preview,
    isFirstMessage,
    isLastMessage,
    message,
    previousContinuations,
    isChatHistoryPreview,
    isFinishedStreaming,
    onFinishStreaming,
    previewOverrides,
  } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _ = !preview ? useStore() : emptyGlobalStore();

  const styles = useStyles(preview);

  const isLoading = message.incomplete;
  const searchSuggestions = _.helpHub.searchSuggestions;
  const defaultInitialContinuations = !searchSuggestions.loading ? searchSuggestions.continuations : [];
  const continuations = isFirstMessage
    ? defaultInitialContinuations
    : (message.continuations || []).filter((continuation) => !previousContinuations.has(continuation));

  const unmounted = React.useRef(false);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const isAnswerBlankString = React.useMemo(() => {
    const strippedAnswer =
      message.value.answer
        ?.replace(/<\/?[^>]+(>|$)/g, '') // empty HTML tags
        .replace(/[\n\r]/g, '') // newlines
        .trim() || '';
    return strippedAnswer.length === 0;
  }, [message.value.answer]);

  const showActionsOnly = message?.no_answer && (isLoading || message.extras.length > 0);
  const showFallbackCTA = message?.no_answer && message.extras.length === 0;
  const showTextAnswer = !isAnswerBlankString && !showActionsOnly;

  const finishedDisplayingTextAnswer = !isLoading && isFinishedStreaming;

  if (!isLoading && isAnswerBlankString) {
    onFinishStreaming();
  }

  // Check if any extras have a blank action -> this means it's a confirmation action,
  // and clicking it will send the button text as a user message
  const hasTextReplyButton = message.extras.some((extra) => !extra.action);

  const showFeedbackButtons = !isFirstMessage && !hasTextReplyButton && finishedDisplayingTextAnswer;
  const textAnswerActions = message.value.source_docs
    .map((doc) => doc?.command.next_steps)
    .flatMap((next_steps) => next_steps)
    .filter((next_step) => isAction(next_step) && next_step.cta);

  const messageExtrasToDisplay = message.extras.filter((extra) => {
    // Don't show extras that are already being shown as answer CTAs
    const extraInTextAnswerActions = textAnswerActions.some(
      (answerAction) => isAction(answerAction) && isEqual(answerAction.action, extra.action),
    );

    // Only show reply buttons (ie, extra w/o an action) if it's the last message
    const shouldShowButton = extra.action || isLastMessage;

    return shouldShowButton && !extraInTextAnswerActions;
  });

  return (preview && previewOverrides?.useThemeV2) || _.flags?.['release-themes-v2'] ? (
    <StyledChatContent isLoading={!finishedDisplayingTextAnswer} isBotMessage>
      {/* this is the pulsing "..." */}
      <AnimationTransition
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={isLoading && !showTextAnswer}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            padding: '8px 0px',
            justifyContent: 'center',
            alignItems: 'center',
            height: '40px',
          }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              css={css`
                animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

                @keyframes pulse {
                  0%,
                  100% {
                    opacity: 1;
                  }
                  50% {
                    opacity: 0.5;
                  }
                }
              `}
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: CB_COLORS.neutral600,
                animationDelay: `${index * 0.25}s`,
              }}
            />
          ))}
        </div>
      </AnimationTransition>

      <StyledChatMessage
        isBotMessage
        timestamp={finishedDisplayingTextAnswer ? message.modified : undefined}
        isMounted={showTextAnswer}
      >
        <BotMessageText
          preview={preview}
          isLoading={isLoading}
          isFinishedStreaming={isFinishedStreaming}
          onFinishStreaming={onFinishStreaming}
          answer={message.value.answer}
          messageId={message.uuid ?? ''}
          previewOverrides={previewOverrides}
        />

        {message.value.source_docs.length > 0 && finishedDisplayingTextAnswer && (
          <BotMessageFooter preview={preview} message={message} />
        )}

        {showFeedbackButtons && (
          <FeedbackButtons
            preview={preview}
            isChatHistoryPreview={isChatHistoryPreview}
            message={message}
            previewOverrides={previewOverrides}
          />
        )}
      </StyledChatMessage>

      <AnimationTransition
        style={{ display: 'flex', flexDirection: 'column', gap: 'var(--layout-gap)', width: '100%' }}
        entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
        isMounted={
          finishedDisplayingTextAnswer &&
          (message.fallback_labeled_actions.length > 0 ||
            textAnswerActions.length > 0 ||
            messageExtrasToDisplay.length > 0)
        }
      >
        {showFallbackCTA &&
          message.fallback_labeled_actions.map((fallbackAction, idx) => (
            <BotMessageAction
              key={idx}
              idx={idx}
              action={fallbackAction.action}
              buttonLabel={fallbackAction.cta}
              sendChat={props.sendChat}
              preview={preview}
              isChatHistoryPreview={isChatHistoryPreview}
              previewOverrides={previewOverrides}
            />
          ))}

        {textAnswerActions.map((answerCTA, idx) => {
          if (!isAction(answerCTA) || !answerCTA.cta) return null;
          return (
            <BotMessageAction
              key={idx}
              idx={idx}
              action={answerCTA.action}
              buttonLabel={answerCTA.cta}
              sendChat={props.sendChat}
              preview={preview}
              isChatHistoryPreview={isChatHistoryPreview}
              previewOverrides={previewOverrides}
            />
          );
        })}

        {messageExtrasToDisplay.map((extra, idx) => (
          <BotMessageAction
            key={idx}
            idx={idx}
            action={extra.action}
            buttonLabel={extra.text}
            buttonType={extra.type}
            message={extra.message}
            sendChat={props.sendChat}
            preview={preview}
            isChatHistoryPreview={isChatHistoryPreview}
            previewOverrides={previewOverrides}
          />
        ))}
      </AnimationTransition>
    </StyledChatContent>
  ) : (
    <div
      style={{
        maxWidth: '100%',
        minWidth: 0,
        display: 'flex',
        flexDirection: 'column',
        ...(isLoading && { minHeight: 200 }),
      }}
    >
      <div css={[styles.feedBackButtonsParent[preview ? 'preview' : 'regular']]}>
        <AnimationTransition
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={isLoading && !showTextAnswer}
        >
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', padding: '8px 0px' }}>
            {Array.from({ length: 3 }).map((_, index) => (
              <div
                key={index}
                css={css`
                  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;

                  @keyframes pulse {
                    0%,
                    100% {
                      opacity: 1;
                    }
                    50% {
                      opacity: 0.5;
                    }
                  }
                `}
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  backgroundColor: CB_COLORS.neutral600,
                  animationDelay: `${index * 0.25}s`,
                }}
              />
            ))}
          </div>
        </AnimationTransition>

        <AnimationTransition
          style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={
            showTextAnswer ||
            message.fallback_labeled_actions.length > 0 ||
            textAnswerActions.length > 0 ||
            messageExtrasToDisplay.length > 0
          }
        >
          {showTextAnswer && (
            <BotMessageText
              preview={preview}
              isLoading={isLoading}
              isFinishedStreaming={isFinishedStreaming}
              onFinishStreaming={onFinishStreaming}
              answer={message.value.answer}
              messageId={message.uuid ?? ''}
            />
          )}

          {finishedDisplayingTextAnswer &&
            showFallbackCTA &&
            message.fallback_labeled_actions.map((fallbackAction, idx) => (
              <BotMessageAction
                key={idx}
                idx={idx}
                action={fallbackAction.action}
                buttonLabel={fallbackAction.cta}
                sendChat={props.sendChat}
                preview={preview}
                isChatHistoryPreview={isChatHistoryPreview}
                previewOverrides={previewOverrides}
              />
            ))}

          {finishedDisplayingTextAnswer &&
            textAnswerActions.map((answerCTA, idx) => {
              if (!isAction(answerCTA) || !answerCTA.cta) return null;
              return (
                <BotMessageAction
                  key={idx}
                  idx={idx}
                  action={answerCTA.action}
                  buttonLabel={answerCTA.cta}
                  sendChat={props.sendChat}
                  preview={preview}
                  isChatHistoryPreview={isChatHistoryPreview}
                  previewOverrides={previewOverrides}
                />
              );
            })}

          {finishedDisplayingTextAnswer &&
            messageExtrasToDisplay.map((extra, idx) => (
              <BotMessageAction
                key={idx}
                idx={idx}
                action={extra.action}
                buttonLabel={extra.text}
                buttonType={extra.type}
                message={extra.message}
                sendChat={props.sendChat}
                preview={preview}
                isChatHistoryPreview={isChatHistoryPreview}
                previewOverrides={previewOverrides}
              />
            ))}
        </AnimationTransition>

        <AnimationTransition
          style={{ width: '100%', marginTop: '12px' }}
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={message.value.source_docs.length > 0 && showTextAnswer}
        >
          <BotMessageFooter preview={preview} message={message} />
        </AnimationTransition>

        <AnimationTransition
          entry={{ keyframes: builtinKeyframes.fadeIn, durationMs: 300 }}
          isMounted={!isChatHistoryPreview && finishedDisplayingTextAnswer && continuations.length > 0 && isLastMessage}
        >
          <Continuations preview={preview} continuations={continuations} />
        </AnimationTransition>

        {showFeedbackButtons && (
          <FeedbackButtons preview={preview} isChatHistoryPreview={isChatHistoryPreview} message={message} />
        )}
      </div>
    </div>
  );
};

export default BotMessage;
