import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { Form, Collapse } from '@commandbar/design-system/components/antd';

import { HelpDocsIntegration } from '@commandbar/internal/middleware/helpDocsIntegration';
import {
  IAudienceType,
  IHelpDocsIntegrationType,
  IOrganizationSettingsType,
} from '@commandbar/internal/middleware/types';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { useReportEvent } from '../../../../hooks/useEventReporting';
import { ButtonRow, StyledCollapse } from '../../../styles';
import SourceSettingsForm from './SourceSettingsForm';
import { DryRunData } from '../../../integrations/shared/types';
import helpSyncIntegrationList from '../../../integrations/help-center/active-help-center-integrations';
import { IntegrationSpec } from '../../../integrations/shared/types';
import { useIntegrationsContext } from '../../../util/IntegrationContext';
import { updateIntegrations, onIntegrationError } from '../../../integrations/shared/utils';
import { Exception } from '@sentry/react';
import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';
import { useAppContext } from 'editor/src/AppStateContext';
import { useRunSync } from '../useRunSync';
import { isConditionGroupValid } from 'editor/src/editor/conditions/validate';
import { AudienceSelect } from 'editor/src/editor/AudienceSelector';
import { RefreshCw01 } from '@commandbar/design-system/icons/react';

interface IProps {
  onCancel: () => void;
  onComplete: () => void;
  setSelectedIntegration: (integration: IntegrationSpec | undefined) => void;
  selectedIntegration: IntegrationSpec | undefined;
  token?: string | null;
  allowedHelpSyncSchedule?: IOrganizationSettingsType['help_center_sync'];
  sourceId?: number;
}

const { Panel } = Collapse;

const SourceConfigForm = (props: IProps) => {
  const { organization, rules, flags } = useAppContext();

  const [form] = Form.useForm();
  const { reportEvent } = useReportEvent();
  const showAdvanced = Form.useWatch<boolean>('show_advanced', form);
  const includeSdk = Form.useWatch<boolean>('include_sdk', form);
  const integration = helpSyncIntegrationList.find((i) => i.slug === props.selectedIntegration?.slug);
  const [isValidating, setIsValidating] = React.useState<boolean>(false);
  const [isValid, setIsValid] = React.useState<boolean>(!!props.token);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [editingSource, setEditingSource] = React.useState<IHelpDocsIntegrationType | undefined>(
    props.selectedIntegration?.sources?.find((i) => i.id === props.sourceId),
  );
  const [dryRunData, setDryRunData] = useState<DryRunData | undefined>(
    integration?.getInitialDryRunData && integration?.getInitialDryRunData(props.sourceId),
  );
  const [audience, setAudience] = useState<IAudienceType>(editingSource?.audience || { type: 'all_users' });
  const { addLoadingSource, removeLoadingSource, refreshHelpDocIntegrations } = useIntegrationsContext();
  const runSync = useRunSync(true, props.selectedIntegration, props.sourceId);

  useEffect(() => {
    setEditingSource(props.selectedIntegration?.sources?.find((i) => i.id === props.sourceId));
    if (editingSource || !props.selectedIntegration?.useDryRun) {
      setIsValid(Object.values(form.getFieldsValue()).every((field) => field !== undefined && field !== ''));
    }
  }, [props]);

  /* Checks the validity of the form - if there are errors or specific key fields required from dry run integrations */
  const shouldTryDryRun = (changedValues: Record<string, string>, values: Record<string, string>): boolean => {
    const error = form.getFieldsError().some((item) => item.errors.length > 0);
    const missingRequiredField = integration?.requiredFields.some(
      (field) => values[field] === undefined || values[field] === '',
    );
    if (error || missingRequiredField) {
      setIsValid(false);
      return false;
    }

    setIsValid(true);
    // if we changed a required field (not just edited the fetched fields), do a dry run
    return Object.keys(changedValues).some((field) => integration?.requiredFields.includes(field));
  };

  /* Checks if specific integrations are valid, do dry run, set fetched data fields if applicable */
  const dryRun = async (values: Record<string, string>): Promise<void> => {
    setIsValidating(true);

    try {
      const payload = {
        type: props?.selectedIntegration?.slug,
        meta: integration?.getPayloadMeta(values, [], editingSource),
      };
      const result = await axiosInstance.put('/integrations/dry_run/', { payload });
      setIsValid(result.status === 200);
      const data = integration?.getDryRunData?.(result);
      setDryRunData(Array.isArray(data) ? data : undefined);
    } catch (err) {
      onIntegrationError(err as Exception, integration?.slug);
      setIsValid(false);
    }
    setIsValidating(false);
  };

  const onSubmit = async (formData: Record<string, any>): Promise<void> => {
    if (!props.selectedIntegration) {
      onIntegrationError('Integration sync failed: no integration selected');
      return;
    }
    let runNewSync = false;
    setIsSubmitting(true);
    try {
      const payload = {
        id: -1,
        type: props.selectedIntegration.slug,
        organization: organization?.id.toString() || '',
        schedule: formData['schedule'],
        helphub_view_article_button_hidden: false,
        meta: {
          ...integration?.getPayloadMeta(formData, dryRunData, editingSource),
          default_category: formData['default_category'] ? formData['default_category'] : undefined,
          default_live: formData['default_live'] ? formData['default_live'] : false,
          show_preview: formData['show_preview'] ? formData['show_preview'] : false,
          training_only: formData['training_only'] ? formData['training_only'] : false,
        },
        audience: audience,
      };

      /* If existing integration changed via edit use update, if new create */
      if (editingSource && editingSource.id === props.sourceId) {
        reportEvent('integration edited', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: `${props.selectedIntegration.slug}-${editingSource.id}`,
          eventProps: {
            id: editingSource.id,
            name: props.selectedIntegration.slug,
          },
        });
        HelpDocsIntegration.update({
          ...payload,
          id: editingSource.id,
        });
        addLoadingSource(editingSource.id);
        refreshHelpDocIntegrations();
      } else {
        reportEvent('integration created', {
          segment: true,
          highlight: true,
          slack: true,
          payloadMessage: props.selectedIntegration.slug,
          eventProps: {
            name: props.selectedIntegration.slug,
          },
        });
        const resp = await HelpDocsIntegration.create(payload);
        addLoadingSource(resp.id);
        props.setSelectedIntegration({
          ...props.selectedIntegration,
          sources: [resp, ...(props.selectedIntegration.sources ?? [])],
        });
        await refreshHelpDocIntegrations();
        runNewSync = true;
      }
      props.onComplete();
    } catch (err) {
      onIntegrationError(err as Exception, props.selectedIntegration.slug);

      if (editingSource) {
        removeLoadingSource(editingSource.id);
      }
    } finally {
      setIsSubmitting(false);
    }

    if (runNewSync) {
      runSync();
    } else {
      cmdToast.success(
        'The new settings will be applied on the next sync. You can run a manual sync now to apply the changes immediately.',
      );
    }
    updateIntegrations(props.selectedIntegration);
  };

  const initializeDryRun = (changedValues: Record<string, string>, values: Record<string, string>) => {
    if (shouldTryDryRun(changedValues, values)) {
      return dryRun(values);
    }
  };

  const onValuesChange = props.selectedIntegration?.useDryRun
    ? debounce(initializeDryRun, 1000)
    : debounce(() => {
        setIsValid(!form.getFieldsError().some((item) => item.errors.length > 0));
      }, 500);

  const triggerDryRunButton = (
    <CmdButton
      disabled={isSubmitting || isValidating}
      style={{ flexShrink: 1 }}
      variant="ghost"
      onClick={() => dryRun(form.getFieldsValue())}
    >
      <RefreshCw01 />
    </CmdButton>
  );

  const IntegrationSpecificFormItems = (): JSX.Element => (
    <>
      {integration &&
        integration.getForm({
          editingSource: editingSource,
          showAdvanced: showAdvanced,
          dryRunData: dryRunData,
          includeSdk: includeSdk,
          token: props.token,
          organization: organization,
          triggerDryRunButton: triggerDryRunButton,
        })}
    </>
  );

  return (
    <Form
      style={{ height: '100%' }}
      layout="vertical"
      key={'configure-integration-form'}
      name="createIntegration"
      form={form}
      onValuesChange={onValuesChange}
      onFinish={onSubmit}
      id="connectIntegration"
      size={'middle'}
    >
      <StyledCollapse defaultActiveKey={['1']} ghost expandIconPosition="end">
        <Panel header="Integration details" key="1">
          <IntegrationSpecificFormItems key={'integration-form-details'} />
        </Panel>
        {flags['release-audiences-for-command-types'] && (
          <Panel header={'Targeting'} key="2">
            <Form.Item label="Audience" name="audience" initialValue={audience}>
              <AudienceSelect audience={audience} rules={rules} onChange={(audience) => setAudience(audience)} />
            </Form.Item>
          </Panel>
        )}
        <Panel header="Sync details" key="3" forceRender={true}>
          <SourceSettingsForm
            form={form}
            organizationID={organization?.id.toString()}
            allowedHelpSyncSchedule={props.allowedHelpSyncSchedule}
            editingSource={editingSource}
            type={props.selectedIntegration?.slug ?? ''}
          />
        </Panel>
      </StyledCollapse>

      <ButtonRow>
        <CmdButton onClick={props.onCancel} data-testid={`cancel-sync`}>
          Cancel
        </CmdButton>

        <CmdButton
          disabled={
            !isValid || (audience?.type === 'rule_expression' ? !isConditionGroupValid(audience.expression) : false)
          }
          variant="primary"
          type="submit"
          form="connectIntegration"
          loading={isValidating || isSubmitting}
          data-testid={`connect-sync`}
        >
          {isValidating ? 'Validating' : editingSource ? 'Update' : 'Connect'}
        </CmdButton>
      </ButtonRow>
    </Form>
  );
};

export default SourceConfigForm;
