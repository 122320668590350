import React, { useState, createContext, CSSProperties, useEffect, useContext } from 'react';
import { WidgetPosition, useAnimatedWidgetConfig } from './useAnimatedWidgetConfig';
import { IThemeAnimatedWidgetType, IThemeAnimationType } from '@commandbar/internal/middleware/theme';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import { useDelayUnmount } from '@commandbar/commandbar/shared/util/hooks/useDelayUnmount';

type AnimatedWidgetProps = {
  children: React.ReactNode;
  widget: IThemeAnimatedWidgetType;
  isMounted?: boolean;
  keepMounted?: boolean;
  isOpenByDefault?: boolean;
  enterDelay?: number;
  position?: WidgetPosition;
};

type AnimatedWidgetContextType = {
  animStyles: CSSProperties;
  animType: IThemeAnimationType;
  isAnimatedWidget: boolean;
  durationMS: number;
  setMounted: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  keepMounted?: boolean;
};

const AnimatedWidgetContext = createContext<AnimatedWidgetContextType>({
  animStyles: {},
  durationMS: 0,
  setMounted: () => {
    console.error('No AnimatedWidgetContext.Provider wrapper found');
  },
  setOpen: () => {
    console.error('No AnimatedWidgetContext.Provider wrapper found');
  },
  isAnimatedWidget: false,
  animType: 'instant',
});

/**
 * This wrapper component/context is used to apply animation styles to a widget and is used in combination with the `useAnimatedWidgetConfig` hook to get the animation styles
 * This context is needed mainly because most, if not all, of our widgets use a fixed positioning. We can't simply wrap the widget in an AnimatedWidget and apply container styles to the parent
 * because the widget will lose its fixed positioning because of the transforms applied and will no longer be in the correct location.
 *
 * So instead we calculate the styles needed for the animation store in the context state and apply them to the widget using a `useAnimatedWidget` hook that can be used to get the animation styles
 * and methods to control the animation downstream of the parent on the div that is fixed position directly
 */
export const AnimatedWidget = ({
  children,
  widget,
  isMounted: _isMounted,
  keepMounted,
  isOpenByDefault,
  enterDelay,
  position,
}: AnimatedWidgetProps) => {
  const _ = useStore();

  // Note: `setMounted` is used to control the animation state of the widget only when the widget cannot be controlled by the parent component (ie. Nudges)
  const [isMounted, setMounted] = useState(!!_isMounted || !!keepMounted);
  const [isOpen, setOpen] = useState(!!isOpenByDefault);

  const config = useAnimatedWidgetConfig(widget, { position });
  const shouldRender = useDelayUnmount(!!keepMounted || !!isMounted, config.durationMS);

  const [animStyles, setAnimStyles] = useState(config.initial);

  const enter = () => {
    // FIXME: For some reason Popover nudges appear, but do not animate in without a delay of some kind, so we pass 1ms to the provider
    // See commandbar/src/products/nudges/components/Popover.tsx
    if (enterDelay) {
      const timeout = setTimeout(() => {
        setAnimStyles(config.animated);
        clearTimeout(timeout);
      }, enterDelay);
    } else {
      setAnimStyles(config.animated);
    }
  };

  // When the widget is controlled from the parent via the isMounted prop, we need to respect the isMounted state for visibility
  // When the widget is controlled from the children via `onEnter` or `onExit` we need to respect the isOpen state for visibility
  const isVisible = (!keepMounted && isMounted) || (keepMounted && isOpen);
  useEffect(() => {
    if (config.isProviderEnabled) {
      if (!isVisible) {
        setAnimStyles(config.initial);
      } else if (isVisible && shouldRender) {
        enter();
      }
    }
  }, [isVisible, shouldRender]);

  useEffect(() => {
    setMounted(!!_isMounted);
  }, [_isMounted]);

  return (
    <AnimatedWidgetContext.Provider
      value={{
        animStyles,
        durationMS: config.durationMS,
        setMounted,
        setOpen,
        keepMounted,
        isAnimatedWidget: config.isWidgetAnimated,
        animType: config.type,
      }}
    >
      {config.isProviderEnabled && shouldRender && <>{children}</>}
      {!config.isProviderEnabled && <>{children}</>}
    </AnimatedWidgetContext.Provider>
  );
};

type UseAnimatedWidgetReturnType = {
  animStyles: CSSProperties;
  isAnimatedWidget: boolean;
  onExit: (exitCallback?: () => void, options?: Partial<{ cancellable: boolean }>) => void;
  onEnter: () => void;
};

/**
 * Hook to get the animation styles and methods to control the animation in the child component that the animation needs to be applied
 * See useAnimatedWidgetConfig for more details
 *
 * @returns Animated widget config and methods to control the animation
 */

export const useAnimatedWidget = (): UseAnimatedWidgetReturnType => {
  const context = useContext(AnimatedWidgetContext);

  const onExit = (exitCallback?: () => void, options: Partial<{ cancellable: boolean }> = { cancellable: false }) => {
    if (!context.isAnimatedWidget) {
      exitCallback?.();
      return;
    }

    // When the provider has been told to keep the widget mounted, we're controlling when the widget is open
    if (!context.keepMounted) {
      context.setMounted(false);
    } else {
      context.setOpen(false);
    }

    if (options.cancellable) {
      exitCallback?.();
    } else {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        exitCallback?.();
      }, context.durationMS);
    }
  };

  const onEnter = () => {
    if (!context.isAnimatedWidget) {
      return;
    }

    const timeout = setTimeout(() => {
      // When the provider has been told to keep the widget mounted, we're controlling when the widget is open
      if (!context.keepMounted) {
        context.setMounted(true);
      } else {
        context.setOpen(true);
      }

      clearTimeout(timeout);
    }, 10);
  };

  return { animStyles: context.animStyles, onExit, onEnter, isAnimatedWidget: context.isAnimatedWidget };
};
