import styled from '@emotion/styled';

import { EDITOR_MOBILE_PREVIEW_SCREEN_WIDTH } from '@commandbar/internal/util/operatingSystem';

export const BrowserContent = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const PlainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const CommandBarInlineMount = styled.div<{ isMobilePreview: boolean; show: boolean }>`
  width: ${({ isMobilePreview }) => (isMobilePreview ? `${EDITOR_MOBILE_PREVIEW_SCREEN_WIDTH - 20}px` : '550px')};
  position: absolute;
  top: 40px;
  align-self: center;
  margin-top: 3rem;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};

  @media screen and (max-width: 670px) {
    top: ${({ isMobilePreview }) => (isMobilePreview ? '100px' : '40px')};
    width: ${({ isMobilePreview }) => (isMobilePreview ? '380px' : '550px')};
  }
`;
