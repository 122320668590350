import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { KeywordTooltip } from './keywords/KeywordTooltip';
import { useStore } from 'shared/util/hooks/useStore';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { emptyGlobalStore } from '../store/global-store';
import StyledDocImage from '@commandbar/internal/client/themesV2/components/shared/StyledDocImage';

interface EnrichedHtmlProps {
  html: string;
  preview?: boolean;
}

export const EnrichedHtml: React.FC<EnrichedHtmlProps> = ({ html, preview }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { keywords } = preview ? emptyGlobalStore() : useStore();
  const themeV2 = useThemeV2Context();

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.innerHTML = html;
  }, [html]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    // Find all img elements and add onClick handlers
    const imgs = container.querySelectorAll('img');
    imgs.forEach((img) => {
      const wrapperDiv = document.createElement('div');
      img.parentNode?.replaceChild(wrapperDiv, img);

      ReactDOM.render(<StyledDocImage themeV2={themeV2} imgElement={img} />, wrapperDiv);
    });

    return () => {
      imgs.forEach((img) => {
        const wrapperDiv = img.parentElement;
        if (wrapperDiv) {
          ReactDOM.unmountComponentAtNode(wrapperDiv);
        }
      });
    };
  }, [html, themeV2]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container || keywords.length === 0) return;

    const matchedKeywords = new Set<string>();

    const replaceKeywordInText = (text: string, keyword: string, definition: string) => {
      const regex = new RegExp(`(?<!definition="[^"]*|style="[^"]*)\\b${keyword}(s)?\\b`, 'i');
      return text.replace(regex, (match) => {
        matchedKeywords.add(keyword);
        return `<span data-definition="${definition}">${match}</span>`;
      });
    };

    const keywordslist = keywords.slice().sort((a, b) => b.keyword.length - a.keyword.length);

    const replaceKeywords = (node: Node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        let textContent = node.textContent || '';

        for (const keywordObj of keywordslist) {
          if (!keywordObj.keyword || !keywordObj.is_live || matchedKeywords.has(keywordObj.keyword)) continue;

          textContent = replaceKeywordInText(textContent, keywordObj.keyword, keywordObj.definition);
        }

        if (textContent !== node.textContent || '') {
          const newSpan = document.createElement('span');
          newSpan.innerHTML = textContent;
          node.parentNode?.replaceChild(newSpan, node);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach(replaceKeywords);
      }
    };

    replaceKeywords(container);

    const spans = container.querySelectorAll('span[data-definition]');
    spans.forEach((span) => {
      const definition = span.getAttribute('data-definition');

      // this automatically takes care of nested matches by replacing a spans content with just the text content that won't have the inner span
      const spanContent = span.textContent;
      ReactDOM.render(
        <KeywordTooltip themeV2={themeV2} content={definition}>
          {spanContent}
        </KeywordTooltip>,
        span,
      );
    });

    // Cleanup function to unmount previous tooltips + images divs
    return () => {
      spans.forEach((span) => {
        ReactDOM.unmountComponentAtNode(span);
      });
    };
  }, [html, keywords, themeV2]);

  return <div ref={containerRef} />;
};
