import {
  IAIMessageType,
  IContinuationType,
  IUserAttachmentType,
  IUserMessageType,
} from '@commandbar/internal/middleware/types';
import dayjs from 'dayjs';

export const createAiMessage = (args: {
  uuid?: string | null;
  answer: string;
  incomplete?: boolean;
  continuations?: IContinuationType[];
  no_answer?: boolean;
}): IAIMessageType => {
  const { uuid = null, continuations = [], answer, incomplete = false, no_answer = false } = args;

  return {
    uuid,
    message_type: 'AI',
    incomplete,
    error: '',
    feedback: null,
    no_answer,
    used_fallback: false,
    continuations,
    fallback_ctas: [],
    fallback_labeled_actions: [],
    value: {
      answer,
      command_id: null,
      command_title: '',
      passage_id: null,
      passage_heading: null,
      experiences: [],
      doc: null,
      source_docs: [],
    },
    extras: [],
    modified: dayjs().toString(),
    debug_info: {},
  };
};

export const createUserMessage = (args: {
  uuid?: string | null;
  question: string;
  attachments?: IUserAttachmentType[];
}): IUserMessageType => {
  const { uuid = null, question, attachments } = args;

  return {
    uuid,
    message_type: 'USER',
    value: {
      question,
      command_ids: [],
      attachments: attachments ?? [],
    },
    modified: dayjs().toString(),
  };
};
