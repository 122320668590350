import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { IThemeAnimatedWidgetType, IThemeAnimationType } from '@commandbar/internal/middleware/theme';
import { CSSProperties, useMemo } from 'react';
import { useStore } from 'shared/util/hooks/useStore';
import { getAnimationConfig, getCSSVarValue } from './helpers';
import { IChecklist, INudgePopoverStepType } from '@commandbar/internal/middleware/types';

export type WidgetPosition = IChecklist['position'] | INudgePopoverStepType['form_factor']['position'];

type UseAnimatedWidgetConfig = {
  animated: CSSProperties;
  initial: CSSProperties;
  type: IThemeAnimationType;
  durationMS: number;
  isWidgetAnimated: boolean;
  isProviderEnabled: boolean;
};

type UseAnimatedWidgetConfigOptions = {
  position?: WidgetPosition;
};

/**
 * Hook to get the animation styles and methods to control the animation, used in the AnimatedWidget component to track the correct styles in state
 * NOTE: this hook is used in the AnimatedWidget component and should not be used directly, see internal/src/client/themesV2/components/animations/AnimatedWidget.tsx for more details
 *
 * @param widget - The preset widget to animate
 * @returns Animated and initial styles to apply to widget and animation type
 */
export const useAnimatedWidgetConfig = (
  widget: IThemeAnimatedWidgetType,
  options?: UseAnimatedWidgetConfigOptions,
): UseAnimatedWidgetConfig => {
  const _ = useStore();
  const themeV2 = useThemeV2Context();
  const isProviderEnabled = !!_.flags?.['release-widget-animations'] && !!_.flags?.['release-themes-v2'];
  const config = getAnimationConfig(_, widget, themeV2.themeV2, themeV2.mode, options?.position);
  const prefersReducedMotion = useMemo(() => !!window.matchMedia('(prefers-reduced-motion: reduce)').matches, []);

  const durationMS = config.durationMS;
  const isWidgetAnimated = config.type !== 'instant' && isProviderEnabled && !prefersReducedMotion;

  // It's possible that the transformInitial or transformAnimated CSS vars are not set, in which case we should not apply the transform
  const transformInitial = useMemo(
    () => getCSSVarValue(themeV2.themeV2, themeV2.mode, config.presetVars.transformInitial),
    [themeV2],
  );
  const transformAnimated = useMemo(
    () => getCSSVarValue(themeV2.themeV2, themeV2.mode, config.presetVars.transformAnimated),
    [themeV2],
  );

  const initial = !isWidgetAnimated
    ? {}
    : {
        opacity: 0,
        transform: !!transformInitial ? `var(${config.presetVars.transformInitial})` : undefined,
        transitionProperty: `var(${config.vars.transitionProperty})`,
        transitionTimingFunction: `var(${config.vars.transitionTiming})`,
        transitionDuration: `var(${config.vars.transitionDuration})`,
        transformOrigin: config.additional.transformOrigin,
      };

  const animated = !isWidgetAnimated
    ? {}
    : {
        ...initial,
        opacity: 1,
        transform: !!transformAnimated ? `var(${config.presetVars.transformAnimated})` : undefined,
      };

  return {
    animated,
    initial,
    type: config.type,
    durationMS,
    isWidgetAnimated,
    isProviderEnabled,
  };
};
