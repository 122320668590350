import React, { useEffect, useMemo } from 'react';
import { InterpreterStatus, StateMachine } from 'xstate';
import { useInterpret, useSelector } from '@xstate/react';

import type { ChatHandoffAction, OpenChatAction } from '@cb/types/entities/command/actions';
import type {
  IAIAnswerType,
  IAIMessageType,
  IMessageType,
  IOrganizationType,
  IUserAttachmentType,
} from '@commandbar/internal/middleware/types';

import { IEndUserType } from '@cb/types/entities/endUser';
import { Chat } from '@commandbar/internal/middleware/chat';
import { getSDK } from '@commandbar/internal/client/globals';
import { _user } from '@commandbar/internal/client/symbols';

import ChatStateMachine from './ChatStateMachine';
import ChatMessagePollingMachine from './ChatMessagePollingMachine';
import { fetchAIChatAnswer } from 'shared/sdk/search';
import { useAction } from 'shared/util/hooks/useAction';
import * as GlobalActions from 'shared/store/global-actions';
import { CBStore } from '@commandbar/commandbar/shared/store/global-store';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import { interpolate } from '@commandbar/commandbar/shared/util/Interpolate';
import { getOrCreateChatHandoffInstance } from '@commandbar/internal/middleware/chatHandoff';

export type ChatState = {
  send: (message: string, attachments?: IUserAttachmentType[]) => void;
  refreshChatAfterNegativeFeedback: (chatAndMessageId: IAIAnswerType) => void;
  contactSupport: (action: OpenChatAction | ChatHandoffAction) => void;

  chatID: string;
  chatHistory: IMessageType[];
  partialResponse: IAIMessageType | null;
  userMessage: string | null;
  messageToExternal: string | null;
  userAttachments: IUserAttachmentType[];
  initialUserMessage: string | null | undefined;

  isLoading: boolean;
  isChatHistoryPreview: boolean;
  isReloadingOldChat: boolean;

  handoff: (action: ChatHandoffAction) => void;
};

const ChatStateContext = React.createContext(undefined as unknown as ChatState);

export const PreviewChatStateProvider = ({
  chatHistory,
  children,
  isChatHistoryPreview = false,
}: {
  chatHistory: IMessageType[];
  children: React.ReactNode;
  isChatHistoryPreview?: boolean;
}) => {
  const chatState: ChatState = {
    send: () => {
      /* noop */
    },
    refreshChatAfterNegativeFeedback: () => {
      /* noop */
    },
    contactSupport: () => {
      /* noop */
    },
    chatID: '',
    chatHistory,
    partialResponse: null,
    userMessage: null,
    userAttachments: [],
    initialUserMessage: null,
    messageToExternal: null,

    isLoading: false,
    isChatHistoryPreview: isChatHistoryPreview,
    isReloadingOldChat: false,

    handoff: () => {
      /* noop */
    },
  };

  return <ChatStateContext.Provider value={chatState}>{children}</ChatStateContext.Provider>;
};

export const ChatStateProvider = ({
  isChatMode,
  chatID,
  initialUserMessage,
  organization,
  endUser,
  helphubFilter,
  userPropertiesOverride,
  children,
  initialChatHistory,
}: {
  isChatMode: boolean;
  chatID: string;
  initialUserMessage?: string;
  organization: IOrganizationType;
  endUser: IEndUserType | null | undefined;
  helphubFilter: CBStore['helpHub']['filter'];
  userPropertiesOverride: CBStore['helpHub']['editorCopilotOverrides']['userProperties'];
  children: React.ReactNode;
  initialChatHistory: IMessageType[];
}) => {
  const executeAction = useAction(GlobalActions.executeAction);

  const _ = useStore();
  const { helpHub } = _;

  const helphubFilterRef = React.useRef(helphubFilter);
  useEffect(() => {
    helphubFilterRef.current = helphubFilter;
  }, [helphubFilter]);

  const userPropertiesOverrideRef = React.useRef(userPropertiesOverride);
  useEffect(() => {
    userPropertiesOverrideRef.current = userPropertiesOverride;
  }, [userPropertiesOverride]);

  const machine = useMemo(
    () =>
      ChatStateMachine({
        chatID,
        initialUserMessage,
        organization: {
          ...organization,
          helphub_chat_welcome_message: interpolate(organization.helphub_chat_welcome_message, _, true, false),
        },
        chatHistory: initialChatHistory,
      }),
    [],
  );

  const chatMachineService = useInterpret(machine, {
    actions: {
      'send to agent': (context, event) => {
        getOrCreateChatHandoffInstance(context.chatHandoffType).sendUserMessage(
          organization.id,
          {
            content: context.messageToExternal ?? '',
            attachments: event.attachments,
            chat_id: chatID,
            user: endUser?.slug,
          },
          {
            headers: {
              ...(endUser?.hmac && { 'X-USER-AUTHORIZATION': endUser.hmac }),
              ...(endUser?.slug && { 'X-USER-ID': endUser.slug }),
            },
          },
        );
      },
    },

    services: {
      'open-support-chat': async (context, event) => {
        const messageId = context.chatHistory[context.chatHistory.length - 1]?.uuid || undefined;

        // TOOD: change Engine.executeAction to be async and "await" the success of opening the support chat
        executeAction(event.action, undefined, { messageId });
      },
      'fetch-ai-chat-answer': (context, _event) => {
        let chatAndMessageId: IAIAnswerType | null = null;
        const { userMessage, userAttachments } = context;

        if (_event.type === 'negative feedback') {
          chatAndMessageId = _event.chatAndMessageId;
        } else if (!userMessage) return new Promise((_, reject) => reject('invalid state; userMessage is `null`'));

        return ChatMessagePollingMachine({
          poll: async () => {
            if (!chatAndMessageId) {
              const previewedCopilotSettings = helpHub.previewedCopilotSettings
                ? {
                    ...helpHub.previewedCopilotSettings,
                    name: helpHub.previewedCopilotSettings.name
                      ? interpolate(helpHub.previewedCopilotSettings.name, _, true, false)
                      : null,
                  }
                : undefined;

              const result = await fetchAIChatAnswer(
                organization,
                userMessage as string,
                context.chatID,
                helphubFilterRef.current,
                endUser,
                undefined,
                previewedCopilotSettings,
                userAttachments,
                userPropertiesOverrideRef.current,
                _.isAdmin,
                /* TODO -- to allow canceling inflight request, thread abortController thru here */
              );

              if ('error' in result) {
                throw result.error;
              } else {
                chatAndMessageId = result;
              }
            }
            return await (Chat.readMessage(
              chatAndMessageId.chat_id,
              chatAndMessageId.message_id,
            ) as Promise<IAIMessageType>);
          },
        }) as StateMachine<
          /* TODO: remove this typecast when https://github.com/statelyai/xstate-tools/issues/379 is resolved */
          unknown,
          any,
          any,
          any,
          any,
          any,
          any
        >;
      },
      'retrieve new external messages': (context) => {
        const lastMessageInHistory = context.chatHistory.filter((message) => message.uuid).slice(-1)[0];
        return getOrCreateChatHandoffInstance(context.chatHandoffType).fetchUpdates(
          organization.id,
          chatID,
          lastMessageInHistory?.uuid ? lastMessageInHistory?.uuid : undefined,
        );
      },
      'perform-chat-handoff': ({ chatHandoffType: externalChatHandoffType }) =>
        getOrCreateChatHandoffInstance(externalChatHandoffType).initialize(organization.id, {
          chat_id: chatID,
          user: getSDK()[_user],
        }),
    },
  });

  useEffect(() => {
    if (!isChatMode) return;

    if (chatMachineService.status !== InterpreterStatus.NotStarted) return;

    chatMachineService.start();
  }, [isChatMode]);

  const chatHistory = useSelector(chatMachineService, (state) => state.context.chatHistory);
  const partialResponse = useSelector(chatMachineService, (state) => state.context.partialResponse);
  const userMessage = useSelector(chatMachineService, (state) => state.context.userMessage);
  const messageToExternal = useSelector(chatMachineService, (state) => state.context.messageToExternal);
  const userAttachments = useSelector(chatMachineService, (state) => state.context.userAttachments);

  const isLoading = useSelector(chatMachineService, (state) => state.hasTag('loading'));

  const chatMachine = {
    send: (message: string, attachments?: IUserAttachmentType[]) => {
      chatMachineService.send({ type: 'send', message, attachments });
    },
    refreshChatAfterNegativeFeedback: (chatAndMessageId: IAIAnswerType) => {
      chatMachineService.send({ type: 'negative feedback', chatAndMessageId });
    },

    contactSupport: (action: OpenChatAction | ChatHandoffAction) => {
      chatMachineService.send({ type: 'contact support', action });
    },

    chatID,
    chatHistory,
    partialResponse,
    userMessage,
    messageToExternal,
    userAttachments,
    initialUserMessage,

    isLoading,
    isChatHistoryPreview: false,
    isReloadingOldChat: initialChatHistory.length > 0,

    handoff: (action: ChatHandoffAction) => {
      chatMachineService.send({ type: 'handoff', action });
    },
  };

  return <ChatStateContext.Provider value={chatMachine}>{children}</ChatStateContext.Provider>;
};

const defaultChatMachine: ChatState = {
  send: (_message: string, _attachments?: IUserAttachmentType[]) => {
    return;
  },
  refreshChatAfterNegativeFeedback: (_chatAndMessageId: IAIAnswerType) => {
    return;
  },

  contactSupport: (_action: OpenChatAction | ChatHandoffAction) => {
    return;
  },

  chatID: '',
  chatHistory: [],
  partialResponse: null,
  userMessage: null,
  messageToExternal: null,
  userAttachments: [],
  initialUserMessage: null,

  isLoading: false,
  isChatHistoryPreview: false,
  isReloadingOldChat: false,

  handoff: () => {
    return;
  },
};

export const MockChatStateProvider = ({
  chatHistory = [],
  chatMachine,
  children,
}: {
  chatHistory?: IMessageType[];
  chatMachine?: Partial<ChatState>;
  children: React.ReactNode;
}) => {
  const _chatMachine: ChatState = chatMachine
    ? { ...defaultChatMachine, ...chatMachine }
    : { ...defaultChatMachine, chatHistory };

  return <ChatStateContext.Provider value={_chatMachine}>{children}</ChatStateContext.Provider>;
};

export const useChatState = () => React.useContext(ChatStateContext);
