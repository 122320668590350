/** @jsx jsx  */
import React from 'react';
import styled from '@emotion/styled';
import StyledTimestamp from './StyledTimestamp';
import { jsx } from '@emotion/core';

const Message = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  padding: var(--layout-padding);
  align-items: flex-start;
  gap: var(--layout-gap);
  background: var(--form-control-active-background);
  border-radius: var(--layout-radius-button);
  width: fit-content;
`;

type Props = {
  isBotMessage?: boolean;
  timestamp?: string;
  isMounted?: boolean;
};

const StyledChatMessage = React.forwardRef<HTMLDivElement, Props & React.HTMLAttributes<HTMLDivElement>>(
  ({ children, isBotMessage, timestamp, isMounted = true, ...props }, ref) => {
    return isMounted ? (
      <Message
        ref={ref}
        style={{
          ...(isBotMessage && {
            maxWidth: '100%',
            background: 'var(--background-secondary)',
          }),
        }}
        {...props}
      >
        {children}
        {timestamp && <StyledTimestamp time={timestamp} />}
      </Message>
    ) : null;
  },
);

export default StyledChatMessage;
