import { CmdEmpty, CmdTooltip, CmdTypography } from '@commandbar/design-system/cmd';
import { InfoCircle } from '@commandbar/design-system/icons/react';
import styled from '@emotion/styled';
import PercentChangeTag from './PercentChangeTag';
import { formatMetric } from '../utils';

export interface StateLegendItemProps {
  title: string;
  tooltip?: string;
  metricTooltip?: string;
  percentageChange?: number | null;
  metric?: number;
  lineColor?: string;
  isLoading?: boolean;
  format?: 'number' | 'percentage';
  inverted?: boolean;
}

const StateLegendItemContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const HorizontalLine = styled.div`
  width: 4px;
  height: 44px;
  background-color: ${(props) => props.color};
  border-radius: 4px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MetricContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LegendItemTitleContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  line-height: normal;
`;

const TooltipItemTrigger = styled.span`
  height: 16px;
  display: flex;
`;

export const StateLegendItem = ({
  title,
  tooltip,
  percentageChange,
  metric,
  lineColor,
  isLoading,
  metricTooltip,
  format = 'number',
  inverted = false,
}: StateLegendItemProps) => {
  return (
    <StateLegendItemContainer>
      {!!lineColor && <HorizontalLine color={lineColor} />}
      {isLoading ? (
        <CmdEmpty width={100} height={44} />
      ) : (
        <DetailsContainer>
          <LegendItemTitleContainer>
            <CmdTypography.Body fontWeight="semi-bold" variant="tertiary">
              {title}
            </CmdTypography.Body>
            {tooltip && (
              <CmdTooltip message={tooltip}>
                <TooltipItemTrigger>
                  <InfoCircle color="#797C85" />
                </TooltipItemTrigger>
              </CmdTooltip>
            )}
          </LegendItemTitleContainer>
          <MetricContainer>
            {metricTooltip ? (
              <CmdTooltip message={metricTooltip}>
                <CmdTypography.H3 fontWeight="bold">{formatMetric(metric, format)}</CmdTypography.H3>
              </CmdTooltip>
            ) : (
              <CmdTypography.H3 fontWeight="bold">{formatMetric(metric, format)}</CmdTypography.H3>
            )}
            {!!percentageChange && <PercentChangeTag inverted={inverted} rate={percentageChange} />}
          </MetricContainer>
        </DetailsContainer>
      )}
    </StateLegendItemContainer>
  );
};
