import React, { RefObject } from 'react';
import type {
  IOrganizationType,
  IRecordSettingsByContextKey,
  ICommandType,
  IUserContext,
  ICallbackMap,
  IPlaceholderType,
  IEnvironmentType,
  ICommandCategoryType,
  ISkinType,
  ITabType,
  IChecklist,
  IAdditionalResource,
  IRecommendationSet,
  IContinuationType,
  INudgeType,
  OptionGroupRenderAsType,
  IExperienceHitType,
  ICopilotSettingsPreviewType,
  IKeyword,
  IPageMetadata,
} from '@commandbar/internal/middleware/types';
import type { IThemeV2Type, IThemeType } from '@cb/types/entities/theme';
import type { WidgetTableauSelection } from '@cb/types/misc/widgetTableau';

import type { IEndUserType, IDecideResponseType } from '@cb/types/entities/endUser';
import { DashboardType, GetRecordsService, Option, OptionGroup, Step, StepType } from 'products/spotlight/types';

import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import memoizeOne from 'memoize-one';
import { DEFAULT_PLACEHOLDER } from 'products/spotlight/components/select/input/placeholderHelpers';
import { PlatformType, getOperatingSystem } from '@commandbar/internal/util/operatingSystem';

import { CustomComponent, FormFactorConfig, ProductConfig } from '@commandbar/internal/client/CommandBarClientSDK';
import { emptyEndUserStoreState, EndUserStore } from 'shared/store/end-user/state';
import type { ITheme } from '@commandbar/internal/client/theme';
import type { Flags } from '@cb/types/flags';

import { proxy, ref } from 'valtio';
import { proxyMap } from 'valtio/utils';
import { RenderMode } from 'products/nudges/components/RenderNudge';

import { ISelectHandle } from 'products/spotlight/components/select/CommandSelect';
import { CommandBarClientSDK } from '@commandbar/internal/client/CommandBarClientSDK';

import type { NudgeManagerService } from 'products/nudges/types';
import { TriggerableEntity, TriggerableEntityId } from '@commandbar/internal/middleware/helpers/pushTrigger';
import { initSharedSubs } from './global-subscriptions';
import { initSpotlightSubs } from 'products/spotlight/subscriptions';
import { initNudgesSubs } from 'products/nudges/subscriptions';
import { initChecklistSubs } from 'products/checklists/subscriptions';
import { CommandOption } from '@commandbar/commandbar/products/spotlight/service-selectors';
import { HistoryItem, NavigateOptions } from '../util/router';
import { PreviewState } from '@commandbar/internal/client/extension/preview';
import { RecorderState } from '@commandbar/internal/client/extension/recorder';
import { LocalizedMessages } from '@commandbar/internal/middleware/localizedMessage';

export type AvailabilityCallback = (_: CBStore) => Option[];

// Copied from https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/lodash/common/function.d.ts
export interface DebouncedFunc<T extends (...args: any[]) => any> {
  /**
   * Call the original function, but applying the debounce rules.
   *
   * If the debounced function can be run immediately, this calls it and returns its return
   * value.
   *
   * Otherwise, it returns the return value of the last invocation, or undefined if the debounced
   * function was not invoked yet.
   */
  (...args: Parameters<T>): ReturnType<T> | undefined;

  /**
   * Throw away any pending invocation of the debounced function.
   */
  cancel(): void;

  /**
   * If there is a pending invocation of the debounced function, invoke it immediately and return
   * its return value.
   *
   * Otherwise, return the value from the last invocation, or undefined if the debounced function
   * was never invoked.
   */
  flush(): ReturnType<T> | undefined;
}

/** What's the purpose of this function availabilityDependenciesSnapshot? Great question.
 *  - We have a list of options called `initialOptions` that are the base of options used to show in Spotlight
 *  - To make this list, we run `available()` a bunch of times to figure out which options are available. This is expensive
 *  - There are known dependencies that will impact this list **when spotlight is open** (e.g., records, callbacks, metadata) &
 *    there's a reasonable user expectation that the list should change if these change mid-search.
 *    These are dependencies in a subscription to recalculate `initialOptions`
 *  If we did that only, then we'd still have to run availability everytime spotlight is opened. This is because there are other
 *  dependencies that could change _between_ searches (e.g., recents, page location). This would be expensive
 *  The purpose of this function is to memoize that call, to make spotlight opens fast. Whenever spotlight is opened, we'll
 *  check the dependencies below.
 *
 *  12-27-23 note: This used to be a deny list, but we changed it to an allow list. This means that it should be more efficient,
 *  but could cause bugs if you assume that availability is re-calculated on every open.
 *
 *  TLDR: If you expect spotlight to have options that it doesn't, but those options show up later in the session, look here.
 *
 *  FIXME: This is pretty ugly. Path forward here is (preferred) make availability super performant so we don't need this anymore or (b) make all keys *  in this list subscription dependencies
 */
const spotlightAvailabilityDependenciesSnapshot = (snapshot: CBStore) => {
  return {
    /** Known impact on option availability  */
    commands: snapshot.commands,
    programmaticCommands: snapshot.programmaticCommands,
    hotloadedCommands: snapshot.hotloadedCommands,
    localContextSettings: snapshot.localContextSettings,
    serverContextSettings: snapshot.serverContextSettings,
    context: snapshot.context,
    records: snapshot.spotlight.records,
    callbacks: snapshot.callbacks,
    testMode: snapshot.testMode,
    endUserStore: snapshot.endUserStore,
    location: snapshot.location,
    currentStepIndex: snapshot.spotlight.currentStepIndex,
  };
};

/**
 * Given two snapshots of the `engine` state tree, this will return true if there is a need to recompute option
 * availability.
 */
const needToComputeOptions = (prevSnapshot: CBStore, nextSnapshot: CBStore) => {
  if (!nextSnapshot.spotlight.visible) return false;

  const prevEngine = spotlightAvailabilityDependenciesSnapshot(prevSnapshot);
  const nextEngine = spotlightAvailabilityDependenciesSnapshot(nextSnapshot);
  const equal = isEqual(prevEngine, nextEngine);
  return !equal;
};

type Records = {
  records: any[];
};

type HelpDocBase = {
  command: ICommandType;
  excerpt: string;
};

export type NudgeEventListenerType = Partial<{
  [key in INudgeType['trigger']['type']]: (e: any) => void;
}>;

export type NudgeBannerSpacerListenerType = {
  [id: string]: () => void;
};

export type HelpHubDoc = HelpDocBase &
  (
    | {
        type: 'helpdoc';
      }
    | {
        type: 'link';
      }
    | {
        type: 'video';
      }
    | {
        type: 'other';
      }
  );
export interface ISearchFilter {
  slug: string;
  fn: (option: Option) => boolean;
  renderAs: OptionGroupRenderAsType;
  inputTag?: string; // Optional tag to show to the left of the input
  placeholder?: string; // Placeholder when the search filter is active
  // FIXME: we need a better way to handle this. It's a way to avoid "No results" if the options are async
  emptyInputMessage?: string; // Do we want to show "No results" if a user hasn't typed and there are no results?
}

export interface CBStore {
  spotlight: {
    // loading: FIXME: some of these can probably move out of state
    loadingByKey: Record<string, boolean>;
    showLoadingIndicator: boolean;
    inMinLoadingWindow: boolean;
    minLoadingTimeout: number | undefined;

    builtinAskCopilotOption: null | CommandOption;

    // deprecated?
    emptyMessage: undefined | string;
    previewMode: boolean;

    // core
    visible: boolean /* command bar visibility */;
    refContainer: React.RefObject<ISelectHandle>;
    searchOptionsDebouncer: DebouncedFunc<(cb: VoidFunction) => void>;
    dashboard: undefined | DashboardType;
    inputText: string;
    rawInput: string;
    steps: Step[];
    formFactor: FormFactorConfig;

    // menu options + grouping
    /* the available set of initial options (doesn't include async options or synthetic options created during a search) */
    initialOptions: Option[];
    /* the current set of options */
    currentOptions: Option[];
    /* sorted & filtered set of currentOptions */
    sortedOptions: (Option | OptionGroup)[];
    /* count of available options by tab group key */
    optionCountsByTabGroup: Map<string, number>;
    /* the current set of OptionGroups */
    currentGroups: OptionGroup[];
    expandedGroupKeys: string[];
    // Indicates the virtual focus on MenuList.
    // When set to -1, allows focus to move outside the of the MenuList.
    focusedIndex: number;
    // Indicates focusable element index on MenuRows (e.g. "See More" on OptionGroupHeaders).
    // WHen set to -1, there are no focusable elements within the MenuRow.
    focusableChildIndex: number;
    // NOTE: The proxy version must be passed as an argument because it needs to be evaluated as part of the memoization
    // logic (it cannot be calculated by calling getVersion on the first argument because that's a reference that will
    // always point to the live store object, thus always returning the same version number in this context).
    availableOptionMemoizer: (_: CBStore, stateSnapshot: CBStore, cb: AvailabilityCallback) => Option[];
    currentStepIndex: number;

    // records
    /* records fetching machines */
    recordsMachines: Map<string, { callback: any; service: GetRecordsService }>;
    /* "search callbacks" will load records into here via state machine defined in "get-records-machine.ts" */
    records: Record<string, Records>;

    // Analytics
    // Store command execution history by command id.
    executionHistory: string[];
    previousInputText: string;
    // Deadend tracking
    isBackspacing: boolean;
    cachedInput: string;
    reportKeystrokeDebouncer: DebouncedFunc<(cb: VoidFunction) => void>;

    // features
    showKeyboardShortcutCheatsheet: boolean;
    searchFilter: ISearchFilter | undefined;
    userDefinedCustomComponents: Record<
      Parameters<CommandBarClientSDK['setCustomComponent']>[0],
      (meta?: { step?: Exclude<StepType, StepType.Execute>; activeTab?: string }) => string | CustomComponent | null
    >;
    // If a command fails due to an error (e.g. because it is unavailable):
    //   `errorIndex` is set to the index of the failed command
    //   `errorTimestamp` is set to the current timestamp from Date.now()
    //
    // NOTE: In the future, we would like to store this in Option; for now,
    // there's no way to do that, because mutating state inside Option doesn't
    // cause a re-render
    errorIndex: number;
    errorTimestamp: number;

    // placeholders
    activePlaceholder: string;
    placeholders: IPlaceholderType[];

    // tabs
    tabs: ITabType[];

    // categories
    // map from category name or ID to local category config
    categoryConfig: Record<number, Partial<ICommandCategoryType>>;
    serverCategories: ICommandCategoryType[];
    localCategories: ICommandCategoryType[];
    categories: ICommandCategoryType[];
  };
  /** Old "App State" */

  active: boolean;

  envOverride: { env: string } | { version: string } | null;
  env: string | null;
  version: string | null;
  airgap: boolean;

  // isEditorActive means the editor frame should be/or is loaded, acting as a state replacement for Proxy
  isEditorActive: boolean;
  isEditorVisible: boolean;
  editorPathChangeListeners: ((path: string) => void)[];
  initialEditorPath: string | null;
  editorPreviewDevice: 'mobile' | 'desktop';
  /************************************/
  /**** Availability Dependencies *****/
  /************************************/

  /* current URL */
  location: Location;

  /* Organization */
  organization?: IOrganizationType;

  products: ProductConfig;

  endUser: IEndUserType | null | undefined;
  endUserStore: EndUserStore;

  decide: IDecideResponseType | null | undefined;

  logo: string;
  themeSource: string;

  /* Object search settings -- HACK to remove*/
  serverContextSettings: IRecordSettingsByContextKey;
  localContextSettings: IRecordSettingsByContextKey;

  // merge({}, serverContextSettings, localContextSettings)
  contextSettings: IRecordSettingsByContextKey;

  /* commands */
  programmaticCommands: ICommandType[];
  commands: ICommandType[];
  hotloadedCommands: ICommandType[];
  mockCommands: ICommandType[];

  /* complete context (specified by client through window) */
  context: IUserContext;

  pageMetadata: IPageMetadata;

  /* all callbacks */
  callbacks: ICallbackMap;

  /* an admin can preview and edit commands */
  testMode: boolean;

  /* the user is an admin */
  isAdmin: boolean;

  /* available environments for env switcher -- only relevant for admins */
  environments: IEnvironmentType[] | null;

  /* feature flags */
  flags: Flags | null;

  /*******************************/
  /**** Command Bar Snapshot *****/
  /*******************************/

  /* have the commands loaded? */
  commandsLoaded: boolean;

  baseTheme: undefined | string | Pick<ISkinType, 'logo' | 'skin'>;
  primaryColor: undefined | string;

  /*
   * We use xstate to define and interact with the state machine that handles nudges.
   * Services are interpreted state machines, and we can use them in a couple ways:
   * - Peek inside with service.getSnapshot().
   * This will return the current state of the machine including its context.
   * - service.send() to send events to the machine.
   * The services are fully typed, so you can see which actions are available
   * to send and what data need to be included.
   */
  nudgeManager: NudgeManagerService | null;
  currentModalNudge: {
    nudge: INudgeType;
    stepIndex: number;
    stepCount?: string;
    renderMode: RenderMode;
  } | null;
  nudgeDebugToolBar: {
    position: 'bottom' | 'top';
    bypassGlobalLimit: boolean;
    visible: boolean;
    closeTabWhenToolbarClosed: boolean;
  };

  nudgeEventListeners: NudgeEventListenerType;

  nudgeBannerSpacersListeners: NudgeBannerSpacerListenerType;

  triggerableSelectors: string[];
  timedTriggers: Map<
    { id: TriggerableEntityId; entity: TriggerableEntity & { trigger: { type: 'after_time' } } },
    NodeJS.Timeout
  >;

  checklists: IChecklist[];
  activeChecklist: IChecklist | null;
  queuedChecklist: IChecklist | null;

  keywords: IKeyword[];

  platform: PlatformType;

  detailPreviewGenerator: any;

  components: {
    [key: string]:
      | {
          name: string;
          component: CustomComponent;
        }
      | undefined;
  };

  theme?: ITheme;
  themeV2Override?: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  themeV2Mode: 'light_mode' | 'dark_mode' | 'auto';

  showWidgetTableau: boolean;
  widgetTableauSelection: WidgetTableauSelection;

  editingEndUserShortcutSlug: string | null;

  helpHub: {
    visible: boolean;
    key: number;
    minimized: boolean;
    loading: boolean;
    scrollPosition: number;
    query: string | null;
    searchResults: HelpHubDoc[];
    experienceSearchResults: IExperienceHitType[];
    hubDoc: HelpHubDoc | null;
    hubDocHeading: string | null;
    recommendationSets: IRecommendationSet[] | null;
    previewedRecommendationSet: IRecommendationSet | null;
    previewedCopilotSettings: ICopilotSettingsPreviewType | null;
    additionalResources: IAdditionalResource[];
    parsingUrlParams: boolean;
    searchSuggestions: { loading: false; continuations: IContinuationType[] } | { loading: true };
    gaveFeedback: Record<string, number>;
    chatOnly: boolean;
    filter: { labels?: string[] } | null;
    initialPage: { item: HistoryItem; options?: NavigateOptions } | null;
    activeChat: string | null;
    editorCopilotOverrides: {
      user?: string | undefined;
      userProperties?: Record<string, any> | undefined;
      filter?: string[] | undefined;
    };
  };

  trackedAppEvents: Set<string>;

  extension: {
    recorder: RecorderState;
    preview: PreviewState;
  };

  localizedMessages: LocalizedMessages;
  themes: IThemeType[];
}

/** FIXME: VA: Importing this from the service causes issues for tests */
const initEmptyBaseStep = (): Step => ({
  type: StepType.Base,
  completed: false,
  resource: null,
  selected: null,
});

export const emptyGlobalStore = (): CBStore => ({
  spotlight: {
    loadingByKey: {},
    showLoadingIndicator: false,
    inMinLoadingWindow: false,
    minLoadingTimeout: undefined,
    emptyMessage: undefined,
    previewMode: false,

    builtinAskCopilotOption: null,

    visible: false,
    refContainer: null as unknown as RefObject<ISelectHandle>,
    searchOptionsDebouncer: debounce((cb) => cb(), 150),
    dashboard: undefined,
    inputText: '',
    rawInput: '',
    // Steps should always have at least one actionable step; this is the default
    steps: [initEmptyBaseStep()],
    formFactor: { type: 'modal' },

    initialOptions: [],
    currentOptions: [],
    sortedOptions: [],
    optionCountsByTabGroup: new Map(),
    currentGroups: [],
    expandedGroupKeys: [],
    focusedIndex: 0,
    focusableChildIndex: -1,
    // NOTE: instead of importing a function here (would cause a dependency cycle) or defining it here (would be
    // sloppy and might still cause a cycle), the caller is instead expected to pass the implementation
    // (dependency-injection style) of the function at call time.
    availableOptionMemoizer: memoizeOne(
      (_, _engine, cb) => cb(_),
      // The callback argument is expected to be static and is ignored entirely for this equality function.
      ([_n, nextSnapshot], [_p, prevSnapshot]) =>
        prevSnapshot === nextSnapshot || !needToComputeOptions(prevSnapshot, nextSnapshot),
    ),
    currentStepIndex: 0,

    recordsMachines: proxyMap(),
    records: {},

    executionHistory: [],
    previousInputText: '',
    isBackspacing: false,
    cachedInput: '',
    reportKeystrokeDebouncer: debounce((cb) => cb(), 500),

    searchFilter: undefined,
    showKeyboardShortcutCheatsheet: false,
    userDefinedCustomComponents: {
      footer: () => null,
      header: () => null,
      tabHeader: () => null,
      menuHeader: () => null,
      input: () => null,
      sidepanel: () => null,
      navPaneHeader: () => null,
      navPaneFooter: () => null,
      defaultState: () => null,
      emptyState: () => null,
    },
    errorIndex: -1,
    errorTimestamp: 0,

    activePlaceholder: DEFAULT_PLACEHOLDER,
    placeholders: [],

    tabs: [],

    categories: [],
    categoryConfig: {},
    serverCategories: [],
    localCategories: [],
  },
  /** Old 'app' state */

  active: false,

  env: null,
  envOverride: null,
  version: null,
  airgap: false,
  isEditorActive: false,
  isEditorVisible: false,
  editorPathChangeListeners: [],
  initialEditorPath: null,
  editorPreviewDevice: 'desktop',

  /** Old 'engine' state */
  location: ref(window.location),

  organization: undefined,
  products: ['spotlight', 'nudges', 'checklists', 'help_hub'],
  endUser: undefined,
  endUserStore: emptyEndUserStoreState(),
  decide: undefined,
  logo: '',
  themeSource: '',

  localContextSettings: {},
  serverContextSettings: {},
  contextSettings: {},

  programmaticCommands: [],
  commands: [],
  hotloadedCommands: [],
  mockCommands: [],

  pageMetadata: {},
  context: {},
  callbacks: {},
  testMode: false,
  isAdmin: false,
  environments: null,

  flags: null,

  commandsLoaded: false,

  baseTheme: undefined,
  primaryColor: undefined,

  nudgeManager: null,
  currentModalNudge: null,
  nudgeDebugToolBar: {
    visible: false,
    closeTabWhenToolbarClosed: false,
    bypassGlobalLimit: true,
    position: 'bottom',
  },

  nudgeEventListeners: {
    on_rage_click: undefined,
    on_user_confusion: undefined,
    smart_delay: undefined,
  },

  nudgeBannerSpacersListeners: {},

  checklists: [],
  activeChecklist: null,
  queuedChecklist: null,

  keywords: [],

  triggerableSelectors: [],
  timedTriggers: new Map(),

  platform: getOperatingSystem(),

  detailPreviewGenerator: null,
  components: {},

  theme: undefined,

  editingEndUserShortcutSlug: null,

  helpHub: {
    visible: false,
    key: 0,
    minimized: false,
    loading: false,
    scrollPosition: 0,
    query: null,
    searchResults: [],
    experienceSearchResults: [],
    hubDoc: null,
    hubDocHeading: null,
    recommendationSets: null,
    previewedRecommendationSet: null,
    previewedCopilotSettings: null,
    additionalResources: [],
    parsingUrlParams: false,
    searchSuggestions: { loading: true },
    gaveFeedback: {},
    chatOnly: false,
    filter: null,
    initialPage: null,
    activeChat: null,
    editorCopilotOverrides: {},
  },

  trackedAppEvents: new Set(),
  showWidgetTableau: false,
  widgetTableauSelection: { type: 'all', id: '' },
  themeV2Mode: 'light_mode',
  extension: {
    preview: { enabled: false },
    recorder: { enabled: false },
  },
  localizedMessages: {},
  themes: [],
});

export const initValtioGlobalStore = (): CBStore =>
  proxy({
    ...emptyGlobalStore(),
  });

export const initGlobalSubs = (_: CBStore): VoidFunction => {
  const unsubs = [...initSharedSubs(_), ...initSpotlightSubs(_), ...initNudgesSubs(_), ...initChecklistSubs(_)];
  return () => unsubs.forEach((unsub) => unsub());
};
