import { useAppContext } from 'editor/src/AppStateContext';
import Layout from '../../components/Layout';
import ActionClicksTable from './ActionClicksTable';
import PageClicksTable from './PageClicksTable';
import StackedBarChart from './StackedBarChart';
import useAnalyticSpotlight from './useAnalyticSpotlight';

const AnalyticsSpotlightDashboard = () => {
  const { data, isLoading, fetchSpotlightData, error, handleDownloadCsv, isDownloading } = useAnalyticSpotlight();
  const { organizationSettings } = useAppContext();

  return (
    <Layout
      title="Spotlight"
      disableDemoData={isLoading}
      isDownloading={isDownloading}
      onDownloadCSV={[
        {
          label: 'Actions',
          action: () => handleDownloadCsv('actions'),
        },
        {
          label: 'Pages',
          action: () => handleDownloadCsv('pages'),
        },
      ]}
    >
      <div style={{ display: 'flex', gap: '16px', flexDirection: 'column', width: '100%' }}>
        <StackedBarChart
          data={data?.stacked_bar_chart_data}
          isLoading={isLoading}
          title="Activity"
          subTitle="See how users have been interacting with Spotlight"
          legendItems={[
            {
              title: 'Opens',
              percentageChange: data?.opens_total?.percent_change,
              metric: data?.opens_total?.metric,
              isLoading: isLoading,
            },
            {
              title: 'Page clicks',
              percentageChange: data?.links_total?.percent_change,
              metric: data?.links_total?.metric,
              lineColor: '#4a70f2',
              isLoading: isLoading,
            },
            {
              title: 'Action clicks',
              percentageChange: data?.actions_total?.percent_change,
              metric: data?.actions_total?.metric,
              lineColor: '#F87171',
              isLoading: isLoading,
            },
            {
              title: organizationSettings?.spotlight_ask_copilot_label ?? 'Ask Copilot',
              percentageChange: data?.copilot_queries_total?.percent_change,
              metric: data?.copilot_queries_total?.metric,
              isLoading: isLoading,
            },
            {
              title: 'Success rate',
              percentageChange: data?.success_rate?.percent_change,
              metric: data?.success_rate?.metric,
              isLoading: isLoading,
              format: 'percentage',
            },
          ]}
        />

        <div style={{ display: 'flex', gap: '16px', flexFlow: 'wrap' }}>
          <div style={{ flex: 1 }}>
            <PageClicksTable
              pageClicksData={data?.page_clicks_table}
              isLoading={isLoading}
              fetchSpotlightData={fetchSpotlightData}
              error={!!error}
            />
          </div>

          <div style={{ flex: 1 }}>
            <ActionClicksTable
              actionClicksData={data?.action_clicks_table}
              isLoading={isLoading}
              fetchSpotlightData={fetchSpotlightData}
              error={!!error}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AnalyticsSpotlightDashboard;
