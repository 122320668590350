import { CmdColumnDef, CmdSortableColumn, CmdSwitch } from '@commandbar/design-system/cmd';
import { PencilLine, Plus } from '@commandbar/design-system/icons/react';
import { useState } from 'react';
import { TableRowData } from '@commandbar/internal/middleware/analytics/common';
import StatCard from '../../components/StatCard';
import { Link } from 'react-router-dom';
import { formatPercentage, formatRelativeTime } from '../../utils';
import ActionDropdown from '../../components/ActionDropdown';
import Table from '../../components/Table';
import Layout from '../../components/Layout';
import { StatusBadge } from '@commandbar/design-system/components/antd';

import useAnalyticSurvey from './useAnalyticSurvey';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import * as nudgeSearchParams from '@commandbar/internal/proxy-editor/nudge_search_params';
import { AnalyticsPage, getAnalyticsDashboardRoute } from 'commandbar.com/src/components/Home';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const AnalyticsSurveysDashboard = () => {
  const [showUnpublished, setShowUnpublished] = useState<boolean>(false);
  const { isLoading, error, fetchSurveyData, downloadCsv, isDownloading, data } = useAnalyticSurvey({
    showUnpublished,
  });

  const { user } = useAuth();

  const columns: CmdColumnDef<TableRowData, string>[] = [
    {
      accessorKey: 'nudge_slug',
      header: 'Survey',
      enableGlobalFilter: true,
      cell: (r) => {
        const nudgeId = r.row.original.nudge_id;
        const isLive = !!r.row.original.is_live;

        return (
          <Link to={`/analytics/surveys/${nudgeId}`}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {showUnpublished && <StatusBadge color={isLive ? 'green' : 'orange'} />}
              {r?.getValue() || 'Survey'}
            </div>
          </Link>
        );
      },
    },
    {
      accessorKey: 'total_responses',
      header: ({ column }) => <CmdSortableColumn column={column} title="Responses" />,
    },
    {
      accessorKey: 'completion_rate',
      header: ({ column }) => <CmdSortableColumn column={column} title="Response rate" />,
      cell: ({ cell }) => formatPercentage(cell.getValue()),
    },
    {
      accessorKey: 'last_shown',
      header: ({ column }) => <CmdSortableColumn column={column} title="Last seen (UTC)" />,
      cell: ({ cell }) => formatRelativeTime(cell.getValue() as string),
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    columns.push({
      accessorKey: 'action',
      header: '',
      width: 40,
      cell: ({ row }) => (
        <ActionDropdown
          options={[
            {
              label: 'Edit',
              icon: <PencilLine />,
              url: `/editor/surveys/${row.original.nudge_id}`,
            },
          ]}
        />
      ),
    });
  }

  return (
    <Layout title="Surveys" disableDemoData={isLoading} isDownloading={isDownloading} onDownloadCSV={downloadCsv}>
      <div
        style={{
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '16px',
          }}
        >
          <StatCard
            isLoading={isLoading}
            title="Active"
            subtitle="Total live surveys"
            format="number"
            metric={data?.active?.metric}
          />
          <StatCard
            title="Responses"
            subtitle="Total responses received from users"
            format="number"
            metric={data?.responses?.metric}
            rate={data?.responses?.percent_change}
            isLoading={isLoading}
          />
          <StatCard
            title="Response rate"
            subtitle="Avg response rate for active surveys"
            format="percentage"
            metric={data?.completion_rate?.metric}
            rate={data?.completion_rate?.percent_change}
            isLoading={isLoading}
          />
        </div>
        <div>
          <Table
            columns={columns}
            data={data?.table.rows || []}
            isLoading={isLoading}
            empty={{
              error: !!error,
              refetchData: fetchSurveyData,
              additionalActions: [
                {
                  label: 'Create a survey',
                  icon: <Plus />,
                  to: `/editor${editorRoutes.SURVEYS_ROUTE}/new?${nudgeSearchParams.FROM}=${getAnalyticsDashboardRoute(
                    AnalyticsPage.SURVEYS,
                  )}`,
                },
              ],
            }}
            toolBarChildren={
              <div>
                <CmdSwitch
                  checked={showUnpublished}
                  onCheckedChange={() => setShowUnpublished(!showUnpublished)}
                  onLabel="Show unpublished, but previously viewed Surveys"
                  offLabel="Show unpublished, but previously viewed Surveys"
                  tooltip="Surveys that are (a) unpublished; (b) have at least one view."
                />
              </div>
            }
          />
        </div>
      </div>
    </Layout>
  );
};

export default AnalyticsSurveysDashboard;
