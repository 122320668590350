import React from 'react';
import { Alert, Input, Form, Select } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

import { Eye, EyeOff } from '@commandbar/design-system/icons/react';
import { filterDryRunData } from '../shared/utils';

const Freshdesk: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.FRESHDESK,
  name: 'Freshdesk',
  slug: 'freshdesk',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/freshdesk-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/freshdesk-icon.png',
  useDryRun: true,
  isDisabled: false,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={"In order to import Freshdesk help center docs, you'll have to first find your Freshdesk API Key."}
    />
  ),
  route: '/freshdesk',

  getForm({ dryRunData, editingSource, triggerDryRunButton }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={editingSource?.type + 'api_key'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.api_key}
          required={true}
          label={'API Key'}
          name={'api_key'}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: `Please enter your API Key. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input.Password
            iconRender={(visible) => (visible ? <Eye height={16} /> : <EyeOff height={16} />)}
            style={{ width: '100%' }}
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          key={editingSource?.type + 'domain'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.domain}
          required={true}
          label={'Domain'}
          name={'domain'}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              pattern: /^(www\.)?[a-zA-Z0-9@:%.\-_+~#=]{2,256}\.freshdesk.com$/gi,
              message: `Please enter a valid URL (e.g., yourdomain.freshdesk.com). Don't include 'https' prefixes or trailing slashes. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input style={{ width: '100%' }} placeholder="yourdomain.freshdesk.com" />
        </Form.Item>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Form.Item
            key={editingSource?.type + 'folders'}
            validateTrigger="onSubmit"
            initialValue={dryRunData}
            label="Folders"
            name="folders"
            rules={[{ required: true, message: 'This is a required field.' }]}
            style={{ flex: 5 }}
            required
          >
            <Select
              size="large"
              mode="multiple"
              style={{ width: '100%' }}
              options={dryRunData}
              defaultValue={dryRunData}
              maxTagCount="responsive"
              optionFilterProp="label"
              allowClear
              labelInValue
            />
          </Form.Item>
          <div style={{ marginTop: '12px' }}>{triggerDryRunButton}</div>
        </div>
      </>
    );
  },

  getInitialDryRunData(sourceId) {
    return this.sources?.find((i) => i.id === sourceId)?.meta?.folders;
  },

  getDryRunData(result) {
    return JSON.parse(result.data).folders.map(
      (folder: { name: string; id: string | number; host_domain: string }) => ({
        label: folder.name,
        value: Number(folder.id),
        key: String(folder.id),
        host_domain: folder.host_domain,
      }),
    );
  },

  getPayloadMeta(data, dryRunData) {
    const meta = this.requiredFields.reduce((a, v) => ({ ...a, [v]: data[v] }), {});
    const bonusMeta =
      dryRunData && dryRunData.length > 0 ? { folders: filterDryRunData(data['folders'], dryRunData) } : {};
    return { ...meta, ...bonusMeta };
  },

  requiredFields: ['domain', 'api_key'],
  fetchedFields: ['folders'],
};

export default Freshdesk;
