/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';
import { CSSObject, jsx } from '@emotion/core';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import Arrow from '@commandbar/commandbar/products/nudges/components/Arrow';

// default styles that are editable in the the theme editor
export const styles: CSSObject = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  background: 'var(--background-primary)',
  width: '100%',
  borderRadius: 'inherit',

  a: {
    color: 'var(--content-link)',
    textDecoration: 'none',

    '&:hover': {
      color: 'var(--content-link-hover)',
      textDecoration: 'underline',
    },
  },
};

const containerStyles: CSSObject = {
  position: 'relative',
  width: 'var(--popover-width)',
  maxWidth: 'var(--tooltip-max-width)',
  borderRadius: 'var(--layout-radius-card)',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'var(--border-primary)',
  boxShadow: 'var(--layout-shadow) var(--layout-shadow-color)',
  pointerEvents: 'all',
};

const surveyStyles: CSSObject = {
  minWidth: 'var(--popover-width)',
  width: 'unset',
  maxWidth: 'unset',
};

const keywordStyles: CSSObject = {
  width: 'unset',
};

// extra styles that an org cannot edit
const Tooltip = styled.div`
  z-index: ${Z.Z_NUDGE};
`;

const Container = styled.div``;

type StyledTooltipProps = {
  variant: 'survey' | 'keyword' | 'default';
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
  arrow?: boolean;
};

const StyledTooltip = React.forwardRef<HTMLDivElement, StyledTooltipProps & React.HTMLAttributes<HTMLDivElement>>(
  ({ children, title, arrow, variant, themeV2, ...props }, ref) => {
    return (
      <Container
        ref={ref}
        css={[
          containerStyles,
          variant === 'survey' && surveyStyles,
          variant === 'keyword' && keywordStyles,
          { margin: `${arrow ? '12px 0px' : 'unset'}` },
        ].filter(Boolean)}
        {...props}
      >
        <Arrow type="tooltip" containerStyles={{ display: `${arrow ? 'inline-block' : 'none'}` }} />
        <Tooltip css={styles}>{children}</Tooltip>
      </Container>
    );
  },
);

export default StyledTooltip;
