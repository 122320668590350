import React, { type CSSProperties, type Key, type ReactNode } from 'react';

import { Popover, PopoverContent, PopoverTrigger } from '@commandbar/commandbar/shared/components/popover';
import Z from '@commandbar/internal/client/Z';
import { AnimatedWidget } from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';

type CTAWithTooltipProps = {
  children: ReactNode;
  style?: CSSProperties;
  childrenStyle?: CSSProperties;
  id?: Key;
  useTooltip: boolean;
  message?: string;
};

export const CTATooltip = ({ children, id, message, style, childrenStyle, useTooltip = true }: CTAWithTooltipProps) => {
  return useTooltip && message ? (
    <AnimatedWidget widget="tooltip" keepMounted>
      <Popover autoPlacement={false} offset={12} placement="bottom" style={style} flip>
        <PopoverTrigger asChild>
          <div
            style={{
              cursor: 'not-allowed',
              width: '100%',
              position: 'relative',
              display: 'flex',
            }}
          >
            {children}
          </div>
        </PopoverTrigger>
        <PopoverContent
          labelledBy={`commandbar-disabled-cta-${id}-tooltip`}
          describedBy={`commandbar-disabled-cta-${id}-tooltip`}
          style={{ width: 'max-content', zIndex: Z.Z_INDEX_MAX }}
          portalToId="commandbar-rc-tooltip-container"
        >
          <div
            style={{
              display: 'inline-flex',
              maxWidth: '260px',
              padding: '6px 12px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              borderRadius: '6px',
              background: '#000',
              boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
            }}
          >
            <span
              id={`commandbar-disabled-cta-${id}-tooltip`}
              style={{
                color: '#fff',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '140%',
              }}
            >
              {message}
            </span>
          </div>
        </PopoverContent>
      </Popover>
    </AnimatedWidget>
  ) : (
    <div
      style={{
        display: 'flex',
        flex: '1 1 0',
        ...childrenStyle,
      }}
    >
      {children}
    </div>
  );
};
