import { Checkbox, Form, Select, SubHeading } from '@commandbar/design-system/components/antd';
import { CmdButton, CmdInput, CmdLabel, CmdTextarea, cmdToast } from '@commandbar/design-system/cmd';
import MarkdownEditor from '@commandbar/design-system/components/MarkdownEditor';
import { ButtonRow, DrawerTitle, FooterRow, StyledDrawer } from '../shared/styles';
import { useCallback, useEffect, useState } from 'react';
import { XClose } from '@commandbar/design-system/icons/react';
import * as Organization from '@commandbar/internal/middleware/organization';
import Logger from '@commandbar/internal/util/Logger';
import type { Action } from '@cb/types/entities/command/actions';
import type { IChatType, IEditorCommandType } from '@commandbar/internal/middleware/types';
import { ActionEditor } from 'editor/src/editor/ActionEditor';
import { MediaBlockInnerContainer } from 'editor/src/editor/nudges/styled';
import debounce from 'lodash/debounce';
import { DrawerFooterDeleteButton, isLabeledAction } from '../shared/utils';
import { useAppContext } from 'editor/src/AppStateContext';
import * as Command from '@commandbar/internal/middleware/command';
import { Chat } from '@commandbar/internal/middleware/chat';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { FormItemHorizontal } from '../../integrations/shared/styles';
import { useModS } from '@commandbar/internal/hooks/useModS';
import { osControlKey } from '@commandbar/internal/util/operatingSystem';

export function getAnswerString(content: IEditorCommandType['content']): string {
  if (Array.isArray(content)) {
    if (typeof content[0] === 'object') {
      if (content[0]?.type === 'markdown') {
        return content[0].value;
      }
    }
  } else if (typeof content === 'object') {
    if (content?.type === 'markdown') {
      return content.value;
    }
  }
  return '';
}

type createAnswerProps = {
  question: string;
  answer: string;
  organizationId: string;
  categoryId: number;
  isLive: boolean;
  cta: string;
  action: Action | undefined;
  chat?: IChatType;
  trainingOnly: boolean;
  copilotUseVerbatim: boolean;
};

const AnswerEditDrawer = ({
  open,
  onClose,
  organizationId,
  activeAnswer,
  prefilledValues,
}: {
  open: boolean;
  onClose: () => void;
  organizationId: string | number | undefined;
  activeAnswer: IEditorCommandType | undefined;
  prefilledValues?: { question: string; answer: string; chat: IChatType };
}) => {
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [cta, setCta] = useState('');
  const [action, setAction] = useState<Action>({ type: 'link', value: '' });
  const [isValid, setIsValid] = useState<boolean>(!!prefilledValues?.answer && !!prefilledValues.question);
  const { user } = useAuth();
  const trainingOnly = Form.useWatch('trainingOnly', form);

  const createAnswer = async ({
    question,
    answer,
    organizationId,
    categoryId,
    isLive,
    cta,
    action,
    chat,
    trainingOnly,
    copilotUseVerbatim,
  }: createAnswerProps) => {
    const newCommand = Command.decodeEditorCommand({
      id: -1,
      organization: organizationId,
      category: categoryId,
      text: question,
      explanation: answer,
      content: { type: 'markdown', value: answer },
      is_live: isLive,
      template: { type: 'helpdoc', value: '', doc_type: 'answer' },
      training_only: trainingOnly,
      copilot_use_verbatim: copilotUseVerbatim || false, // hack to ensure value is always set
      next_steps: cta && action ? [{ cta: cta, action: action }] : [],
      show_in_helphub_search: true,
      show_in_spotlight_search: false,
    });

    const newAnswer = await save(newCommand);

    if (chat) {
      await Chat.update({
        ...chat,
        answer: newAnswer.id,
      });
    }
    setIsSaving(false);
  };

  const {
    dispatch: {
      commands: { save },
    },
  } = useAppContext();

  useEffect(() => {
    if (activeAnswer) {
      form.setFieldsValue({
        isLive: activeAnswer.is_live,
        question: activeAnswer.text,
        answer: getAnswerString(activeAnswer.content),
        trainingOnly: activeAnswer.training_only,
        copilotUseVerbatim: activeAnswer.copilot_use_verbatim,
      });
      isLabeledAction(activeAnswer.next_steps[0]) ? setCta(activeAnswer.next_steps[0].cta) : setCta('');
      isLabeledAction(activeAnswer.next_steps[0])
        ? setAction(activeAnswer.next_steps[0].action)
        : setAction({ type: 'link', value: '' });
    } else if (prefilledValues) {
      form.setFieldsValue({
        question: prefilledValues.question,
        answer: prefilledValues.answer,
      });
    } else {
      form.resetFields();
    }
  }, [activeAnswer, prefilledValues]);

  const onDrawerClose = () => {
    setIsValid(false);
    form.resetFields();
    setCta('');
    setAction({ type: 'link', value: '' });
    onClose();
  };

  const isAllowedToPublish = hasRequiredRole(user, 'editor');
  const isAllowedToSave = hasRequiredRole(user, form.getFieldValue('isLive') ? 'editor' : 'contributor');

  const checkIsValid = () => {
    setIsValid(
      !form.getFieldsError().some((item) => item.errors.length > 0) &&
        Object.values(form.getFieldsValue(['question', 'answer'])).filter((val) => val === undefined || val === '')
          .length === 0 &&
        isAllowedToSave,
    );
  };

  useModS(() => {
    if (!isValid) return;

    onFinish({
      isLive: form.getFieldValue('isLive'),
      question: form.getFieldValue('question'),
      answer: form.getFieldValue('answer'),
      trainingOnly: form.getFieldValue('trainingOnly'),
      copilotUseVerbatim: form.getFieldValue('copilotUseVerbatim'),
    });
  });

  const onFinish = useCallback(
    async ({
      isLive,
      question,
      answer,
      trainingOnly,
      copilotUseVerbatim,
    }: {
      isLive: boolean;
      question: string;
      answer: string;
      trainingOnly: boolean;
      copilotUseVerbatim: boolean;
    }) => {
      if (!organizationId) return;

      setIsSaving(true);

      try {
        if (activeAnswer) {
          await save({
            ...activeAnswer,
            text: question,
            explanation: answer,
            content: { type: 'markdown', value: answer },
            is_live: isLive,
            training_only: trainingOnly,
            copilot_use_verbatim: copilotUseVerbatim || false, // hack to ensure value is always set
            next_steps: cta && action ? [{ cta: cta, action: action }] : [],
          });
        } else {
          const categories = await Organization.listCommandCategories(organizationId.toString());
          const categoryId = categories.find(({ name }) => name === 'Help')?.id ?? categories[0]?.id;
          await createAnswer({
            organizationId: organizationId.toString(),
            categoryId,
            isLive,
            question,
            answer,
            cta,
            action,
            chat: prefilledValues?.chat,
            trainingOnly,
            copilotUseVerbatim,
          });
        }
      } catch (error) {
        cmdToast.error('Something went wrong while creating the custom answer.');
        Logger.error('Error creating answer: ', error);
      } finally {
        setIsSaving(false);
        onDrawerClose();
      }
    },
    [createAnswer, form, cta, action],
  );

  if (!organizationId) return <></>;

  const FooterButtons = () => (
    <ButtonRow>
      <CmdButton variant="ghost" onClick={onDrawerClose}>
        Cancel
      </CmdButton>
      <CmdButton disabled={!isValid} loading={isSaving} variant="primary" type="submit" form="create-answer">
        {activeAnswer ? 'Save Changes' : 'Create Answer'}
        <span style={{ opacity: 0.5, marginLeft: 4 }}> {osControlKey('S')}</span>
      </CmdButton>
    </ButtonRow>
  );

  return (
    <StyledDrawer
      key={'create-answer-drawer' + activeAnswer ? activeAnswer?.id.toString() : '-1'}
      width="500px"
      placement="right"
      onClose={onDrawerClose}
      open={open}
      closeIcon={<XClose />}
      maskStyle={{ color: '#000000', opacity: '0.2' }}
      title={<DrawerTitle>{activeAnswer ? 'Edit Answer' : 'New Answer'}</DrawerTitle>}
      footer={
        activeAnswer ? (
          <FooterRow>
            <DrawerFooterDeleteButton
              disabled={!isAllowedToSave}
              activeCommand={activeAnswer}
              onDelete={() => {
                onDrawerClose();
              }}
            />
            <FooterButtons />
          </FooterRow>
        ) : (
          <FooterButtons />
        )
      }
    >
      <Form
        style={{ height: '100%' }}
        layout="vertical"
        key={'create-answer' + activeAnswer ? activeAnswer?.id.toString() : '-1'}
        name="create-answer"
        form={form}
        onFinish={onFinish}
        onValuesChange={debounce(() => {
          checkIsValid();
        }, 300)}
      >
        <Form.Item
          key={'isLive'}
          required={true}
          validateTrigger="onChange"
          label={<CmdLabel>Status</CmdLabel>}
          name={'isLive'}
          initialValue={activeAnswer?.is_live ?? true}
        >
          <Select disabled={!isAllowedToPublish}>
            <Select.Option key={'unpublished'} value={false}>
              Unpublished
            </Select.Option>
            <Select.Option key={'published'} value={true}>
              Published
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          key={'question'}
          required={true}
          validateTrigger="onChange"
          label={<CmdLabel>Question</CmdLabel>}
          name={'question'}
          rules={[{ required: true, message: 'This is a required field.' }]}
          initialValue={activeAnswer?.text}
        >
          <CmdTextarea className="resize-x" style={{ minHeight: '86px' }} fullWidth />
        </Form.Item>
        <Form.Item
          key={'answer'}
          required={true}
          validateTrigger="onChange"
          label={<CmdLabel>Answer</CmdLabel>}
          name={'answer'}
          rules={[{ required: true, message: 'This is a required field.' }]}
        >
          <MarkdownEditor style={{ minHeight: '128px' }} fullWidth />
        </Form.Item>

        {!trainingOnly && (
          <div style={{ marginTop: '12px', marginBottom: '16px' }}>
            <MediaBlockInnerContainer>
              <div style={{ display: 'flex', gap: 8, flexDirection: 'column', width: '100%' }}>
                <SubHeading>CTA</SubHeading>
                <CmdInput
                  key={activeAnswer?.id}
                  fullWidth
                  value={cta}
                  onChange={(e) => {
                    setCta(e.target.value);
                    checkIsValid();
                  }}
                  style={{ height: '32px', fontSize: 14 }}
                  placeholder="Label"
                />

                <ActionEditor
                  possibleTypes={[
                    'click',
                    'execute_command',
                    'help_doc',
                    'video',
                    'link',
                    'page',
                    'open_chat',
                    'open_bar',
                    'nudge',
                    'questlist',
                  ]}
                  action={action}
                  onActionChange={(action) => {
                    if (action.type === 'dismiss' || action.type === 'snooze') return;
                    if (action) {
                      setAction(action);
                      checkIsValid();
                    }
                  }}
                />
              </div>
            </MediaBlockInnerContainer>
          </div>
        )}

        <FormItemHorizontal
          style={{ marginBottom: 0 }}
          key={'trainingOnly'}
          name={'trainingOnly'}
          valuePropName="checked"
          label={
            <CmdLabel tooltip="When enabled, this answer will only be used to improve AI generated Copilot answers and won't be shown in search results.">
              Use for training only
            </CmdLabel>
          }
        >
          <Checkbox />
        </FormItemHorizontal>

        <FormItemHorizontal
          key={'copilotUseVerbatim'}
          name={'copilotUseVerbatim'}
          valuePropName="checked"
          label={
            <CmdLabel tooltip="Copilot will try to match the language of this answer as closely as possible.">
              Use verbatim in Copilot responses
            </CmdLabel>
          }
        >
          <Checkbox />
        </FormItemHorizontal>
      </Form>
    </StyledDrawer>
  );
};

export default AnswerEditDrawer;
