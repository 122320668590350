import _get from 'lodash/get';
import _set from 'lodash/set';
import { ICommandType } from '@commandbar/internal/middleware/types';
import { EVENT_CATEGORY, EVENT_TYPE, IEventPayload } from './types';
import { getProxySDK } from '@commandbar/internal/client/proxy';
import { _configuration } from '@commandbar/internal/client/symbols';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { getSentry } from '@commandbar/internal/util/sentry';
import { EventCommandDetails } from './EventHandler';

interface ICATEGORY_DENY_LIST {
  [k: string]: any;
}

/**
  Maps categories in the block list to paths in the event payload to be sanitized
 */
export const CATEGORY_DENY_LIST: ICATEGORY_DENY_LIST = {
  commands: ['attrs.command', 'attrs.commandText'],
  help_hub_search: ['attrs.helpHub.query', 'attrs.helpHubDoc.query'],
  user_inputs_and_deadends: [
    'attrs.inputText',
    'attrs.helpHub.query',
    'attrs.helpHubDoc.query',
    /*
    We need to define this path as an array of strings since lodash parses `inputText[*]`
    as string
  */
    ['attrs', 'inputText[*]'],
    'attrs.selections',
  ],
  records: ['attrs.resource', 'attrs.record'],
  urls: ['attrs.url', 'context.page', 'attrs.commandDetails.url'],
};

export const isDevelopmentMode = () => {
  return process.env.NODE_ENV && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test');
};

export const getUserType = (isAdmin: boolean) => {
  if (isAdmin) return 'admin';
  if (!!LocalStorage.get('adm', '')) return 'likely-admin';
  return 'end_user';
};

/**
 * Types of events
 *
 * UserEvent: Triggered by user behavior (e.g, open, execution)
 * InternalEvent: Internal CommandBar tracking (e.g., availability of commands)
 **/
export const isUserEvent = (eventType: EVENT_TYPE): boolean => {
  return [EVENT_TYPE.Track, EVENT_TYPE.Identify, EVENT_TYPE.Log].includes(eventType);
};

export const isInternalEvent = (eventType: EVENT_TYPE): boolean => {
  return [EVENT_TYPE.Availability].includes(eventType);
};

// TODO: This is overkill - we can fold this into shouldReport
export const getEventCategory = (eventType: EVENT_TYPE): EVENT_CATEGORY => {
  if (isUserEvent(eventType)) return 'user';
  if (eventType === EVENT_TYPE.Error) return 'error';
  if (isInternalEvent(eventType)) return 'internal';
  return 'unknown';
};

export const shouldReport = (eventType: EVENT_TYPE, hasBooted: boolean): boolean => {
  const eventCategory = getEventCategory(eventType);

  switch (eventCategory) {
    case 'user': {
      return hasBooted;
    }
    case 'internal': {
      return hasBooted;
    }
    case 'error': {
      return true;
    }
    case 'unknown':
    default: {
      return false;
    }
  }
};

export const shouldReportToServer = (sendToServerInLocalHost: boolean, debugMode: boolean): boolean => {
  const notSilentMode = !isSilentMode();
  const notAirgapped = !getProxySDK()[_configuration]?.airgap;

  const canReportToServerInThisEnv =
    !isDevelopmentMode() || sendToServerInLocalHost || !!LocalStorage.get('testAnalytics', false) || debugMode;

  return notSilentMode && notAirgapped && canReportToServerInThisEnv;
};

export const isSilentMode = () => {
  const proxy = getProxySDK();
  const silent = proxy[_configuration]?.silent;

  if (silent === undefined) {
    getSentry()?.addBreadcrumb({
      message: 'Silent mode configuration is undefined',
      data: {
        configuration: proxy[_configuration],
        org: proxy[_configuration]?.uuid,
      },
    });
  }

  return silent !== false;
};

export const getEventAttributeBlockList = (): string[] => {
  const proxy = getProxySDK();
  const eventAttributeBlockList = proxy[_configuration]?.eventAttributeBlockList;

  if (!eventAttributeBlockList) {
    getSentry()?.addBreadcrumb({
      message: 'Event Attribute Block List is undefined',
      data: {
        configuration: proxy[_configuration],
        org: proxy[_configuration]?.uuid,
      },
    });
  }

  return eventAttributeBlockList || [];
};

/**
 * Returns true if object contains circular references
 * @param obj object
 * @returns boolean
 */
const hasCircularReferences = (obj: any) => {
  try {
    JSON.stringify(obj);
    return false;
  } catch (err) {
    return true;
  }
};

const sanitizeField = (payload: any, field: any) => {
  const value = _get(payload, field);

  /**
    If value is a string or number, mask the value and return
   */
  if (typeof value === 'string' || typeof value === 'number') {
    return _set(payload, field, '*'.repeat(`${value}`.length));
  }

  if (typeof value === 'object') {
    for (const key of Object.keys(value)) {
      /**
        Check for circular references early on in recursive function calls to avoid
        infinite loops.
       */
      if (hasCircularReferences(value)) {
        return _set(payload, field, {});
      }

      payload = _set(payload, field, sanitizeField(value, key));
    }
  }

  return payload;
};

export const stripEventAttributes = (payload: IEventPayload, categories: string[]): IEventPayload => {
  let strippedPayload = structuredClone(payload);
  for (const category of categories) {
    const denyList = CATEGORY_DENY_LIST[category] || [];

    for (const field of denyList) {
      strippedPayload = sanitizeField(strippedPayload, field);
    }
  }

  return strippedPayload;
};

/**
 * Helper function to return command details depending
 * upon the type of command
 */
export const getAdditionalCommandDetails = (command: ICommandType): EventCommandDetails => {
  const commandDetails: EventCommandDetails = {};

  if (command.template.type === 'link') {
    commandDetails.url = command.template.value;
  }

  return commandDetails;
};
