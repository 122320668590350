import { useEffect, useRef } from 'react';
import { init as initCommandBar } from 'commandbar';

import { goTo, registerCallbacks, registerRouterFunc } from '../util/commandBarCallbacks';
import { useTeamMembers } from '../util/useTeamMembers';
import { useHistory } from 'react-router';

const useStartCommandBar = (email: string, hmac: string) => {
  const history = useHistory();
  const commandBarInitialized = useRef(false);
  const { inviteTeammate, sendReferral, inviteTeammateWithRole } = useTeamMembers();
  const isLocalAPI =
    process.env.IS_PREVIEW ||
    process.env.REACT_APP_API_URL?.includes('localhost:8000') ||
    localStorage.getItem('commandbar.lc')?.includes('api=preview_');

  useEffect(() => {
    if (!commandBarInitialized.current) {
      initCommandBar(isLocalAPI ? 'foocorp' : '0f3bdb73');

      commandBarInitialized.current = true;

      registerRouterFunc(history);
    }

    return registerCallbacks({
      goToSection: (args: any) => goTo(args.section.anchor, history),
      openIntercomChat: () => window.Intercom('showNewMessage'),
      inviteTeammate: inviteTeammate,
      inviteTeammateWithRole: inviteTeammateWithRole,
      refer: (args: any) => {
        sendReferral(args.email);
      },
    });
  }, [inviteTeammate, sendReferral]);

  useEffect(() => {
    if (commandBarInitialized.current) {
      window.CommandBar.boot(email, {}, { hmac, canOpenEditor: email?.endsWith('@commandbar.com') });
    }
  }, [email]);
};

export default useStartCommandBar;
