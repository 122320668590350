import * as Reporting from 'shared/services/analytics/Reporting';
import { removeDeepLinkParams } from './helpers';
import * as GlobalActions from 'shared/store/global-actions';

import { isHelpHubWidgetAvailable } from './selectors';

import type { CBStore, HelpHubDoc } from 'shared/store/global-store';
import type { Action } from '@cb/types/entities/command/actions';
import type { IAIMessageType, IContinuationType, IRecommendationSet } from '@commandbar/internal/middleware/types';

import Logger from '@commandbar/internal/util/Logger';
import { isCopilotOnlyMode } from './selectors';
import { isHelpHubMarketingSite, isStudioPreview } from '@commandbar/internal/util/location';
import { Chat } from '@commandbar/internal/middleware/chat';
import { getSentry } from '@commandbar/internal/util/sentry';
import { getTriggerKey, isMobileDevice } from '@commandbar/internal/util/operatingSystem';
import { HistoryItem, NavigateOptions } from 'shared/util/router';
import helpdocService from 'shared/services/services/helpdocService';
import LocalStorage from '@commandbar/internal/util/LocalStorage';

export const setHelpHubQuery = (_: CBStore, query: string) => {
  _.helpHub.query = query;
};

export const executeActionFromHH = (
  _: CBStore,
  navigate: (item: HistoryItem, options?: NavigateOptions | undefined) => void,
  action: Action,
  e?: React.MouseEvent<HTMLElement>,
  type: 'helphub_recommendation' | 'helphub_additional_resource' | 'helphub_search' = 'helphub_recommendation',
) => {
  const defaultAction = () => {
    GlobalActions.executeAction(_, action, e, {}, { type }, (openedNewTab) => {
      if (!openedNewTab) {
        toggleHelpHubVisible(_);
      }
    });
  };

  const flags = _?.flags;

  if (action.type === 'execute_command') {
    helpdocService.getHelpdocCommand(_, action.meta.command).then((command) => {
      if (command && (command.template.type === 'helpdoc' || command.template.type === 'video')) {
        if (
          (flags?.['switch-open-help-doc-in-new-tab'] || (!!e && getTriggerKey(e))) &&
          typeof command.template.value === 'string' &&
          command.template.value !== ''
        ) {
          window.open(command.template.value, '_blank');
        } else {
          navigate({ page: 'docs', params: { docId: command.id } });
        }
      } else {
        defaultAction();
      }
    });
  } else if (action.type === 'open_copilot') {
    navigate({ page: 'start-chat', params: { initialQuery: action.query } });
  } else {
    defaultAction();
  }
};

export const setSearchSuggestions = (_: CBStore, continuations: IContinuationType[]) => {
  _.helpHub.searchSuggestions = { loading: false, continuations };
};

export const setInitialPage = (_: CBStore, navigateTo: { item: HistoryItem; options?: NavigateOptions }) => {
  _.helpHub.initialPage = navigateTo;
};

export const setFeedbackGiven = async (_: CBStore, aiAnswerValue: IAIMessageType['value'], rating: number) => {
  const { message_id, answer } = aiAnswerValue;

  if (!!message_id && !!_.organization) {
    _.helpHub.gaveFeedback[message_id.toString()] = rating;

    const payload = {
      message_id,
      rating,
      user_properties_override: _.helpHub.editorCopilotOverrides.userProperties,
    };
    Reporting.helpHubChatFeedback({ message_id, rating, message: answer });
    try {
      const response = await Chat.createAnswerFeedback(_.organization.id, payload);
      return response;
    } catch (err: any) {
      getSentry()?.captureException(err);
      Logger.warn(`AI feedback error: ${err}`);
    }
  }
};

export const setHelpHubDoc = (_: CBStore, doc: HelpHubDoc | null) => {
  _.helpHub.hubDoc = doc;
};

const originalBodyStyles = { overflow: '' };
// When we open HelpHub/Copilot on mobile, we want to lock the body scroll so that the user
// can scroll on the helphub without affecting their positiojn on the page
const lockBodyScroll = (_: CBStore, lock: boolean) => {
  if (lock) {
    originalBodyStyles.overflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = originalBodyStyles.overflow || '';
  }
};

export const toggleHelpHubVisible = (_: CBStore) => {
  if (_.helpHub.visible) {
    if (isStudioPreview() || isHelpHubMarketingSite) return;
    Reporting.helpHubClosed({ query: _.helpHub.query });
  } else {
    Reporting.helpHubOpened({ query: _.helpHub.query });
  }
  _.helpHub.visible = !_.helpHub.visible;

  if (isMobileDevice()) {
    lockBodyScroll(_, _.helpHub.visible);
  }

  setHelpHubQuery(_, '');

  if (!_.helpHub.visible) {
    removeDeepLinkParams();
    setHelpHubDoc(_, null);
    LocalStorage.set('copilot.open', false);
  }
};

export const setHelpHubVisible = (
  _: CBStore,
  visible: boolean,
  config?: {
    article?: HelpHubDoc | null;
    query?: string;
    chatOnly?: boolean;
  },
) => {
  if (!isHelpHubWidgetAvailable(_)) {
    if (visible && !(_.organization?.helphub_enabled || _.organization?.copilot_enabled) && _.isAdmin) {
      Logger.warn(`HelpHub is not enabled. Go to https://app.commandbar.com/configure to turn it on.`);
    }

    return;
  }

  const wasVisible = _.helpHub.visible;

  const article = config?.article;

  if (article) {
    setHelpHubDoc(_, article);
  } else {
    setHelpHubDoc(_, null);
  }

  if (wasVisible === visible) {
    return;
  }

  // if HH is minimized, and we want to set it visible, unminize it first
  if (visible && _.helpHub.minimized) {
    setHelpHubMinimized(_, false);
  }

  _.helpHub.visible = visible;

  if (config?.chatOnly) {
    _.helpHub.chatOnly = config.chatOnly;
  } else {
    _.helpHub.chatOnly = false;
  }

  if (isMobileDevice()) {
    lockBodyScroll(_, _.helpHub.visible);
  }
  if (!wasVisible && visible) {
    _.helpHub.key += 1; // force re-render helphub widget
    Reporting.helpHubOpened({ query: _.helpHub.query });
  } else if (wasVisible && !visible) {
    Reporting.helpHubClosed({ query: _.helpHub.query });
  }

  if (isCopilotOnlyMode(_)) {
    Reporting.helpHubEngagement({ query: _.helpHub.query }, 'chat_started');
  }

  setHelpHubQuery(_, config?.query || '');
};

export const setHelpHubMinimized = (_: CBStore, minimized: boolean) => {
  // NOTE: cannot minimize on Studio nor Marketing site
  if (isStudioPreview() || isHelpHubMarketingSite) {
    return;
  }

  const wasMinimized = _.helpHub.minimized;
  _.helpHub.minimized = minimized;

  LocalStorage.set('copilot.minimized', minimized);
  if (!wasMinimized && minimized) Reporting.helpHubMinimized({ query: _.helpHub.query });
  else if (wasMinimized && !minimized) Reporting.helpHubUnminimized({ query: _.helpHub.query });
};

export const setHelpHubRecommendationSets = (_: CBStore, recommendationSets: IRecommendationSet[]) => {
  _.helpHub.recommendationSets = recommendationSets;
};

export const setPreviewRecommendationSet = (_: CBStore, recommendationSet: IRecommendationSet | null) => {
  _.helpHub.previewedRecommendationSet = recommendationSet;
};
