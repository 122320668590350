import type { ITemplate } from '@cb/types/entities/command/template';
import type { IPushTrigger } from '@commandbar/internal/middleware/types';

import { EVENT_NAME } from './types';
import { CBEvent, CBEventName } from './v2/types';

// Events to pass to client event handler
export const CLIENT_HANDLER_EVENT_TYPES: {
  internal: EVENT_NAME;
  external: EventType;
}[] = [
  { internal: 'Abandoned search', external: 'abandoned_search' },
  { internal: 'Command suggestion', external: 'command_suggestion' },
  { internal: 'Command execution', external: 'command_execution' },
  { internal: 'New search', external: 'opened' },
  { internal: 'Exited', external: 'closed' },
  { internal: 'No results for query', external: 'no_results_for_query' },
  { internal: 'Client-Error', external: 'client_error' },
  { internal: 'User changed shortcut', external: 'shortcut_edited' },
  { internal: 'Preview shown', external: 'preview_shown' },
  { internal: 'Next step selected', external: 'next_step_selected' },
  { internal: 'Preview link opened', external: 'preview_link_opened' },
  { internal: 'Preview engagement', external: 'preview_engagement' },
  { internal: 'Nudge shown', external: 'nudge_shown' },
  { internal: 'Nudge clicked', external: 'nudge_clicked' },
  { internal: 'Nudge completed', external: 'nudge_completed' },
  { internal: 'Nudge dismissed', external: 'nudge_dismissed' },
  { internal: 'Nudge snoozed', external: 'nudge_snoozed' },
  { internal: 'Questlist shown', external: 'questlist_shown' },
  { internal: 'Questlist engagement', external: 'questlist_engagement' },
  { internal: 'Questlist item engagement', external: 'questlist_item_engagement' },
  { internal: 'HelpHub opened', external: 'help_hub_opened' },
  { internal: 'HelpHub closed', external: 'help_hub_closed' },
  { internal: 'HelpHub unminimized', external: 'help_hub_unminimized' },
  { internal: 'HelpHub minimized', external: 'help_hub_minimized' },
  { internal: 'HelpHub engagement', external: 'help_hub_engagement' },
  { internal: 'HelpHub doc opened', external: 'help_hub_doc_opened' },
  { internal: 'HelpHub doc closed', external: 'help_hub_doc_closed' },
  { internal: 'HelpHub doc engagement', external: 'help_hub_doc_engagement' },
  { internal: 'Survey response', external: 'survey_response' },
  { internal: 'No chat response', external: 'no_chat_response' },
];

export type EventCommandDetails = {
  url?: string;
};

export interface AbandonedSearchEvent {
  type: 'abandoned_search';
  /** Event data passed for `abandoned_search` events (deadends). */
  /** The id of the currently active command, if there is one. */
  command?: number;
  /** DEPRECATED. The text of the deadend. */
  ['inputText[*]']: string;
  /** The text of the deadend. */
  inputText: string;
  /** The context key of the currently active resource, if there is one. */
  resource?: string;
  /** The trigger for the deadend. */
  trigger: 'Backspaced' | 'Closed with text' | 'Resetting search';
  /** The number of {CommandOptions, ParameterOptions}, if there are any. */
  results?: { numCommands: number; numRecords: number };
  /** command_ids of previously executed commands in the session */
  previousCommands?: string[];
}

export interface ClosedEvent {
  type: 'closed';
  /** Event data passed for `command_execution` events. */
  /** The user's input text when the bar was closed. */
  inputText: string;
  /** True if this command was triggered by a shortcut. */
  shortcut?: boolean;
}

export type PreviewEngagementType = 'scrolled' | 'clicked_link' | 'watched_video';
export type HelpHubDocEngagementType = 'scrolled' | 'clicked_link' | 'watched_video' | 'opened';
export type HelpHubEngagementType =
  | 'search'
  | 'scrolled'
  | 'chat_started'
  | 'chat_closed'
  | 'chat_reset'
  | 'chat_message'
  | 'chat_feedback'
  | 'continuation_clicked'
  | 'suggested_query_clicked'
  | 'chat_action_clicked';
export type ChecklistEngagementType = 'expanded' | 'collapsed' | 'dismissed' | 'completed';
export type ChecklistItemEngagementType = 'expanded' | 'completed' | 'cta_clicked' | 'skipped';

export type EngagementType =
  | ChecklistEngagementType
  | ChecklistItemEngagementType
  | PreviewEngagementType
  | HelpHubEngagementType
  | HelpHubDocEngagementType;

export type PushTrigger = IPushTrigger;

export interface ChecklistEvent {
  type: 'questlist_shown' | 'questlist_engagement';
  questlist: {
    id: number;
    trigger: PushTrigger;
    title: string;
    total_items: number;
    completed_items: number;
    completed: boolean;
  };
  engagement_type?: ChecklistEngagementType;
}

export interface ChecklistItemEvent {
  type: 'questlist_item_engagement';
  questlist_item: {
    id: number;
    questlist_id: number;
    title: string;
    cta?: string;
    goal:
      | {
          type: 'page_visited';
          value: string;
        }
      | {
          type: 'command_executed';
          meta: {
            command: string;
          };
        }
      | {
          type: 'element_clicked';
          value: string;
        }
      | {
          type: 'conditions_met';
        }
      | { type: 'cta_clicked' }
      | {
          type: 'event_tracked';
        };
    completed: boolean;
  };
  engagement_type: ChecklistItemEngagementType;
}

export interface PreviewEvent {
  type: 'preview_shown' | 'next_step_selected' | 'preview_link_opened' | 'preview_engagement';
  preview: {
    preview_type: 'html' | 'markdown' | 'plaintext' | 'react' | 'video' | 'component';
    form_factor: 'inline' | 'popover';
    time_shown?: number;
    source: {
      command?: string;
      record?: string;
    };
    id: string;
  };
}

export interface PreviewEngagementEvent extends PreviewEvent {
  type: 'preview_engagement';
  engagement_type: PreviewEngagementType;
}
export type ChatHistory = { question: string; answer: { message_id: number | undefined; message: string } | null }[];

export interface HelpHubEvent {
  type: 'help_hub_closed' | 'help_hub_opened' | 'help_hub_engagement';
  helpHub: {
    query: string | null | undefined;
  };
}
/*
 * chat_message
 *   from: 'ai' | 'user'
 *   message: string
 *   no_answer: no_answer || used_fallback
 */
type HelpHubEngagementEventExtended =
  | {
      engagement_type: 'search' | 'scrolled' | 'chat_started' | 'chat_closed' | 'chat_reset';
    }
  | {
      engagement_type: 'suggested_query_clicked';
      suggested_query: string;
    }
  | {
      engagement_type: 'continuation_clicked';
      continuation: string;
      chat_history: ChatHistory;
    }
  | {
      engagement_type: 'chat_action_clicked';
      chat_id: string;
      action: any;
    }
  | {
      engagement_type: 'chat_message';
      from: 'ai' | 'user';
      message: string;
      fallback: boolean;
      chat_id: string;
    }
  | {
      engagement_type: 'chat_feedback';
      message_id: number;
      message: string;
      rating: number;
    };

export type HelpHubEngagementEvent = HelpHubEvent & { type: 'help_hub_engagement' } & HelpHubEngagementEventExtended;

export interface HelpHubDocEvent {
  type: 'help_hub_doc_closed' | 'help_hub_doc_opened' | 'help_hub_doc_engagement';
  helpHubDoc: {
    time_shown?: number;
    query: string | null | undefined;
    trigger?: ExecutionEventSource;
    command?: number | string | undefined;
    third_party_id?: string | null;
    url?: string;
    title?: string;
  };
}

export interface HelpHubDocEngagementEvent extends HelpHubDocEvent {
  type: 'help_hub_doc_engagement';
  engagement_type: HelpHubDocEngagementType;
}

export interface HelpHubDocEngagementEvent extends HelpHubDocEvent {
  type: 'help_hub_doc_engagement';
  engagement_type: HelpHubDocEngagementType;
}

export interface NudgeStepButton {
  cta: string;
  type: 'primary' | 'secondary';
}

export interface NudgeEvent {
  type: 'nudge_seen' | 'nudge_clicked' | 'nudge_completed' | 'nudge_dismissed' | 'nudge_snoozed';
  nudge: {
    id: string;
    slug: string;
    template_source: string;
    frequency_limit: 'no_limit' | 'once_per_session' | 'once_per_user' | 'until_interaction';
    trigger: PushTrigger;
    step:
      | {
          id: number;
          title: string;
          button?: NudgeStepButton;
        }
      | undefined;
  };
  status: { is_preview: boolean; is_live: boolean };
}

export interface SurveyResponseEvent {
  type: 'survey_response';
  response:
    | { type: 'number' | 'nps'; value: number; emoji: string | undefined; max: number }
    | { type: 'string'; value: string }
    | { type: 'string'; value: string[] };
  nudge: NudgeEvent['nudge'];
  status: { is_preview: boolean; is_live: boolean };
}

export type ExecutionEventSource =
  | { type: 'bar' }
  | { type: 'helphub' }
  | { type: 'helphub_additional_resource' }
  | { type: 'helphub_recommendation' }
  | { type: 'helphub_search' }
  | { type: 'nudge'; id: number | string }
  | { type: 'questlist'; id: number }
  | { type: 'copilot' };

export interface CommandExecutionEvent {
  type: 'command_execution';
  /** Event data passed for `command_execution` events. */
  /** The category id of the command. Only provided if the command has a category. */
  category?: string | number | null;
  /** The text of the category */
  categoryText?: string;
  /**
   * The unique id of the command. For commands defined via the Editor, the value will be a number. For programmatic
   * commands, the `name` (string) provided will be used.
   */
  command: number | string | undefined;
  /** The text of the command */
  commandText: string;
  /** The source of the command. */
  source: string;
  /** The source of the command execution. */
  eventSource: ExecutionEventSource;
  /** DEPRECATED. The text input.*/
  ['inputText[*]']: string;
  /** The text input.*/
  inputText?: string;
  /** True if this command was triggered by a shortcut. */
  shortcut?: boolean;
  /** Ranking order of the command option */
  ranking?: number;
  /** command_ids of previously executed commands in the session */
  previousCommands?: string[];
  /** Command types */
  commandType?: ITemplate['type'];
  /** Selected arguments */
  selections?: Record<string, unknown>;
  commandDetails: EventCommandDetails;
}

export interface EndUserShortcutChangedEvent {
  type: 'shortcut_edited';
  /** Event data passed for `command_execution` events. */
  /** The category id of the command. Only provided if the command has a category. */
  category?: number | null;
  /**
   * The unique id of the command. For commands defined via the Editor, the value will be a number. For programmatic
   * commands, the `name` (string) provided will be used.
   */
  command: number | string | undefined;
  /** The text of the command */
  commandText: string;
  /** The source of the command. */
  source: string;
  /** The updated shortcut. */
  oldShortcut: string;
  newShortcut: string;
  defaultShortcut: string;
}

export interface CommandSuggestionEvent {
  type: 'command_suggestion';
  /** The text of the suggestion. */
  text: string;
}

export interface OpenedEvent {
  type: 'opened';
  /** Event data passed for `command_execution` events. */
  /**
   * The entrypoint through which the bar was opened.
   *
   * * `launcher`: Click on the provided CommandBar launcher
   * * `keyboard`: Cmd/Ctrl+K
   * * `programmatic`: Call to `window.CommandBar.open()`. Clicks on the
   *   [commandbar-launcher](https://www.npmjs.com/package/commandbar-launcher) component register as programmatic
   *   opens.
   */
  trigger: 'launcher' | 'keyboard' | 'programmatic' | 'nudge';

  /** The placeholder showing when the user opened the bar. */
  placeholder: string;
}
export type OpenedEventTrigger = OpenedEvent['trigger'];

export interface NoResultsForQueryEvent {
  type: 'no_results_for_query';
  /** Event data passed for `no_results_for_query` event */

  /** The user's input text when the no results state was reached. */
  inputText: string;

  eventSource: ExecutionEventSource;
}

export interface NoChatResponseEvent {
  type: 'no_chat_response';
  /** Event data passed for `no_results_for_query` event */

  /** The user's input text when the no results state was reached. */
  chatHistory: ChatHistory;
}

export interface ClientErrorEvent {
  type: 'client_error';
  /** Event data passed for `client_error` event */

  /** The error message */
  message: string;
  url: string;

  command_id?: number | string;
  nudge_id?: number | string;
  questlist_id?: number | string;
  questlist_item_id?: number | string;
  foobar_version?: string;
}

export type EventData =
  | AbandonedSearchEvent
  | ClosedEvent
  | CommandExecutionEvent
  | CommandSuggestionEvent
  | NudgeEvent
  | OpenedEvent
  | NoResultsForQueryEvent
  | EndUserShortcutChangedEvent;

export type EventHandler = (eventName: EventType, eventData: EventData) => void;

export type EventSubscriber = EventHandler;
export type EventSubscriberV2 = (eventName: CBEventName, eventData: Omit<CBEvent, 'sent_at'>) => void;

export type EventSubscriberOptions = {
  v2?: true;
};

export type EventType =
  | 'abandoned_search'
  | 'command_suggestion'
  | 'command_execution'
  | 'nudge_shown'
  | 'nudge_clicked'
  | 'nudge_completed'
  | 'nudge_dismissed'
  | 'nudge_snoozed'
  | 'opened'
  | 'closed'
  | 'no_results_for_query'
  | 'client_error'
  | 'shortcut_edited'
  | 'preview_shown'
  | 'next_step_selected'
  | 'preview_link_opened'
  | 'preview_engagement'
  | 'questlist_shown'
  | 'questlist_engagement'
  | 'questlist_item_engagement'
  | 'help_hub_opened'
  | 'help_hub_closed'
  | 'help_hub_unminimized'
  | 'help_hub_minimized'
  | 'help_hub_engagement'
  | 'help_hub_doc_opened'
  | 'help_hub_doc_closed'
  | 'help_hub_doc_engagement'
  | 'survey_response'
  | 'no_chat_response'
  | 'sdk_method_called';
