/** @jsx jsx  */
import React from 'react';
import styled from '@emotion/styled';
import { jsx } from '@emotion/core';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import type { IUserAttachmentType } from '@commandbar/internal/middleware/types';
import StyledImageDialog from '../shared/StyledImageDialog';

const Container = styled.div`
  display: flex;
  align-self: stretch;
  width: 100%;
  overflow: hidden;
  align-items: center;
  padding: var(--layout-padding);
  gap: var(--layout-gap);
  border-radius: var(--layout-radius-card);
  background: var(--background-primary);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
`;

const Title = styled.div`
  /* label/base/semibold */
  font-family: var(--font-font-family);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  line-height: var(--font-line-height-normal);

  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.div`
  color: var(--content-secondary);

  /* label/sm/medium */
  font-family: var(--font-font-family);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-normal);

  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledImage = styled.img`
  cursor: pointer;
  border-radius: var(--layout-radius-button);
  height: 32px;
  width: 32px;
`;

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode'; generatedCSSClassname: string };
  attachment: IUserAttachmentType;
};

const StyledCopilotAttachmentMessage = ({ themeV2, attachment }: Props) => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Container>
      <StyledImage onClick={() => setShowModal(true)} src={attachment.media_preview_url} />
      <TextContainer>
        <Title>{attachment.title}</Title>
        <Subtitle>{attachment.description}</Subtitle>
      </TextContainer>
      <StyledImageDialog
        themeV2={themeV2}
        src={attachment.media_preview_url}
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
    </Container>
  );
};

export default StyledCopilotAttachmentMessage;
