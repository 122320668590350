import * as t from 'io-ts';

import { AdminFilter, CardDataV, DateFilter, TableDataV, FunnelDataV, PaginationFilter } from './common';
import { read, mkQueryString } from '../generics';
import * as axiosInstance from '../network';

const AnalyticsAnnouncementsDataV = t.type({
  active: CardDataV,
  views: CardDataV,
  snooze_rate: CardDataV,
  table: TableDataV,
});

export type AnalyticsAnnouncementsData = t.TypeOf<typeof AnalyticsAnnouncementsDataV>;

const AnalyticsAnnouncementsDetailDataV = t.type({
  sessions: CardDataV,
  completion_rate: CardDataV,
  snooze_rate: CardDataV,
  end_user_funnel: FunnelDataV,
  announcementName: t.string,
  goal: t.union([CardDataV, t.undefined, t.null]),
  survey_table: t.union([TableDataV, t.undefined, t.null]),
});

const AnalyticsAnnouncementsUsersDataV = t.type({
  views: TableDataV,
});

export type AnalyticsAnnouncementsDetailData = t.TypeOf<typeof AnalyticsAnnouncementsDetailDataV>;
export type AnalyticsAnnouncementsUsersData = t.TypeOf<typeof AnalyticsAnnouncementsUsersDataV>;

type AnnouncementsParams = {
  show_unpublished: string;
} & DateFilter &
  AdminFilter;

export type AnnouncementsDetailParams = {
  show_unpublished?: string;
  goal_event_name?: string;
} & DateFilter &
  AdminFilter;

export type AnnouncementsUsersParams = {
  search_filter: string;
} & DateFilter &
  AdminFilter &
  PaginationFilter;

export class AnalyticsAnnouncements {
  public static read = (params: AnnouncementsParams) =>
    read(AnalyticsAnnouncementsDataV, `analytics/v2/announcements`)(params);
  public static downloadDetail = async (id: string, params: AnnouncementsParams): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/announcements/${id}/views/export/${queryString}`);
  };
  public static readDetail = (id: string, params: AnnouncementsDetailParams) =>
    read(AnalyticsAnnouncementsDetailDataV, `analytics/v2/announcements/${id}`)(params);
  public static readUsers = (id: string, params: AnnouncementsUsersParams) =>
    read(AnalyticsAnnouncementsUsersDataV, `analytics/v2/announcements/${id}/users`)(params);
  public static download = async (params: AnnouncementsParams): Promise<Blob> => {
    const queryString = mkQueryString(params);
    return axiosInstance.getBlob(`analytics/v2/announcements/export/${queryString}`);
  };
}
