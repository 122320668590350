import React from 'react';
import StyledPrimaryButton from './StyledPrimaryButton';
import StyledSecondaryButton from './StyledSecondaryButton';
import StyledTertiaryButton from './StyledTertiaryButton';
import type { IThemeV2Type } from '@cb/types/entities/theme';

type CTAButtonProps = {
  buttonType?: 'primary' | 'secondary' | 'snooze' | 'tertiary';
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const StyledCTAButton = ({
  children,
  buttonType,
  ...props
}: CTAButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  switch (buttonType) {
    case 'secondary':
      return <StyledSecondaryButton {...props}>{children}</StyledSecondaryButton>;
    case 'tertiary':
    case 'snooze':
      return <StyledTertiaryButton {...props}>{children}</StyledTertiaryButton>;
    case 'primary':
    default:
      return <StyledPrimaryButton {...props}>{children}</StyledPrimaryButton>;
  }
};

export default StyledCTAButton;
