import { useState } from 'react';
import { formatPercentage, formatRelativeTime } from '../../utils';
import { Link } from 'react-router-dom';

import { CmdSortableColumn, CmdSwitch, CmdColumnDef, CmdTooltip } from '@commandbar/design-system/cmd';
import { PencilLine, Plus } from '@commandbar/design-system/icons/react';
import StatCard from '../../components/StatCard';
import ActionDropdown from '../../components/ActionDropdown';
import Table from '../../components/Table';
import Layout from '../../components/Layout';
import { StatusBadge } from '@commandbar/design-system/components/antd';
import useAnalyticAnnouncements from './useAnalyticAnnouncements';
import dayjs from 'dayjs';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import * as nudgeSearchParams from '@commandbar/internal/proxy-editor/nudge_search_params';
import { AnalyticsPage, getAnalyticsDashboardRoute } from 'commandbar.com/src/components/Home';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';

const AnalyticsAnnouncementsDashboard = () => {
  const [showUnpublished, setShowUnpublished] = useState<boolean>(false);
  const { user } = useAuth();

  const { data, isLoading, error, fetchAnnouncementData, downloadCsv, isDownloading } = useAnalyticAnnouncements({
    showUnpublished,
  });

  const columns: CmdColumnDef<any, any>[] = [
    {
      accessorKey: 'nudge_slug',
      header: 'Announcements',
      cell: (r) => {
        const nudgeId = r.row.original.nudge_id;
        const isLive = r.row.original.is_live;

        return (
          <Link to={`/analytics/announcements/${nudgeId}`}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {showUnpublished && <StatusBadge color={isLive ? 'green' : 'orange'} />}
              {r?.getValue() || 'Announcement'}
            </div>
          </Link>
        );
      },
    },
    {
      accessorKey: 'total_views',
      header: ({ column }) => <CmdSortableColumn column={column} title="Views" />,
    },
    {
      accessorKey: 'snooze_rate',
      header: ({ column }) => <CmdSortableColumn column={column} title="Snooze rate" />,
      cell: ({ cell }) => formatPercentage(cell.getValue()),
    },
    {
      accessorKey: 'last_shown',
      header: ({ column }) => <CmdSortableColumn column={column} title="Last seen" />,
      cell: ({ cell }) => (
        <CmdTooltip message={dayjs(cell.getValue()).format('MMM DD, YYYY, hh:mmA')}>
          <span>{formatRelativeTime(cell.getValue())}</span>
        </CmdTooltip>
      ),
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    columns.push({
      accessorKey: 'action',
      header: '',
      width: 40,
      cell: ({ row }) => (
        <ActionDropdown
          options={[
            {
              label: 'Edit',
              icon: <PencilLine />,
              url: `/editor/announcements/${row.original.nudge_id}`,
            },
          ]}
        />
      ),
    });
  }

  return (
    <Layout title="Announcements" disableDemoData={isLoading} onDownloadCSV={downloadCsv} isDownloading={isDownloading}>
      <div
        style={{
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '16px',
          }}
        >
          <StatCard
            title="Active"
            subtitle="Total live announcements"
            format="number"
            metric={data?.active?.metric}
            isLoading={isLoading}
          />
          <StatCard
            title="Views"
            subtitle="Total times your announcements have been viewed"
            format="number"
            metric={data?.views?.metric}
            rate={data?.views?.percent_change}
            isLoading={isLoading}
          />
          <StatCard
            title="Snooze rate"
            subtitle="Avg. snooze rate for announcements"
            format="percentage"
            metric={data?.snooze_rate?.metric}
            rate={data?.snooze_rate?.percent_change}
            isLoading={isLoading}
          />
        </div>
        <div>
          <Table
            columns={columns}
            data={data?.table?.rows || []}
            isLoading={isLoading}
            empty={{
              error: !!error,
              refetchData: fetchAnnouncementData,
              additionalActions: [
                {
                  label: 'Create an announcement',
                  icon: <Plus />,
                  to: `/editor${editorRoutes.ANNOUNCEMENTS_ROUTE}/new?${
                    nudgeSearchParams.FROM
                  }=${getAnalyticsDashboardRoute(AnalyticsPage.ANNOUNCEMENTS)}`,
                },
              ],
            }}
            toolBarChildren={
              <div>
                <CmdSwitch
                  checked={showUnpublished}
                  onCheckedChange={() => setShowUnpublished(!showUnpublished)}
                  onLabel="Show unpublished, but previously viewed Announcements"
                  offLabel="Show unpublished, but previously viewed Announcements"
                  tooltip="Announcements that are (a) unpublished; (b) have at least one view."
                />
              </div>
            }
          />
        </div>
      </div>
    </Layout>
  );
};

export default AnalyticsAnnouncementsDashboard;
