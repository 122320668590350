import React, { useMemo, useRef } from 'react';
import { BarStack } from '@visx/shape';
import { Group } from '@visx/group';
import { GridRows } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import useResizeObserver from './useResizeObserver';
import { formatDate, tooltipStyles, TooltipData, formatToolTip } from './utils';
import { Loading } from './loading';
import { GraphDetailsStatCard } from './GraphDetailsStatCard';
import { StackedBarChartRow } from '@commandbar/internal/middleware/analytics/common';
import { capitalize } from 'lodash';

export type BarStackProps = {
  chartData?: StackedBarChartRow[];
  isLoading?: boolean;
  colorRange: string[];
};
const verticalMargin = 120;

const CmdStackedBarChart = ({ chartData = [], isLoading, colorRange }: BarStackProps) => {
  if (isLoading) {
    return <Loading />;
  }
  return <StackedBarChart chartData={chartData} colorRange={colorRange} />;
};

const StackedBarChart = ({ chartData, colorRange }: { chartData: StackedBarChartRow[]; colorRange: string[] }) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({ scroll: true });
  let tooltipTimeout: number;

  const resizeRef = useRef<HTMLDivElement>(null);
  const { width: parentWidth, height: parentHeight } = useResizeObserver<HTMLDivElement>(resizeRef);

  const keys = useMemo(() => (chartData.length > 0 ? Object.keys(chartData[0].data) : []), [chartData]);

  const maxLabelWidth = useMemo(() => {
    const maxSum = Math.max(...chartData.map((d) => Object.values(d.data).reduce((a, b) => a + b, 0)));
    return maxSum.toString().length * 8 + 40;
  }, [chartData]);

  const xMax = parentWidth - (maxLabelWidth - 20);
  const yMax = parentHeight - verticalMargin;

  const getDate = (d: any) => d.date;

  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: chartData.map(getDate),
        padding: 0.4,
      }),
    [xMax, chartData],
  );

  const yScale = useMemo(() => {
    const maxSum = Math.max(...chartData.map((d) => Object.values(d.data).reduce((a, b) => a + b, 0)));
    return scaleLinear<number>({
      range: [yMax, 0],
      round: true,
      domain: [0, maxSum],
    });
  }, [yMax, chartData]);

  const colorScale = useMemo(() => {
    // if there aren't enough colors provided, use grey for the rest of the keys
    const updatedColorRange = [...colorRange, ...Array(Math.max(0, keys.length - colorRange.length)).fill('#e7e8ea')];
    return scaleOrdinal<string, string>({
      domain: keys,
      range: updatedColorRange,
    });
  }, [keys]);

  return (
    <div ref={resizeRef} style={{ width: '100%', height: '100%' }}>
      <div ref={containerRef}>
        <svg width={parentWidth} height={parentHeight}>
          <rect width={parentWidth} height={parentHeight} fill="#fff" rx={14} />
          <AxisLeft
            scale={yScale}
            hideAxisLine
            hideTicks
            tickFormat={(value) => `${Number(value).toLocaleString()}`}
            numTicks={5}
            top={verticalMargin / 2}
            left={maxLabelWidth / 2}
            tickLabelProps={{
              fill: '#797C85',
              fontSize: 12,
              textAnchor: 'middle',
              fontFamily: 'Inter',
              fontWeight: 500,
            }}
          />
          <Group top={verticalMargin / 2} left={maxLabelWidth - 20}>
            <GridRows scale={yScale} width={xMax} height={yMax} stroke="rgba(0, 0, 0, 0.16)" numTicks={5} />
            <BarStack
              data={chartData.map(({ date, data }) => ({
                date,
                ...data,
              }))}
              keys={keys}
              x={getDate}
              xScale={xScale}
              yScale={yScale}
              color={colorScale}
            >
              {(barStacks) =>
                barStacks.map((barStack, i) =>
                  barStack.bars.map((bar, index) => {
                    const firstValueKey = Object.keys(bar.bar.data).find(
                      (key) => Number(bar.bar.data[key as keyof typeof bar.bar.data]) > 0,
                    );
                    const lastValueKey = Object.keys(bar.bar.data)
                      .reverse()
                      .find((key) => Number(bar.bar.data[key as keyof typeof bar.bar.data]) > 0);

                    const heightShift = 2;
                    const isFirstWithValue = firstValueKey === bar.key;
                    const isLastWithValue = lastValueKey === bar.key;
                    const numBars = Object.values(bar.bar.data).filter((val) => Number(val) > 0).length;
                    const isOnly = numBars === 1;
                    const isLast = barStacks.length - 1 === i || isLastWithValue;
                    const adjustedY = isLast && !isOnly ? bar.y - heightShift : bar.y;

                    const barHeightOffset = numBars === 2 ? heightShift : heightShift * numBars;

                    const pathD = isLast
                      ? `M${bar.x},${isOnly ? bar.y + bar.height : adjustedY + bar.height - barHeightOffset} v-${
                          bar.height
                        } q0,-5 5,-5 h${bar.width - 10} q5,0 5,5 v${bar.height}`
                      : `M${bar.x},${isFirstWithValue ? bar.y - heightShift : adjustedY - barHeightOffset} v${
                          bar.height + heightShift
                        } h${bar.width} v-${bar.height + heightShift} h-${bar.width}`;

                    return (
                      <svg key={`${barStack.key}-${index}`}>
                        <path
                          key={`bar-${barStack.key}-${index}`}
                          d={pathD}
                          fill={bar.height === 0 ? 'transparent' : bar.color}
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                          }}
                          onMouseMove={(event) => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);

                            const barX = bar.x;
                            const barWidth = bar.width;
                            const left = barX + barWidth / 2 - 100;
                            const top = event.clientY - verticalMargin * 2;

                            showTooltip({
                              tooltipData: bar,
                              tooltipTop: top,
                              tooltipLeft: left,
                            });
                          }}
                        />
                        <rect
                          key={`gap-${barStack.key}-${index}`}
                          x={bar.x}
                          y={0}
                          width={bar.width}
                          height={yMax}
                          fill="rgba(0,0,0,0)"
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);
                          }}
                          onMouseMove={(event) => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);

                            const left = bar.x + bar.width / 2 - 100;
                            const top = event.clientY - verticalMargin * 2;

                            showTooltip({
                              tooltipData: bar,
                              tooltipTop: top,
                              tooltipLeft: left,
                            });
                          }}
                        />
                      </svg>
                    );
                  }),
                )
              }
            </BarStack>
          </Group>
          <AxisBottom
            top={yMax + 60}
            scale={xScale}
            left={maxLabelWidth - 20}
            tickFormat={formatDate}
            hideTicks
            hideAxisLine
            numTicks={5}
            tickLabelProps={{
              fill: '#797C85',
              fontSize: 12,
              textAnchor: 'middle',
              fontFamily: 'Inter',
              fontWeight: 500,
            }}
          />
        </svg>

        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop && tooltipTop + 10}
            left={tooltipLeft && tooltipLeft + 125}
            style={{
              ...tooltipStyles,
              padding: 0,
              background: 'transparent',
              border: 'none',
              boxShadow: 'none',
              width: 200,
            }}
          >
            <GraphDetailsStatCard
              subtitle={formatToolTip(tooltipData.bar.data['date'])}
              metric={Object.values(tooltipData.bar.data)
                .reduce((sum: number, value) => (typeof value === 'number' ? sum + value : sum), 0)
                .toLocaleString()}
              title="Sessions"
              values={Object.entries(tooltipData.bar.data)
                .filter(([key, _]) => key !== 'date')
                .map(([key, value]) => ({
                  color: colorScale(key),
                  title: key
                    .split('_')
                    .map((word, index) => (index === 0 ? capitalize(word) : word))
                    .join(' '),
                  metric: (value as number).toLocaleString(),
                }))}
            />
          </TooltipInPortal>
        )}
      </div>
    </div>
  );
};

export { CmdStackedBarChart };
