import {
  IChecklist,
  IChecklistItem,
  ICommandType,
  INudgeStepType,
  INudgeType,
} from '@commandbar/internal/middleware/types';

import Analytics from './Analytics';
import * as SpotlightServiceSelectors from 'products/spotlight/service-selectors';
import { _search, _configuration } from '@commandbar/internal/client/symbols';
import { getSDK } from '@commandbar/internal/client/globals';
import { CBStore } from 'shared/store/global-store';
import {
  AbandonedSearchEvent,
  ChecklistEngagementType,
  ChecklistEvent,
  ChecklistItemEngagementType,
  ChecklistItemEvent,
  CommandExecutionEvent,
  CommandSuggestionEvent,
  EndUserShortcutChangedEvent,
  ExecutionEventSource,
  NoResultsForQueryEvent,
  NudgeEvent,
  OpenedEvent,
  OpenedEventTrigger,
  PreviewEvent,
  ClosedEvent,
  HelpHubEvent,
  HelpHubDocEvent,
  PreviewEngagementType,
  HelpHubDocEngagementType,
  PreviewEngagementEvent,
  HelpHubDocEngagementEvent,
  HelpHubEngagementEvent,
  SurveyResponseEvent,
  ChatHistory,
  NoChatResponseEvent,
  ClientErrorEvent,
  NudgeStepButton,
} from './EventHandler';
import debounce from 'lodash/debounce';
import { getAdditionalCommandDetails } from './helpers';
import { getSentry } from '@commandbar/internal/util/sentry';
import { IEventAttributes } from './types';
import { getNudgeStep } from '@commandbar/internal/util/nudges';

/*********************************************************************** */
/*                   Log events - logged in Data warehouse               */
/*********************************************************************** */

export const startSession = (sessionID: string) => {
  Analytics.log('New session', {
    session: sessionID,
  });
};

export const endSession = () => {
  const currentTime = Date.now();
  const session = getSDK()[_configuration].session;
  if (typeof session === 'string') {
    // Report the session length (in seconds)
    try {
      const sessionLength = (currentTime - parseInt(session.substring(0, currentTime.toString().length))) / 1000;
      Analytics.log(
        'End session',
        {
          length: sessionLength,
        },
        true,
      );
    } catch (e) {
      getSentry()?.captureException(e);
    }
  }
};

export const deadend = (inputText: string, trigger: AbandonedSearchEvent['trigger'], _: CBStore) => {
  const visibleOptions = SpotlightServiceSelectors.search(inputText, _, _.spotlight.currentOptions);
  const results = {
    numCommands: visibleOptions.filter(SpotlightServiceSelectors.isCommandOption).length,
    numRecords: visibleOptions.filter(SpotlightServiceSelectors.isParameterOption).length,
  };

  const payload: Omit<AbandonedSearchEvent, 'type'> = {
    'inputText[*]': inputText,
    inputText,
    command: SpotlightServiceSelectors.getActiveCommand(_)?.id,
    trigger,
    resource: SpotlightServiceSelectors.getActiveRecord(_)?.category.contextKey,
    previousCommands: _.spotlight.executionHistory,
    results,
  };
  Analytics.log('Abandoned search', payload);
};

export const startSearch = (trigger: OpenedEventTrigger, placeholder: string) => {
  const searchID = `${getSDK()[_configuration].session}-${Date.now().toString()}`;
  getSDK()[_search] = searchID;

  const payload: Omit<OpenedEvent, 'type'> = { trigger, placeholder };

  Analytics.log('New search', payload);
};

export const endSearch = (inputText: string, _: CBStore) => {
  const payload: Omit<ClosedEvent, 'type'> = { inputText };

  Analytics.log('Exited', payload);

  const activeCommand = SpotlightServiceSelectors.getActiveCommand(_);
  const activeRecord = SpotlightServiceSelectors.getActiveRecord(_)?.category.contextKey;

  // If the search is ended with a non empty state, treat it as a deadend
  if (inputText !== '' || activeCommand || activeRecord) {
    deadend(inputText, 'Closed with text', _);
  }
};

export const reportHelpHubDocEventEvent = (
  name: 'HelpHub doc opened' | 'HelpHub doc closed',
  helpHubDoc: HelpHubDocEvent['helpHubDoc'],
) => {
  Analytics.log(name, { helpHubDoc });
};

const reportHelpHubEventEvent = (
  name: 'HelpHub opened' | 'HelpHub closed' | 'HelpHub unminimized' | 'HelpHub minimized',
  helpHub: HelpHubEvent['helpHub'],
) => {
  Analytics.log(name, { helpHub });
};

export const helpHubDocEngagement = (
  helpHubDoc: HelpHubDocEngagementEvent['helpHubDoc'],
  engagement_type: HelpHubDocEngagementType,
) => {
  const payload: Omit<HelpHubDocEngagementEvent, 'type'> = {
    helpHubDoc,
    engagement_type,
  };

  Analytics.log('HelpHub doc engagement', payload);
};

export const helpHubEngagement = (
  helpHub: HelpHubEngagementEvent['helpHub'],
  engagement_type: 'search' | 'scrolled' | 'chat_started' | 'chat_closed' | 'chat_reset',
) => {
  const payload: Omit<HelpHubEngagementEvent, 'type'> = {
    helpHub,
    engagement_type,
  };

  Analytics.log('HelpHub engagement', payload);
};

export const helpHubSuggestedQuery = (helpHub: HelpHubEngagementEvent['helpHub'], suggested_query: string) => {
  const payload: HelpHubEngagementEvent = {
    type: `help_hub_engagement`,
    helpHub,
    engagement_type: 'suggested_query_clicked',
    suggested_query,
  };

  Analytics.log('HelpHub engagement', payload);
};

export const helpHubContinuationClicked = (
  helpHub: HelpHubEngagementEvent['helpHub'],
  chat_history: ChatHistory,
  continuation: string,
) => {
  const payload: HelpHubEngagementEvent = {
    type: `help_hub_engagement`,
    helpHub,
    engagement_type: 'continuation_clicked',
    chat_history,
    continuation,
  };

  Analytics.log('HelpHub engagement', payload);
};

export const helpHubChatActionClicked = (chat_id: string, action: any) => {
  const payload: HelpHubEngagementEvent = {
    type: `help_hub_engagement`,
    engagement_type: 'chat_action_clicked',
    helpHub: { query: null },
    chat_id,
    action,
  };

  Analytics.log('HelpHub engagement', payload);
};

export const helpHubChatMessage = (from: 'ai' | 'user', message: string, fallback: boolean, chat_id: string) => {
  const payload: HelpHubEngagementEvent = {
    type: `help_hub_engagement`,
    engagement_type: 'chat_message',
    from,
    helpHub: { query: null },
    chat_id,
    message,
    fallback,
  };
  Analytics.log('HelpHub engagement', payload);
};

export const helpHubChatFeedback = ({
  message,
  message_id,
  rating,
}: {
  message: string;
  message_id: number;
  rating: number;
}) => {
  const payload: HelpHubEngagementEvent = {
    type: `help_hub_engagement`,
    engagement_type: 'chat_feedback',
    helpHub: { query: null },
    message_id,
    message,
    rating,
  };
  Analytics.log('HelpHub engagement', payload);
};

export const helpHubOpened = (helpHub: HelpHubEvent['helpHub']) => reportHelpHubEventEvent('HelpHub opened', helpHub);
export const helpHubClosed = (helpHub: HelpHubEvent['helpHub']) => reportHelpHubEventEvent('HelpHub closed', helpHub);
export const helpHubUnminimized = (helpHub: HelpHubEvent['helpHub']) =>
  reportHelpHubEventEvent('HelpHub unminimized', helpHub);
export const helpHubMinimized = (helpHub: HelpHubEvent['helpHub']) =>
  reportHelpHubEventEvent('HelpHub minimized', helpHub);

export const helpHubDocOpened = (helpHub: HelpHubDocEvent['helpHubDoc']) =>
  reportHelpHubDocEventEvent('HelpHub doc opened', helpHub);
export const helpHubDocClosed = (helpHub: HelpHubDocEvent['helpHubDoc']) =>
  reportHelpHubDocEventEvent('HelpHub doc closed', helpHub);

export const searchInputChange = (inputText: string, _: CBStore) => {
  const activeCommand = SpotlightServiceSelectors.getActiveCommand(_);
  const activeRecord = SpotlightServiceSelectors.getActiveRecord(_)?.category.contextKey;

  Analytics.log('Search input', {
    'inputText[*]': inputText,
    inputText,
    command: activeCommand && activeCommand.id,
    resource: activeRecord,
  });
};

export const onboardingTooltip = (message: string) => {
  Analytics.log('Onboarding tooltip shown', { message: message });
};

export const customTooltip = (message: string) => {
  Analytics.log('Tooltip shown', { message: message });
};

export const suggestion = (text: string) => {
  const payload: Omit<CommandSuggestionEvent, 'type'> = {
    text,
  };
  Analytics.log('Command suggestion', payload);
};

export const previewShown = (preview: PreviewEvent['preview']) => {
  const payload: Omit<PreviewEvent, 'type'> = {
    preview,
  };

  Analytics.log('Preview shown', payload);
};

export const nextStepSelected = (preview: PreviewEvent['preview']) => {
  const payload: Omit<PreviewEvent, 'type'> = {
    preview,
  };

  Analytics.log('Next step selected', payload);
};

export const previewLinkOpen = (preview: PreviewEvent['preview']) => {
  const payload: Omit<PreviewEvent, 'type'> = {
    preview,
  };

  Analytics.log('Preview link opened', payload);
};

export const previewEngagement = (
  preview: PreviewEngagementEvent['preview'],
  engagement_type: PreviewEngagementType,
) => {
  const payload: Omit<PreviewEngagementEvent, 'type'> = {
    preview,
    engagement_type,
  };

  Analytics.log('Preview engagement', payload);
};

export const nudgeShown = (n: INudgeType, stepIndex: number, isSimulated: boolean) => {
  const step = getNudgeStep(n, stepIndex);

  if (!step) return;

  const payload: Omit<NudgeEvent, 'type'> = {
    nudge: {
      id: String(n.id),
      trigger: n.trigger,
      template_source: n.template_source,
      slug: n.slug,
      frequency_limit: n.frequency_limit,
      step: {
        id: step.id,
        title: step.title,
      },
    },
    status: {
      is_preview: isSimulated,
      is_live: n.is_live,
    },
  };
  Analytics.log('Nudge shown', payload);
};

export const nudgeCompleted = (n: INudgeType, stepIndex: number, isSimulated: boolean) => {
  const step = getNudgeStep(n, stepIndex);

  if (!step) return;

  const payload: Omit<NudgeEvent, 'type'> = {
    nudge: {
      id: String(n.id),
      trigger: n.trigger,
      template_source: n.template_source,
      slug: n.slug,
      frequency_limit: n.frequency_limit,
      step: {
        id: step.id,
        title: step.title,
      },
    },
    status: {
      is_preview: isSimulated,
      is_live: n.is_live,
    },
  };
  Analytics.log('Nudge completed', payload);
};

export const nudgeDismissed = (n: INudgeType, stepIndex: number, isSimulated: boolean) => {
  const step = getNudgeStep(n, stepIndex);

  if (!step) return;

  const payload: Omit<NudgeEvent, 'type'> = {
    nudge: {
      id: String(n.id),
      trigger: n.trigger,
      template_source: n.template_source,
      slug: n.slug,
      frequency_limit: n.frequency_limit,
      step: {
        id: step.id,
        title: step.title,
      },
    },
    status: {
      is_preview: isSimulated,
      is_live: n.is_live,
    },
  };
  Analytics.log('Nudge dismissed', payload);
};

export const nudgeSnoozed = (n: INudgeType, stepIndex: number, isSimulated: boolean) => {
  const step = getNudgeStep(n, stepIndex);

  if (!step) return;

  const payload: Omit<NudgeEvent, 'type'> = {
    nudge: {
      id: String(n.id),
      trigger: n.trigger,
      template_source: n.template_source,
      slug: n.slug,
      frequency_limit: n.frequency_limit,
      step: {
        id: step.id,
        title: step.title,
      },
    },
    status: {
      is_preview: isSimulated,
      is_live: n.is_live,
    },
  };
  Analytics.log('Nudge snoozed', payload);
};

export const nudgeStepClicked = (n: INudgeType, stepIndex: number, isSimulated: boolean, button?: NudgeStepButton) => {
  const step = getNudgeStep(n, stepIndex);

  if (!step) return;

  const payload: Omit<NudgeEvent, 'type'> = {
    nudge: {
      id: String(n.id),
      trigger: n.trigger,
      template_source: n.template_source,
      slug: n.slug,
      frequency_limit: n.frequency_limit,
      step: {
        id: step.id,
        title: step.title,
        ...(button && { button: button }),
      },
    },
    status: {
      is_preview: isSimulated,
      is_live: n.is_live,
    },
  };
  Analytics.log('Nudge clicked', payload);
};

export const checklistShown = (c: IChecklist, completed: boolean) => {
  const payload: Omit<ChecklistEvent, 'type'> = {
    questlist: {
      id: c.id,
      title: c.title,
      completed_items: 0,
      total_items: c.items.length,
      trigger: c.trigger,
      completed,
    },
  };

  Analytics.log('Questlist shown', payload);
};

export const checklistEngagement = (
  c: IChecklist,
  completed_items: number,
  engagement_type: ChecklistEngagementType,
  completed: boolean,
) => {
  const payload: Omit<ChecklistEvent, 'type'> = {
    questlist: {
      id: c.id,
      title: c.title,
      completed_items,
      total_items: c.items.length,
      trigger: c.trigger,
      completed,
    },
    engagement_type,
  };

  Analytics.log('Questlist engagement', payload);
};

export const checklistItemEngagement = (
  item: IChecklistItem,
  questlist_id: number,
  completed: boolean,
  engagement_type: ChecklistItemEngagementType,
) => {
  const payload: Omit<ChecklistItemEvent, 'type'> = {
    questlist_item: {
      id: item.id,
      questlist_id,
      completed,
      cta: item.cta,
      goal: item.goal.type === 'conditions_met' ? { type: 'conditions_met' } : item.goal,
      title: item.title,
    },
    engagement_type,
  };

  Analytics.log('Questlist item engagement', payload);
};

export const execution = (
  command: ICommandType,
  inputText: string,
  meta?: {
    shortcut?: boolean;
    categoryText?: string;
    placeholder?: string;
    selections?: Record<string, any>;
  },
  eventSource?: ExecutionEventSource,
) => {
  const payload: Omit<CommandExecutionEvent, 'type'> = {
    command: command?.id >= 1 ? command.id : command.name, // command.name used for programmatic commands
    commandText: command.text,
    commandDetails: getAdditionalCommandDetails(command),
    category: command.category,
    categoryText: meta?.categoryText,
    source: command.source || 'standard',
    commandType: command.template?.type,
    'inputText[*]': inputText,
    inputText,
    eventSource: eventSource || { type: 'bar' },
    ...(meta || {}),
  };

  Analytics.log('Command execution', payload);
};

export const changedShortcut = (
  command: ICommandType,
  oldShortcut: string,
  newShortcut: string,
  defaultShortcut: string,
  meta?: { [key: string]: any },
) => {
  const payload: Omit<EndUserShortcutChangedEvent, 'type'> = {
    command: command?.id >= 1 ? command.id : command.name, // command.name used for programmatic commands
    commandText: command.text,
    category: command.category,
    source: command.source || 'standard',
    oldShortcut,
    newShortcut,
    defaultShortcut,
    ...(meta || {}),
  };

  Analytics.log('User changed shortcut', payload);
};

// NOTE: some nuance here: https://github.com/tryfoobar/monobar/pull/1554/files#r942109861
export const unavailableShortcut = (shortcut: string[], command: ICommandType) => {
  Analytics.log('Unavailable shortcut', {
    shortcut,
    command: command.id,
    commandText: command.text,
    url: window.location.href,
  });
};

export const noChatResponse = (chatHistory: ChatHistory) => {
  const payload: Omit<NoChatResponseEvent, 'type'> = {
    chatHistory,
  };

  Analytics.log('No chat response', payload, true);
};

export const clickedBranding = () => {
  Analytics.log('Clicked branding', {});
};

export const surveyResponse = (
  n: INudgeType,
  step: INudgeStepType,
  response: SurveyResponseEvent['response'],
  isSimulated: boolean,
) => {
  const payload: Omit<SurveyResponseEvent, 'type'> = {
    response,
    nudge: {
      id: String(n.id),
      trigger: n.trigger,
      template_source: n.template_source,
      slug: n.slug,
      frequency_limit: n.frequency_limit,
      step: {
        id: step.id,
        title: step.title,
      },
    },
    status: {
      is_preview: isSimulated,
      is_live: n.is_live,
    },
  };

  Analytics.log('Survey response', payload, true);
};

/**
 * Tmp: debounce NoResults because it can be very spammy when client search is turned on
 */
export const noResultsForQuery: (inputText: string, eventSource?: ExecutionEventSource) => void = debounce(
  (inputText: string, eventSource?: ExecutionEventSource) => {
    const payload: Omit<NoResultsForQueryEvent, 'type'> = {
      inputText,
      eventSource: eventSource || { type: 'bar' },
    };
    Analytics.log('No results for query', payload);
  },
  500,
);

type ErrorType = 'not_booted' | 'nudge_step_element_not_found' | 'checklist_item_element_not_found';

export const error = (message: ErrorType, attrs: Omit<ClientErrorEvent, 'type' | 'url' | 'message'>) => {
  const payload: Partial<IEventAttributes> = {
    type: 'client_error',
    url: window.location.href,
    error: {
      ...attrs,
      message,
      url: window.location.href,
    },
  };

  Analytics.error(payload);
};

export const sdkMethodCalled = (method: 'addEventSubscriber' | 'addEventHandler') => {
  const payload: Partial<IEventAttributes> = {
    type: 'sdk_method_called',
    source: JSON.stringify({
      method,
    }),
  };

  Analytics.log('SDK method called', payload);
};
