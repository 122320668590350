import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';
import { Alert, Input, Form } from '@commandbar/design-system/components/antd';

import { Eye, EyeOff } from '@commandbar/design-system/icons/react';

const Ghost: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.GHOST,
  name: 'Ghost',
  slug: 'ghost',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/ghost-logo.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/ghost-icon.png',
  useDryRun: true,
  isDisabled: false,
  description: 'Sync posts with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={"In order to import Ghost posts, you'll have to first find your Ghost content API Key."}
    />
  ),
  route: '/ghost',

  getForm({ editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={editingSource?.type + 'api_key'}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.api_key}
          required={true}
          label={'API Key'}
          name={'api_key'}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: `Please enter your API Key. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input.Password
            iconRender={(visible) => (visible ? <Eye height={16} /> : <EyeOff height={16} />)}
            style={{ width: '100%' }}
            autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item
          key={editingSource?.type + '-domain'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.domain}
          required={true}
          label="Admin Domain"
          name="domain"
          tooltip={{
            title: 'Your ghost.io admin domain',
          }}
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              pattern: /^(www\.)?[a-zA-Z0-9@:%.\-_+~#=]{2,256}\.ghost.io$/gi,
              message: `Please enter a valid URL (e.g., yourdomain.ghost.io). Don't include 'https' prefixes or trailing slashes. `,
            },
          ]}
          style={{ flex: 5 }}
        >
          <Input style={{ width: '100%' }} placeholder="yourdomain.ghost.io" />
        </Form.Item>

        <Form.Item
          key={editingSource?.type + '-site_url'}
          validateTrigger="onChange"
          initialValue={editingSource?.meta?.site_url}
          label="Site URL"
          name="site_url"
          rules={[
            {
              pattern: /^(www\.)?[a-zA-Z0-9@:%.\-_+~#=]{2,256}\/.*$/gi,
              message: `Please enter a valid URL (e.g., yourwebsite.com/blog). Don't include 'https' prefixes or trailing slashes. `,
            },
          ]}
          style={{ flex: 5 }}
          tooltip={{
            title: 'The URL where you host your articles if using a custom domain',
          }}
        >
          <Input style={{ width: '100%' }} placeholder="yourwebsite.com/blog" />
        </Form.Item>
      </>
    );
  },

  getPayloadMeta(data) {
    return {
      api_key: data['api_key'],
      domain: data['domain'],
      site_url: data['site_url'],
    };
  },
  requiredFields: ['domain', 'api_key', 'site_url'],
  fetchedFields: [],
};

export default Ghost;
