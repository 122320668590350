'use client';

import * as React from 'react';
import { cn } from '../util';
import { type VariantProps } from 'class-variance-authority';
import { pillStyles } from './styles';
type BasePillProps = React.HTMLAttributes<HTMLDivElement>;
type VariantPillProps = VariantProps<typeof pillStyles>;

export type PillProps = BasePillProps & VariantPillProps;

const CmdPill = React.forwardRef<HTMLDivElement, PillProps>(({ variant, ...props }, ref) => {
  const className = cn(
    pillStyles({
      variant,
    }),
  );
  return <div ref={ref} className={cn(className)} {...props} />;
});

export { CmdPill };
