import { HelpCenterIntegrationSpec } from '../shared/types';
import Zendesk from './Zendesk';
import Intercom from './Intercom';
import Freshdesk from './Freshdesk';
import Insided from './Insided';
import WixAnswers from './WixAnswers';
import Wordpress from './Wordpress';
import Gitbook from './Gitbook';
import Ghost from './Ghost';
import Readme from './Readme';
import Document360 from './Document360';
import Archbee from './Archbee';
import Helpjuice from './Helpjuice';
import IntercomScrape from './IntercomScrape';
import HubSpotScrape from './HubSpotScrape';
import Helpscout from './Helpscout';
import Confluence from './Confluence';
import VanillaForums from './VanillaForums';
import Web from './Web';
import WebV2 from './WebV2';
import Guru from './Guru';
import Zoho from './Zoho';
import Skilljar from './Skilljar';

const HelpCenterIntegrationList: HelpCenterIntegrationSpec[] = [
  Zendesk,
  Intercom,
  Freshdesk,
  WixAnswers,
  Wordpress,
  Insided,
  Gitbook,
  Ghost,
  Readme,
  Document360,
  Archbee,
  Helpjuice,
  IntercomScrape,
  HubSpotScrape,
  Helpscout,
  Confluence,
  VanillaForums,
  Web,
  WebV2,
  Guru,
  Zoho,
  Skilljar,
];

export default HelpCenterIntegrationList;
