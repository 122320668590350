import { CommandExecutionEvent, EventData, EventType } from 'shared/services/analytics/EventHandler';
import { getSDK } from '@commandbar/internal/client/globals';
import { _eventSubscriptions } from '@commandbar/internal/client/symbols';
import { IChecklist, IChecklistItem } from '@commandbar/internal/middleware/types';
import isEqual from 'lodash/isEqual';
import { updateEndUserStore } from 'shared/store/end-user/actions';

import { runBooleanExpression } from 'shared/services/targeting/helpers';
import {
  checklistPassesPageConditions,
  dontShowChecklist,
  getChecklistNumCompletedItems,
  hasIncompleteItems,
  isChecklistInPreviewMode,
  previouslyShownChecklist,
} from './selectors';
import { isItemCompleted } from './selectors';
import { getElement } from '@commandbar/internal/util/dom';
import Logger from '@commandbar/internal/util/Logger';
import { ref } from 'valtio';
import { CBStore } from 'shared/store/global-store';
import * as Reporting from 'shared/services/analytics/Reporting';
import { releaseConfetti } from 'shared/components/Confetti';
import { ChecklistInteractionState } from '@cb/types/entities/endUser';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { getConditions } from '@commandbar/internal/middleware/helpers/rules';
import { isAvailableToEndUser } from 'shared/store/helpers';
import { openEditorIfLoaded } from 'shared/store/util/editorUtils';
import { executeAction } from 'shared/store/executables/executable-actions';
import * as NudgeServiceActions from 'products/nudges/service-actions';
import { Metadata } from '@commandbar/internal/client/CommandBarClientSDK';
import { passesAudienceConditions } from 'shared/services/targeting/audience';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';

export const showChecklist = (_: CBStore, c: IChecklist) => {
  _.activeChecklist = c;
};

export const hideChecklist = (_: CBStore) => {
  _.activeChecklist = null;
};

export const setChecklists = (_: CBStore, checklists: IChecklist[]) => {
  for (const checklist of checklists) {
    if (checklist.trigger.type === 'when_element_appears') {
      _.triggerableSelectors.push(checklist.trigger.meta.selector);
    }
  }

  const hasChanged = !isEqual(_.checklists, checklists);

  if (hasChanged) {
    _.checklists = ref(checklists);
    if (_.activeChecklist !== null) {
      // re-set the activeChecklist after updating the list of checklists
      _.activeChecklist = checklists.find((c) => c.id === _.activeChecklist?.id) || null;
    } else {
      // Check if conditions have changed
      triggerChecklists(_, { type: 'when_conditions_pass' });
    }
  }
};

const activateChecklist = (_: CBStore, checklist: IChecklist) => {
  _.activeChecklist = checklist;
  _.queuedChecklist = null;

  const allChecklistData = { ..._.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[checklist.id] || {};
  const newChecklistData = { ...allChecklistData, [checklist.id]: { ...thisChecklistData, isseen: true } };

  updateEndUserStore(_, { checklists: newChecklistData, activeChecklistId: checklist.id }, 'checklist_interactions');

  // trigger any nudges that are waiting for the checklist to be viewed
  NudgeServiceActions.sendIndirectTrigger(_, { type: 'when_conditions_pass' });

  const completed = !hasIncompleteItems(_, checklist);
  Reporting.checklistShown(checklist, completed);
  Track.checklist.viewed(checklist);
};

export const forceTriggerChecklist = (_: CBStore, checklist: IChecklist) => {
  if (isAvailableToEndUser(_, checklist) && checklistPassesPageConditions(_, checklist)) {
    clearChecklistFields(_, checklist, ['isSkipped', 'isCompleted', 'isExpanded']);
    activateChecklist(_, checklist);
  }
};

export const triggerChecklists = (_: CBStore, trigger: IChecklist['trigger'], properties: Metadata = {}) => {
  if (
    typeof _.endUser === 'undefined' ||
    _.checklists.length === 0 ||
    !_.products.includes('checklists') ||
    isStudioPreview()
  )
    return;

  const isTriggered = (checklist: IChecklist): boolean => {
    /**
     * Note: the 'when_share_link_viewed' trigger does not have to be set for share linking to work.
     * Rather, nudges/QLs with this trigger cannot be viewed unless it is via share link
     */
    if (checklist.trigger.type === 'when_share_link_viewed') return false;
    if (trigger?.type === 'when_page_reached' && checklist.trigger.type === 'when_page_reached') {
      return trigger.meta.url.includes(checklist.trigger.meta.url);
    }
    if (trigger?.type === 'on_event' && checklist.trigger.type === 'on_event') {
      if (trigger.meta.event !== checklist.trigger.meta.event) return false;

      if (checklist.trigger.meta.condition_group) {
        const result = runBooleanExpression(checklist.trigger.meta.condition_group, _, '', {
          eventProperties: properties,
        });

        return result.passed;
      } else {
        return true;
      }
    }

    return isEqual(checklist.trigger, trigger);
  };

  // show a previously shown checklist immediately even if remote interactions haven't been loaded yet
  const previouslyShown = previouslyShownChecklist(_);

  if (!!previouslyShown) {
    const previousPassesConditions =
      checklistPassesPageConditions(_, previouslyShown) && isAvailableToEndUser(_, previouslyShown);

    if (!_.activeChecklist && previousPassesConditions) {
      _.activeChecklist = previouslyShown;
    } else if (!!_.activeChecklist && _.activeChecklist?.id === previouslyShown.id && !previousPassesConditions) {
      // if the conditions are not met anymore, we need to hide the checklist
      // if the active checklist does not match the previously shown checklist, it means that it is an editor preview that we don't want to hide
      // we do not clear the activeChecklistId flag in end-user store because we want to show this checklist again once conditions are met again
      _.activeChecklist = null;
    }
  }

  // if the checklist is shown as editor mock, ignore this
  if (!!_.activeChecklist && !_.activeChecklist?._editorState) {
    // if we found a previously shown checklist in the remote end-user store and enqueued a checklist while we waited for the remote data to load, we clear it
    if (_.endUserStore.hasRemoteLoaded && !!_.queuedChecklist) {
      _.queuedChecklist = null;
    }

    if (dontShowChecklist(_, _.activeChecklist)) {
      _.activeChecklist = null;
    } else if (
      // active checklist has been cleared e.g via reset checklist
      _.endUserStore.hasRemoteLoaded &&
      _.endUserStore.data.checklist_interactions?.activeChecklistId === null
    ) {
      _.activeChecklist = null;
    } else {
      return;
    }
  }

  if (!!_.queuedChecklist && dontShowChecklist(_, _.queuedChecklist)) {
    _.queuedChecklist = null;
  }

  if (!_.queuedChecklist) {
    // check if there is a new (=not previously shown) checklist to activate
    for (let i = 0; i < _.checklists.length; i++) {
      const c = _.checklists[i];

      if ((!c.is_live && !_.isAdmin) || dontShowChecklist(_, c)) {
        continue;
      }

      if (isTriggered(c) && passesAudienceConditions(_, 'checklists', c) && checklistPassesPageConditions(_, c)) {
        // we only want to activate a new checklist once the end user store has been fully loaded
        // so we queue the checklist first and activate it once the end user store is loaded and no previously shown checklist has been found
        _.queuedChecklist = c;
        break;
      }
    }
  }

  // Activate a new checklist and modify end user store accordingly
  // we only do this once the end user store has been fully loaded
  if (_.endUserStore.hasRemoteLoaded && !!_.queuedChecklist) {
    activateChecklist(_, _.queuedChecklist);
  }
};

export const updateChecklistItemConditionsGoals = (_: CBStore) => {
  const activeChecklist = _.activeChecklist;

  if (!activeChecklist) {
    return;
  }

  activeChecklist.items
    .filter((item) => !isItemCompleted(_, activeChecklist, item))
    .forEach((item) => {
      switch (item.goal.type) {
        case 'page_visited':
          if (!!item.goal.value && _.location.href.includes(item.goal.value)) {
            onChecklistItemComplete(_, item);
          }
          break;
        case 'conditions_met':
          if (
            getConditions(item.goal.expression).length > 0 &&
            runBooleanExpression(item.goal.expression, _, '').passed
          ) {
            onChecklistItemComplete(_, item);
          }
          break;
        default:
          //other goals are listened to in the component or processed after CTA click
          break;
      }
    });
};

const goalHasMatchingEvent = <Goal extends IChecklistItem['goal'], Key extends string>(
  goal: Goal,
  eventKey: Key,
): goal is Goal & { type: 'event_tracked'; event: Key } => goal.type === 'event_tracked' && goal.event === eventKey;

export const updateChecklistItemEventGoals = (_: CBStore, eventKey: string, properties: Metadata) => {
  const activeChecklist = _.activeChecklist;

  if (!activeChecklist) {
    return;
  }

  for (const item of activeChecklist.items) {
    if (!goalHasMatchingEvent(item.goal, eventKey) || isItemCompleted(_, activeChecklist, item)) {
      continue;
    }

    const shouldCompleteItem = item.goal.condition_group
      ? runBooleanExpression(item.goal.condition_group, _, '', {
          eventProperties: properties,
        }).passed
      : true;

    if (shouldCompleteItem) {
      onChecklistItemComplete(_, item);
    }
  }
};

export const onChecklistItemSelect = (_: CBStore, item: IChecklistItem, e: React.MouseEvent<HTMLElement>) => {
  if (!!_.activeChecklist) {
    const checklist = _.activeChecklist;
    Reporting.checklistItemEngagement(item, checklist.id, isItemCompleted(_, checklist, item), 'cta_clicked');
    Track.checklist.ctaClicked(checklist, item);
  }

  if (isStudioPreview()) {
    _.callbacks['__standalone-editor-checklist-action-cta-clicked']();
    return;
  }

  if (item.goal.type === 'cta_clicked') {
    onChecklistItemComplete(_, item);
  }

  executeAction(_, item.action, e, {}, { type: 'questlist', id: _.activeChecklist?.id || 0 });
};

export const onChecklistItemComplete = (_: CBStore, item: IChecklistItem, skipped = false) => {
  if (!_.activeChecklist) {
    return;
  }
  if (item.celebrate) releaseConfetti();

  const checklist = _.activeChecklist;
  const incomplete = hasIncompleteItems(_, checklist);

  if (skipped) {
    Reporting.checklistItemEngagement(item, checklist.id, true, 'skipped');
    Track.checklist.itemSkipped(checklist, item, incomplete);
  } else {
    Track.checklist.itemCompleted(checklist, item, incomplete);
  }

  Reporting.checklistItemEngagement(item, checklist.id, true, 'completed');

  const allChecklistData = { ..._.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[_.activeChecklist.id] || {};
  const items = { ...thisChecklistData?.items } || {};
  items[item.id] = { completedTS: new Date().toISOString() };
  const newChecklistData = { ...allChecklistData, [_.activeChecklist.id]: { ...thisChecklistData, items } };
  updateEndUserStore(_, { checklists: newChecklistData }, 'checklist_interactions');

  if (!hasIncompleteItems(_, checklist)) {
    Reporting.checklistEngagement(checklist, checklist.id, 'completed', true);
    Track.checklist.completed(checklist);
    // trigger any nudges that are waiting for the checklist to be completed
    NudgeServiceActions.sendIndirectTrigger(_, { type: 'when_conditions_pass' });
  }

  if (checklist.celebrate && !hasIncompleteItems(_, checklist)) {
    releaseConfetti();
  }
};

export const addGoalListeners = (_: CBStore, checklist: IChecklist, items: IChecklistItem[]): (() => void) => {
  const removeListenerFns: (() => void)[] = [];

  items
    .filter((item) => !isItemCompleted(_, checklist, item))
    .forEach((item) => {
      switch (item.goal.type) {
        case 'command_executed':
          const command = item.goal.meta.command;
          const sdk = getSDK();
          if (!!sdk[_eventSubscriptions] && sdk[_eventSubscriptions] !== undefined) {
            const symbol = Symbol(`checklistItemListener-${item.id}`);

            sdk[_eventSubscriptions]?.set(symbol, [
              (name: EventType, data: EventData) => {
                if (name === 'command_execution') {
                  if (`${(data as CommandExecutionEvent).command}` === command && _.activeChecklist) {
                    onChecklistItemComplete(_, item);
                  }
                }
              },
              undefined,
            ]);

            removeListenerFns.push(() => {
              sdk[_eventSubscriptions]?.delete(symbol);
            });
          }
          break;
        case 'element_clicked': {
          const { goal } = item;
          const maxAttempts = 4;
          let attempts = 0;

          const attemptToAddListener = () => {
            const element = getElement(goal.value);
            if (element) {
              const clickListener = () => {
                onChecklistItemComplete(_, item);
              };
              element.addEventListener('click', clickListener);

              removeListenerFns.push(() => {
                element.removeEventListener('click', clickListener);
              });

              clearInterval(intervalId);
            } else {
              attempts++;
              if (attempts >= maxAttempts) {
                clearInterval(intervalId);
                Logger.warn(`Couldn't find element for click goal: ${goal.value}`);
              }
            }
          };

          const intervalId = setInterval(attemptToAddListener, 500);
          break;
        }
        case 'page_visited':
          // handled in updateChecklists as subscription to location and context change
          break;
        case 'conditions_met':
          // handled in updateChecklists as subscription to location and context change
          break;
        case 'cta_clicked':
          // handled in onChecklistItemSelect
          break;
      }
    });

  return () => {
    removeListenerFns.forEach((fn) => fn());
  };
};

export const markChecklistAsCompleted = (_: CBStore, checklist: IChecklist) => {
  _.activeChecklist = null;
  const allChecklistData = { ..._.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[checklist.id] || {};
  const newChecklistData = { ...allChecklistData, [checklist.id]: { ...thisChecklistData, isCompleted: true } };

  updateEndUserStore(_, { checklists: newChecklistData, activeChecklistId: null }, 'checklist_interactions');
  const completed = !hasIncompleteItems(_, checklist);

  Reporting.checklistEngagement(checklist, checklist.items.length, 'dismissed', completed);
  Track.checklist.dismissed(checklist, !completed);

  // trigger any nudges that are waiting for the checklist to be dismissed
  NudgeServiceActions.sendIndirectTrigger(_, { type: 'when_conditions_pass' });

  if (isChecklistInPreviewMode(checklist)) {
    /** This is part of the "Preview" experience. The editor closes when a QL is previewed and re-opens when the preview is completed. */
    openEditorIfLoaded();
  }
};

export const markChecklistAsSkipped = (_: CBStore, checklist: IChecklist) => {
  _.activeChecklist = null;
  const allChecklistData = { ..._.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[checklist.id] || {};
  const newChecklistData = {
    ...allChecklistData,
    [checklist.id]: { ...thisChecklistData, isSkipped: true },
  };

  updateEndUserStore(_, { checklists: newChecklistData, activeChecklistId: null }, 'checklist_interactions');
  const completed = !hasIncompleteItems(_, checklist);

  Reporting.checklistEngagement(checklist, getChecklistNumCompletedItems(_, checklist), 'dismissed', completed);
  Track.checklist.dismissed(checklist, !completed);

  // trigger any nudges that are waiting for the checklist to be dismissed
  NudgeServiceActions.sendIndirectTrigger(_, { type: 'when_conditions_pass' });

  if (isChecklistInPreviewMode(checklist)) {
    /** This is part of the "Preview" experience. The editor closes when a QL is previewed and re-opens when the preview is completed. */
    openEditorIfLoaded();
  }
};

export const setChecklistExpandedState = (_: CBStore, checklist: IChecklist, value: boolean) => {
  const allChecklistData = { ..._.endUserStore.data.checklist_interactions.checklists } || {};
  const thisChecklistData = allChecklistData[checklist.id] || {};
  const newChecklistData = {
    ...allChecklistData,
    [checklist.id]: { ...thisChecklistData, isExpanded: value },
  };

  updateEndUserStore(_, { checklists: newChecklistData }, 'checklist_interactions');
};

export const clearChecklistData = (_: CBStore, checklist: IChecklist, clearFromActive = false) => {
  const allChecklistData = { ..._.endUserStore.data.checklist_interactions.checklists } || {};
  delete allChecklistData[checklist.id];

  const dataUpdate: any = { checklists: allChecklistData };

  if (clearFromActive && _.activeChecklist?.id === checklist.id) {
    if (!_.activeChecklist._editorState) {
      _.activeChecklist = null;
    }

    dataUpdate.activeChecklistId = null;
  }

  updateEndUserStore(_, dataUpdate, 'checklist_interactions');
};

export const clearChecklistFields = (
  _: CBStore,
  checklist: IChecklist,
  fieldsToClear: Array<keyof ChecklistInteractionState>,
) => {
  const allChecklistData = { ..._.endUserStore.data.checklist_interactions.checklists } || {};
  if (!allChecklistData[checklist.id]) return;
  for (const field of fieldsToClear) {
    if (allChecklistData[checklist.id][field] !== undefined) {
      delete allChecklistData[checklist.id][field];
    }
  }
  updateEndUserStore(_, { checklists: allChecklistData }, 'checklist_interactions');
};

export const resetChecklist = (_: CBStore, id: IChecklist['id'], keepOpen = false) => {
  const checklist = _.checklists.find(({ id: _id }) => _id === id);
  if (checklist) {
    clearChecklistData(_, checklist, !keepOpen);
  }
};
