import { useLocation, useParams } from 'react-router-dom';

import { CmdBreadcrumb } from '@commandbar/design-system/cmd';
import { AnalyticsSurveys } from '@commandbar/internal/middleware/analytics/surveys';
import { useAppContext } from 'editor/src/AppStateContext';
import { getSurveyUsersDemoData } from '../../analyticDemoData';
import FunnelChart from '../../components/FunnelChart';
import { GoalCard } from '../../components/GoalCard';
import Layout from '../../components/Layout';
import NPSChart from '../../components/NPSChart';
import StatCard from '../../components/StatCard';
import { useGoalEventName } from '../../useGoalEventName';
import SurveyResponseTable from '../SurveyResponseTable';
import { ViewsTable } from '../ViewsTable';
import { useAnalyticSurveyDetail } from './useAnalyticSurveyDetail';

const AnalyticsSurveysDetailDashboard = () => {
  const { id } = useParams<{ id: string }>();
  const { flags } = useAppContext();
  const [goalEventName, setGoalEventName] = useGoalEventName('surveys', id);
  const location = useLocation();

  const {
    isLoading,
    error,
    fetchData,
    downloadCsv,
    isDownloading,
    downloadCsvResponses,
    data,
    surveyDetails,
    downloadCsvViews,
  } = useAnalyticSurveyDetail({ id, goalEventName });

  return (
    <Layout
      title={`${data?.table.rows[0].step_title || 'Survey'}`}
      titleLink={`/editor/surveys/${id}`}
      subtitle={<CmdBreadcrumb items={[{ label: 'Surveys', to: '/analytics/surveys' }]} />}
      disableDemoData={true}
      isDownloading={isDownloading}
      onDownloadCSV={[
        {
          label: 'Table details',
          action: downloadCsv,
        },
        {
          label: 'Survey responses',
          action: downloadCsvResponses,
        },
        {
          label: 'Survey views',
          action: downloadCsvViews,
        },
      ]}
    >
      <div
        style={{
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              flexBasis: '25%',
              gap: '16px',
            }}
          >
            <StatCard
              title="Views"
              subtitle="Total times survey was shown"
              format="number"
              metric={data?.total_views?.metric}
              rate={data?.total_views?.percent_change}
              isLoading={isLoading}
            />
            <StatCard
              title="Responses"
              subtitle="Total survey responses received"
              format="number"
              metric={data?.total_responses?.metric}
              rate={data?.total_responses?.percent_change}
              isLoading={isLoading}
            />
            <StatCard
              title="Response rate"
              subtitle="Avg response rate for this survey"
              format="percentage"
              metric={data?.completion_rate?.metric}
              rate={data?.completion_rate?.percent_change}
              isLoading={isLoading}
            />
            {data?.nps && (
              <StatCard
                title="NPS"
                subtitle="Net promoter score"
                format="number"
                metric={data?.nps?.metric}
                rate={data?.nps?.percent_change}
                isLoading={isLoading}
              />
            )}
            {flags['release-nudge-goals'] && (
              <GoalCard
                title={goalEventName ? 'Goal rate' : 'Set a goal'}
                subtitle={goalEventName ? 'Avg. event completion rate' : 'Measure the impact of this survey.'}
                metric={data?.goal?.metric}
                rate={data?.goal?.percent_change}
                eventName={goalEventName}
                isLoading={isLoading}
                onGoalEventChange={setGoalEventName}
              />
            )}
          </div>

          {data?.nps_chart && (
            <NPSChart
              title="NPS distribution"
              subtitle="Breakdown of detractors, passives, and promoters"
              data={data?.nps_chart}
              isLoading={isLoading}
            />
          )}

          <FunnelChart
            title="Performance"
            subtitle="Discover how each step of your survey has been performing"
            chartHeight={400}
            data={data?.end_user_funnel}
            isLoading={isLoading}
            style={{ flexGrow: 1 }}
          />
        </div>

        {!!data?.table && (
          <SurveyResponseTable
            isLoading={isLoading}
            error={error}
            fetchData={fetchData}
            surveyDetails={surveyDetails}
            rows={data?.table.rows || []}
          />
        )}

        <ViewsTable
          id={id}
          readUsers={AnalyticsSurveys.readUsers}
          getDemoData={() => getSurveyUsersDemoData(location.pathname.includes('nps'))}
        />
      </div>
    </Layout>
  );
};

export default AnalyticsSurveysDetailDashboard;
