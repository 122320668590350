import { CmdSortableColumn, CmdTypography } from '@commandbar/design-system/cmd';

import { useParams } from 'react-router-dom';
import { TableRowData } from '@commandbar/internal/middleware/analytics/common';
import { CmdColumnDef } from '@commandbar/design-system/cmd';
import { useAnalyticsContext } from '../AnalyticsContext';
import NudgeDetailPanelTrigger from './surveys/NudgeDetailPanel';
import { formatNumber, formatPercentage } from '../utils';
import Table from '../components/Table';
import { SurveyDetailsWithResponses } from '@commandbar/internal/middleware/analytics/surveys';
import { FaceSmile, Hash01, Star01, UnorderedList } from '@commandbar/design-system/icons/react';

export const getAverageDisplay = (step: SurveyDetailsWithResponses['nudge_steps'][number] | undefined) => {
  const responses = step?.responses;
  if (!responses || responses.length === 0) return null;

  if (step.survey_details?.type === 'survey_list') {
    const frequencyMap: { [key: string]: number } = {};
    let mostFrequentString = null;
    let maxFrequency = 0;

    for (const response of responses) {
      if (typeof response.value === 'string') {
        frequencyMap[response.value] = (frequencyMap[response.value] || 0) + 1;
        if (frequencyMap[response.value] > maxFrequency) {
          maxFrequency = frequencyMap[response.value];
          mostFrequentString = response.value;
        }
      }
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
        <UnorderedList /> {mostFrequentString}
      </div>
    );
  } else if (step.survey_details?.type === 'survey_rating') {
    let sum = 0;
    let count = 0;

    for (const response of responses) {
      if (typeof response.value === 'number') {
        sum += response.value;
        count++;
      } else {
        return null;
      }
    }

    if (count > 0) {
      const average = sum / count;

      if (step.survey_details?.meta?.type === 'stars') {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
            <Star01 /> {formatNumber(average)}
          </div>
        );
      } else if (step.survey_details?.meta?.type === 'numbers' || step.survey_details?.meta?.type === 'nps') {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
            <Hash01 /> {formatNumber(average)}
          </div>
        );
      } else if (step.survey_details?.meta?.type === 'emojis') {
        return (
          <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
            <FaceSmile /> {step.survey_details.meta.emojis?.[Math.floor(average)] || null}
          </div>
        );
      }
    }
  }

  return null;
};

const SurveyResponseTable = ({
  surveyDetails,
  isLoading,
  error,
  fetchData,
  rows,
}: {
  surveyDetails?: SurveyDetailsWithResponses;
  isLoading: boolean;
  error: boolean;
  fetchData: () => void;
  rows: { [key: string | number]: any }[];
}) => {
  const { id } = useParams<{ id: string }>();
  const { timeFilterRange } = useAnalyticsContext();

  const columns: CmdColumnDef<TableRowData, string | number>[] = [
    {
      accessorKey: 'step_title',
      header: 'Step',
      enableGlobalFilter: true,
      cell: (cell) => (
        <NudgeDetailPanelTrigger
          nudgeId={id}
          triggerDisplayValue={cell.getValue().toString()}
          initilActiveIndex={Number(cell.row.id)}
          timeFilterRange={timeFilterRange}
          surveyDetails={surveyDetails}
        />
      ),
    },
    {
      accessorKey: 'responses',
      header: ({ column }) => <CmdSortableColumn column={column} title="Responses" />,
      cell: ({ getValue }) => formatNumber(getValue()),
    },
    {
      accessorKey: 'response_rate',
      header: ({ column }) => <CmdSortableColumn column={column} title="Response rate" />,
      cell: ({ getValue }) => formatPercentage(getValue()),
    },
    {
      accessorKey: 'step_title',
      header: 'Avg. rating',
      cell: ({ row }) => {
        const stepTitle = row.original.step_title;
        const step = surveyDetails?.nudge_steps.find((step) => step.title === stepTitle);
        return getAverageDisplay(step);
      },
    },
  ];

  return (
    <Table
      toolBarChildren={<CmdTypography.Body fontWeight="semi-bold">Responses</CmdTypography.Body>}
      columns={columns}
      data={rows || []}
      isLoading={isLoading}
      empty={{
        error,
        refetchData: fetchData,
      }}
    />
  );
};

export default SurveyResponseTable;
