import {
  CmdSheet,
  CmdButton,
  CmdButtonTabs,
  CmdAvatar,
  CmdColumnDef,
  CmdTypography,
} from '@commandbar/design-system/cmd';

import { ClockRewind, UserSquare, X } from '@commandbar/design-system/icons/react';
import { IEndUserAdmin } from '@commandbar/internal/middleware/endUserAdmin';
import React from 'react';
import InteractionHistory from './InteractionHistory';
import PropertiesTable from './PropertiesTable';

type Obj = { [key: string]: any };
type PropertyList = { key: string; value: any }[];

function convertToPropertyList(obj: Obj, prefix = ''): PropertyList {
  const result: PropertyList = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const newKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        // Recursively handle objects
        result.push(...convertToPropertyList(value, newKey));
      } else if (Array.isArray(value)) {
        // Convert arrays to strings
        result.push({ key: newKey, value: `[${value.join('","')}]` });
      } else {
        // Handle other values
        result.push({ key: newKey, value });
      }
    }
  }

  return result;
}

const UserDetailDrawer = ({
  endUser,
  onOpenChange,
}: {
  endUser: IEndUserAdmin | null;
  onOpenChange: (value: boolean) => void;
}) => {
  const [activeTab, setActiveTab] = React.useState('history');
  if (!endUser) return null;

  const propertyColumns: CmdColumnDef<Obj, string>[] = [
    {
      accessorKey: 'key',
      header: 'Property',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'value',
      enableGlobalFilter: true,
      header: 'Value',
    },
  ];

  const properties = convertToPropertyList(endUser.user_properties);

  return (
    <CmdSheet open={!!endUser} onOpenChange={onOpenChange} modal={true}>
      <CmdSheet.Content
        style={{ width: '560px', right: '12px' }}
        onInteractOutside={(event) => event.preventDefault()}
        closeable={false}
        overlay={true}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '12px' }}>
          <CmdSheet.Title style={{ margin: '0' }}>
            <span style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {' '}
              <CmdAvatar size="md" variant="initials">
                {endUser.slug[0].toUpperCase()}
              </CmdAvatar>
              {endUser.slug}
            </span>
          </CmdSheet.Title>
          <CmdSheet.Close asChild>
            <CmdButton variant="ghost" icon={<X />} />
          </CmdSheet.Close>
        </div>
        <div
          style={{
            padding: '0px 16px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <CmdButtonTabs
            className="w-full"
            variant="group"
            activeKey={activeTab}
            onChange={(t) => setActiveTab(t)}
            tabs={[
              {
                label: (
                  <>
                    <ClockRewind /> History
                  </>
                ),
                key: 'history',
              },
              {
                label: (
                  <>
                    <UserSquare /> User properties
                  </>
                ),
                key: 'userProperties',
              },
            ]}
          />

          {activeTab === 'userProperties' ? (
            <PropertiesTable
              searchPlaceholder="Search properties and values"
              showPagination
              isLoading={properties == null}
              data={properties || []}
              columns={propertyColumns}
              getRowId={(row) => row.name}
              searchResultChildren={<CmdTypography.Body>No properties found</CmdTypography.Body>}
            />
          ) : (
            <InteractionHistory endUser={endUser} />
          )}
        </div>
      </CmdSheet.Content>
    </CmdSheet>
  );
};

export default UserDetailDrawer;
