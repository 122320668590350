import { Alert, Form, Input, Select } from '@commandbar/design-system/components/antd';
import { type HelpCenterIntegrationSpec, INTEGRATION_TYPE, type SourceConfigFormProps } from '../shared/types';

import { Eye, EyeOff } from '@commandbar/design-system/icons/react';
import { filterDryRunData } from '../shared/utils';

const Skilljar: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.SKILLJAR,
  name: 'Skilljar',
  slug: 'skilljar',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/skilljar.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/icons/skilljar-icon.png',
  isDisabled: false,
  useDryRun: true,
  description: 'Automatically sync articles with Command AI.',
  guidance: (
    <Alert
      type="info"
      showIcon
      message={"In order to import Skilljar courses and lessons, you'll have to first find your Skilljar API key."}
    />
  ),
  route: '/skilljar',

  getForm({ dryRunData, editingSource }: SourceConfigFormProps) {
    return (
      <>
        <Form.Item
          key={`${editingSource?.type}api_key`}
          validateTrigger="onSubmit"
          initialValue={editingSource?.meta?.api_key}
          label="API Key"
          name="api_key"
          rules={[
            { required: true, message: 'This is a required field.' },
            {
              message: 'Please enter your API Key. ',
            },
          ]}
          style={{ flex: 5 }}
          required
        >
          <Input.Password
            iconRender={(visible) => (visible ? <Eye height={16} /> : <EyeOff height={16} />)}
            style={{ width: '100%' }}
            autoComplete="new-password"
          />
        </Form.Item>

        <Form.Item
          key={`${editingSource?.type}domains`}
          validateTrigger="onSubmit"
          initialValue={dryRunData}
          label="Domains"
          name="domains"
          rules={[{ required: true, message: 'This is a required field.' }]}
          style={{ flex: 5 }}
          required
        >
          <Select
            size="large"
            mode="multiple"
            style={{ width: '100%' }}
            options={dryRunData}
            defaultValue={dryRunData}
            maxTagCount="responsive"
            optionFilterProp="label"
            allowClear
            labelInValue
          />
        </Form.Item>
      </>
    );
  },

  getInitialDryRunData(sourceId) {
    return this.sources?.find((i) => i.id === sourceId)?.meta?.domains;
  },

  getDryRunData(result) {
    return JSON.parse(result.data).domains.map((domain: string) => ({
      label: domain,
      value: domain,
      key: domain,
    }));
  },

  getPayloadMeta(data, dryRunData) {
    const meta = this.requiredFields.reduce((a, v) => ({ ...a, [v]: data[v] }), {});
    const bonusMeta =
      dryRunData && dryRunData.length > 0 ? { domains: filterDryRunData(data.domains, dryRunData) } : {};
    return { ...meta, ...bonusMeta };
  },

  requiredFields: ['api_key'],
  fetchedFields: ['domains'],
};

export default Skilljar;
