import { cva } from 'class-variance-authority';

export const pillStyles = cva(
  [
    'whitespace-nowrap',
    'font-medium',
    'flex',
    'border border-solid',
    'items-center',
    'gap-2',
    'justify-center',
    'relative',
    'select-none',
    'cursor-default',
    'rounded-full',
    'min-w-32',
    'w-fit',
    'h-6',
    'px-sm',
    'py-xs',
    'focus:border-blue700',
    'focus:shadow-input',
    'shadow-[0_0_0_2px_white]',
  ],
  {
    variants: {
      variant: {
        default: [
          'bg-surfaceMid',
          'text-gray700',
          'border-borderPrimary',
          'hover:text-gray1000',
          'hover:bg-surfaceDisabled',
        ],
        info: ['bg-blue100', 'text-blue700', 'border-blue300', 'hover:text-blue1000', 'hover:bg-blue200'],
        success: ['bg-green100', 'text-green700', 'border-green300', 'hover:text-green1000', 'hover:bg-green200'],
        warning: ['bg-orange100', 'text-orange700', 'border-orange300', 'hover:text-orange1000', 'hover:bg-orange200'],
        error: ['bg-red100', 'text-red700', 'border-red300', 'hover:text-red1000', 'hover:bg-orange200'],
      },
      disabled: {
        true: ['cursor-not-allowed'],
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);
