import styled from '@emotion/styled';

import ChatsTable from './ChatsTable';
import Layout from '../../components/Layout';

import { useEffect, useState } from 'react';
import { AnalyticsCopilot, AnalyticsCopilotData } from '@commandbar/internal/middleware/analytics/copilot';
import * as Sentry from '@sentry/react';

import StatCard from '../../components/StatCard';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { useCSVDownload } from '../../useCSVDownload';
import { getCopilotDemoData } from '../../analyticDemoData';
import StackedBarChart from '../spotlight/StackedBarChart';

const StyledCardContainer = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 1148px) {
    width: 100%;
    flex-direction: column;

    * {
      min-width: 0 !important;
    }
  }
`;

const AnalyticsCopilotDashboard = () => {
  const [data, setData] = useState<AnalyticsCopilotData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();

  const { downloadCsv: downloadCsvOverview, isDownloading: isDownloadingOverview } = useCSVDownload({
    name: `copilot-overview`,
    download: () => AnalyticsCopilot.download('overview', timeFilterRange),
  });

  const { downloadCsv: downloadCsvHistory, isDownloading: isDownloadingHistory } = useCSVDownload({
    name: `copilot-history`,
    download: () => AnalyticsCopilot.download('history', timeFilterRange),
  });

  useEffect(() => {
    let ignore = false;

    if (isDemoData) {
      setData(getCopilotDemoData(timeFilterRange));
      setIsLoading(false);
    } else {
      const fetchAnalyticsCopilot = () => {
        setIsLoading(true);

        AnalyticsCopilot.read({ ...timeFilterRange, include_admin_data: includeAdminData.toString() })
          .then((res) => {
            if (!ignore) {
              setData(res);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            console.error(error);
          })
          .finally(() => {
            if (!ignore) {
              setIsLoading(false);
            }
          });
      };

      fetchAnalyticsCopilot();
    }

    return () => {
      ignore = true;
    };
  }, [timeFilterRange, isDemoData, includeAdminData]);

  return (
    <Layout
      title="Copilot"
      disableDemoData={isLoading}
      isDownloading={isDownloadingOverview || isDownloadingHistory}
      onDownloadCSV={[
        {
          label: 'Overview',
          action: downloadCsvOverview,
        },
        {
          label: 'Chat history',
          action: downloadCsvHistory,
        },
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <StyledCardContainer>
          <StatCard
            title="AI answer rate"
            subtitle="Percent of messages Copilot was able to answer without a fallback"
            format="percentage"
            metric={data?.success_rate?.metric}
            rate={data?.success_rate?.percent_change}
            isLoading={isLoading}
            style={{ flex: 1, minWidth: 'unset' }}
          />
          <StatCard
            title="Handoff rate"
            subtitle="Percent of chats escalated to a human agent"
            format="percentage"
            metric={data?.handoff_rate?.metric}
            rate={data?.handoff_rate?.percent_change}
            isLoading={isLoading}
            style={{ flex: 1, minWidth: 'unset' }}
            inverted
          />
          <StatCard
            title="Avg. handle time"
            subtitle="Average time it takes Copilot to resolve a customer query"
            format="time"
            metric={data?.handle_time?.metric}
            rate={data?.handle_time?.percent_change}
            isLoading={isLoading}
            style={{ flex: 1, minWidth: 'unset' }}
            inverted
          />
          <StatCard
            title="Unique users"
            subtitle="Total unique users who have chatted with Copilot"
            format="number"
            metric={data?.unique_users?.metric}
            rate={data?.unique_users?.percent_change}
            isLoading={isLoading}
            style={{ flex: 1, minWidth: 'unset' }}
          />
        </StyledCardContainer>

        <div style={{ display: 'flex', gap: '16px', flexDirection: 'column', width: '100%' }}>
          <StackedBarChart
            data={data?.stacked_bar_chart_data}
            isLoading={isLoading}
            title="Activity"
            subTitle="See how users are interacting with Copilot"
            legendItems={[
              {
                title: 'Sessions',
                percentageChange: data?.total_opens?.percent_change,
                metric: data?.total_opens?.metric,
                isLoading: isLoading,
                lineColor: '#4a70f2',
              },
              {
                title: 'Fallbacks',
                percentageChange: data?.total_fallbacks?.percent_change,
                metric: data?.total_fallbacks?.metric,
                isLoading: isLoading,
                lineColor: '#FDBA74',
                inverted: true,
              },
              {
                title: 'Handoffs',
                percentageChange: data?.total_handoffs?.percent_change,
                metric: data?.total_handoffs?.metric,
                isLoading: isLoading,
                lineColor: '#F87171',
                inverted: true,
              },
            ]}
          />
        </div>

        <ChatsTable timeFilterRange={timeFilterRange} />
      </div>
    </Layout>
  );
};

export default AnalyticsCopilotDashboard;
