import * as React from 'react';
import * as SheetPrimitive from '@radix-ui/react-dialog';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from '../util';
import { ButtonProps, buttonStyles } from '../button';
import { X } from '@commandbar/design-system/icons/react';
import { CmdDivider, typographyStyles } from '../index';
interface CmdSheetCompound extends React.FC<SheetPrimitive.DialogProps> {
  Trigger: React.FC<React.ComponentPropsWithoutRef<typeof SheetPrimitive.Trigger>>;
  Close: typeof SheetPrimitive.Close;
  Cancel: React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SheetPrimitive.Close> & ButtonProps>;
  Portal: typeof SheetPrimitive.Portal;
  Overlay: React.ForwardRefExoticComponent<
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay> & React.RefAttributes<HTMLDivElement>
  >;
  Content: React.ForwardRefExoticComponent<
    VariantProps<typeof sheetVariants> &
      React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content> &
      React.RefAttributes<HTMLDivElement> & { closeable?: boolean; width?: number; overlay?: boolean }
  >;
  Header: React.FC<React.HTMLAttributes<HTMLDivElement>>;
  Footer: React.FC<React.HTMLAttributes<HTMLDivElement> & { divider?: true }>;
  Title: React.ForwardRefExoticComponent<
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title> & React.RefAttributes<HTMLHeadingElement>
  >;
  Body: React.ForwardRefExoticComponent<
    React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description> & React.RefAttributes<HTMLDivElement>
  >;
}

const CmdSheet: CmdSheetCompound = SheetPrimitive.Root as CmdSheetCompound;

CmdSheet.Overlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      'fixed inset-0 z-max bg-black/30  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
    ref={ref}
  />
));
CmdSheet.Overlay.displayName = 'CmdSheetOverlay';

const sheetVariants = cva(
  'fixed z-max gap-4 bg-surfaceBase overflow-hidden shadow-lg rounded-button transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500',
  {
    variants: {
      side: {
        top: 'inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top',
        bottom:
          'inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom',
        left: 'inset-y-0 left-sm border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left',
        right:
          'inset-y-sm right-sm border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right ',
      },
    },
    defaultVariants: {
      side: 'right',
    },
  },
);

CmdSheet.Content = React.forwardRef(
  ({ side = 'right', className, children, closeable = false, width = 560, overlay = false, ...props }, ref) => (
    <CmdSheet.Portal>
      {overlay && <CmdSheet.Overlay />}
      <SheetPrimitive.Content
        {...props}
        ref={ref}
        className={cn(sheetVariants({ side }), className, `w-[${width}px] sm:max-w-[${width}px]`)}
      >
        {closeable && (
          <SheetPrimitive.Close
            className={cn(buttonStyles({ variant: 'ghost' }), 'absolute top-sm focus:shadow-none active:top-[7px]')}
          >
            <X />
          </SheetPrimitive.Close>
        )}
        <div className="overflow-auto h-full w-full">{children}</div>
      </SheetPrimitive.Content>
    </CmdSheet.Portal>
  ),
);

CmdSheet.Content.displayName = 'CmdSheetContent';

CmdSheet.Trigger = ({ children, ...props }) => (
  <SheetPrimitive.Trigger className="cursor-pointer bg-transparent border-0" {...props}>
    {children}
  </SheetPrimitive.Trigger>
);

CmdSheet.Close = SheetPrimitive.Close;
CmdSheet.Portal = SheetPrimitive.Portal;

CmdSheet.Cancel = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Close>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Close> & ButtonProps & { buttonText?: string }
>(
  (
    {
      className,
      buttonText = 'Cancel',
      variant = 'ghost',
      disabled = false,
      size,
      modifier,
      fullWidth,
      loading,
      ...props
    },
    ref,
  ) => (
    <SheetPrimitive.Close
      ref={ref}
      className={cn(buttonStyles({ variant, disabled, size, modifier, fullWidth, loading }), className)}
      {...props}
    >
      {buttonText}
    </SheetPrimitive.Close>
  ),
);
CmdSheet.Cancel.displayName = 'CmdSheetCancel';

CmdSheet.Header = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'sticky top-0 left-0 right-0 w-full flex items-center justify-between bg-surfaceBase z-[1] p-lg',
      className,
    )}
    {...props}
  ></div>
);
CmdSheet.Header.displayName = 'CmdSheetHeader';

CmdSheet.Footer = ({
  className,
  divider,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { divider?: boolean }) => (
  <>
    <div className={cn('absolute bottom-0 left-0 w-full bg-surfaceBase', className)} {...props}>
      {divider && <CmdDivider />}
      <div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 p-4', className)}>
        {children}
      </div>
    </div>
    <div className="h-xxxxxl"></div>
  </>
);

CmdSheet.Footer.displayName = 'CmdSheetFooter';

CmdSheet.Title = React.forwardRef(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    {...props}
    ref={ref}
    className={cn(typographyStyles({ fontWeight: 'semi-bold', fontSize: 'lg' }), className)}
  />
));
CmdSheet.Title.displayName = 'CmdSheetTitle';

CmdSheet.Body = React.forwardRef(({ className, ...props }, ref) => (
  <SheetPrimitive.Description {...props} ref={ref} className={cn('px-lg pb-lg', className)} />
));
CmdSheet.Body.displayName = 'CmdSheetBody';

export { CmdSheet };
