import snarkdown from '@bpmn-io/snarkdown';

export type MarkdownString = string;
export type HtmlString = string;

const NEW_LINE_PATTERN = /(?:\r?\n){2,}/;

// https://github.com/developit/snarkdown/issues/11
export const toHtml = (markdown: MarkdownString): HtmlString =>
  markdown
    .split(NEW_LINE_PATTERN)
    .map((line) =>
      [' ', '\t', '#', '-', '*', '>'].some((char) => line.startsWith(char) || line === '<br>')
        ? snarkdown(line)
        : `<p>${snarkdown(line)}</p>`,
    )
    .join('\n');
