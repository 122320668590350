import { useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { ChatAnalytics } from '@commandbar/internal/middleware/chatAnalytics';
import Logger from '@commandbar/internal/util/Logger';

import { Action as ButtonAction } from '@cb/types/entities/command/actions';
import type { IMessageType, IUserMessageType } from '@commandbar/internal/middleware/types';
import { DateFilter } from '@commandbar/internal/middleware/analytics/common';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import {
  getCopilotChatDemoData,
  getCopilotChatDetailDemoData,
} from 'commandbar.com/src/components/analytics/dashboards/copilot/chatDemoData';
import { Chat } from '@commandbar/internal/middleware/chat';
import dayjs from 'dayjs';
import { cmdToast } from '@commandbar/design-system/cmd';
import * as Command from '@commandbar/internal/middleware/command';
import { isAction } from '@cb/types/entities/command/actions';
import isEqual from 'lodash/isEqual';

export enum FEEDBACK {
  ALL = 'all',
  POSITIVE = '1',
  NEGATIVE = '-1',
  UNRATED = '0',
  BOTH = '2',
}
export enum SORT_METHOD {
  NEWEST = '-created',
  OLDEST = 'created',
  MOST_MESSAGES = 'num_messages',
  FEWEST_MESSAGES = '-num_messages',
}

export enum HIGHLIGHT_TYPE {
  POSITIVE_FEEDBACK = 'Positive feedback',
  NEGATIVE_FEEDBACK = 'Negative feedback',

  NUDGE = 'Nudge',
  PAGE = 'Page',
  ACTION = 'Action',

  CHAT_HANDOFF = 'Chat handoff',
  FALLBACK = 'Fallback',
  ERROR = 'Error',
  WORKFLOW = 'Workflow',
  API = 'API',

  NONE = 'None',
}

export interface ChatTableItem {
  id?: string;
  chat: string;
  highlights: HIGHLIGHT_TYPE[];
  answer: number | null;
  created?: string;
  end_user?: string;
  messages: Array<IMessageType>;
  num_messages: number;
  editor_tags: string[];
}

export const PAGE_SIZE = 25;
export const URL_CHAT_ID_PARAM = 'chatId';
export const URL_PAGE_NUMBER_PARAM = 'page';
export const URL_XRAY_OPEN = 'xray';

type ChatTableState = {
  selectedChat: { id: string; messages: Array<IMessageType>; editor_tags: string[] } | null;
  chatTableData: ChatTableItem[];
  pagination: {
    totalItems: number;
    currentItems: number;
    previousPage: number | null;
    currentPage: number;
    nextPage: number | null;
  };
  chatMessages: Array<{ id: string; messages: Array<IMessageType>; editor_tags: string[] }>;
  isLoading: boolean;
  chatIdFromUrl: string | null;
  hasSetFromUrl: boolean;
  tagFilter: string;
  userFilter: string[];
  highlightFilter: HIGHLIGHT_TYPE[];
  sortMethod: SORT_METHOD;
  searchFilter: string;
  isXrayOpen: boolean;
};

type Action =
  | { type: 'SELECT_CHAT'; payload: string | null }
  | { type: 'SELECT_PREVIOUS_CHAT' }
  | { type: 'SELECT_NEXT_CHAT' }
  | { type: 'SELECT_FIRST_CHAT' }
  | { type: 'SELECT_CHAT_FROM_URL'; payload: string | null }
  | {
      type: 'SET_CHAT_TABLE_DATA';
      payload: {
        data: ChatTableItem[];
        pagination: {
          totalItems: number;
          currentItems: number;
          nextPage: number | null;
          previousPage: number | null;
        };
        chatMessages: Array<{ id: string; messages: Array<IMessageType>; editor_tags: string[] }>;
      };
    }
  | { type: 'SET_CURRENT_PAGE'; payload: number }
  | { type: 'SET_LOADING' }
  | { type: 'SET_READY' }
  | { type: 'SET_CHAT_ID_FROM_URL'; payload: string | null }
  | { type: 'FILTER_BY_TAG'; payload: string }
  | { type: 'FILTER_BY_USER'; payload: string[] }
  | { type: 'SET_SORT_METHOD'; payload: SORT_METHOD }
  | { type: 'FILTER_BY_TERM'; payload: string }
  | { type: 'FILTER_BY_HIGHLIGHT'; payload: HIGHLIGHT_TYPE[] }
  | { type: 'CLEAR_FILTERS' }
  | { type: 'SET_CHAT_TAGS'; payload: { chat_id: string; tags: string[]; callback: () => Promise<void> } }
  | { type: 'SET_XRAY_OPEN'; payload: boolean };

const chatTableReducer = (state: ChatTableState, action: Action): ChatTableState => {
  switch (action.type) {
    case 'SELECT_CHAT':
      if (action.payload === null) {
        return { ...state, selectedChat: null };
      }

      const chat = state.chatMessages.find((chat) => chat.id === action.payload);

      if (chat) {
        return {
          ...state,
          selectedChat: { id: chat.id, messages: chat.messages ?? [], editor_tags: chat.editor_tags },
        };
      }

      return state;
    case 'SET_CHAT_TAGS':
      if (!state.selectedChat) {
        return state;
      }

      Chat.setTags({ chat_id: action.payload.chat_id, tags: action.payload.tags }).then(() => {
        action.payload.callback();
      });

      return {
        ...state,
        chatTableData: state.chatTableData.map((chat) => {
          if (chat.id === action.payload.chat_id) {
            return {
              ...chat,
              editor_tags: action.payload.tags,
            };
          }
          return chat;
        }),
        chatMessages: state.chatMessages.map((chat) => {
          if (chat.id === action.payload.chat_id) {
            return {
              ...chat,
              editor_tags: action.payload.tags,
            };
          }
          return chat;
        }),
        selectedChat: {
          id: state.selectedChat.id,
          messages: state.selectedChat.messages ?? [],
          editor_tags: action.payload.tags,
        },
      };

    case 'SELECT_FIRST_CHAT':
      const firstChat = state.chatMessages?.[0];

      if (firstChat) {
        return {
          ...state,
          selectedChat: {
            id: firstChat.id,
            messages: firstChat.messages,
            editor_tags: firstChat.editor_tags,
          },
        };
      }

      return state;

    case 'SELECT_PREVIOUS_CHAT':
      if (!state.selectedChat) {
        return state;
      }

      const currentIndexPrev = state.chatMessages.findIndex((chat) => chat.id === state.selectedChat?.id);

      if (currentIndexPrev === -1 || currentIndexPrev === 0) {
        return state;
      }

      return {
        ...state,
        selectedChat: state.chatMessages[currentIndexPrev - 1],
      };

    case 'SELECT_NEXT_CHAT':
      if (!state.selectedChat) {
        return state;
      }

      const currentIndexNext = state.chatMessages.findIndex((chat) => chat.id === state.selectedChat?.id);

      if (currentIndexNext === -1 || currentIndexNext === state.chatMessages.length - 1) {
        return state;
      }

      return {
        ...state,
        selectedChat: state.chatMessages[currentIndexNext + 1],
      };

    case 'SELECT_CHAT_FROM_URL':
      const chatFromUrl = state.chatMessages.find((chat) => chat.id === action.payload);

      if (chatFromUrl) {
        return {
          ...state,
          selectedChat: {
            id: chatFromUrl.id,
            messages: chatFromUrl.messages,
            editor_tags: chatFromUrl.editor_tags,
          },
          hasSetFromUrl: true,
        };
      }

      return state;
    case 'SET_CHAT_TABLE_DATA':
      return {
        ...state,
        chatTableData: action.payload.data,
        pagination: { ...state.pagination, ...action.payload.pagination },
        chatMessages: action.payload.chatMessages,
      };
    case 'SET_CURRENT_PAGE':
      return { ...state, pagination: { ...state.pagination, currentPage: action.payload } };
    case 'SET_LOADING':
      return { ...state, isLoading: true };
    case 'SET_READY':
      return { ...state, isLoading: false };
    case 'SET_CHAT_ID_FROM_URL':
      return { ...state, chatIdFromUrl: action.payload, hasSetFromUrl: false };
    case 'SET_XRAY_OPEN':
      return { ...state, isXrayOpen: action.payload };
    case 'FILTER_BY_TAG':
      return {
        ...state,
        tagFilter: action.payload,
        isLoading: true,
        pagination: { ...state.pagination, currentPage: 0 },
      };
    case 'FILTER_BY_USER':
      return {
        ...state,
        userFilter: action.payload,
        isLoading: true,
        pagination: { ...state.pagination, currentPage: 0 },
      };
    case 'SET_SORT_METHOD':
      return {
        ...state,
        sortMethod: action.payload,
        isLoading: true,
        pagination: { ...state.pagination, currentPage: 0 },
      };
    case 'FILTER_BY_TERM':
      return action.payload === state.searchFilter
        ? state
        : {
            ...state,
            searchFilter: action.payload,
            isLoading: true,
            pagination: { ...state.pagination, currentPage: 0 },
          };
    case 'FILTER_BY_HIGHLIGHT':
      return {
        ...state,
        highlightFilter: action.payload,
        isLoading: true,
        pagination: { ...state.pagination, currentPage: 0 },
      };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        isLoading: true,
        tagFilter: initialState.tagFilter,
        userFilter: initialState.userFilter,
        highlightFilter: initialState.highlightFilter,
        sortMethod: initialState.sortMethod,
        searchFilter: initialState.searchFilter,
        pagination: { ...state.pagination, currentPage: 0 },
      };
    default:
      return state;
  }
};

const initialState: ChatTableState = {
  selectedChat: null,
  chatTableData: [],
  pagination: {
    totalItems: 0,
    currentItems: 0,
    previousPage: null,
    currentPage: 0,
    nextPage: null,
  },
  chatMessages: [],
  isLoading: true,
  chatIdFromUrl: null,
  hasSetFromUrl: false,
  tagFilter: 'all',
  userFilter: [],
  highlightFilter: Object.values(HIGHLIGHT_TYPE),
  sortMethod: SORT_METHOD.NEWEST,
  searchFilter: '',
  isXrayOpen: false,
};

type UseChatTableParams = {
  timeFilterRange: DateFilter;
};

export const useChatTable = ({ timeFilterRange }: UseChatTableParams) => {
  const location = useLocation();
  const { isDemoData, includeAdminData } = useAnalyticsContext();
  const [
    {
      selectedChat,
      chatTableData,
      pagination,
      isLoading,
      chatIdFromUrl,
      tagFilter,
      userFilter,
      highlightFilter,
      sortMethod,
      searchFilter,
      hasSetFromUrl,
      isXrayOpen,
    },
    dispatch,
  ] = useReducer(chatTableReducer, initialState);

  const processMessageExtras = async (message: IMessageType) => {
    if (message.message_type !== 'AI' || !message.extras || !Array.isArray(message.extras)) {
      return [];
    }

    const getHighlightType = async (action: ButtonAction | undefined) => {
      if (!action) return null;
      const type = action.type;
      if (!type || type === 'no_action') return null;

      switch (type) {
        case 'execute_command':
          const command = await Command.get(action?.meta?.command);
          if (!command) return null;
          return command?.template?.type === 'link' ? HIGHLIGHT_TYPE.PAGE : HIGHLIGHT_TYPE.ACTION;
        case 'questlist':
        case 'nudge':
          return HIGHLIGHT_TYPE.NUDGE;
        case 'chat_handoff':
        case 'open_chat':
          return HIGHLIGHT_TYPE.CHAT_HANDOFF;
        default:
          return null;
      }
    };

    const textAnswerActions = message.value.source_docs
      .map((doc) => doc?.command.next_steps)
      .flatMap((next_steps) => next_steps)
      .filter((next_step) => isAction(next_step) && next_step.cta);

    const messageExtrasToDisplay = message.extras.filter((extra) => {
      const extraInTextAnswerActions = textAnswerActions.some(
        (answerAction) => isAction(answerAction) && isEqual(answerAction.action, extra.action),
      );
      return !extraInTextAnswerActions;
    });

    const answerCTAPromises = textAnswerActions.map(async (next_step) =>
      typeof next_step === 'object' ? getHighlightType(next_step.action) : null,
    );

    const extraPromises = messageExtrasToDisplay.map(async (extra) => getHighlightType(extra.action));

    const fallbackPromises =
      message.no_answer && message.extras.length === 0
        ? message.fallback_labeled_actions.map(async (fallback) => getHighlightType(fallback.action))
        : [];

    const highlights = await Promise.all(answerCTAPromises.concat(extraPromises).concat(fallbackPromises));
    const filteredHighlights = highlights.filter((highlight) => highlight !== null) as HIGHLIGHT_TYPE[];
    return filteredHighlights;
  };

  const handleFetchChatAnalytics = async () => {
    dispatch({ type: 'SET_LOADING' });

    if (isDemoData) {
      dispatch({
        type: 'SET_CHAT_TABLE_DATA',
        payload: {
          data: getCopilotChatDemoData(),
          pagination: {
            totalItems: 1,
            currentItems: 1,
            nextPage: 0,
            previousPage: 0,
          },
          chatMessages: getCopilotChatDetailDemoData(),
        },
      });
      dispatch({ type: 'SET_READY' });
      return;
    }

    try {
      const params: Record<string, string> = {
        page_size: String(PAGE_SIZE),
        search: searchFilter,
        sort_order: sortMethod,
        start_date: timeFilterRange.start_date,
        ...(timeFilterRange.end_date !== dayjs().format('YYYY-MM-DD') && { end_date: timeFilterRange.end_date }),
        include_admin_chats: includeAdminData.toString(),
      };

      if (tagFilter !== 'all') {
        params['tag'] = tagFilter;
      }

      if (userFilter.length > 0) {
        params['end_users'] = userFilter.join(',');
      }

      if (highlightFilter.length !== Object.values(HIGHLIGHT_TYPE).length) {
        params['highlights'] = highlightFilter.join(',');
      }

      const shouldFetchFromUrl = !hasSetFromUrl && chatIdFromUrl;

      if (shouldFetchFromUrl) {
        params['chat_id'] = chatIdFromUrl;
      } else if (pagination.currentPage) {
        params['page'] = String(pagination.currentPage + 1);
      } else {
        params['page'] = '1';
      }

      const response = await ChatAnalytics.listChats({ ...params });

      const getFeedbackType = (feedback: number): FEEDBACK => {
        switch (feedback) {
          case -1:
            return FEEDBACK.NEGATIVE;
          case 1:
            return FEEDBACK.POSITIVE;
          case 0:
          default:
            return FEEDBACK.UNRATED;
        }
      };

      const processChatResult = async ({
        messages,
        id,
        answer,
        created,
        end_user,
        editor_tags,
      }: {
        messages: Array<IMessageType>;
        id: string;
        answer: number | null;
        created: string;
        end_user?: string | number | null;
        editor_tags: string[];
      }) => {
        const processedMessages = await messages?.reduce<
          Promise<{
            firstUserMessage: IUserMessageType | undefined;
            highlights: HIGHLIGHT_TYPE[];
          }>
        >(
          async (accPromise, message) => {
            const acc = await accPromise;

            if (message.message_type === 'USER' && acc.firstUserMessage === undefined) {
              acc.firstUserMessage = message;
            }

            if (message.message_type === 'AI') {
              const experiencesEmptyOrUndefined = (message.value?.experiences?.length ?? 0) === 0;
              const extrasEmptyOrUndefined = (message.extras?.length ?? 0) === 0;
              if (message.no_answer && message.error === '' && experiencesEmptyOrUndefined && extrasEmptyOrUndefined) {
                acc.highlights.push(HIGHLIGHT_TYPE.FALLBACK);
              }

              if (message.error !== '') {
                acc.highlights.push(HIGHLIGHT_TYPE.ERROR);
              }

              const processedHighlights = await processMessageExtras(message);
              acc.highlights.push(...processedHighlights);

              const messageFeedback = getFeedbackType(message.feedback?.rating ?? 0);

              if (messageFeedback === FEEDBACK.POSITIVE) {
                acc.highlights.push(HIGHLIGHT_TYPE.POSITIVE_FEEDBACK);
              } else if (messageFeedback === FEEDBACK.NEGATIVE) {
                acc.highlights.push(HIGHLIGHT_TYPE.NEGATIVE_FEEDBACK);
              }

              if (message.debug_info?.used_workflows?.length) {
                acc.highlights.push(HIGHLIGHT_TYPE.WORKFLOW);
              }

              if (message.debug_info?.used_apis?.length) {
                acc.highlights.push(HIGHLIGHT_TYPE.API);
              }
            }

            return acc;
          },
          Promise.resolve({
            firstUserMessage: undefined,
            highlights: [],
          }),
        );

        const { firstUserMessage, highlights } = processedMessages ?? {};

        return {
          id,
          answer,
          key: id,
          chat: firstUserMessage?.value.question ?? '',
          highlights,
          created,
          end_user: end_user === null ? undefined : end_user?.toString(),
          messages,
          num_messages: messages.length,
          editor_tags: editor_tags,
        };
      };

      const chatTableItems: ChatTableItem[] = await Promise.all(response.results.map(processChatResult));

      dispatch({
        type: 'SET_CHAT_TABLE_DATA',
        payload: {
          data: chatTableItems,
          pagination: {
            totalItems: response.total_count,
            currentItems: response.current_count,
            nextPage: response.next,
            previousPage: response.previous,
          },
          chatMessages: response.results.map(({ id, messages, editor_tags }) => ({
            id,
            messages,
            editor_tags,
          })),
        },
      });

      if (shouldFetchFromUrl) {
        if (response.chat_id_found) {
          dispatch({ type: 'SELECT_CHAT_FROM_URL', payload: chatIdFromUrl });
        } else {
          cmdToast.error('Chat ID not found.');
          dispatch({ type: 'SELECT_FIRST_CHAT' });
        }
      }
    } catch (error) {
      Logger.error('Failed to fetch chat analytics', error);
      cmdToast.error('Failed to fetch chat analytics.');
    } finally {
      dispatch({ type: 'SET_READY' });
    }
  };

  const history = useHistory();

  const closeChat = () => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete(URL_CHAT_ID_PARAM);
    history.replace(`${history.location.pathname}?${searchParams.toString()}`);

    dispatch({
      type: 'SELECT_CHAT',
      payload: null,
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newChatIdFromUrl = searchParams.get(URL_CHAT_ID_PARAM);
    const newPageNumberFromURL = searchParams.get(URL_PAGE_NUMBER_PARAM);
    const isXrayOpen = searchParams.get(URL_XRAY_OPEN) === 'true';

    if (pagination.currentPage.toString() !== newPageNumberFromURL) {
      searchParams.set(URL_PAGE_NUMBER_PARAM, pagination.currentPage.toString());
      history.replace(`${history.location.pathname}?${searchParams.toString()}`);
    }

    if (isXrayOpen) {
      dispatch({ type: 'SET_XRAY_OPEN', payload: true });
    }

    // do nothing if there is no url param and no selected chat
    if (!newChatIdFromUrl && !selectedChat) {
      return;
    }

    // if there is a chatId in the URL, and it is different from the selected chat, we need to make changes so that these match
    if (newChatIdFromUrl !== selectedChat?.id) {
      if (selectedChat) {
        // if there is no chatId in the URL, and there is a selected chat set the url param
        searchParams.set(URL_CHAT_ID_PARAM, selectedChat.id);
        history.replace(`${history.location.pathname}?${searchParams.toString()}`);
      } else if (newChatIdFromUrl !== chatIdFromUrl) {
        // if there is no selected chat but a changed URL param, it means the user opened this URl directly, so we need to set the selected chat
        dispatch({ type: 'SET_CHAT_ID_FROM_URL', payload: newChatIdFromUrl });
        // we also want to set the page number properly for the chat we opened
        if (newPageNumberFromURL) {
          dispatch({ type: 'SET_CURRENT_PAGE', payload: parseInt(newPageNumberFromURL) });
        }
      }
    }
  }, [location.search, chatIdFromUrl, selectedChat, pagination.currentPage]);

  useEffect(() => {
    const debouncedFetch = debounce(handleFetchChatAnalytics, 250);
    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [
    timeFilterRange,
    pagination.currentPage,
    searchFilter,
    sortMethod,
    tagFilter,
    userFilter,
    highlightFilter,
    chatIdFromUrl,
    isDemoData,
    includeAdminData,
  ]);

  return [
    {
      selectedChat,
      chatTableData,
      pagination,
      isLoading,
      tagFilter,
      userFilter,
      highlightFilter,
      sortMethod,
      searchFilter,
      handleFetchChatAnalytics,
      closeChat,
      isXrayOpen,
    },
    dispatch,
  ] as const;
};
