/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';
import { css, jsx } from '@emotion/core';
import { IOrganizationType } from '@commandbar/internal/middleware/types';

// default styles that are editable in the the theme editor
export const styles = css({
  display: 'flex',
  width: 'var(--popover-width)',
  flexDirection: 'column',
  alignItems: 'flex-start',
  pointerEvents: 'all',
  position: 'relative',

  borderRadius: 'var(--layout-radius-card)',
  border: '1px solid var(--border-primary)',
  background: 'var(--background-primary)',

  boxShadow: 'var(--layout-shadow) var(--layout-shadow-color)',

  a: {
    color: 'var(--content-link)',
    textDecoration: 'none',

    '&:hover': {
      color: 'var(--content-link-hover)',
      textDecoration: 'underline',
    },
  },
});

const surveyStyles = css({
  minWidth: 'var(--popover-width)',
  width: 'unset',
});

// extra styles that an org cannot edit
const Popover = styled.div`
  z-index: ${Z.Z_NUDGE};
`;

type Props = {
  survey: boolean;
  organization: IOrganizationType | undefined;
};

const StyledNudgePopover = React.forwardRef<HTMLDivElement, Props & React.HTMLAttributes<HTMLDivElement>>(
  ({ survey, organization, children, ...props }, ref) => {
    return (
      <Popover ref={ref} css={[styles, survey && surveyStyles].filter(Boolean)} {...props}>
        {children}
      </Popover>
    );
  },
);

export default StyledNudgePopover;
