import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import StyledTooltip from '@commandbar/internal/client/themesV2/components/shared/StyledTooltip';
import StyledKeyword from '@commandbar/internal/client/themesV2/components/keyword/StyledKeyword';
import Z from '@commandbar/internal/client/Z';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import StyledNudgeTitleBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeTitleBlock';

export const KeywordTooltip = (props: {
  children: React.ReactNode;
  content: string | null;
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
}) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number; valid: boolean }>({
    top: 0,
    left: 0,
    valid: false,
  });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLSpanElement>(null);

  const popupContainer = React.useMemo(() => {
    return document.getElementById('commandbar-themeV2-root') ?? null;
  }, []);

  useEffect(() => {
    if (visible && targetRef.current && tooltipRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: targetRect.top + window.scrollY - tooltipRect.height - 4,
        left: targetRect.left + window.scrollX + targetRect.width / 2 - tooltipRect.width / 2,
        valid: true,
      });
    }
  }, [visible]);

  if (!popupContainer || !props.content) {
    return <>{props.children}</>;
  }

  return (
    <React.Fragment>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <StyledKeyword ref={targetRef} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
        {props.children}
      </StyledKeyword>
      {visible &&
        targetRef.current &&
        ReactDOM.createPortal(
          <StyledTooltip
            ref={tooltipRef}
            style={{
              position: 'absolute',
              top: tooltipPosition.top,
              left: tooltipPosition.left,
              zIndex: Z.Z_TOOLTIPS,
              visibility: tooltipPosition.valid ? 'visible' : 'hidden',
            }}
            themeV2={props.themeV2}
            variant={'keyword'}
            className="commandbar-keyword-tooltip"
          >
            <StyledNudgeTitleBlock content={props.content} dismissible />
          </StyledTooltip>,
          popupContainer,
        )}
    </React.Fragment>
  );
};
