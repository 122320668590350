import debounce from 'lodash/debounce';

import { hasRageTrigger, hasUserConfusionTrigger, hasSmartDelayTrigger, getDebuggedNudge } from './selectors';

import type { CBStore } from 'shared/store/global-store';
import type { INudgeType } from '@commandbar/internal/middleware/types';
import { sendIndirectTrigger, sendDirectedTrigger } from './actions';
import { TriggerEvent } from './nudgeManagerMachine';

/* Smart triggers */
export const triggerSmartNudge = (
  _: CBStore,
  trigger: INudgeType['trigger'],
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
) => {
  const debuggedNudge = getDebuggedNudge(_);
  // If a nudge is being simulated, only trigger that nudge
  if (debuggedNudge) {
    sendDirectedTrigger(_, debuggedNudge, trigger, overrides, properties);
  } else if (!_.activeChecklist) {
    sendIndirectTrigger(_, trigger, overrides, properties);
  }
};

export const setupAndTriggerSmartTriggers = (
  _: CBStore,
  nudges: Array<INudgeType>,
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
) => {
  if (hasRageTrigger(_, nudges)) {
    setupRageClickTrigger(_, overrides, properties);
  }
  if (hasUserConfusionTrigger(_, nudges)) {
    setupUserConfusionTrigger(_, overrides, properties);
  }
  if (hasSmartDelayTrigger(_, nudges)) {
    setupSmartDelayTrigger(_, overrides, properties);
  }
};

const setupUserConfusionTrigger = (
  _: CBStore,
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
): ((event: MouseEvent) => void) => {
  const requiredStrokes = 15;
  const velocityMin = 5;
  const maxIntervalBetweenStrokesMs = 100;
  const resetTimeMs = 500;
  let strokeCount = 0;
  let lastX = -1;
  let lastY = -1;
  let lastTimestamp = -1;
  let resetTimeout: ReturnType<typeof setTimeout>;

  removeUserConfusionTrigger(_);

  const listenForUserConfusion = (event: MouseEvent) => {
    const { clientX, clientY, timeStamp } = event;
    if (lastX !== -1 && lastY !== -1 && lastTimestamp !== -1) {
      const dx = Math.abs(clientX - lastX);
      const dy = Math.abs(clientY - lastY);
      const distance = Math.sqrt(dx ** 2 + dy ** 2);
      const velocity = distance / (timeStamp - lastTimestamp);

      if (velocity > velocityMin && timeStamp - lastTimestamp <= maxIntervalBetweenStrokesMs) {
        strokeCount += 1;

        clearTimeout(resetTimeout);
        resetTimeout = setTimeout(() => (strokeCount = 0), resetTimeMs);
      }

      if (strokeCount >= requiredStrokes) {
        triggerSmartNudge(_, { type: 'on_user_confusion' }, overrides, properties);
        strokeCount = 0;
        clearTimeout(resetTimeout);
      }
    }

    lastX = clientX;
    lastY = clientY;
    lastTimestamp = timeStamp;
  };

  document.body.addEventListener('mousemove', listenForUserConfusion);
  _.nudgeEventListeners.on_user_confusion = listenForUserConfusion;

  return listenForUserConfusion;
};

const setupSmartDelayTrigger = (
  _: CBStore,
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
): (() => void) => {
  const inactiveLimitMs = 5000;
  const noActivityTriggerWindowMs = 60000;
  let pastNoTriggerWindow = false;
  removeSmartDelayTrigger(_);

  const handleUserInactive = () => {
    if (!!pastNoTriggerWindow) {
      triggerSmartNudge(_, { type: 'smart_delay' }, overrides, properties);
    }
  };

  setTimeout(() => (pastNoTriggerWindow = true), noActivityTriggerWindowMs);
  const debouncedHandler = debounce(handleUserInactive, inactiveLimitMs);

  document.body.addEventListener('mousemove', debouncedHandler);
  document.body.addEventListener('keydown', debouncedHandler);

  _.nudgeEventListeners.smart_delay = debouncedHandler;

  return debouncedHandler;
};

const setupRageClickTrigger = (
  _: CBStore,
  overrides?: TriggerEvent['overrides'],
  properties?: TriggerEvent['properties'],
) => {
  const rageClicks = 4;
  const maxIntervalBetweenClicksMs = 500;
  const resetTimeMs = 500;
  let resetTimeout: ReturnType<typeof setTimeout>;

  let clickCount = 0;
  let lastClickTime = 0;

  removeRageTrigger(_);

  const listener = () => {
    const currentTime = Date.now();

    if (currentTime - lastClickTime <= maxIntervalBetweenClicksMs) {
      clickCount += 1;
      clearTimeout(resetTimeout);
      resetTimeout = setTimeout(() => (clickCount = 0), resetTimeMs);
    }
    lastClickTime = currentTime;
    if (clickCount >= rageClicks) {
      triggerSmartNudge(_, { type: 'on_rage_click' }, overrides, properties);
      clickCount = 0;
      clearTimeout(resetTimeout);
    }
  };

  document.body.addEventListener('click', listener);

  _.nudgeEventListeners.on_rage_click = listener;

  return listener;
};

/* Remove smart trigger helpers */
export const removeRageTrigger = (_: CBStore) => {
  const listener = _.nudgeEventListeners.on_rage_click;
  if (listener) document.body.removeEventListener('click', listener);
};

export const removeUserConfusionTrigger = (_: CBStore) => {
  const listener = _.nudgeEventListeners.on_user_confusion;
  if (listener) document.body.removeEventListener('mousemove', listener);
};

export const removeSmartDelayTrigger = (_: CBStore) => {
  const listener = _.nudgeEventListeners.smart_delay;
  if (listener) {
    document.body.removeEventListener('click', listener);
    document.body.removeEventListener('keydown', listener);
  }
};

export const removeSmartListeners = (_: CBStore) => {
  removeRageTrigger(_);
  removeSmartDelayTrigger(_);
  removeUserConfusionTrigger(_);
};
