import styled from '@emotion/styled';

const StyledInputButtons = styled.div`
  position: absolute;
  right: calc(var(--form-control-padding-x) + var(--layout-padding));
  bottom: calc(min(var(--form-control-height) / 2, var(--form-control-padding-x)) + var(--layout-padding));
  display: flex;
  align-items: flex-start;
`;

export default StyledInputButtons;
