import * as t from 'io-ts';

export type DateFilter = {
  start_date: string;
  end_date: string;
};

export type PaginationFilter = {
  page: string;
  page_size: string;
};

export type AdminFilter = {
  include_admin_data: string;
};

export type SortParams = {
  sort_order?: 'asc' | 'desc';
  sort_column?: string;
};

// ------------ Card -------------

export const CardDataV = t.type({
  type: t.literal('card'),
  metric: t.number,
  percent_change: t.union([t.number, t.null, t.undefined]),
});

export type CardData = t.TypeOf<typeof CardDataV>;

// ------------ Table -------------

const TableRowDataV = t.record(t.string, t.union([t.string, t.number, t.boolean, t.null, t.undefined]));

export type TableRowData = t.TypeOf<typeof TableRowDataV>;

const SortedType = t.partial({
  columnId: t.string,
  direction: t.union([t.literal('asc'), t.literal('desc')]),
});

const TableDataMetaV = t.type({
  totalRows: t.number,

  sorted: t.union([SortedType, t.undefined]),

  pageSize: t.union([t.number, t.undefined]),
  currentPage: t.number,
  totalPages: t.number,
});

export const TableDataV = t.type({
  type: t.literal('table'),
  columnIds: t.array(t.string),
  rows: t.array(TableRowDataV),
  meta: t.union([TableDataMetaV, t.undefined]),
});

export type TableData = t.TypeOf<typeof TableDataV>;

// ------------ Funnel -------------

export const FunnelDataV = t.type({
  type: t.literal('funnel'),
  steps: t.array(
    t.type({
      label: t.string,
      count: t.number,
    }),
  ),
});

export type FunnelData = t.TypeOf<typeof FunnelDataV>;

// ------------ Chart -------------

export const BarChartDataV = t.type({
  type: t.literal('chart'),
  chartType: t.literal('bar'),
  data: t.record(t.string, t.number),
});

export type BarChartData = t.TypeOf<typeof BarChartDataV>;

const StackedBarChartRowV = t.type({
  date: t.string,
  data: t.record(t.string, t.number),
});

export type StackedBarChartRow = t.TypeOf<typeof StackedBarChartRowV>;

export const StackedBarChartDataV = t.type({
  type: t.literal('chart'),
  chartType: t.literal('stacked_bar'),
  data: t.array(StackedBarChartRowV),
});

export type StackedBarChartData = t.TypeOf<typeof StackedBarChartDataV>;
