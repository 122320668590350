import { CmdSheet, CmdButton, CmdTooltip } from '@commandbar/design-system/cmd';
import React, { useState, useEffect } from 'react';
import { isEqual } from 'lodash';

import { IAvailabilityRule, INamedRule, defaults } from '@commandbar/internal/middleware/helpers/rules';
import { useAppContext } from 'editor/src/AppStateContext';
import { defaultConditions, defaultConditionType } from 'editor/src/editor/conditions/categories';
import { useReportEvent } from 'commandbar.com/src/hooks/useEventReporting';
import Rule from 'editor/src/editor/audiences/Rule';
import { Trash04, X } from '@commandbar/design-system/icons/react';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { isDuplicateName, useRuleValidationError } from './validation';

const RuleDetailDrawer = ({
  editingRule,
  setIsOpen,
  deleteRule,
}: {
  editingRule?: INamedRule;
  setIsOpen: (value: boolean) => void;
  deleteRule: (rule: INamedRule) => void;
}) => {
  const {
    rules,
    dispatch: {
      rules: { addRule, changeRule },
    },
  } = useAppContext();
  const { user } = useAuth();

  const { reportEvent } = useReportEvent();

  const [localRule, setLocalRule] = useState<INamedRule>(
    editingRule || {
      ...defaults,
      is_audience: true,
      id: -1,
      name: '',
      created: undefined,
      expression: {
        type: 'AND',
        exprs: [{ type: 'CONDITION', condition: defaultConditions[defaultConditionType] as IAvailabilityRule }],
      },
    },
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const handleSave = async (rule: INamedRule) => {
    setIsLoading(true);
    try {
      if (editingRule) {
        await changeRule(rule.id)(rule).then(() => {
          setIsOpen(false);
        });
        reportEvent('audience updated', {
          segment: true,
          highlight: true,
          slack: true,
          eventProps: {
            name: rule.name,
          },
        });
      } else {
        await addRule(rule, false).then(() => {
          setIsOpen(false);
        });
        reportEvent('audience created', {
          segment: true,
          highlight: true,
          slack: true,
          eventProps: {
            name: rule.name,
          },
        });
      }
    } catch (error) {
      console.error('Error saving rule:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const hasEditPermissions = hasRequiredRole(user, 'contributor');
  const ruleErrors = useRuleValidationError(localRule);

  const hasChanges = (local: any, edit: any): boolean => {
    return !isEqual(local, edit);
  };

  useEffect(() => {
    setIsDirty(hasChanges(localRule, editingRule));
  }, [localRule, editingRule]);

  return (
    <>
      <CmdSheet.Header>
        <CmdSheet.Title>{editingRule ? 'Edit Audience' : 'Create Audience'} </CmdSheet.Title>
        <CmdSheet.Close asChild>
          <CmdButton variant="ghost" icon={<X />} />
        </CmdSheet.Close>
      </CmdSheet.Header>
      <CmdSheet.Body>
        <Rule
          initialEditing
          isDuplicateName={(rule: INamedRule) => isDuplicateName(rules, rule)}
          rule={localRule}
          onSave={handleSave}
          onRuleChange={(newRule: INamedRule) => {
            setLocalRule(newRule);
          }}
        />
      </CmdSheet.Body>

      <CmdSheet.Footer divider={true}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          {!!editingRule && hasEditPermissions && (
            <CmdButton variant="ghost" icon={<Trash04 />} onClick={() => deleteRule(editingRule)} />
          )}

          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '8px' }}>
            <CmdSheet.Cancel />
            <CmdTooltip
              showIf={!!ruleErrors.length}
              message={
                <ul
                  style={{
                    listStyleType: 'none',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {ruleErrors.map((error, index) => (
                    <li key={`audienceerror-${index}`}>{error.message}</li>
                  ))}
                </ul>
              }
            >
              <CmdButton
                variant="primary"
                disabled={ruleErrors.length > 0 || !hasEditPermissions || isLoading || !isDirty}
                onClick={() => localRule && handleSave(localRule)}
              >
                Save changes
              </CmdButton>
            </CmdTooltip>
          </div>
        </div>
      </CmdSheet.Footer>
    </>
  );
};

export default RuleDetailDrawer;
