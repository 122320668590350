import { useCallback, useEffect, useState } from 'react';
import { IntegrationSpec, Source } from './integrations/shared/types';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import { HelpDocsIntegration } from '@commandbar/internal/middleware/helpDocsIntegration';
import helpSyncIntegrationList from './integrations/help-center/active-help-center-integrations';
import IntegrationsContext from './util/IntegrationContext';
import { PROCESSING_OPERATION } from './content/sources/SourceProcessingProgress';
import { useAppContext } from 'editor/src/AppStateContext';

const IntegrationsContextProvider = ({
  organization,
  children,
}: {
  organization: IOrganizationType | undefined;
  children: React.ReactNode;
}) => {
  const { flags } = useAppContext();
  const [helpDocIntegrations, setHelpDocIntegrations] = useState<IntegrationSpec[]>([]);
  const [loadingSources, setLoadingSources] = useState<Map<Source['id'], PROCESSING_OPERATION>>(new Map());

  /* Grab the list of integrations for the current org */
  const refreshHelpDocIntegrations = useCallback(async () => {
    return HelpDocsIntegration.list().then((raw) => {
      let merged = helpSyncIntegrationList.map((integration) => {
        integration.sources = raw.filter((i) => i.type === integration.slug);
        return integration;
      });

      // TODO(pjhul): Remove this flag once we are ready to release the Zoho Work Drive integration
      if (!flags?.['release-zoho-work-drive-integration']) {
        merged = merged.filter((integration) => integration.slug !== 'zoho');
      }

      // TODO(wes): Remove this flag once we are ready to release the Skilljar integration
      if (!flags?.['release-skilljar-integration']) {
        merged = merged.filter((integration) => integration.slug !== 'skilljar');
      }

      setHelpDocIntegrations(merged);
      return merged;
    });
  }, [organization?.id]);

  useEffect(() => {
    if (!organization?.id) {
      return;
    }
    refreshHelpDocIntegrations();
  }, [organization?.id]);

  const contextValue = {
    organizationId: organization?.id,
    helpDocIntegrations: helpDocIntegrations,
    refreshHelpDocIntegrations: refreshHelpDocIntegrations,
    hasHelpDocIntegrations: helpDocIntegrations.some((integration) => integration.sources),
    loadingSources,
    addLoadingSource: (source: Source['id'], operation?: PROCESSING_OPERATION) => {
      setLoadingSources((loadingSources) => {
        const newSources = new Map(loadingSources);
        newSources.set(source, operation || PROCESSING_OPERATION.SYNCING);
        return newSources;
      });
    },
    removeLoadingSource: (source: Source['id']) => {
      setLoadingSources((loadingSources) => {
        const newSources = new Map(loadingSources);
        newSources.delete(source);
        return newSources;
      });
    },
  };

  return <IntegrationsContext.Provider value={contextValue}>{children}</IntegrationsContext.Provider>;
};

export default IntegrationsContextProvider;
