import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CmdButton, cmdToast } from '@commandbar/design-system/cmd';
import { CheckCircleBroken, PlusCircle, Settings02 } from '@commandbar/design-system/icons/react';
import * as Organization from '@commandbar/internal/middleware/organization';
import type { IInternalSettingsType } from '@commandbar/internal/middleware/types';
import Logger from '@commandbar/internal/util/Logger';
import { useReportEvent } from 'commandbar.com/src/hooks/useEventReporting';
import {
  Card,
  Content,
  Header,
  IntegrationActionButtons,
  IntegrationDescription,
  IntegrationTitle,
  Tag,
} from '../../styles';
import { useIntegrationsContext } from '../../util/IntegrationContext';
import { useAppContext } from 'editor/src/AppStateContext';

export const THIN_ANALYTICS_INTEGRATIONS = [
  {
    slug: 'amplitude',
    name: 'Amplitude',
    description: 'Configure your Amplitude integration',
    imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/amplitude.svg',
    snippet: 'amplitude.track()',
  },
  {
    slug: 'logrocket',
    name: 'LogRocket',
    description: 'Send Command AI events to LogRocket',
    imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/logrocket.svg',
    snippet: 'LogRocket.track()',
  },
  {
    slug: 'mixpanel',
    name: 'Mixpanel',
    description: 'Send Command AI events to Mixpanel',
    imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/mixpanel.svg',
    snippet: 'mixpanel.track()',
  },
  {
    slug: 'posthog',
    name: 'PostHog',
    description: 'Send Command AI events to PostHog',
    imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/posthog.svg',
    snippet: 'posthog.capture()',
  },
  {
    slug: 'rudderstack',
    name: 'RudderStack',
    description: 'Send Command AI events to RudderStack',
    imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/rudderstack.svg',
    snippet: 'rudderanalytics.track()',
  },
] as const;

export type ThinAnalyticsIntegrationConfig = (typeof THIN_ANALYTICS_INTEGRATIONS)[number];

interface IntegrationHeaderProps {
  isEnabled: boolean;
  slug: ThinAnalyticsIntegrationConfig['slug'];
  imgURL: ThinAnalyticsIntegrationConfig['imgURL'];
}

const IntegrationHeader = ({ isEnabled, slug, imgURL }: IntegrationHeaderProps) => (
  <Header active={isEnabled}>
    <img className={`integration-logo ${slug.toLowerCase()}`} alt="integration-logo" src={imgURL} />
    {isEnabled && (
      <Tag>
        <CheckCircleBroken color="#176832" height="16px" />
      </Tag>
    )}
  </Header>
);

interface IntegrationContentProps {
  name: ThinAnalyticsIntegrationConfig['name'];
  description: ThinAnalyticsIntegrationConfig['description'];
}

const IntegrationContent = ({ name, description }: IntegrationContentProps) => (
  <Content>
    <IntegrationTitle>{name}</IntegrationTitle>
    <IntegrationDescription>{description}</IntegrationDescription>
  </Content>
);

interface IntegrationCardProps {
  integration: ThinAnalyticsIntegrationConfig;
  toggleIntegration: (
    operation: 'enable' | 'disable',
    slug: ThinAnalyticsIntegrationConfig['slug'],
    name: ThinAnalyticsIntegrationConfig['name'],
  ) => void;
  isEnabled: boolean;
}

const IntegrationCard = ({ integration, toggleIntegration, isEnabled }: IntegrationCardProps) => {
  const history = useHistory();
  const { reportEvent } = useReportEvent();

  const { slug, name, description, imgURL } = integration;

  const reportIntegrationEvent = useCallback(
    (action: 'enable' | 'disable') => {
      reportEvent(`${slug} integration interacted with`, {
        segment: true,
        highlight: true,
        slack: true,
        payloadMessage: `${action === 'disable' ? 'Disabled' : 'Enabled'} Integration`,
      });
    },
    [reportEvent, slug],
  );

  const handleButtonClick = useCallback(() => {
    if (!isEnabled) {
      toggleIntegration('enable', slug, name);
      reportIntegrationEvent('enable');
    }

    history.push(`/integrations/${slug}`);
  }, [history, isEnabled, name, reportIntegrationEvent, slug, toggleIntegration]);

  return (
    <Card>
      <IntegrationHeader isEnabled={isEnabled} slug={slug} imgURL={imgURL} />
      <IntegrationContent name={name} description={description} />

      <IntegrationActionButtons>
        <CmdButton
          size="lg"
          data-testid={`${isEnabled ? 'enabled' : 'disabled'}-${slug}`}
          onClick={handleButtonClick}
          fullWidth
        >
          {isEnabled ? (
            <>
              <Settings02 /> Configure
            </>
          ) : (
            <>
              <PlusCircle /> Enable
            </>
          )}
        </CmdButton>
      </IntegrationActionButtons>
    </Card>
  );
};

export const ThinAnalyticsIntegrationSection = ({ searchTerm }: { searchTerm: string }) => {
  const { organizationId } = useIntegrationsContext();
  const [savedIntegrations, setSavedIntegrations] = useState<IInternalSettingsType['integrations']>();
  const { flags } = useAppContext();

  const getUpdatedIntegrations = useCallback(
    (operation: 'enable' | 'disable', integrationSlug: ThinAnalyticsIntegrationConfig['slug']) => {
      const clonedIntegrations = { ...savedIntegrations };

      if (operation === 'enable') {
        clonedIntegrations[integrationSlug] = { enabled: true };
      } else if (operation === 'disable' && clonedIntegrations?.[integrationSlug]) {
        delete clonedIntegrations[integrationSlug];
      }

      return clonedIntegrations;
    },
    [savedIntegrations],
  );

  const refreshHelpDocIntegrations = useCallback(async () => {
    if (organizationId) {
      try {
        const { integrations } = await Organization.readInternal(organizationId.toString());
        setSavedIntegrations(integrations);
      } catch (err) {
        Logger.error(err);
        cmdToast.error('Failed to fetch integrations.');
      }
    }
  }, [organizationId]);

  useEffect(() => {
    refreshHelpDocIntegrations();
  }, [refreshHelpDocIntegrations]);

  const toggleIntegration = useCallback(
    async (
      operation: 'enable' | 'disable',
      integrationSlug: ThinAnalyticsIntegrationConfig['slug'],
      integrationName: ThinAnalyticsIntegrationConfig['name'],
    ) => {
      if (organizationId) {
        try {
          await Organization.updateInternal({
            id: organizationId.toString(),
            integrations: {
              ...getUpdatedIntegrations(operation, integrationSlug),
            },
          });
          cmdToast.success(`Successfully ${operation}d ${integrationName}`);
          refreshHelpDocIntegrations();
        } catch (err) {
          Logger.error(err);
          cmdToast.error('Something went wrong');
        }
      }
    },
    [getUpdatedIntegrations, organizationId, refreshHelpDocIntegrations],
  );

  return (
    <>
      {THIN_ANALYTICS_INTEGRATIONS.filter((integration) =>
        integration.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
        .filter(({ name }) => (flags['amplitude-audience-integration'] ? name !== 'Amplitude' : true))
        .map((integration) => (
          <IntegrationCard
            key={integration.slug}
            integration={integration}
            toggleIntegration={toggleIntegration}
            isEnabled={savedIntegrations?.[integration.slug]?.enabled}
          />
        ))}
    </>
  );
};
