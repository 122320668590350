import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';

import {
  AnalyticsAnnouncements,
  type AnalyticsAnnouncementsDetailData,
} from '@commandbar/internal/middleware/analytics/announcements';
import { useAnalyticsContext } from '../../AnalyticsContext';
import { getAnnouncementsDetailsDemoData } from '../../analyticDemoData';
import useSurveyDetails from '../surveys/useSurveyDetails';
import { useCSVDownload } from '../../useCSVDownload';

interface UseAnalyticsAnnouncementsDetailsProps {
  id: string;
  goalEventName?: string;
}

export const useAnalyticsAnnouncementsDetails = ({ id, goalEventName }: UseAnalyticsAnnouncementsDetailsProps) => {
  const [data, setData] = useState<AnalyticsAnnouncementsDetailData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();

  const { surveyDetails, fetchData: fetchSurveyDetailsWithResponses } = useSurveyDetails({
    nudgeId: id,
    listFilter: 'most_recent',
    timeFilterRange,
  });

  const { downloadCsv, isDownloading } = useCSVDownload({
    name: 'announcements',
    download: () =>
      AnalyticsAnnouncements.downloadDetail(id, {
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        show_unpublished: 'false',
        include_admin_data: includeAdminData.toString(),
      }),
  });

  const fetchData = useCallback(() => {
    setError(false);
    setIsLoading(true);
    AnalyticsAnnouncements.readDetail(id, {
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      include_admin_data: includeAdminData.toString(),
      goal_event_name: goalEventName,
    })
      .then(setData)
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    fetchSurveyDetailsWithResponses();
  }, [fetchSurveyDetailsWithResponses, goalEventName, id, includeAdminData, timeFilterRange]);

  useEffect(() => {
    if (isDemoData) {
      setData(getAnnouncementsDetailsDemoData());
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, [fetchData, isDemoData]);

  return {
    data,
    isLoading,
    error,
    fetchData,
    surveyDetails,
    downloadCsv,
    isDownloading,
  };
};
