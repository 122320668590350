import { useState } from 'react';
import { formatPageUrl, formatRelativeTime } from '../../utils';

import {
  CmdColumnDef,
  CmdTypography,
  CmdTooltip,
  CmdDropdown,
  CmdDataTable,
  CmdTableError,
  CmdTableNoResultCta,
} from '@commandbar/design-system/cmd';
import { BookOpen01, FaceIdSquare, PlusCircle, SearchMd } from '@commandbar/design-system/icons/react';
import ActionDropdown from '../../components/ActionDropdown';
import Layout from '../../components/Layout';

import dayjs from 'dayjs';
import useDeadends from './useDeadends';
import { ResultTypeFilter, WidgetTypeFilter } from '@commandbar/internal/middleware/analytics/deadends';
import { createAnswer } from '../copilot/util';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { PAGE_SIZE } from '../copilot/state';

const typeFilterOptions: { label: string; value: ResultTypeFilter }[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'No results',
    value: 'no_results',
  },
  {
    label: 'Unhelpful Results',
    value: 'unhelpful_results',
  },
];

const widgetFilterOptions: { label: string; value: WidgetTypeFilter }[] = [
  { label: 'All', value: 'all' },
  { label: 'Spotlight', value: 'spotlight' },
  { label: 'HelpHub', value: 'helphub' },
  { label: 'Copilot', value: 'copilot' },
];

const tooltips: { [key: string]: { [key: string]: string } } = {
  copilot: { noResult: 'Fallback was triggered', unhelpful: 'User marked Copilot’s response with a down vote' },
  bar: { noResult: 'Zero results returned', unhelpful: 'Results are returned, but no search result item is clicked' },
  helphub: {
    noResult: 'Zero results returned',
    unhelpful: 'Results are returned, but no page, action (including “Ask Copilot”), record is clicked',
  },
};

const AnalyticsDeadendsDashboard = () => {
  const [typeFilter, setTypeFilter] = useState<{ label: string; value: ResultTypeFilter }>(typeFilterOptions[0]);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const { user } = useAuth();
  const [widgetFilters, setWidgetFilters] = useState<{ label: string; value: WidgetTypeFilter }[]>([
    widgetFilterOptions[0],
  ]);

  const handleFilterChange = (filterValue: { label: string; value: WidgetTypeFilter }, isChecked: boolean) => {
    if (filterValue.value === 'all') {
      if (isChecked) {
        setWidgetFilters([filterValue]);
      } else {
        setWidgetFilters([]);
      }
    } else {
      if (isChecked) {
        if (!widgetFilters.some((f) => f.value === 'all')) {
          setWidgetFilters([...widgetFilters, filterValue]);
        }
      } else {
        if (widgetFilters.some((f) => f.value === 'all')) {
          const newFilters = widgetFilterOptions
            .filter((option) => option.value !== 'all' && option.value !== filterValue.value)
            .map((option) => ({ label: option.label, value: option.value }));
          setWidgetFilters(newFilters);
        } else {
          setWidgetFilters(widgetFilters.filter((f) => f.value !== filterValue.value));
        }
      }
    }
  };

  const { data, isLoading, error, fetchDeadendsData, isDemoData, isDownloading, downloadCsv, page, setPage } =
    useDeadends({
      widgetFilter: widgetFilters.map((filter) => filter.value).join(','),
      typeFilter: typeFilter.value,
      searchFilter: searchFilter,
    });

  const columns: CmdColumnDef<any, any>[] = [
    {
      accessorKey: 'query',
      header: 'Query',
      enableGlobalFilter: true,
      width: 500,
      cell: ({ cell, row }) => {
        const icon =
          row.original.source === 'bar' ? (
            <SearchMd />
          ) : row.original.source === 'copilot' ? (
            <FaceIdSquare />
          ) : (
            <BookOpen01 />
          );
        return (
          <CmdTypography.Body
            variant="primary"
            style={{
              display: 'flex',
              maxWidth: '500px',
              alignItems: 'center',
              gap: '4px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {icon}
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: 'calc(100% - 24px)',
              }}
              title={cell.renderValue().toString()}
            >
              {cell.renderValue()}
            </span>
          </CmdTypography.Body>
        );
      },
    },
    {
      accessorKey: 'page',
      header: 'Page',
      cell: ({ cell }) => (
        <CmdTooltip message={cell.getValue()}>
          <>{formatPageUrl(cell.getValue())}</>
        </CmdTooltip>
      ),
    },
    {
      accessorKey: 'created_timestamp',
      header: () => 'Date',
      cell: ({ cell }) => (
        <CmdTooltip message={dayjs(cell.getValue()).format('MMM DD, YYYY, hh:mmA')}>
          <span>{formatRelativeTime(cell.getValue())}</span>
        </CmdTooltip>
      ),
    },
    {
      accessorKey: 'end_user',
      header: 'User id',
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'result_type',
      header: 'Type',
      cell: ({ cell, row }) => {
        const copy = tooltips[row.original.source][row.original.result_type === 'No result' ? 'noResult' : 'unhelpful'];

        return (
          <CmdTooltip message={copy}>
            <span>{cell.renderValue()}</span>
          </CmdTooltip>
        );
      },
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    columns.push({
      accessorKey: 'action',
      header: '',
      width: 40,
      cell: ({ row }) => {
        const options =
          row.original.source === 'spotlight'
            ? [
                {
                  label: 'Create Page',
                  icon: <PlusCircle />,
                  url: `/editor/pages`,
                },
                {
                  label: 'Create Action',
                  icon: <PlusCircle />,
                  url: `/editor/actions`,
                },
              ]
            : row.original.source === 'copilot'
            ? [
                {
                  label: 'Create Answer',
                  icon: <PlusCircle />,
                  onClick: () => createAnswer(row.original.id),
                },
              ]
            : [];

        if (!options) {
          return null;
        }
        return <ActionDropdown options={options} />;
      },
    });
  }

  return (
    <Layout title="Deadends" disableDemoData={isLoading} onDownloadCSV={downloadCsv} isDownloading={isDownloading}>
      <div
        style={{
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CmdDataTable
          columns={columns}
          data={data?.table.rows ?? []}
          isLoading={isLoading}
          emptyResultChildren={
            !!error ? (
              <CmdTableError onClick={fetchDeadendsData} />
            ) : (
              <CmdTableNoResultCta onClick={fetchDeadendsData}>
                {(defaultAction) => (
                  <>
                    <span className="item-center">
                      <CmdTypography.Body variant="secondary" fontWeight="medium">
                        Deadends capture situations where your users may be using some CommandBar experience to look for
                        something they couldn't find, like asking Copilot a question it didn't know the answer to, or
                        searching for something that retuned no results in HelpHub.
                      </CmdTypography.Body>
                    </span>
                    <div style={{ display: 'flex', gap: '4px' }}>{defaultAction}</div>
                  </>
                )}
              </CmdTableNoResultCta>
            )
          }
          toolBarChildren={
            <div
              style={{
                display: 'flex',
                gap: '8px',
              }}
            >
              <CmdDropdown.Menu>
                <CmdDropdown.SelectTrigger disabled={isDemoData} style={{ minWidth: '150px' }}>
                  Widget: {widgetFilters.map((filter) => filter.label).join(', ')}
                </CmdDropdown.SelectTrigger>

                <CmdDropdown.Content style={{ minWidth: '150px' }}>
                  {widgetFilterOptions.map((option) => (
                    <CmdDropdown.CheckboxItem
                      key={option.value}
                      checked={widgetFilters.some((f) => f.value === option.value || f.value === 'all')}
                      onCheckedChange={(isChecked) => handleFilterChange(option, isChecked)}
                    >
                      {option.label}
                    </CmdDropdown.CheckboxItem>
                  ))}
                </CmdDropdown.Content>
              </CmdDropdown.Menu>

              <CmdDropdown.Menu>
                <CmdDropdown.SelectTrigger disabled={isDemoData} style={{ minWidth: '150px' }}>
                  Type: {typeFilter.label}
                </CmdDropdown.SelectTrigger>
                <CmdDropdown.Content style={{ minWidth: '150px' }}>
                  <CmdDropdown.RadioGroup
                    value={typeFilter.value}
                    onValueChange={(value) =>
                      setTypeFilter(
                        typeFilterOptions.find((option) => option.value === (value as ResultTypeFilter)) ||
                          typeFilterOptions[0],
                      )
                    }
                  >
                    <CmdDropdown.RadioItem value="all">All</CmdDropdown.RadioItem>
                    <CmdDropdown.RadioItem value="no_results">No Results</CmdDropdown.RadioItem>
                    <CmdDropdown.RadioItem value="unhelpful_results">Unhelpful Results</CmdDropdown.RadioItem>
                  </CmdDropdown.RadioGroup>
                </CmdDropdown.Content>
              </CmdDropdown.Menu>
            </div>
          }
          state={{
            globalFilter: searchFilter,
            pagination: {
              pageIndex: page,
              pageSize: PAGE_SIZE,
            },
          }}
          onGlobalFilterChange={(value) => {
            setSearchFilter(value);
          }}
          manualPagination
          manualSorting
          manualFiltering
          onPaginationChange={(updater) => {
            if (typeof updater === 'function') {
              const newState = updater({
                pageSize: PAGE_SIZE,
                pageIndex: page,
              });
              setPage(newState.pageIndex);
            }
          }}
          pageCount={data?.table.meta?.totalPages}
          rowCount={data?.table.meta?.totalRows}
        />
      </div>
    </Layout>
  );
};

export default AnalyticsDeadendsDashboard;
