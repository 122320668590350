import { CmdBarGraph, CmdCard, CmdTypography } from '@commandbar/design-system/cmd';
import { BarChartData, CardData } from '@commandbar/internal/middleware/analytics/common';
import PercentChangeTag from '../../components/PercentChangeTag';
import { formatMetric } from '../../utils';

type ChartProps = {
  chartData?: BarChartData | null;
  totalOpens?: CardData | null;
  isLoading?: boolean;
};

const ChatsChart = ({ chartData, isLoading = false, totalOpens }: ChartProps) => {
  return (
    <CmdCard style={{ flex: '2 1 auto', width: '500px' }}>
      <CmdCard.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <div>
            <CmdTypography.Body fontWeight="semi-bold">Opens</CmdTypography.Body>
            <CmdTypography.HelpText variant="secondary" fontWeight="medium">
              Copilot opened by a user
            </CmdTypography.HelpText>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <CmdTypography.H1 fontWeight="semi-bold">{formatMetric(totalOpens?.metric, 'number')}</CmdTypography.H1>
            {!!totalOpens?.percent_change && <PercentChangeTag rate={totalOpens?.percent_change} />}
          </div>
        </div>
      </CmdCard.Header>
      <CmdCard.Content style={{ height: '300px' }}>
        <CmdBarGraph chartData={chartData?.data} isLoading={isLoading} />
      </CmdCard.Content>
    </CmdCard>
  );
};

export default ChatsChart;
