import { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Layout, Steps, Typography, Page } from '@commandbar/design-system/components/antd';
import { Loading01 } from '@commandbar/design-system/icons/react';
import Logger from '@commandbar/internal/util/Logger';
import * as Organization from '@commandbar/internal/middleware/organization';

import HubSpotConnectionStep from './HubSpotConnectionStep';
import { HubSpotConfigurationStep } from './HubSpotConfigurationStep';
import { useReportEvent } from '../../../../hooks/useEventReporting';
import Spinner from '../../../table/Spinner';
import { ContentBox, StepContent, StepsContainer } from './HubSpotIntegrationStyles';

import type { IOrganizationType } from '@commandbar/internal/middleware/types';
import { cmdToast } from '@commandbar/design-system/cmd';

const { Step } = Steps;

const STEPS = [
  {
    title: 'Connect to HubSpot',
    stepHeading: 'Connect your HubSpot account to CommandBar',
    component: HubSpotConnectionStep,
  },
  {
    title: 'Configure HubSpot',
    stepHeading: 'Select lists to sync and toggle sending CommandBar events to HubSpot',
    component: HubSpotConfigurationStep,
  },
];

const getCurrentStep = (steps: typeof STEPS, idx: number) => steps[idx] || {};

interface UseFetchHubSpotContextProps {
  organizationId: string;
}

const useFetchHubSpotContext = ({ organizationId }: UseFetchHubSpotContextProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stepIdx, setStepIdx] = useState(0);

  const [context, setContext] = useState<HubSpotContext>({
    send_events_to_hubspot: false,
    installed: false,
    lists: {},
  });

  useEffect(() => {
    setIsLoading(true);
    Organization.readInternal(organizationId)
      .then(({ integrations }) => {
        const installed = !!integrations.hubspot?.tokens?.access_token;

        setContext({
          send_events_to_hubspot: integrations.hubspot?.send_events_to_hubspot ?? false,
          lists: integrations.hubspot?.lists ?? {},
          installed,
        });

        if (installed) {
          setStepIdx(1);
        }
      })
      .catch((err) => {
        Logger.error(err);
        cmdToast.error('Failed to fetch HubSpot integration configuration.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [organizationId]);

  return {
    isLoading,
    stepIdx,
    context,
    setStepIdx,
  };
};

const useReportIntegrationVisited = () => {
  const { reportEvent } = useReportEvent();

  useEffect(() => {
    reportEvent('HubSpot integration visited', {
      segment: true,
      highlight: true,
      slack: true,
    });
  }, [reportEvent]);
};

export interface HubSpotContext {
  send_events_to_hubspot: boolean;
  installed: boolean;
  lists: Record<string, string>;
}

interface HubSpotIntegrationPageProps {
  organization: IOrganizationType;
}

const HubSpotIntegrationPage = ({ organization }: HubSpotIntegrationPageProps) => {
  const history = useHistory();
  const { isLoading, context, stepIdx, setStepIdx } = useFetchHubSpotContext({
    organizationId: organization.id.toString(),
  });
  const { stepHeading, component: CurrentStepComponent } = useMemo(() => getCurrentStep(STEPS, stepIdx), [stepIdx]);

  useReportIntegrationVisited();

  const handleNext = useCallback(() => {
    if (stepIdx === STEPS.length - 1) {
      history.push('/integrations');
    } else {
      setStepIdx((idx) => idx + 1);
    }
  }, [history, setStepIdx, stepIdx]);

  return (
    <Page section="Integrations" title="HubSpot" description="Connect HubSpot with Command AI.">
      <Layout.Content style={{ padding: '25px 10px', flexGrow: 1, height: 'inherit' }}>
        {isLoading ? (
          <Spinner>
            <Loading01 width={32} />
          </Spinner>
        ) : (
          <>
            <Steps current={stepIdx}>
              {STEPS.map(({ title }, idx) => (
                <Step key={`step-${idx}`} title={title} />
              ))}
            </Steps>
            <StepsContainer>
              <ContentBox>
                <Typography.Title level={4}>
                  {stepIdx + 1}. {stepHeading}
                </Typography.Title>
                <StepContent>
                  <CurrentStepComponent
                    handleNext={handleNext}
                    context={context}
                    organizationId={organization.id.toString()}
                  />
                </StepContent>
              </ContentBox>
            </StepsContainer>
          </>
        )}
      </Layout.Content>
    </Page>
  );
};

export default HubSpotIntegrationPage;
