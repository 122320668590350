import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import type { ActorRefFrom } from 'xstate';

import { SurveyResponseEvent } from '@commandbar/commandbar/shared/services/analytics/EventHandler';
import type {
  INudgeBannerStepType,
  INudgeButtonAction,
  INudgeCursorStepType,
  INudgePinStepType,
  INudgeStepContentButtonBlockType,
  INudgeStepContentHelpDocBlockType,
  INudgeStepType,
  INudgeTooltipStepType,
  INudgeType,
} from '@commandbar/internal/middleware/types';
import type { NudgeInteractionState } from '@cb/types/entities/endUser';

import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { getElement } from '@commandbar/internal/util/dom';
import helpdocService from 'shared/services/services/helpdocService';
import { runBooleanExpression } from 'shared/services/targeting/helpers';
import type { EndUserStore } from 'shared/store/end-user/state';
import { getCommandById } from 'shared/store/global-selectors';
import type { CBStore } from 'shared/store/global-store';
import type { NudgeContext } from './nudgeMachine';
import NudgeMachine from './nudgeMachine';
import type { TriggerEvent } from './nudgeManagerMachine';
import { getNudgeStep, isPinStep } from '@commandbar/internal/util/nudges';

// TODO: make this configurable
export const MAX_RENDERED_NUDGES = 1;

export type SmartNudgesContext = {
  rageClick: INudgeType[];
  smartDelay: INudgeType[];
  userConfusion: INudgeType[];
};

export const getAllNudgeServices = (_: CBStore) => _.nudgeManager?.getSnapshot()?.context.nudgeMachines;

export const getDebugService = (_: CBStore) => {
  const debuggingNudge = _.nudgeManager?.getSnapshot()?.context.debugMode.currentNudge;
  if (debuggingNudge) {
    return getNudgeService(_, debuggingNudge.id);
  }
};

export const getNudgeService = (_: CBStore, id: INudgeType['id']) =>
  _.nudgeManager?.getSnapshot().context.nudgeMachines.get(id.toString());

export const getNudgeServiceSnapshot = (_: CBStore, id: INudgeType['id']) => getNudgeService(_, id)?.getSnapshot();

export const getNudgeById = (_: CBStore, id: INudgeType['id']): INudgeType | undefined =>
  getNudgeServiceSnapshot(_, id)?.context.nudge;

export const getAllNudges = (_: CBStore): Array<INudgeType> => {
  const nudgeServices = getAllNudgeServices(_);
  return Array.from(nudgeServices?.values() ?? [])
    .map((service) => service.getSnapshot()?.context?.nudge)
    .filter((nudge): nudge is INudgeType => Boolean(nudge));
};

export const getDebuggedNudge = (_: CBStore, options = { getOriginal: false }): INudgeType | undefined => {
  if (_.nudgeManager?.initialized) {
    const debuggingNudge = options.getOriginal
      ? _.nudgeManager?.getSnapshot()?.context.debugMode.originalNudge
      : _.nudgeManager?.getSnapshot()?.context.debugMode.currentNudge;

    if (debuggingNudge) {
      return getNudgeById(_, debuggingNudge.id);
    }
  }
};

export const getRenderingServices = (
  _: CBStore,
  serviceFilter: (
    state: ReturnType<ActorRefFrom<typeof NudgeMachine>['getSnapshot']>,
    fallback?: boolean,
  ) => boolean | undefined = () => true,
) => {
  const nudgeServices = getAllNudgeServices(_);
  const servicesInRenderLoop = Array.from(nudgeServices?.values() ?? []).filter((service) => {
    const nudgeMachine = service.getSnapshot();
    return nudgeMachine?.matches('Step.Render Loop') && serviceFilter(nudgeMachine);
  });

  return servicesInRenderLoop;
};

export const getSmartNudges = (nudges: Array<INudgeType>): SmartNudgesContext => {
  return {
    rageClick: nudges.filter((nudge) => nudge.trigger.type === 'on_rage_click'),
    smartDelay: nudges.filter((nudge) => nudge.trigger.type === 'smart_delay'),
    userConfusion: nudges.filter((nudge) => nudge.trigger.type === 'on_user_confusion'),
  };
};

export const hasRageTrigger = (_: CBStore, nudges: Array<INudgeType>) => {
  return !!nudges.filter((nudge) => nudge.trigger.type === 'on_rage_click' && passesFrequencyCondition(_, nudge))
    .length;
};

export const hasSmartDelayTrigger = (_: CBStore, nudges: Array<INudgeType>) => {
  return !!nudges.filter((nudge) => nudge.trigger.type === 'smart_delay' && passesFrequencyCondition(_, nudge)).length;
};

export const hasUserConfusionTrigger = (_: CBStore, nudges: Array<INudgeType>) => {
  return !!nudges.filter((nudge) => nudge.trigger.type === 'on_user_confusion' && passesFrequencyCondition(_, nudge))
    .length;
};

export const hasBannerNudge = (_: CBStore, nudges: Array<INudgeType>) => {
  return !!nudges.filter((nudge) => isBannerNudge(nudge));
};

export const isNudgeDismissible = (nudge: INudgeType): boolean =>
  nudge.dismissible === undefined ? true : nudge.dismissible;

export const isNudgeSnoozable = (nudge: INudgeType): boolean => !!nudge.snoozable;

export const passesMaxRenderedNudges = (_: CBStore): boolean => {
  const nudgesInRenderLoop = getRenderingServices(_, (state) => {
    if (state?.context.nudge) {
      const currentlyRunningStep = getNudgeStep(state.context.nudge, state.context.stepIndex);
      return currentlyRunningStep?.form_factor.type !== 'tooltip';
    }
  });
  return nudgesInRenderLoop.length < MAX_RENDERED_NUDGES;
};

export const isScheduledNudge = (nudge: INudgeType): nudge is INudgeType & { trigger: { type: 'scheduled' } } =>
  nudge.trigger.type === 'scheduled';

export const passesSchedule = (
  _: CBStore,
  { id, trigger }: INudgeType & { trigger: { type: 'scheduled' } },
): boolean => {
  const nudgeData = getNudgeDataFromUserStore(_, id);
  const lastSeen = nudgeData?.seenTs?.[nudgeData.seenTs.length - 1];
  const interval = trigger.meta.interval;
  const value = trigger.meta.value;
  const now = dayjs();

  if (lastSeen) {
    const lastSeenMoment = dayjs(lastSeen);
    const diff = now.diff(lastSeenMoment, interval);
    return diff >= value;
  }

  return true;
};

export const passesGlobalLimit = (_: CBStore): boolean => {
  const limit = _.organization?.nudge_rate_limit;
  const period = _.organization?.nudge_rate_period;

  if (limit === null || limit === undefined || !period) return true;

  if (period === 'session') {
    const debugService = getDebugService(_);
    /* We don't want to count non-debug nudges towards the global limit.
     * When debugging a nudge, the admin should not have their experience impacted by the nudges
     * they have seen while using the app as a non-admin.
     */
    if (debugService) {
      const allNudgeSeenTs = debugService.getSnapshot()?.context?.nudgeSeenThisSessionTs;
      return (allNudgeSeenTs?.length ?? 0) < limit;
    }

    const allNudgeServices = getAllNudgeServices(_);

    if (allNudgeServices) {
      const allNudgeSeenTs = Array.from(allNudgeServices?.values()).flatMap(
        (service) => service.getSnapshot()?.context?.nudgeSeenThisSessionTs,
      );

      return (allNudgeSeenTs?.length ?? 0) < limit;
    }

    return true;
  }

  const allNudgesSeen = Object.values(_.endUserStore.data.nudges_interactions ?? {})
    .flatMap((nudgeData) => nudgeData.seenTs)
    .filter(Boolean);

  const nudgesSeenInPeriod = allNudgesSeen.filter((timeSeen) => {
    const timeSeenMoment = dayjs(timeSeen);
    const now = dayjs();
    const diff = now.diff(timeSeenMoment, period);

    return diff <= 1;
  }).length;

  return nudgesSeenInPeriod < limit;
};

export const passesStatus = (nudge: INudgeType): boolean => nudge.is_live;

export const passesFrequencyCondition = (_: CBStore, nudge: INudgeType): boolean => {
  switch (nudge.frequency_limit) {
    case 'no_limit':
      return true;
    case 'once_per_session':
      return !getNudgeServiceSnapshot(_, nudge.id)?.context?.nudgeSeenThisSessionTs.length;
    case 'once_per_user':
      return !getNudgeDataFromUserStore(_, nudge.id)?.nudgeSeen;
    case 'until_interaction': {
      // until_interaction is determined by if completed or dismiss action occured
      // nudgeInteracted is used by endUser store to track a users nudge engagements + metadata
      const savedNudgeInteractions = getNudgeDataFromUserStore(_, nudge.id);
      return !(savedNudgeInteractions?.nudgeCompleted || savedNudgeInteractions?.nudgeDismissed);
    }
  }
};

export const passesSnoozedConditions = (_: CBStore, nudge: INudgeType): boolean => {
  const snoozedUntilTs = getNudgeDataFromUserStore(_, nudge.id)?.snoozedUntilTs;
  return !(snoozedUntilTs && snoozedUntilTs > Date.now());
};

export const passesPageConditions = (_: CBStore, nudge: INudgeType): boolean =>
  !nudge.show_expression || runBooleanExpression(nudge.show_expression, _, '').passed;

export const passesFlip = (
  prevPassedConditions: NudgeContext['prevPassedConditions'],
  triggerEvent: NudgeContext['triggerEvent'],
  step?: INudgeStepType,
) => {
  if (isTooltipStep(step)) {
    return true;
  }

  if (prevPassedConditions && triggerEvent?.trigger.type === 'when_conditions_pass') {
    return false;
  }

  return true;
};

export const getCommandByIdIncludingHelpdocCommands = (_: CBStore) => async (commandId: string) => {
  let command = getCommandById(_, commandId) ?? null;

  if (!command) {
    if (_.organization?.id) command = await helpdocService.getHelpdocCommand(_, commandId);
  }

  return command;
};

export const passesTriggerMatch = (_: CBStore, nudge: INudgeType, triggerEvent: TriggerEvent | null) => {
  const nudgeState = getNudgeServiceSnapshot(_, nudge.id);

  if (!nudgeState?.done) {
    if (
      triggerEvent?.trigger.type === 'on_event' &&
      nudge.trigger.type === 'on_event' &&
      triggerEvent?.trigger.meta.event === nudge.trigger.meta.event
    ) {
      if (nudge.trigger.meta.condition_group) {
        const result = runBooleanExpression(nudge.trigger.meta.condition_group, _, '', {
          eventProperties: triggerEvent?.properties,
        });

        return result.passed;
      }

      return true;
    }

    if (triggerEvent?.trigger.type === 'when_conditions_pass' && nudge.trigger.type === 'scheduled') {
      return true;
    }

    return isEqual(triggerEvent?.trigger, nudge.trigger);
  }

  return false;
};

export const stepHasTargetElement = (nudge: INudgeType, stepIndex: NudgeContext['stepIndex']) => {
  const step = getNudgeStep(nudge, stepIndex);
  return step?.form_factor.type === 'pin';
};

export const stepHasCommand = (nudge: INudgeType, stepIndex: NudgeContext['stepIndex']) =>
  getNudgeStep(nudge, stepIndex)?.content.find(
    (block): block is INudgeStepContentButtonBlockType => block.type === 'button',
  )?.meta?.action?.type === 'execute_command';

export const hasRemainingSteps =
  (nudge: INudgeType) =>
  ({ stepIndex }: NudgeContext) =>
    stepIndex < nudge.steps.length - 1;

const getAllNudgeDataFromUserStore = (_: CBStore): EndUserStore['data']['nudges_interactions'] => {
  let nudgesInteractions = null;

  if (_.endUserStore.data.nudges_interactions) {
    nudgesInteractions = _.endUserStore.data.nudges_interactions;
  } else {
    const savedContext = LocalStorage.get('nudges', false);
    const parsedSavedContext: EndUserStore['data']['nudges_interactions'] =
      typeof savedContext === 'string' ? JSON.parse(savedContext) : undefined;

    nudgesInteractions = parsedSavedContext;
  }

  // if any (legacy) nudge interaction is missing stepIndexStack, we need to add it
  if (nudgesInteractions && typeof nudgesInteractions === 'object') {
    const entriesMissingStepIndex = Object.entries(nudgesInteractions).filter(
      ([, nudgeData]) => nudgeData.stepIndexStack === undefined,
    );

    if (entriesMissingStepIndex.length > 0) {
      return {
        ...nudgesInteractions,
        ...Object.fromEntries(
          entriesMissingStepIndex.map(([id, nudgeData]) => [
            id,
            { ...nudgeData, stepIndexStack: Array.from({ length: nudgeData.currentStep ?? 0 }, (_, i) => i).reverse() },
          ]),
        ),
      };
    }
  }

  return nudgesInteractions;
};

export const getNudgeDataFromUserStore = (_: CBStore, nudgeId: INudgeType['id']): NudgeInteractionState | undefined =>
  getAllNudgeDataFromUserStore(_)?.[Number(nudgeId)];

export const passesPinnedElement = (nudge: INudgeType, stepIndex: number): boolean => {
  const step = getNudgeStep(nudge, stepIndex);

  if (!step) return false;

  if (step.form_factor.type === 'pin' || step.form_factor.type === 'tooltip') {
    return !!getElement(step.form_factor.anchor_selector || step.form_factor.anchor);
  }

  return true;
};

export const isLikelyAdmin = () => !!LocalStorage.get('editor', false);

export const shouldBypassGlobalLimit = (_: CBStore, currentStep?: INudgeStepType) =>
  currentStep?.form_factor.type === 'tooltip' ||
  currentStep?.form_factor.type === 'banner' ||
  (_.nudgeDebugToolBar.visible && _.nudgeDebugToolBar.bypassGlobalLimit);

type EventDetails = {
  button_type?: 'primary' | 'secondary' | 'help_doc' | 'snooze';
  surveyResponse?: SurveyResponseEvent['response'];
};

const evaluateCondition = (
  condition: { operator: unknown; operand: unknown; action: unknown },
  event: EventDetails,
) => {
  if (event.surveyResponse) {
    switch (condition.operator) {
      case 'eq':
        return event.surveyResponse?.value === condition.operand;
      case 'neq':
        return event.surveyResponse?.value !== condition.operand;
      case 'gt':
        return (
          typeof event.surveyResponse?.value === 'number' &&
          typeof condition.operand === 'number' &&
          event.surveyResponse?.value > condition.operand
        );
      case 'lt':
        return (
          typeof event.surveyResponse?.value === 'number' &&
          typeof condition.operand === 'number' &&
          event.surveyResponse?.value < condition.operand
        );
      default:
        return false;
    }
  }
  return false;
};

const getImmediateAction = (_: CBStore, step: INudgeStepType, event: EventDetails): INudgeButtonAction | null => {
  if (event.button_type === 'help_doc') {
    const helpDocCommand = step.content.find(
      (block): block is INudgeStepContentHelpDocBlockType => block.type === 'help_doc_command',
    )?.meta;

    if (helpDocCommand) {
      return { type: 'execute_command', meta: { command: helpDocCommand.command } };
    }
  }

  // needed for implicit snooze button
  if (event.button_type === 'snooze') {
    return { type: 'snooze' };
  }

  const buttonMeta = step.content.find(
    (block): block is INudgeStepContentButtonBlockType =>
      block.type === 'button' && block.meta?.button_type === event?.button_type,
  )?.meta;

  const buttonAction = buttonMeta?.action;

  if (buttonAction && buttonAction?.type !== 'no_action') {
    return buttonAction;
  }
  return null;
};

const getActionBasedOnConditions = (
  _: CBStore,
  step: INudgeStepType,
  event: EventDetails,
): INudgeButtonAction | null => {
  const buttonMeta = step.content.find(
    (block): block is INudgeStepContentButtonBlockType =>
      block.type === 'button' && block.meta?.button_type === event?.button_type,
  )?.meta;

  const conditionalActions = buttonMeta?.conditional_actions;

  if (conditionalActions) {
    for (const conditionalAction of conditionalActions) {
      if (evaluateCondition(conditionalAction, event)) {
        return conditionalAction.action || null;
      }
    }
  }

  return null;
};

export const determineAction = (_: CBStore, step: INudgeStepType, event: EventDetails): INudgeButtonAction | null =>
  getImmediateAction(_, step, event) ?? getActionBasedOnConditions(_, step, event);

export const getSeenNudges = (_: CBStore) => {
  const nudgeServices = getAllNudgeServices(_);
  const allNudgeInteractions = getAllNudgeDataFromUserStore(_);

  const getLastSeenTime = (id: INudgeType['id']) => {
    if (!allNudgeInteractions) return;
    const nudgeInteractions = allNudgeInteractions[Number(id)];
    return nudgeInteractions.seenTs?.[nudgeInteractions.seenTs.length - 1];
  };

  if (!nudgeServices || !allNudgeInteractions) return;

  return Object.entries(allNudgeInteractions)
    .reduce<Array<{ nudge: INudgeType; lastSeen: number }>>((acc, [id, context]) => {
      if (nudgeServices.has(id) && context.nudgeSeen) {
        const nudgeContext = nudgeServices.get(id)?.getSnapshot()?.context;

        if (nudgeContext && nudgeContext.nudge.is_live) {
          acc.push({ nudge: nudgeContext.nudge, lastSeen: getLastSeenTime(id) || 0 });
        }
      }

      return acc;
    }, [])
    .sort((a, b) => {
      return b.lastSeen - a.lastSeen;
    });
};

export const isTooltipStep = (step?: INudgeStepType): step is INudgeTooltipStepType =>
  step?.form_factor.type === 'tooltip';
export const isTooltipNudge = (nudge?: INudgeType) => nudge && isTooltipStep(getNudgeStep(nudge, 0));

export const isBannerStep = (step?: INudgeStepType): step is INudgeBannerStepType =>
  step?.form_factor.type === 'banner';
export const isBannerNudge = (nudge?: INudgeType) => nudge && isBannerStep(getNudgeStep(nudge, 0));

// tooltips and banner should not count towards the global limit
export const isNoGlobalLimitFormFactorStep = (nudge: INudgeType, stepIndex: number) => {
  const step = getNudgeStep(nudge, stepIndex);
  return isBannerStep(step) || isTooltipStep(step);
};

export const isCursorStep = (nudge?: INudgeType, step?: INudgeStepType): step is INudgeCursorStepType =>
  isPinStep(step) &&
  (step.form_factor.copilot_intro ?? true) &&
  nudge?.steps.findIndex((_step) => isEqual(_step, step)) === 0;

export const getPinAnchors = (
  _: CBStore,
  { step, anchorOverride }: { step: INudgePinStepType; anchorOverride?: string },
) => {
  let targetEl = undefined;
  let advanceTriggerEl = undefined;

  const isAnchor = _.extension.recorder.experience?.field === 'form_factor.anchor';
  const isAdvanceTrigger = _.extension.recorder.experience?.field === 'form_factor.advance_trigger';
  const advanceTriggerElAnchor = isAdvanceTrigger
    ? anchorOverride ?? step?.form_factor.advance_trigger
    : step?.form_factor.advance_trigger;

  const targetElAnchor = isAnchor
    ? anchorOverride ?? (step.form_factor.anchor_selector || step.form_factor.anchor)
    : step.form_factor.anchor_selector || step.form_factor.anchor;

  advanceTriggerEl = advanceTriggerElAnchor ? getElement(advanceTriggerElAnchor) : undefined;
  targetEl = targetElAnchor ? getElement(targetElAnchor) : undefined;

  return { targetEl, advanceTriggerEl };
};
