import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { CmdTooltip, CmdTypography } from '@commandbar/design-system/cmd';
import type { CmdColumnDef } from '@commandbar/design-system/cmd';
import type { TableData, TableRowData } from '@commandbar/internal/middleware/analytics/common';
import type { AdminFilter, DateFilter, PaginationFilter } from '@commandbar/internal/middleware/analytics/common';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import Table from '../components/Table';
import { formatRelativeTime } from '../utils';
import { PAGE_SIZE } from './copilot/state';

const tooltips = {
  Strike: 'User closed nudge within 2 seconds',
  View: 'User viewed the nudge',
  Dismissal: ' User closed the nudge',
  Completion: 'User has completed the nudge',
};

type ViewsTableData = { views: TableData };

interface ViewsTableProps {
  id: string;
  readUsers: (
    id: string,
    params: { search_filter: string } & DateFilter & AdminFilter & PaginationFilter,
  ) => Promise<ViewsTableData>;
  getDemoData: () => ViewsTableData;
}

export const ViewsTable = ({ id, readUsers, getDemoData }: ViewsTableProps) => {
  const [data, setData] = useState<ViewsTableData>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');

  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();

  useEffect(() => {
    setPage(0);
  }, [timeFilterRange, searchFilter, includeAdminData]);

  const fetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    readUsers(id, {
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      include_admin_data: includeAdminData.toString(),
      page: page.toString(),
      page_size: PAGE_SIZE.toString(),
      search_filter: searchFilter,
    })
      .then(setData)
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [readUsers, id, includeAdminData, timeFilterRange, page, searchFilter]);

  useEffect(() => {
    if (isDemoData) {
      setData(getDemoData());
    } else {
      fetchData();
    }
  }, [fetchData, isDemoData, getDemoData]);

  const viewColumns: CmdColumnDef<TableRowData, string | number>[] = [
    {
      accessorKey: 'end_user',
      header: 'User id',
      enableGlobalFilter: true,
      cell: ({ cell }) => <span>{cell.getValue() ?? 'Anonymous user'}</span>,
    },
    {
      accessorKey: 'event_name',
      header: 'Action',
      cell: ({ cell }) => (
        <CmdTooltip message={tooltips[cell.getValue() as keyof typeof tooltips]}>
          <span>{cell.getValue()}</span>
        </CmdTooltip>
      ),
    },
    {
      accessorKey: 'timestamp',
      header: 'Date',
      cell: ({ cell }) => (
        <CmdTooltip message={dayjs(cell.getValue()).format('MMM DD, YYYY, hh:mmA')}>
          <span>{formatRelativeTime(cell.getValue())}</span>
        </CmdTooltip>
      ),
    },
  ];

  return (
    <Table
      columns={viewColumns}
      toolBarChildren={<CmdTypography.Body fontWeight="semi-bold">Views</CmdTypography.Body>}
      data={data?.views.rows || []}
      searchPlaceholder={'Search by User id'}
      isLoading={isLoading}
      empty={{
        error,
        refetchData: fetchData,
      }}
      state={{
        globalFilter: searchFilter,
        pagination: {
          pageIndex: page,
          pageSize: PAGE_SIZE,
        },
      }}
      onGlobalFilterChange={(value: string) => {
        setSearchFilter(value);
      }}
      manualPagination
      manualSorting
      manualFiltering
      onPaginationChange={(updater) => {
        if (typeof updater === 'function') {
          const newState = updater({
            pageSize: PAGE_SIZE,
            pageIndex: page,
          });
          setPage(newState.pageIndex);
        }
      }}
      pageCount={data?.views.meta?.totalPages}
      rowCount={data?.views.meta?.totalRows}
    />
  );
};
