import React from 'react';

import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import StyledPrimaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledPrimaryButton';
import StyledSecondaryButton from '@commandbar/internal/client/themesV2/components/shared/StyledSecondaryButton';
import StyledChatMessage from '@commandbar/internal/client/themesV2/components/copilot/StyledChatMessage';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import ActionButton from '@commandbar/commandbar/shared/components/ActionButton';
import { emptyGlobalStore } from '@commandbar/commandbar/shared/store/global-store';
import { useChatState } from '../../store/useChatState';
import { getNudgeById, isCursorStep } from '@commandbar/commandbar/products/nudges/service-selectors';
import * as HelpHubServiceActions from 'products/helphub/service-actions';
import * as GlobalActions from 'shared/store/global-actions';
import * as Reporting from 'shared/services/analytics/Reporting';
import { useAction } from 'shared/util/hooks/useAction';
import { getNudgeStep } from '@commandbar/internal/util/nudges';
import { Action, ChatHandoffAction, OpenChatAction } from '@cb/types/entities/command/actions';
import { getNativeCallback } from '@commandbar/commandbar/shared/util/hooks/useMobileExperience';
import { getSentry } from '@commandbar/internal/util/sentry';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';
import { ACTIONS_ROUTE, PAGES_ROUTE, getNudgeRoute } from '@commandbar/internal/proxy-editor/editor_routes';
import { getChecklistById } from '@commandbar/commandbar/products/checklists/service-selectors';

type BotMessageActionProps = {
  idx: number;
  action?: Action;
  buttonLabel?: string;
  buttonType?: 'button_primary' | 'button_secondary';
  message?: string;
  sendChat: (message: string) => void;
  preview: boolean;
  isChatHistoryPreview: boolean;
  previewOverrides?: { useThemeV2: boolean };
};

const BotMessageAction: React.FC<BotMessageActionProps> = (props) => {
  const {
    idx,
    action,
    buttonLabel = 'Click here',
    buttonType = 'button_primary',
    message,
    preview,
    isChatHistoryPreview,
    previewOverrides,
  } = props;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _ = !preview ? useStore() : emptyGlobalStore();
  const chatState = useChatState();
  const themeV2 = useThemeV2Context();

  const disabled = preview || isChatHistoryPreview;

  const setHelpHubMinimized = useAction(HelpHubServiceActions.setHelpHubMinimized);
  const executeAction = useAction(GlobalActions.executeAction);

  const shouldContactSupport = (action: Action): action is OpenChatAction | ChatHandoffAction => {
    if (!(action.type === 'open_chat' || action.type === 'chat_handoff')) {
      return false;
    }

    if (action?.meta?.type === 'intercom') {
      if (!window.intercomSettings?.user_id) {
        getSentry()?.captureMessage('Intercom user_id not found on window.intercomSettings', 'warning');
      }

      return window.Intercom && (!!window.intercomSettings?.user_id || !!window.Intercom('getVisitorId'));
    }

    return false;
  };

  const shouldHandoffChat = (action: Action): action is OpenChatAction | ChatHandoffAction => {
    return (
      (action.type === 'chat_handoff' && action.meta.type === 'zendesk') ||
      (action.type === 'open_chat' && action.meta.type === 'zendesk_handoff') ||
      (action.type === 'chat_handoff' && action.meta.type === 'sfdc_chat') ||
      (action.type === 'chat_handoff' && action.meta.type === 'sfdc_messaging')
    );
  };

  const shouldMinimizeHelpHub = (action: Action) => {
    if (action.type === 'nudge') {
      const nudge = getNudgeById(_, action.value);
      if (nudge) {
        const step = getNudgeStep(nudge, 0);
        if (isCursorStep(nudge, step)) {
          // INFO: cursors will automatically minimize HelpHub
          return false;
        }
      }
    }

    if (action.type === 'execute_command') {
      const commandId = action.meta.command;
      const command = _.commands.find((c) => c.id === parseInt(commandId));

      // this is a helpdoc command
      if (command?.template.type === 'helpdoc') {
        return false;
      }
      // this is a trigger command -- we still need to check if it triggers a helpdoc command
      else if (command?.template.type === 'trigger' && command.template.value.type === 'execute_command') {
        const nestedCommandId = command.template.value.meta.command;
        const nestedCommand = _.commands.find((c) => c.id === parseInt(nestedCommandId));
        if (nestedCommand?.template.type === 'helpdoc') {
          return false;
        }
      }
    }

    return true;
  };

  const onExtraClick = (e?: React.MouseEvent) => {
    if (action) {
      if (preview) {
        switch (action.type) {
          case 'execute_command':
            const commandId = action.meta.command;
            const command = _.commands.find((c) => c.id === parseInt(commandId));
            if (command) {
              window.open(
                `${process.env.REACT_APP_DASHBOARD_URL}/editor${
                  command.template.type === 'link' ? PAGES_ROUTE : ACTIONS_ROUTE
                }/${command.id}`,
                '_blank',
              );
            }
            break;
          case 'nudge':
            const nudge = getNudgeById(_, action.value);
            if (nudge) {
              window.open(`/editor${getNudgeRoute(nudge)}/${nudge.id}`, '_blank');
            }
            break;
          case 'questlist':
            const checklist = getChecklistById(_, action.value);
            if (checklist) {
              window.open(`/editor/checklists/${checklist.id}`, '_blank');
            }
            break;
        }
      } else {
        Track.copilot.experienceClicked(chatState.chatID, action); // events v2
        Reporting.helpHubChatActionClicked(chatState.chatID, action); // events v1
        // When HelpHub/Copilot is rendered in a Native App WebView,
        // copilot experiences will not work but in our Native Apps we send the fallback action to the native app
        const nativeCallback = getNativeCallback();
        if (window._cbIsWebView && nativeCallback) {
          nativeCallback(JSON.stringify(action));
        } else if (shouldContactSupport(action)) {
          chatState.contactSupport(action);
        } else if (shouldHandoffChat(action)) {
          chatState.handoff(action);
        } else {
          executeAction(action, e, { chatHistory: chatState.chatHistory }, { type: 'copilot' });
          if (shouldMinimizeHelpHub(action)) {
            setHelpHubMinimized(true);
          }
        }
      }
    } else {
      props.sendChat(buttonLabel);
    }
  };

  const useThemesV2 = (preview && previewOverrides?.useThemeV2) || _.flags?.['release-themes-v2'];

  switch (buttonType) {
    case 'button_secondary':
      return useThemesV2 ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {message && <StyledChatMessage isBotMessage>{message}</StyledChatMessage>}
          <StyledSecondaryButton themeV2={themeV2} disabled={disabled} onClick={onExtraClick} key={idx} textWrap>
            {buttonLabel}
          </StyledSecondaryButton>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          {message}
          <ActionButton variant={'secondary'} disabled={disabled} onClick={onExtraClick} key={idx} preview={preview}>
            {buttonLabel}
          </ActionButton>
        </div>
      );
    case 'button_primary':
      return useThemesV2 ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {message && <StyledChatMessage isBotMessage>{message}</StyledChatMessage>}
          <StyledPrimaryButton themeV2={themeV2} disabled={disabled} onClick={onExtraClick} key={idx} textWrap>
            {buttonLabel}
          </StyledPrimaryButton>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          {message}
          <ActionButton variant={'primary'} disabled={disabled} onClick={onExtraClick} key={idx} preview={preview}>
            {buttonLabel}
          </ActionButton>
        </div>
      );
  }
};

export default BotMessageAction;
