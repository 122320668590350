import React, { useState, useEffect, useRef, FC } from 'react';
import { Checkbox, Form, Input, Row, Col } from '@commandbar/design-system/components/antd';
import { HelpCenterIntegrationSpec, SourceConfigFormProps, INTEGRATION_TYPE } from '../shared/types';

import { FormItemHorizontal } from '../shared/styles';

const WebV2: HelpCenterIntegrationSpec = {
  type: INTEGRATION_TYPE.WEBV2,
  name: 'Website',
  slug: 'web_v2',
  imgURL: 'https://staticassets.commandbar.com/site-assets/integrations/web-icon.svg',
  iconURL: 'https://staticassets.commandbar.com/site-assets/integrations/web-icon.svg',
  useDryRun: false,
  isDisabled: false,
  description: 'Add content from any public website',
  guidance: null,
  route: '/website',

  getForm({ editingSource }: SourceConfigFormProps) {
    const FormComponent: FC = () => {
      const pathIncludeInitial = editingSource?.meta?.include_path;
      const pathExcludeInitial = editingSource?.meta?.exclude_path;
      const [filterByPath, setFilterByPath] = useState(pathIncludeInitial || pathExcludeInitial);
      const filterByPathRef = useRef<any>(null);
      const urlRef = useRef<any>(null);

      useEffect(() => {
        if (
          pathIncludeInitial ||
          pathExcludeInitial ||
          (filterByPathRef.current && filterByPathRef.current?.input.checked)
        ) {
          setFilterByPath(true);
        }

        urlRef.current.focus();
      }, [editingSource]);

      return (
        <>
          <Form.Item
            label="Site URL"
            key={editingSource?.type + 'domain'}
            validateTrigger="onChange"
            initialValue={editingSource?.meta?.recursive_urls?.[0] ?? editingSource?.meta?.single_urls?.[0] ?? ''}
            name="domain"
            rules={[
              { required: true, message: 'The domain is a required field.' },
              {
                pattern: /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([/?].*)?$/,
                message: 'Please enter a valid website URL.',
              },
            ]}
            style={{ flex: 5 }}
            required
          >
            <Input placeholder="https://commandbar.com/blog" ref={urlRef} />
          </Form.Item>

          <FormItemHorizontal
            key={editingSource?.type + 'single_page'}
            label="Sync only this URL"
            name="single_page"
            initialValue={!!editingSource?.meta?.single_urls?.[0]}
            valuePropName="checked"
            rules={[{ required: false }]}
          >
            <Checkbox />
          </FormItemHorizontal>

          <FormItemHorizontal
            key={editingSource?.type + 'filter_by_path'}
            label="Filter by path"
            name="filter_by_path"
            valuePropName="checked"
            rules={[{ required: false }]}
            initialValue={!!filterByPath}
          >
            <Checkbox onChange={(e: any) => setFilterByPath(e.target.checked)} ref={filterByPathRef} />
          </FormItemHorizontal>

          {filterByPath && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Include"
                  name="include_path"
                  rules={[{ required: false }]}
                  initialValue={editingSource?.meta?.include_path || ''}
                  tooltip={{
                    title: 'Only include URLs containing path',
                  }}
                >
                  <Input placeholder="/help" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Exclude"
                  name="exclude_path"
                  rules={[{ required: false }]}
                  initialValue={editingSource?.meta?.exclude_path || ''}
                  tooltip={{
                    title: 'Exclude all URLs containing path',
                  }}
                >
                  <Input placeholder="/careers" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      );
    };

    return <FormComponent />;
  },

  getPayloadMeta(data) {
    // Convert domain to an array, separated by commas
    let domains = [];
    if (data['domain']) {
      domains = data['domain'].split(',');
    }

    return {
      single_urls: !!data['single_page'] ? domains : [],
      recursive_urls: !!data['single_page'] ? [] : domains,
      include_path: data['include_path'],
      exclude_path: data['exclude_path'],
    };
  },
  requiredFields: ['single_page', 'domain'],
  fetchedFields: [],
};

export default WebV2;
