import { CmdCombobox, CmdSelect, CmdTypography } from '@commandbar/design-system/cmd';
import styled from '@emotion/styled';
import { useAppContext } from 'editor/src/AppStateContext';
import EndUserChooser from 'editor/src/editor/components/EndUserChooser';
import { HIGHLIGHT_TYPE } from './state';
import { CB_COLORS } from '@commandbar/design-system/colors';

export const FilterSelectionLabel: React.FC = ({ children }) => (
  <CmdSelect.SelectValue asChild>
    <span
      style={{
        fontSize: '14px',
        fontWeight: 500,
      }}
    >
      {children}
    </span>
  </CmdSelect.SelectValue>
);

const StyledSelectTrigger = styled(CmdSelect.SelectTrigger)`
  min-width: 136px;
  height: 32px;
  gap: 8px;
`;

interface FiltersProps {
  tagFilter: string;
  setTagFilter: (tag: string) => void;

  userFilter: string[];
  setUserFilter: (filter: string[]) => void;

  highlightFilter: HIGHLIGHT_TYPE[];
  setHighlightFilter: (filter: HIGHLIGHT_TYPE[]) => void;
}

const highlightFilterOptions = [
  { value: HIGHLIGHT_TYPE.POSITIVE_FEEDBACK, label: 'Positive Feedback', group: 'Feedback' },
  { value: HIGHLIGHT_TYPE.NEGATIVE_FEEDBACK, label: 'Negative Feedback', group: 'Feedback' },

  { value: HIGHLIGHT_TYPE.NUDGE, label: 'Nudge', group: 'Suggestions' },
  { value: HIGHLIGHT_TYPE.PAGE, label: 'Page', group: 'Suggestions' },
  { value: HIGHLIGHT_TYPE.ACTION, label: 'Action', group: 'Suggestions' },

  { value: HIGHLIGHT_TYPE.FALLBACK, label: 'Fallback triggered', group: 'Other' },
  { value: HIGHLIGHT_TYPE.CHAT_HANDOFF, label: 'Chat handoff triggered', group: 'Other' },
  { value: HIGHLIGHT_TYPE.WORKFLOW, label: 'Workflow triggered', group: 'Other' },
  { value: HIGHLIGHT_TYPE.API, label: 'API referenced', group: 'Other' },
  { value: HIGHLIGHT_TYPE.ERROR, label: 'Error message shown', group: 'Other' },
  { value: HIGHLIGHT_TYPE.NONE, label: 'No highlights', group: 'Other' },
];

const highlightComboboxItems = [
  {
    label: 'Feedback',
    items: [
      { value: HIGHLIGHT_TYPE.POSITIVE_FEEDBACK, label: 'Positive Feedback' },
      { value: HIGHLIGHT_TYPE.NEGATIVE_FEEDBACK, label: 'Negative Feedback', separator: true },
    ],
  },
  {
    label: 'Suggestions',
    items: [
      { value: HIGHLIGHT_TYPE.NUDGE, label: 'Nudge' },
      { value: HIGHLIGHT_TYPE.PAGE, label: 'Page' },
      { value: HIGHLIGHT_TYPE.ACTION, label: 'Action', separator: true },
    ],
  },
  {
    label: 'Other',
    items: [
      { value: HIGHLIGHT_TYPE.FALLBACK, label: 'Fallback triggered' },
      { value: HIGHLIGHT_TYPE.CHAT_HANDOFF, label: 'Chat handoff triggered' },
      { value: HIGHLIGHT_TYPE.WORKFLOW, label: 'Workflow triggered' },
      { value: HIGHLIGHT_TYPE.API, label: 'API referenced' },
      { value: HIGHLIGHT_TYPE.ERROR, label: 'Error message shown', separator: true },
    ],
  },
  { value: HIGHLIGHT_TYPE.NONE, label: 'No highlights' },
];

const Filters = ({
  tagFilter,
  setTagFilter,
  userFilter,
  setUserFilter,
  highlightFilter,
  setHighlightFilter,
}: FiltersProps) => {
  const { tags } = useAppContext();

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flexWrap: 'wrap' }}>
      <CmdCombobox
        multiSelect
        selectedOptions={highlightFilter}
        items={highlightComboboxItems}
        emptyState="No highlights found"
        onChange={(value) => {
          const newHighlightFilter = highlightFilter.some((item) => item === value)
            ? highlightFilter.filter((item) => item !== value)
            : [...highlightFilter, value as HIGHLIGHT_TYPE];
          setHighlightFilter(newHighlightFilter);
        }}
        triggerValueOverride={
          <span style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CmdTypography.Body variant="primary">Highlights: </CmdTypography.Body>
            {highlightFilter.length === highlightFilterOptions.length ? (
              <CmdTypography.Body variant="primary">All</CmdTypography.Body>
            ) : (
              <div
                style={{
                  borderRadius: '100%',
                  background: CB_COLORS.blue700,
                  height: '16px',
                  width: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span
                  style={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: 'white',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {highlightFilter.length}
                </span>
              </div>
            )}
          </span>
        }
      />

      <CmdSelect.Menu
        value={tagFilter}
        onValueChange={(value) => {
          setTagFilter(value);
        }}
      >
        <StyledSelectTrigger>
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <CmdTypography.Body variant="primary">Tags: </CmdTypography.Body>
            <FilterSelectionLabel>{tagFilter === 'all' ? 'All' : tagFilter}</FilterSelectionLabel>
          </div>

          <CmdSelect.SelectContent>
            <CmdSelect.SelectItem key={'all'} value={'all'}>
              {'All'}
            </CmdSelect.SelectItem>
            {tags.map((tag) => (
              <CmdSelect.SelectItem key={tag.name} value={tag.name}>
                {tag.name}
              </CmdSelect.SelectItem>
            ))}
          </CmdSelect.SelectContent>
        </StyledSelectTrigger>
      </CmdSelect.Menu>

      <EndUserChooser
        style={{
          width: 'unset',
          minWidth: '136px',
          height: '32px',
          gap: '8px',
        }}
        endUserSlugs={userFilter}
        onEndUserChange={(newEndUser) => {
          setUserFilter(
            userFilter.includes(newEndUser.slug)
              ? userFilter.filter((slug) => slug !== newEndUser.slug)
              : [...userFilter, newEndUser.slug],
          );
        }}
        placeholder="All"
        prefixText="Users:"
        prefixIcon={null}
        searchPlaceholder="Filter by user ID"
      />
    </div>
  );
};

export default Filters;
