import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import Page from '@commandbar/design-system/components/antd/Page';
import {
  CmdButton,
  CmdColumnDef,
  CmdDataTable,
  CmdRow,
  CmdSheet,
  CmdSortableColumn,
  CmdSwitch,
  CmdTableError,
  CmdTableNoResultCta,
  CmdTooltip,
  CmdTypography,
} from '@commandbar/design-system/cmd';
import { IUserProperty, UserProperty } from '@commandbar/internal/middleware/userProperty';
import PropertyDetailDrawer from './PropertyDetailDrawer';
import React from 'react';
import { Brackets, CalendarDate, Hash02, List, Menu03, Toggle03Right } from '@commandbar/design-system/icons/react';
import dayjs from 'dayjs';
import { formatRelativeTime } from '../analytics/utils';
import { StatusBadge } from '@commandbar/design-system/components/antd';

export const propertyTypesDisplay: Record<IUserProperty['type'], [string, React.FC]> = {
  any: ['Any', () => null],
  string: ['String', Menu03],
  numeric: ['Numeric', Hash02],
  boolean: ['Boolean', Toggle03Right],
  datetime: ['Date & Time', CalendarDate],
  array: ['List', List],
  object: ['Object', Brackets],
};

type UserPropertyTypeProps = {
  propertyType: IUserProperty['type'];
};

export const UserPropertyType: React.FC<UserPropertyTypeProps> = ({ propertyType }) => {
  const [displayName, Icon] = propertyTypesDisplay[propertyType];

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Icon /> <CmdTypography variant="contentMid">{displayName}</CmdTypography>
    </div>
  );
};

export const PropertiesPage = () => {
  const [properties, setProperties] = useState<IUserProperty[] | null>(null);
  const [currentProperty, setCurrentProperty] = useState<IUserProperty | null>(null);
  const [includeHidden, setIncludeHidden] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const propertyColumns: CmdColumnDef<IUserProperty, string>[] = [
    {
      accessorKey: 'name',
      header: 'Property name',
      enableGlobalFilter: true,
      onClick: (row: CmdRow<IUserProperty>) => {
        setCurrentProperty(row.original);
      },

      cell: ({ row }) => {
        const record = row.original;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {includeHidden && <StatusBadge color={record.hidden ? 'orange' : 'green'} />}
            <div>
              <CmdTypography.Body variant="contentMid">{record.name}</CmdTypography.Body>
              <CmdTypography.HelpText variant="secondary" style={{ maxWidth: '500px' }} className="truncate">
                {record.description}
              </CmdTypography.HelpText>
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: 'type',
      header: 'Type',
      cell: ({ row }) => {
        return <UserPropertyType propertyType={row.original.type} />;
      },
      enableGlobalFilter: true,
    },
    {
      accessorKey: 'last_seen_at',
      header: ({ column }) => <CmdSortableColumn column={column} title="Last seen" />,
      enableGlobalFilter: true,
      cell: ({ cell }) => (
        <CmdTooltip message={dayjs(cell.getValue()).format('MMM DD, YYYY, hh:mmA')}>
          <span>{formatRelativeTime(cell.getValue())}</span>
        </CmdTooltip>
      ),
    },
  ];

  const saveUserProperty = async (newProperty: IUserProperty) => {
    await UserProperty.update(newProperty);

    // Optimistically update the property in the table if the request succeeded
    setProperties((properties) => {
      if (!properties) return properties;

      const existingProperty = properties.findIndex((p) => p.id === newProperty.id);

      if (existingProperty === -1) {
        // Log Sentry error
        return properties;
      }

      return [...properties.slice(0, existingProperty), newProperty, ...properties.slice(existingProperty + 1)];
    });

    // Optimistically update the property used in the slide-out
    setCurrentProperty(newProperty);
  };

  const fetchUserProperties = async () => {
    setHasError(false);

    try {
      const result = await UserProperty.list({ include_hidden: includeHidden.toString() });
      setProperties(result);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      setHasError(true);
    }
  };

  useEffect(() => {
    setHasError(true);

    fetchUserProperties();
  }, [includeHidden]);

  return (
    <Page
      disableVerticalContentPadding
      whiteBg={false}
      title="User Properties"
      description="Manage User Properties received from your app to use in conditions and interpolation."
    >
      <CmdSheet open={!!currentProperty} onOpenChange={(value) => !value && setCurrentProperty(null)} modal={true}>
        {currentProperty ? (
          <PropertyDetailDrawer
            property={currentProperty}
            onSave={saveUserProperty}
            close={() => setCurrentProperty(null)}
          />
        ) : null}
      </CmdSheet>

      <CmdDataTable
        searchPlaceholder="Search"
        showPagination
        isLoading={properties == null}
        data={properties || []}
        columns={propertyColumns}
        state={{
          rowSelection: {
            [currentProperty?.id || '']: true,
          },
        }}
        getRowId={(row) => row.id}
        rowStyle={{
          height: '48px',
        }}
        toolBarChildren={
          <div>
            <CmdSwitch
              checked={includeHidden}
              onCheckedChange={() => setIncludeHidden((hidden) => !hidden)}
              onLabel="Show hidden properties"
              offLabel="Show hidden properties"
            />
          </div>
        }
        emptyResultChildren={
          hasError ? (
            <CmdTableError onClick={fetchUserProperties} />
          ) : (
            <CmdTableNoResultCta onClick={fetchUserProperties}>
              <CmdButton href="https://www.commandbar.com/docs/platform/data-types/user-properties/" target="_blank">
                Start sending user properties
              </CmdButton>
            </CmdTableNoResultCta>
          )
        }
      />
    </Page>
  );
};

export default PropertiesPage;
