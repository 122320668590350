/** @jsx jsx  */
import { jsx } from '@emotion/core';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { useTheme } from 'emotion-theming';
import ReactDOM from 'react-dom';
import { ITheme } from '@commandbar/internal/client/theme';
import type { INudgeType } from '@commandbar/internal/middleware/types';
import { useStore } from 'shared/util/hooks/useStore';
import { getNudgeStep } from '@commandbar/internal/util/nudges';
import StyledNudgeBannerTitleBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeBannerTitleBlock';
import StyledNudgeBannerSpacer from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeBannerSpacer';
import StyledNudgeBanner from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeBanner';
import StyledNudgeBannerBody from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeBannerBody';
import StyledNudgeBannerCloseButtonContainer from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeBannerCloseButtonContainer';
import ContentContainer from '../ContentContainer';
import { DraftFooter } from '@commandbar/commandbar/shared/components/admin-facing/DraftFooter';
import { getNudgeService, isNudgeDismissible, isNudgeSnoozable } from '../../store/selectors';
import StyledNudgeCloseButton from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeCloseButton';
import { useThemeV2Context, BindThemeV2ForNudge } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { useAction } from '@commandbar/commandbar/shared/util/hooks/useAction';
import { dismissNudge } from '../../store/actions';
import { useLinkClickHandler } from '../../hooks/useLinkClickHandler';
import { useMobileExperience } from '@commandbar/commandbar/shared/util/hooks/useMobileExperience';
import Z from '@commandbar/internal/client/Z';
import { RenderMode } from '../RenderNudge';
import CloseButton from '../CloseButton';
import { isStudioPreview } from '@commandbar/internal/util/location';
import useInjectPortalRoot from './useInjectPortalRoot';
import { Actions } from '../Actions';
import { SurveyResponseProvider } from '../SurveyResponseProvider';

const getStyles = (theme: ITheme): Record<string, CSSProperties> => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: theme.nudges.background,
    color: theme.nudges.color,
    zIndex: Z.Z_COMMANDBAR - 2,
    boxShadow: theme.nudges.boxShadow,
    fontFamily: theme.nudges.fontFamily,
    transition: 'opacity 0.15s ease-in-out',
    pointerEvents: 'all',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    padding: `12px 12px ${theme.nudges.gap}`,
  },
  title: {
    fontFamily: theme.nudges.titleFontFamily,
    fontSize: theme.nudges.titleFontSize,
    fontWeight: theme.nudges.titleFontWeight,
    lineHeight: theme.nudges.titleLineHeight,
    color: theme.nudges.titleColor,
    zIndex: Z.Z_COMMANDBAR - 1,
    cursor: 'auto',
  },
  content: {
    fontFamily: theme.nudges.contentFontFamily,
    color: theme.nudges.contentColor,
    fontSize: theme.nudges.contentFontSize,
    fontWeight: theme.nudges.contentFontWeight,
    lineHeight: theme.nudges.contentLineHeight,
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.nudges.gap,
    alignItems: 'center',
  },
  ctaButton: {
    fontFamily: theme.nudges.ctaFontFamily,
    padding: theme.nudges.ctaPadding,
    background: theme.nudges.ctaBackground,
    color: theme.nudges.ctaColor,
    border: theme.nudges.ctaBorder,
    boxShadow: theme.nudges.ctaBoxShadow,
    textShadow: theme.nudges.ctaTextShadow,
    borderRadius: theme.nudges.ctaBorderRadius,
    fontSize: theme.nudges.ctaFontSize,
    lineHeight: theme.nudges.ctaLineHeight,
    fontWeight: theme.nudges.ctaFontWeight,
  },
  ctaSecondaryButton: {
    fontFamily: theme.nudges.ctaSecondaryFontFamily,
    padding: theme.nudges.ctaSecondaryPadding,
    background: theme.nudges.ctaSecondaryBackground,
    color: theme.nudges.ctaSecondaryColor,
    border: theme.nudges.ctaSecondaryBorder,
    boxShadow: theme.nudges.ctaSecondaryBoxShadow,
    textShadow: theme.nudges.ctaSecondaryTextShadow,
    borderRadius: theme.nudges.ctaSecondaryBorderRadius,
    fontSize: theme.nudges.ctaSecondaryFontSize,
    lineHeight: theme.nudges.ctaSecondaryLineHeight,
    fontWeight: theme.nudges.ctaSecondaryFontWeight,
  },
  snoozeButton: {
    background: theme.nudges.snoozeButtonBackground,
    fontFamily: theme.nudges.snoozeButtonFontFamily,
    color: theme.nudges.snoozeButtonColor,
    border: theme.nudges.snoozeButtonBorder,
    textShadow: theme.nudges.snoozeButtonTextShadow,
    fontSize: theme.nudges.snoozeButtonFontSize,
    lineHeight: theme.nudges.snoozeButtonLineHeight,
    fontWeight: theme.nudges.snoozeButtonFontWeight,
    width: theme.nudges.snoozeButtonWidth,
  },
  closeButtonOverrides: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  stepCount: {
    fontFamily: theme.nudges.stepCountFontFamily,
    color: theme.nudges.stepCountColor,
    fontSize: theme.nudges.stepCountFontSize,
    fontWeight: theme.nudges.stepCountFontWeight,
  },
});

type BannerProps = {
  renderMode: RenderMode;
  nudge: INudgeType;
  stepIndex: number;
  stepCount?: string;
  preventRepositionOnEditorOpen?: boolean;
};

const BannerComponent = ({ nudge, stepIndex, renderMode }: BannerProps) => {
  const _ = useStore();
  const step = getNudgeStep(nudge, stepIndex);
  const { theme }: { theme: ITheme } = useTheme();
  const themeV2 = useThemeV2Context();
  const { isStudioMobilePreview, studioMobilePreviewWrapper, mobileStyles, isMobileDevice } = useMobileExperience();
  const getService = (id: INudgeType['id']) => getNudgeService(_, id);
  const dismiss = useAction(dismissNudge);
  const handleLinkClick = useLinkClickHandler(() => dismiss(nudge, renderMode));
  const service = renderMode !== RenderMode.MOCK ? getService(nudge.id) : undefined;
  const styles = getStyles(theme);

  const { portalRootElement } = useInjectPortalRoot({ nudge, stepIndex, renderMode });

  const position = step?.form_factor.type === 'banner' && step?.form_factor?.position;
  const placement = step?.form_factor.type === 'banner' && step.form_factor?.placement;
  const bannerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isStudioPreview() && placement !== 'overlay') {
      const updateHeight = () => {
        if (bannerRef.current && portalRootElement) {
          portalRootElement.style.height = `${bannerRef.current.offsetHeight}px`;
        }
      };

      updateHeight();

      const observer = new ResizeObserver(updateHeight);
      if (bannerRef.current) {
        observer.observe(bannerRef.current);
      }

      return () => {
        observer.disconnect();
      };
    }
  }, [bannerRef, portalRootElement, renderMode]);

  const content = () => {
    if (step)
      return (
        <SurveyResponseProvider service={service} step={step}>
          {_.flags?.['release-themes-v2'] ? (
            <StyledNudgeBanner
              ref={bannerRef}
              data-testid={`commandbar-banner-${nudge.id}-${String(step?.id)}${
                renderMode === RenderMode.MOCK ? '-mock' : ''
              }`}
              style={
                isMobileDevice
                  ? {
                      ...mobileStyles.nudges.banner.container,
                      [position === 'top' ? 'top' : 'bottom']: 0,
                    }
                  : {
                      position: 'absolute',
                      top: position === 'top' ? 0 : undefined,
                      bottom: position === 'bottom' && placement === 'sticky' ? 0 : undefined,
                      ...(isStudioPreview() && {
                        top: '50%',
                        bottom: 'auto',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                      }),
                    }
              }
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {isNudgeDismissible(nudge) && (
                  <StyledNudgeBannerSpacer
                    style={isMobileDevice ? mobileStyles.nudges.banner.spaceContainer : {}}
                  ></StyledNudgeBannerSpacer>
                )}
                <StyledNudgeBannerBody style={isMobileDevice ? mobileStyles.nudges.banner.body : {}}>
                  <StyledNudgeBannerTitleBlock
                    title={step?.title}
                    content={step?.content
                      .map((block) => (block.type === 'markdown' && block.meta.value ? block.meta.value : undefined))
                      .filter((block) => !!block)
                      .pop()}
                    handleContentLinkClick={handleLinkClick}
                    style={isMobileDevice ? mobileStyles.nudges.banner.title : {}}
                  />

                  <ContentContainer
                    step={step}
                    stepIndex={stepIndex}
                    service={service}
                    markdownStyles={styles.content}
                    primaryButtonStyles={styles.ctaButton}
                    secondaryButtonStyles={styles.ctaSecondaryButton}
                    snoozeButtonStyles={styles.snoozeButton}
                    stepCountStyles={styles.stepCount}
                    stepCount={undefined}
                    renderMode={renderMode}
                    snoozable={isNudgeSnoozable(nudge)}
                    snoozeLabel={nudge.snooze_label}
                    handleContentLinkClick={handleLinkClick}
                    snoozable_on_all_steps={nudge.snoozable_on_all_steps}
                  />

                  <Actions
                    step={step}
                    stepCount={undefined}
                    stepIndex={stepIndex}
                    renderMode={renderMode}
                    snooze={{
                      enabled: isNudgeSnoozable(nudge),
                      label: nudge.snooze_label,
                      enabledOnAllSteps: nudge.snoozable_on_all_steps,
                      duration: nudge.snooze_duration,
                    }}
                    service={service}
                  />
                </StyledNudgeBannerBody>

                {isNudgeDismissible(nudge) && (
                  <StyledNudgeBannerCloseButtonContainer
                    style={isMobileDevice ? mobileStyles.nudges.banner.closeButtonContainer : {}}
                  >
                    <StyledNudgeCloseButton themeV2={themeV2} onClick={() => dismiss(nudge, renderMode)} />
                  </StyledNudgeBannerCloseButtonContainer>
                )}
              </div>
              {!nudge.is_live && renderMode === RenderMode.MOCK && (
                <DraftFooter details={{ type: 'nudge', nudge: nudge, stepIndex }} />
              )}
            </StyledNudgeBanner>
          ) : (
            <div
              data-testid={`commandbar-banner-${nudge.id}-${String(step?.id)}${
                renderMode === RenderMode.MOCK ? '-mock' : ''
              }`}
              key={step?.id}
              aria-labelledby="commandbar-nudge-title"
              style={{
                ...styles.container,
                position: 'absolute',
                top: position === 'top' ? 0 : undefined,
                bottom: position === 'bottom' && placement === 'sticky' ? 0 : undefined,
                ...(isStudioPreview() &&
                  !isMobileDevice && {
                    top: '50%',
                    bottom: 'auto',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                  }),
                ...(isMobileDevice && {
                  ...mobileStyles.nudges.banner.container,
                  [position === 'top' ? 'top' : 'bottom']: '10px',
                  flexDirection: 'column',
                  width: '100%',
                }),
              }}
              ref={bannerRef}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  padding: theme.nudges.gap,
                  justifyContent: 'space-between',
                  ...(isMobileDevice && {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }),
                }}
              >
                {isNudgeDismissible(nudge) && !isMobileDevice && <StyledNudgeBannerSpacer />}
                <div
                  style={{
                    ...styles.body,
                    gap: theme.nudges.gap,
                    ...(isMobileDevice && {
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      marginRight: '24px',
                    }),
                  }}
                >
                  <span id="commandbar-nudge-title" style={styles.title} role="heading" aria-level={3}>
                    {step?.title}
                  </span>
                  <ContentContainer
                    step={step}
                    stepIndex={stepIndex}
                    service={service}
                    markdownStyles={styles.content}
                    primaryButtonStyles={styles.ctaButton}
                    secondaryButtonStyles={styles.ctaSecondaryButton}
                    snoozeButtonStyles={styles.snoozeButton}
                    stepCountStyles={styles.stepCount}
                    stepCount={undefined}
                    renderMode={renderMode}
                    snoozable={isNudgeSnoozable(nudge)}
                    snoozeLabel={nudge.snooze_label}
                    handleContentLinkClick={handleLinkClick}
                    snoozable_on_all_steps={nudge.snoozable_on_all_steps}
                  />
                </div>
                {isNudgeDismissible(nudge) && (
                  <div style={{ padding: '12px', ...(isMobileDevice && { position: 'absolute', top: 0, right: 0 }) }}>
                    <CloseButton onInteraction={() => dismiss(nudge, renderMode)} />
                  </div>
                )}
              </div>
              {!nudge.is_live && renderMode === RenderMode.MOCK && (
                <DraftFooter details={{ type: 'nudge', nudge: nudge, stepIndex }} />
              )}
            </div>
          )}
        </SurveyResponseProvider>
      );

    return null;
  };

  if (isStudioMobilePreview && studioMobilePreviewWrapper) {
    return ReactDOM.createPortal(content(), studioMobilePreviewWrapper);
  }

  if (step) {
    return content();
  }
  return null;
};

const Banner = (props: BannerProps) => (
  <BindThemeV2ForNudge nudge={props.nudge}>
    <BannerComponent {...props} />
  </BindThemeV2ForNudge>
);

export default Banner;
