import { arrow, autoUpdate, computePosition, hide, offset } from '@floating-ui/dom';
import { createActorContext } from '@xstate/react';
import React, { type ReactNode, type RefObject, useRef } from 'react';
import { type StateFrom, assign, createMachine } from 'xstate';

import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import type { ElementInformation, INudgeTooltipStepType } from '@commandbar/internal/middleware/types';
import { getElement } from '@commandbar/internal/util/dom';
import { fastIsElementVisible as isElementVisible } from '@commandbar/internal/util/dom';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { calcOffsetFromInput } from '@commandbar/internal/util/nudges';
import { RenderMode } from '../RenderNudge';
import { TestID } from './constants';

const checkForTargetRemoval = (targetElement: Element, mutationList: MutationRecord[], onRemoval: () => void) => {
  for (const mutation of mutationList) {
    switch (mutation.type) {
      case 'childList': {
        for (const removedNode of mutation.removedNodes) {
          if (removedNode === targetElement || removedNode.contains(targetElement)) {
            onRemoval();
          }
        }
        break;
      }
      case 'attributes': {
        if (!isElementVisible(targetElement)) {
          onRemoval();
        }
        break;
      }
      default:
        break;
    }
  }
};

export type TooltipEvents =
  | { type: 'TARGET_ELEMENT_FOUND'; targetElement: Element }
  | { type: 'TARGET_ELEMENT_REMOVED' }
  | { type: 'SHOW' }
  | { type: 'HIDE' }
  | { type: 'POSITION'; position: { x: number; y: number } }
  | { type: 'ASSIGN_WRAPPER'; inlineWrapperElement: HTMLDivElement }
  | { type: 'DESTROY' };

interface TooltipContext {
  marker: {
    ref: RefObject<HTMLButtonElement>;
    position: {
      x: number;
      y: number;
    } | null;
    offset: INudgeTooltipStepType['form_factor']['marker']['positioning']['offset'];
    alignment: INudgeTooltipStepType['form_factor']['marker']['positioning']['position'];
  };
  renderMode: RenderMode;
  anchor: string | ElementInformation;
  inlineWrapperElement?: HTMLDivElement;
}

const tooltipMachine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QBUD2qA2AXAlgBwGIARAUQGVkAlAeQE0BtABgF1FQ9VYddUA7NkAA9EARgBMYgOwA6AGwBmBbICsYgCwAOZSMkAaEAE9EAWjUTpATkaMJIjfKkORAX2f60mXHmk4IGMARMrEggHFw8-CHCCGqy0moiypIadozyjLIiahb6Rgh28tIZDiqqjBpiGuWu7ujY+D5+AfQiweyc3Dh8AtFmccryVpKDsWIWA-K5iGJ20vIiDpJJqiJZjJI1IB713gCGvADGABaoAE44vFAEAArUZACSyPfUAHJBAmGd3VGIGpJi0mUFjU8iqqmU2T0hhMOmkkjUEIkslkFhU62qbi2dS80n2xzOFyuyAAgpQAOIkZAAfRIABkSABZEgvamURnUABqJCI7xCnwiPUQyI0gLEynKY3FAxEsimCGM42kGhRGhBFnEBUqm22OLxJ3Ol2ksBOAHdeAQABL3Ui89rhLqRUDRcSxIricqgjQWMSyLRy4xe+IKSSyMzjMTyCHKbXYhp6gmGo6+CBgc1kC3UADqttCHQFP3yIkYFjmY0kSyyQOUyv9iThS3VCSq8hDNhjngaFwwF0JjX8BGJZAeZJeVMzlGJ12uJEoOf5DsFhak0gWC1kGSBamKcrUkhEc2R8l383XFnVG0xOoaJt2nUuAAIAGZne9YXanGBYe9gfwAW1TWAEAAYtQACqLxEFSJLkpSNL0kyLJznmC4FkWSRFKoW4aCkGTIlCeQzHExSVF6shiIwqiqu2OzSDed5QE+L5vh+YBfj+YD-rwgEgeBkHQRS1J0oyzLIC0bS5va3xOqI6IYeoPrJEeKJiHKGpFOUGT2HYCRmBetQdt4XY9pcBD8bBQkIay7JcjyLAfMhUlCEKvqiuKkiotYjAIju2hFN6FiDKoLa4a4mK8KgKbwCEV54PZkmOk58r7tWZh2CIqIBVo5T+vM+6xC2CjKgoyhudROK+P4cVfAlzrenIKjJAsijjN6aiqTK0jqKMMqMOl6iKGVDQHABYCnJAVX5tJ+TKHE9gtmoC0Bas4waHK8yFD6wItloYyonpWIGbihz6oSE0oVNRaZKWFjltoCIrbK0LyhIhSoi2wyhoMQyDXsx0JlARqmgl86Oc66j7go8wQgswJFipT3GHWt2NqkLZAhkP1HfiBoA0mEApsDDk1aIRYlhYFSrJIaRbrI7n+oG+UhmGQVRpjRm8KdfJE4uqJxAk4jueusQQpMT3yIoSqHlkyKxFRl6xoZvDdhzhoVWAZ2gzJWTxKsUgecLII7sCcxhlDKTiJk0by4d7O9uz41c-Fi4LBCnV7iiu6ZEeaRG4UxZljd5b2GesiY3RuAPs+pyvu+n7fn+AEa8TCDrjIWgOGM4gVMkORPQURQKGWqwJCLYihc4QA */
    id: 'Tooltip',
    initial: 'idle',
    states: {
      idle: {
        always: [
          {
            target: 'centered',
            cond: 'shouldCenter',
            description:
              'This is the case when we are editing a step in the studio or with the extension and the target element is unavailable.',
          },
          'waiting for target element',
        ],
      },

      centered: {
        type: 'final',
      },

      anchoring: {
        initial: 'hidden',

        invoke: [
          {
            src: 'trackTargetElement',
            id: 'trackTargetElement',
          },
        ],

        states: {
          shown: {
            on: {
              HIDE: {
                target: '#Tooltip.anchoring.hidden',
              },
            },
          },

          hidden: {
            on: {
              SHOW: 'shown',
            },
          },
        },

        on: {
          POSITION: {
            actions: ['updatePosition'],
          },

          TARGET_ELEMENT_REMOVED: 'waiting for target element',
        },
      },

      inlining: {
        invoke: {
          src: 'wrapTargetElement',
          id: 'wrapTargetElement',
        },

        states: {
          idle: {
            on: {
              ASSIGN_WRAPPER: {
                target: 'inlined',
                actions: ['assignInlineWrapper'],
              },
            },
          },

          inlined: {
            type: 'final',
          },
        },

        initial: 'idle',

        on: {
          TARGET_ELEMENT_REMOVED: 'waiting for target element',
        },
      },

      'waiting for target element': {
        on: {
          TARGET_ELEMENT_FOUND: [
            {
              target: 'inlining',
              cond: 'isInlineAligned',
            },
            'anchoring',
          ],
        },

        invoke: {
          src: 'waitForTargetElement',
          id: 'waitForTargetElement',
        },
      },
    },
    on: {
      DESTROY: {
        actions: ['removeTooltip'],
      },
    },
    schema: {
      events: {} as TooltipEvents,
      context: {} as TooltipContext,
    },
    predictableActionArguments: true,
    tsTypes: {} as import('./state.typegen').Typegen0,
  },
  {
    actions: {
      updatePosition: assign({
        marker: ({ marker }, { position }) => ({
          ...marker,
          position,
        }),
      }),
      assignInlineWrapper: assign({
        inlineWrapperElement: (_, { inlineWrapperElement }) => inlineWrapperElement,
      }),
    },
  },
);

enum States {
  IS_IDLE = 'isIdle',
  IS_MARKER_ANCHORED = 'isMarkerAnchored',
  IS_MARKER_INLINED = 'isMarkerInlined',
  IS_MARKER_CENTERED = 'isMarkerCentered',
  IS_MARKER_POSITIONED = 'isMarkerPositioned',
}

export const stateSelectors: Record<States, (state: StateFrom<typeof tooltipMachine>) => boolean> = {
  isIdle: ({ matches }) => matches('idle'),
  isMarkerAnchored: ({ matches }) => matches('anchoring.shown'),
  isMarkerInlined: ({ matches, context }) => matches('inlining.inlined') && !!context.inlineWrapperElement,
  isMarkerCentered: ({ matches }) => matches('centered'),
  isMarkerPositioned: ({ matches }) => matches('anchoring.shown') || matches('centered'),
};

export const TooltipMachineContext = createActorContext(tooltipMachine);

interface TooltipMachineContextProviderProps {
  id: string;
  children: ReactNode;
  renderMode: RenderMode;
  offset: INudgeTooltipStepType['form_factor']['marker']['positioning']['offset'];
  alignment: INudgeTooltipStepType['form_factor']['marker']['positioning']['position'];
  anchor: string | ElementInformation;
  handleDestroy?: () => void;
}

export const TooltipMachineContextProvider = ({
  id,
  children,
  renderMode,
  offset: _offset,
  alignment,
  anchor,
  handleDestroy,
}: TooltipMachineContextProviderProps) => {
  const { showWidgetTableau, messageBus } = useStore();
  const markerRef = useRef<HTMLButtonElement>(null);

  return (
    <TooltipMachineContext.Provider
      options={{
        guards: {
          shouldCenter: ({ anchor }) =>
            (!showWidgetTableau && isStudioPreview()) || (renderMode === RenderMode.MOCK && !getElement(anchor)),
          isInlineAligned: () => alignment === 'inline_left' || alignment === 'inline_right',
        },
        context: {
          marker: {
            ref: markerRef,
            position: null,
            offset: _offset,
            alignment,
          },
          renderMode,
          anchor,
        },
        services: {
          waitForTargetElement:
            ({ anchor }) =>
            (sendBack) => {
              const targetElement = getElement(anchor);
              if (isElementVisible(targetElement)) {
                return sendBack({ type: 'TARGET_ELEMENT_FOUND', targetElement });
              }

              const handleMutation = () => {
                const targetElement = getElement(anchor);
                if (isElementVisible(targetElement)) {
                  sendBack({ type: 'TARGET_ELEMENT_FOUND', targetElement });
                }
              };

              messageBus.subscribe('dom_mutation', handleMutation, `tooltip_wait_for_target_${id}`);

              return () => {
                messageBus.unsubscribe('dom_mutation', `tooltip_wait_for_target_${id}`);
              };
            },
          trackTargetElement:
            ({ marker }, { targetElement }) =>
            (sendBack) => {
              const markerElement = marker.ref.current;
              if (!markerElement) {
                return;
              }

              const { offset: _offset, alignment } = marker;

              const arrowEl = document.querySelector('.commandbar-nudge-arrow') as HTMLElement | null;

              const updatePosition = () => {
                computePosition(targetElement, markerElement, {
                  placement: alignment === 'left' ? 'left' : 'right',
                  strategy: 'fixed',
                  middleware: [
                    offset({
                      mainAxis: calcOffsetFromInput(_offset.x),
                      crossAxis: calcOffsetFromInput(_offset.y),
                    }),
                    hide({
                      strategy: 'escaped',
                      padding: {
                        top: calcOffsetFromInput(_offset?.y ?? '0'),
                        bottom: -calcOffsetFromInput(_offset?.y ?? '0'),
                        ...(alignment === 'left'
                          ? {
                              right: calcOffsetFromInput(_offset?.x ?? '0'),
                              left: -calcOffsetFromInput(_offset?.x ?? '0'),
                            }
                          : {
                              right: -calcOffsetFromInput(_offset?.x ?? '0'),
                              left: calcOffsetFromInput(_offset?.x ?? '0'),
                            }),
                      },
                    }),
                    ...(arrowEl ? [arrow({ element: arrowEl })] : []), // Conditionally include the arrow middleware
                  ],
                }).then(({ y, x, middlewareData }) => {
                  sendBack({ type: 'POSITION', position: { x, y } });
                  sendBack(middlewareData.hide?.escaped ? 'HIDE' : 'SHOW');

                  // Handle arrow-specific logic if `arrowEl` exists and middlewareData.arrow is present
                  if (arrowEl && middlewareData.arrow) {
                    const escapedOffsets: any = middlewareData.hide?.escapedOffsets;
                    const placement = escapedOffsets.top < escapedOffsets.bottom ? 'bottom' : 'top';

                    Object.assign(arrowEl.style, {
                      top: placement === 'top' ? ' -8px' : 'unset',
                      bottom: placement === 'bottom' ? ' -8px' : 'unset',
                    });
                  }
                });
              };
              const cleanupMarker = autoUpdate(targetElement, markerElement, updatePosition);

              messageBus.subscribe(
                'dom_mutation',
                ({ mutationList }) =>
                  checkForTargetRemoval(targetElement, mutationList, () =>
                    sendBack({ type: 'TARGET_ELEMENT_REMOVED' }),
                  ),
                `tooltip_check_for_target_removal_${id}`,
              );

              return () => {
                cleanupMarker();
                messageBus.unsubscribe('dom_mutation', `tooltip_check_for_target_removal_${id}`);
              };
            },
          wrapTargetElement:
            ({ marker }, { targetElement }) =>
            (sendBack) => {
              const inlineWrapperElement = document.createElement('div');
              inlineWrapperElement.setAttribute('data-testid', TestID.Wrapper);
              inlineWrapperElement.style.position = 'relative';
              inlineWrapperElement.style.display = 'inline-flex';
              inlineWrapperElement.style.alignItems = 'baseline';
              inlineWrapperElement.style.width = 'fit-content';
              inlineWrapperElement.style.flexDirection = marker.alignment === 'inline_right' ? 'row' : 'row-reverse';

              targetElement.parentNode?.insertBefore(inlineWrapperElement, targetElement);
              targetElement.parentNode?.removeChild(targetElement);
              inlineWrapperElement.appendChild(targetElement);

              sendBack({ type: 'ASSIGN_WRAPPER', inlineWrapperElement });

              messageBus.subscribe(
                'dom_mutation',
                ({ mutationList }) =>
                  checkForTargetRemoval(targetElement, mutationList, () =>
                    sendBack({ type: 'TARGET_ELEMENT_REMOVED' }),
                  ),
                `tooltip_check_for_target_removal_wrap_${id}`,
              );

              return () => {
                const parentOfWrapper = inlineWrapperElement.parentNode;
                if (document.contains(parentOfWrapper)) {
                  parentOfWrapper?.insertBefore(targetElement, inlineWrapperElement);
                  parentOfWrapper?.removeChild(inlineWrapperElement);
                }
                messageBus.unsubscribe('dom_mutation', `tooltip_check_for_target_removal_wrap_${id}`);
              };
            },
        },
        actions: {
          removeTooltip: handleDestroy,
        },
      }}
    >
      {children}
    </TooltipMachineContext.Provider>
  );
};
