import React from 'react';

import { LoadingLogoAnimation } from '@commandbar/internal/client/LoadingLogo';

import './style/App.css';
import 'antd/dist/antd.less';

import Router from './Router';

import { Spin } from '@commandbar/design-system/components/antd';
import { AuthProvider } from '@commandbar/internal/hooks/useAuth';
import { IUserType } from '@commandbar/internal/middleware/types';
import { useIdentifyUser } from './hooks/useEventReporting';
import { H } from 'highlight.run';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router';

declare global {
  interface Window {
    intercomSettings: {
      app_id: 'g88tzfur' | string;
      user_id?: string;
      email?: string;
      user_hash?: string;
      hide_default_launcher?: boolean;
    };
  }
}

export const Loading = () => (
  <div
    style={{
      minHeight: '100vh',
      fontFamily: 'Avenir Next',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spin indicator={<LoadingLogoAnimation />} />
  </div>
);

const App: React.FC = () => {
  const history = useHistory();
  const { identifyUser } = useIdentifyUser();

  const updateTracking = (user: IUserType | null) => {
    // clear Org ID from Chrome Extension
    // (window as any).chrome?.runtime?.sendMessage(COMMANDBAR_CHROME_EXTENSION_ID, {
    //   message: 'SET_DEFAULT_ORGANIZATION_ID',
    //   defaultOrganizationId: '',
    // });

    if (!!user) {
      if (!user?.email.includes('@commandbar.com')) {
        H.init('memo99d2', { recordCrossOriginIframe: true });
        identifyUser(user, {
          segment: true,
          highlight: true,
        });
        H.getSessionURL().then((sessionUrl) => {
          Sentry.setContext('highlight', {
            url: sessionUrl,
          });
        });
      }

      // Pass information to Intercom
      window.intercomSettings = {
        app_id: 'g88tzfur',
        user_id: user?.email,
        email: user?.email,
        user_hash: user?.intercom_hash,
        hide_default_launcher: true,
      };
      window.Intercom('boot');
    }
  };

  const onUserChange = (user: IUserType | null) => {
    updateTracking(user);
  };

  const onLogout = () => {
    window.CommandBar.shutdown();

    updateTracking(null);

    history.push('/login');
  };

  return (
    <AuthProvider loadingElement={<Loading />} onUserChange={onUserChange} onLogout={onLogout}>
      <Router />
    </AuthProvider>
  );
};

export default App;
