import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { CBStore } from 'shared/store/global-store';

import { runBooleanExpression } from 'shared/services/targeting/helpers';
import { findMostSpecificRecommendationSet } from './helpers';
import { actionIsAvailable } from 'shared/store/executables/executable-selectors';
import { passesAudienceConditions } from 'shared/services/targeting/audience';
import type { LabeledAction } from '@cb/types/entities/command/actions';
import type { IRecommendationSet } from '@commandbar/internal/middleware/types';

export const isCopilotOnlyMode = (_: CBStore): boolean => {
  // Tmp: Used by helphub.commandbar.com to showcase chatonly mode
  if (!!LocalStorage.get('chatOnlyMode', false)) {
    return true;
  }

  // FIXME: Used in the editor to open the chat directly from the Copilot tab.
  // We should find a more permanent solution for this once we figure out launchers.
  if (_.helpHub.chatOnly) {
    return true;
  }

  return Boolean(!_.showWidgetTableau && _?.organization?.copilot_enabled && !_?.organization?.helphub_enabled);
};

export const isHelpHubWidgetAvailable = (_: CBStore): boolean => {
  return !!(_.organization?.helphub_enabled || _.organization?.copilot_enabled) && _.products.includes('help_hub');
};

export const getHelpHubRecommendations = async (_: CBStore): Promise<LabeledAction[]> => {
  if (_.helpHub.previewedRecommendationSet) {
    const recommendationSet = _.helpHub.previewedRecommendationSet;

    if (recommendationSet) {
      const actionsAvailability = await Promise.all(
        recommendationSet.actions.map((action) => actionIsAvailable(_, action.action)),
      );
      return recommendationSet.actions.filter((_, index) => actionsAvailability[index]);
    }
  }

  const passesPageConditions = ({ show_expression }: IRecommendationSet) =>
    !show_expression || runBooleanExpression(show_expression, _, '').passed;

  const validRecommendationSets = _.helpHub.recommendationSets?.filter(
    (set) => set.is_live && passesAudienceConditions(_, 'recommendation_sets', set) && passesPageConditions(set),
  );

  if (validRecommendationSets?.length) {
    const mostSpecificRecommendationSet = findMostSpecificRecommendationSet(validRecommendationSets);
    if (mostSpecificRecommendationSet) {
      const actionsAvailability = await Promise.all(
        mostSpecificRecommendationSet.actions.map((action) => actionIsAvailable(_, action.action)),
      );

      const availableActions = mostSpecificRecommendationSet.actions.filter((_, index) => actionsAvailability[index]);

      return availableActions;
    }
  }

  return [];
};
