/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import type { IThemeV2Type } from '@cb/types/entities/theme';
import { XClose } from '@commandbar/design-system/icons/react';
import StyledTertiaryIconButton from '../shared/StyledTertiaryIconButton';

type Props = {
  themeV2: { themeV2: IThemeV2Type; mode: 'light_mode' | 'dark_mode' };
};

const StyledNudgeCloseButton = ({ ...props }: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <StyledTertiaryIconButton data-testid="nudge-step-close-button" {...props}>
      <XClose />
    </StyledTertiaryIconButton>
  );
};

export default StyledNudgeCloseButton;
