import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { routerGetUserConfirmation } from 'editor/src/InAppWidget';

// needed in order to use locale-specific format strings e.g. "lll"
// https://day.js.org/docs/en/display/format#list-of-localized-formats
dayjs.extend(localizedFormat);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const container = document.getElementById('root')!;

const envsToReportSentry = ['dev', 'labs', 'prod'];
const isProd = process.env.NODE_ENV === 'production';
const environment = process.env?.REACT_APP_BUILD_TARGET || 'local';
const dsn = 'https://425de8e19724c6885dcb23f4dbe0f6f6@o13027.ingest.us.sentry.io/4508255161286656';
const release = process.env?.SENTRY_RELEASE?.trim() || 'local';
const shouldInitSentry = isProd && envsToReportSentry.includes(environment);

Sentry.init({
  dsn: shouldInitSentry ? dsn : '',
  environment,
  release,
  attachStacktrace: true,
  autoSessionTracking: true,
  beforeSend: (event) => {
    if (!isProd) {
      return null;
    }
    return event;
  },
  debug: !isProd,
  tracesSampleRate: 0.05,
  maxBreadcrumbs: 300,
  ignoreErrors: ['csp', 'script-src-elem'],
});

const Site = () => {
  return <App />;
};

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={<p>Something went wrong. Please reach out to support@command.ai if this persists.</p>}
  >
    <React.StrictMode>
      <BrowserRouter getUserConfirmation={routerGetUserConfirmation}>
        <Site />
      </BrowserRouter>
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
