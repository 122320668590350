import { useState } from 'react';
import { formatPercentage, formatRelativeTime } from '../../utils';

import { Link } from 'react-router-dom';
import { CmdSortableColumn, CmdColumnDef, CmdSwitch, CmdTooltip } from '@commandbar/design-system/cmd';
import Layout from '../../components/Layout';
import StatCard from '../../components/StatCard';
import ActionDropdown from '../../components/ActionDropdown';
import Table from '../../components/Table';
import { StatusBadge } from '@commandbar/design-system/components/antd';

import dayjs from 'dayjs';
import useAnalyticProductTour from './useAnalyticProductTour';

import { PencilLine, Plus } from '@commandbar/design-system/icons/react';
import * as editorRoutes from '@commandbar/internal/proxy-editor/editor_routes';
import * as nudgeSearchParams from '@commandbar/internal/proxy-editor/nudge_search_params';
import { AnalyticsPage, getAnalyticsDashboardRoute } from 'commandbar.com/src/components/Home';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';

const AnalyticsProductToursDashboard = () => {
  const [showUnpublished, setShowUnpublished] = useState<boolean>(false);
  const { user } = useAuth();

  const { isLoading, fetchProductData, data, error, downloadCsv, isDownloading } = useAnalyticProductTour({
    showUnpublished,
  });

  const columns: CmdColumnDef<any, string>[] = [
    {
      accessorKey: 'nudge_slug',
      header: 'Tour',
      enableGlobalFilter: true,
      cell: (r) => {
        const nudgeId = r.row.original.nudge_id;
        const isLive = r.row.original.is_live;

        return (
          <Link to={`/analytics/product-tours/${nudgeId}`}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              {showUnpublished && <StatusBadge color={isLive ? 'green' : 'orange'} />}
              {r?.getValue() || 'Tour'}
            </div>
          </Link>
        );
      },
    },
    {
      accessorKey: 'total_sessions',
      header: ({ column }) => <CmdSortableColumn column={column} title="Views" />,
    },
    {
      accessorKey: 'total_steps',
      header: ({ column }) => <CmdSortableColumn column={column} title="Steps" />,
    },
    {
      accessorKey: 'completion_rate',
      header: ({ column }) => <CmdSortableColumn column={column} title="Completion rate" />,
      cell: ({ cell }) => formatPercentage(cell.getValue()),
    },
    {
      accessorKey: 'last_shown',
      cell: ({ cell }) => (
        <CmdTooltip message={dayjs(cell.getValue()).format('MMM DD, YYYY, hh:mmA')}>
          <span>{formatRelativeTime(cell.getValue())}</span>
        </CmdTooltip>
      ),
      header: ({ column }) => <CmdSortableColumn column={column} title="Last seen" />,
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    columns.push({
      accessorKey: 'action',
      header: '',
      width: 40,
      cell: ({ row }) => (
        <ActionDropdown
          options={[
            {
              label: 'Edit',
              icon: <PencilLine />,
              url: `/editor/product-tours/${row.original.nudge_id}`,
            },
          ]}
        />
      ),
    });
  }

  return (
    <Layout title="Tours" disableDemoData={isLoading} isDownloading={isDownloading} onDownloadCSV={downloadCsv}>
      <div
        style={{
          gap: '16px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '16px',
          }}
        >
          <StatCard
            title="Active"
            subtitle="Total live tours"
            format="number"
            metric={data?.active?.metric}
            isLoading={isLoading}
          />
          <StatCard
            title="Views"
            subtitle="Total tours viewed"
            format="number"
            metric={data?.sessions?.metric}
            rate={data?.sessions?.percent_change}
            isLoading={isLoading}
          />
          <StatCard
            title="Completion rate"
            subtitle="Avg completion rate for active tours"
            format="percentage"
            metric={data?.completion_rate?.metric}
            rate={data?.completion_rate?.percent_change}
            isLoading={isLoading}
          />
        </div>
        <div>
          <Table
            columns={columns}
            data={data?.table.rows || []}
            isLoading={isLoading}
            empty={{
              error: !!error,
              refetchData: fetchProductData,
              additionalActions: [
                {
                  label: 'Create a tour',
                  icon: <Plus />,
                  to: `/editor${editorRoutes.PRODUCT_TOURS_ROUTE}/new?${
                    nudgeSearchParams.FROM
                  }=${getAnalyticsDashboardRoute(AnalyticsPage.TOURS)}`,
                },
              ],
            }}
            toolBarChildren={
              <div>
                <CmdSwitch
                  checked={showUnpublished}
                  onCheckedChange={() => setShowUnpublished(!showUnpublished)}
                  onLabel="Show unpublished, but previously viewed Tours"
                  offLabel="Show unpublished, but previously viewed Tours"
                  tooltip="Tours that are (a) unpublished; (b) have at least one view."
                />
              </div>
            }
          />
        </div>
      </div>
    </Layout>
  );
};

export default AnalyticsProductToursDashboard;
