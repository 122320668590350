import React, { type CSSProperties, type MouseEventHandler, forwardRef } from 'react';
import type { ActorRefFrom } from 'xstate';

import { BindThemeV2ForNudge, useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { DraftFooter } from '@commandbar/commandbar/shared/components/admin-facing/DraftFooter';
import { useMobileExperience } from '@commandbar/commandbar/shared/util/hooks/useMobileExperience';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledCloseButtonContainer from '@commandbar/internal/client/themesV2/components/nudge/StyledCloseButtonContainer';
import StyledNudgeCloseButton from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeCloseButton';
import StyledNudgePin from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgePin';
import StyledNudgeStepCounter from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeStepCounter';
import StyledNudgeTitleBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeTitleBlock';
import type { INudgePinStepType, INudgeType } from '@commandbar/internal/middleware/types';
import type NudgeMachine from '../../store/nudgeMachine';
import { getNudgeService, isNudgeDismissible, isNudgeSnoozable } from '../../store/selectors';
import { Actions } from '../Actions';
import Media from '../ContentContainer';
import { LayoutTemplate } from '../LayoutTemplate';
import { RenderMode } from '../RenderNudge';
import { getFirstMarkdownBlock } from '../utils';
import { useAnimatedWidget } from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';

interface LayoutProps {
  nudge: INudgeType;
  step: INudgePinStepType;
  renderMode: RenderMode;
  handleLinkClick: MouseEventHandler<HTMLElement>;
  stepIndex: number;
  dismissNudge: () => void;
  isOpen: boolean;
  styles: Record<string, CSSProperties>;
  service?: ActorRefFrom<typeof NudgeMachine>;
  stepCount?: string;
}

export const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  ({ nudge, step, renderMode, handleLinkClick, stepIndex, stepCount, isOpen, dismissNudge, styles }, ref) => {
    const _ = useStore();
    const themeV2 = useThemeV2Context();
    const nudgeService = renderMode !== RenderMode.MOCK ? getNudgeService(_, nudge.id) : undefined;
    const { isMobileDevice } = useMobileExperience();
    const { animStyles, isAnimatedWidget } = useAnimatedWidget();

    const close = isNudgeDismissible(nudge) ? (
      <StyledCloseButtonContainer>
        <StyledNudgeCloseButton themeV2={themeV2} onClick={dismissNudge} />
      </StyledCloseButtonContainer>
    ) : null;

    const header = (
      <StyledNudgeTitleBlock
        title={step.title}
        content={getFirstMarkdownBlock(step.content)}
        dismissible={isNudgeDismissible(nudge)}
        handleContentLinkClick={handleLinkClick}
      />
    );

    const media = (
      <Media
        step={step}
        stepIndex={stepIndex}
        service={nudgeService}
        markdownStyles={styles.content}
        primaryButtonStyles={styles.ctaButton}
        secondaryButtonStyles={styles.ctaSecondaryButton}
        snoozeButtonStyles={styles.snoozeButton}
        stepCountStyles={styles.stepCount}
        stepCount={stepCount}
        renderMode={renderMode}
        snoozable={isNudgeSnoozable(nudge)}
        snoozeLabel={nudge.snooze_label}
        snoozable_on_all_steps={nudge.snoozable_on_all_steps}
        handleContentLinkClick={handleLinkClick}
      />
    );

    const stepCounter = stepCount ? <StyledNudgeStepCounter stepCount={stepCount} /> : null;

    const actions = (
      <Actions
        step={step}
        renderMode={renderMode}
        snooze={{
          enabled: isNudgeSnoozable(nudge),
          label: nudge.snooze_label,
          enabledOnAllSteps: nudge.snoozable_on_all_steps,
          duration: nudge.snooze_duration,
        }}
        service={nudgeService}
        stepCount={stepCount}
        stepIndex={stepIndex}
      />
    );

    const footer =
      !nudge.is_live && renderMode === RenderMode.MOCK ? (
        <DraftFooter details={{ type: 'nudge', nudge, stepIndex }} />
      ) : null;

    return (
      <BindThemeV2ForNudge nudge={nudge}>
        <StyledNudgePin
          organization={_.organization}
          survey={!!step.content.find((block) => block.type === 'survey_rating')}
          data-testid="commandbar-nudge-pin-content"
          ref={ref}
          style={{
            width: step.form_factor.layout === 'horizontal' && !isMobileDevice ? 'auto' : undefined,
            ...(!isAnimatedWidget && { visibility: isOpen ? 'visible' : 'hidden' }),
            ...animStyles,
          }}
          aria-labelledby="commandbar-nudge-title"
        >
          <LayoutTemplate
            layout={isMobileDevice ? 'classic' : step.form_factor.layout}
            headerAndMarkdown={header}
            close={close}
            media={media}
            footer={footer}
            stepCounter={stepCounter}
            actions={actions}
            columnWidth="var(--popover-width)"
          />
        </StyledNudgePin>
      </BindThemeV2ForNudge>
    );
  },
);
