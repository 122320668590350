import { cmdToast } from '@commandbar/design-system/cmd';
import { URL_CHAT_ID_PARAM, URL_PAGE_NUMBER_PARAM, URL_XRAY_OPEN } from './state';

export const createAnswer = (chatId: string, question?: string) => {
  const currentUrl = new URL(window.location.origin + '/content/answers');

  const urlParams = currentUrl.searchParams;
  urlParams.set(URL_CHAT_ID_PARAM, encodeURIComponent(chatId));
  if (!!question) {
    urlParams.set('question', encodeURIComponent(question));
  }

  const link = currentUrl.toString();

  window.open(link, '_blank');
};

export const copyChatLink = (chatId: string, pageNumber: number, isXrayOpen = false) => {
  const currentUrl = new URL(window.location.href);

  const urlParams = currentUrl.searchParams;
  urlParams.set(URL_CHAT_ID_PARAM, encodeURIComponent(chatId));
  urlParams.set(URL_PAGE_NUMBER_PARAM, encodeURIComponent(pageNumber));

  if (isXrayOpen) {
    urlParams.set(URL_XRAY_OPEN, 'true');
  }

  const link = currentUrl.toString();

  try {
    navigator.clipboard.writeText(link);
  } catch (err) {
    console.error('Failed to copy text to clipboard:', err);
  }

  cmdToast.success('Copied to clipboard!');
};
