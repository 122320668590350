// generate a container that looks like a mobile wireframe
// and renders the mobile preview in it

import {
  EDITOR_MOBILE_PREVIEW_SCREEN_HEIGHT,
  EDITOR_MOBILE_PREVIEW_SCREEN_WIDTH,
} from '@commandbar/internal/util/operatingSystem';
import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';

const Container = styled.div`
  position: relative;
  z-index: 50;

  @media screen and (max-height: 760px) {
    transform: scale(0.8);
  }
`;

const Screen = styled.div`
  width: ${`${EDITOR_MOBILE_PREVIEW_SCREEN_WIDTH}px`};
  height: ${`${EDITOR_MOBILE_PREVIEW_SCREEN_HEIGHT}px`};
  position: relative;
  border-radius: 56px;
  box-shadow: 0px 0px 0px 7.5px #7d7e7d;
  border: 8px solid #000;
  background: linear-gradient(165deg, white, #ebf0ff 45%, #ebf0ff 55%, white);
  outline: 6px solid #2c2c2b;
  padding-top: 60px;
  padding-bottom: 34px;
  overflow: hidden;

  @media screen and (max-width: 670px) {
    height: 790px;
    width: 400px;
  }

  #commandbar-nudge-container {
    overflow: auto;
  }

  #commandbar-toast-container > div {
    top: 0px;
  }

  .rc-dialog-root .rc-dialog {
    position: absolute;
    top: 0px;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const Top = styled.div`
  position: absolute;
  width: 100%;
  height: 60px;
  top: 0px;
  display: flex;
  justify-content: center;
  padding-top: 12px;
`;

const DynamicIsland = styled.div`
  height: 37px;
  width: 126px;
  border-radius: 24px;
  background-color: #000000;
  transition: height 0.5s cubic-bezier(0.075, 0.82, 0.165, 1), width 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    height: 45px;
    width: 145px;
  }
`;

const Bottom = styled.div`
  position: absolute;
  width: 100%;
  height: 34px;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 8px;
`;

const HomeButton = styled.div`
  height: 5px;
  width: 154px;
  border-radius: 24px;
  background-color: #000000;
`;

const ButtonContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  bottom: 0px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 120px;
  display: flex;
  flex-direction: column;

  &.left {
    left: -12px;
  }

  &.right {
    right: -12px;
    top: 220px;
  }
`;

const Button = styled.span`
  background-color: #1d1e1f;
  display: inline-block;
  width: 5px;
  min-height: 62px;
  border-radius: 2px;

  &.power {
    height: 102px;
  }

  &.volume-up {
    margin-top: 29px;
  }
  &.volume-down {
    margin-top: 16px;
  }

  &.lock {
    min-height: 32px;
  }
`;

const PhoneButtons = () => {
  return (
    <ButtonContainer>
      <ButtonWrapper className="left">
        <Button className="lock" />
        <Button className="volume-up" />
        <Button className="volume-down" />
      </ButtonWrapper>
      <ButtonWrapper className="right">
        <Button className="power" />
      </ButtonWrapper>
    </ButtonContainer>
  );
};

const StatusBar = () => {
  return (
    <Top>
      <DynamicIsland />
    </Top>
  );
};

type MobilePreviewProps = PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

export const MobilePreview: React.FC<MobilePreviewProps> = ({ style, children }) => {
  return (
    <Container style={style} data-testid="mobile-preview-device">
      <PhoneButtons />
      <Screen>
        <StatusBar />
        <Wrapper id="commandbar-mobile-preview-wrapper">
          <div id="commandbar-mobile-nudge-mock-pin" />
          {children}
        </Wrapper>
        <Bottom>
          <HomeButton />
        </Bottom>
      </Screen>
    </Container>
  );
};
