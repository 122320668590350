/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import Z from '@commandbar/internal/client/Z';
import { css, jsx } from '@emotion/core';
import { IOrganizationType } from '@commandbar/internal/middleware/types';
import Arrow from '@commandbar/commandbar/products/nudges/components/Arrow';

// default styles that are editable in the the theme editor
export const styles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  background: 'var(--background-primary)',
  width: '100%',
  borderRadius: 'inherit',

  pointerEvents: 'all',

  a: {
    color: 'var(--content-link)',
    textDecoration: 'none',

    '&:hover': {
      color: 'var(--content-link-hover)',
      textDecoration: 'underline',
    },
  },
});

const containerStyles = css({
  position: 'absolute',
  width: 'var(--popover-width)',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',

  backgroundColor: 'var(--background-primary)',
  boxShadow: 'var(--layout-shadow) var(--layout-shadow-color)',
  borderRadius: 'var(--layout-radius-card)',
  border: '1px solid var(--border-primary)',

  pointerEvents: 'all',
});

const surveyStyles = css({
  minWidth: 'var(--popover-width)',
  width: 'unset',
});

// extra styles that an org cannot edit
const Pin = styled.div`
  z-index: ${Z.Z_NUDGE};
`;

const Container = styled.div``;

type Props = {
  survey: boolean;
  organization: IOrganizationType | undefined;
  arrow?: boolean;
};

const StyledNudgePin = React.forwardRef<HTMLDivElement, Props & React.HTMLAttributes<HTMLDivElement>>(
  ({ survey, organization, arrow, children, ...props }, ref) => {
    return (
      <Container ref={ref} css={[containerStyles, survey && surveyStyles].filter(Boolean)} {...props}>
        <Arrow type="pin" containerStyles={{ display: `${arrow ? 'inline-block' : 'none'}` }} />
        <Pin css={styles}>{children}</Pin>
      </Container>
    );
  },
);

export default StyledNudgePin;
