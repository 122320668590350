import { CmdStackedBarChart, CmdCard, CmdTypography } from '@commandbar/design-system/cmd';
import { StateLegendItem, StateLegendItemProps } from '../../components/StateLegendItem';
import { StackedBarChartData } from '@commandbar/internal/middleware/analytics/common';

type StackedChartProps = {
  data?: StackedBarChartData;
  isLoading?: boolean;
  title: string;
  subTitle: string;
  legendItems: StateLegendItemProps[];
};

const StackedBarChart = ({ data, title, subTitle, legendItems, isLoading = false }: StackedChartProps) => {
  const colorRange: string[] = legendItems
    .map((item) => item.lineColor)
    .filter((color): color is string => color !== undefined);

  return (
    <CmdCard>
      <CmdCard.Header>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
          <div>
            <CmdTypography.Body fontWeight="semi-bold">{title}</CmdTypography.Body>
            <CmdTypography.HelpText variant="secondary" fontWeight="medium">
              {subTitle}
            </CmdTypography.HelpText>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '24px',
          }}
        >
          {legendItems.map((item, index) => (
            <StateLegendItem key={index} {...item} />
          ))}
        </div>
      </CmdCard.Header>
      <CmdCard.Content style={{ height: '300px' }}>
        <CmdStackedBarChart chartData={data?.data} isLoading={isLoading} colorRange={colorRange} />
      </CmdCard.Content>
    </CmdCard>
  );
};

export default StackedBarChart;
