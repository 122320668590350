import * as React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  HeaderGroup,
  Header,
  Cell,
  Row,
  FilterFn,
  PaginationState,
  RowSelectionState,
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';
import {
  CmdTable,
  CmdEmpty,
  CmdCard,
  CmdPagination,
  CmdSearchInput,
  CmdTypography,
} from '@commandbar/design-system/cmd';
import { CmdTableNoData } from '@commandbar/design-system/cmd/table/dataTable/noData';
import { CmdTableNoSearchResult } from '@commandbar/design-system/cmd/table/dataTable/noSearchResult';
import SearchMd from '@commandbar/design-system/icons/react/SearchMd';
import { UserSquare } from '@commandbar/design-system/icons/react';

type CmdRow<TData> = Row<TData>;
type CmdColumnDef<TData, TValue> = ColumnDef<TData, TValue> & {
  width?: number;
  onClick?: (data: CmdRow<TData>) => void;
};

export type CmdSortingState = SortingState;
export type CmdPaginationState = PaginationState;
export type CmdRowSelectionState = RowSelectionState;

interface DataTableProps<TData, TValue> {
  columns: CmdColumnDef<TData, TValue>[];
  data: TData[];
  hasGlobalSearch?: boolean;
  shouldDebounceFilter?: boolean;
  toolBarChildren?: React.ReactChild;
  emptyResultChildren?: React.ReactChild;
  searchResultChildren?: React.ReactChild;
  isLoading?: boolean;
  rowCount?: number;
  rowStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  showPagination?: boolean;
  searchPlaceholder?: string;
}

type UseReactTableProps<TData> = Parameters<typeof useReactTable<TData>>[0];

type CmdDataTableProps<TData, TValue> = DataTableProps<TData, TValue> & Partial<UseReactTableProps<TData>>;

const PropertiesTable = <TData, TValue>({
  columns,
  data,
  toolBarChildren,
  emptyResultChildren,
  searchResultChildren,
  hasGlobalSearch,
  shouldDebounceFilter,
  isLoading,
  rowCount,
  rowStyle,
  searchPlaceholder,
  showPagination = true,
  wrapperStyle,
  ...useReactTableProps
}: CmdDataTableProps<TData, TValue>) => {
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const fuzzyFilter: FilterFn<TData> = (
    row: Row<TData>,
    columnId: string,
    filterValue: string,
    addMeta: (meta: any) => void,
  ) => {
    const itemRank = rankItem(row.getValue(columnId), filterValue);
    addMeta(itemRank);
    return itemRank.passed;
  };

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      ...useReactTableProps.state,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    ...useReactTableProps,
  });

  const renderBody = () => {
    if (isLoading) {
      return Array.from({ length: data.length || 10 }).map((_, index) => (
        <CmdTable.Row key={index} className="h-[40px]">
          {columns.map((_, index) => (
            <CmdTable.Cell key={index}>
              <CmdEmpty />
            </CmdTable.Cell>
          ))}
        </CmdTable.Row>
      ));
    }

    if (data.length > 0 && table.getRowModel().rows?.length > 0) {
      return table.getRowModel().rows.map((row: Row<TData>) => (
        <CmdTable.Row
          key={row.id}
          data-state={row.getIsSelected() && 'selected'}
          style={rowStyle}
          className={`h-[40px] ${row.getIsSelected() ? 'bg-blue100' : ''}`}
        >
          {row.getVisibleCells().map((cell: Cell<TData, TValue>, index) => {
            const columnDef = cell.column.columnDef as CmdColumnDef<TData, TData>;
            return (
              <CmdTable.Cell
                onClick={() => !!columnDef.onClick && columnDef.onClick(row)}
                key={index}
                className={!!columnDef.onClick ? 'cursor-pointer' : ''}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </CmdTable.Cell>
            );
          })}
        </CmdTable.Row>
      ));
    }

    // Indicates there is a search but no matching results
    if (data.length > 0 && !table.getRowModel().rows?.length) {
      return (
        <tr>
          <td>
            <div className="min-h-[400px]">
              <CmdTableNoSearchResult>{searchResultChildren}</CmdTableNoSearchResult>
            </div>
          </td>
        </tr>
      );
    }

    // No data
    return (
      <tr>
        <td>
          <div className="min-h-[400px]">
            <CmdTableNoData>{emptyResultChildren}</CmdTableNoData>
          </div>
        </td>
      </tr>
    );
  };

  if (data.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          gap: '4px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'stretch',
          height: 'calc(100vh - 136px)',
          border: '1px solid #EBEBEB',
          borderRadius: '8px',
        }}
      >
        <UserSquare height={32} width={32} style={{ marginBottom: '4px' }} />
        <CmdTypography.Body variant="primary">No properties</CmdTypography.Body>
        <CmdTypography.Body style={{ width: '188px', textAlign: 'center' }} variant="secondary">
          This user doesn't have any properties.
        </CmdTypography.Body>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <CmdSearchInput
        placeholder={searchPlaceholder}
        value={table.getState().globalFilter ?? ''}
        onChange={(e) => {
          table.setGlobalFilter(e.target.value);
        }}
        fullWidth
        clearable
        prefixElement={<SearchMd />}
      />
      <CmdCard className="w-full px-0 py-0 relative cmd-datatable-card">
        <div className="rounded-md border">
          <CmdTable>
            <CmdTable.Header className="h-[40px]">
              {table.getHeaderGroups().map((headerGroup: HeaderGroup<TData>) => (
                <CmdTable.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header: Header<TData, unknown>) => {
                    return (
                      <CmdTable.Head
                        className="w-1/2"
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{ width: (header.column.columnDef as CmdColumnDef<TData, TData>).width }}
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </CmdTable.Head>
                    );
                  })}
                </CmdTable.Row>
              ))}
            </CmdTable.Header>
            <CmdTable.Body>{renderBody()}</CmdTable.Body>
          </CmdTable>
        </div>

        {!isLoading && showPagination && (
          <CmdCard.Footer>
            <CmdPagination table={table} rowCount={rowCount} />
          </CmdCard.Footer>
        )}
      </CmdCard>
    </div>
  );
};

export default PropertiesTable;
