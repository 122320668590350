import { formatRelativeTime } from '../../utils';
import { useChatTable, ChatTableItem, SORT_METHOD, PAGE_SIZE, HIGHLIGHT_TYPE } from './state';

import {
  CmdCard,
  CmdDataTable,
  CmdDropdown,
  CmdColumnDef,
  CmdSortableColumn,
  CmdSortingState,
  CmdRow,
  CmdTypography,
  CmdTableNoResultCta,
  CmdPill,
  CmdTooltip,
} from '@commandbar/design-system/cmd';
import {
  DotsVertical,
  Link04,
  PlusCircle,
  ThumbsDown,
  ThumbsUp,
  BookmarkCheck,
  AlertHexagon,
  AlertTriangle,
  Zap,
  Compass03,
  Link01,
  MessageSquare01,
  CodeBrowser,
  Route,
} from '@commandbar/design-system/icons/react';
import { Preview } from './Preview';
import Filters from './Filters';
import { useEffect, useState } from 'react';
import { DateFilter } from '@commandbar/internal/middleware/analytics/common';
import { useAnalyticsContext } from '../../AnalyticsContext';
import { copyChatLink, createAnswer } from './util';
import { hasRequiredRole } from '@commandbar/internal/middleware/helpers/permissions';
import { useAuth } from '@commandbar/internal/hooks/useAuth';
import { useAppContext } from 'editor/src/AppStateContext';
import { CB_COLORS } from '@commandbar/design-system/colors';

type ChatRowActionsProps = {
  row: CmdRow<ChatTableItem>;
  currentPage: number;
};

const ChatRowActions: React.FC<ChatRowActionsProps> = ({ row, currentPage }) => {
  return (
    <CmdDropdown.Menu>
      <CmdDropdown.Trigger style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <DotsVertical />
      </CmdDropdown.Trigger>
      <CmdDropdown.Content>
        <CmdDropdown.Item
          key="copy-link"
          onClick={async () => {
            const chatId = row.original.id;

            if (!chatId) {
              throw new Error('Chat id is not available');
            }

            copyChatLink(chatId, currentPage);
          }}
        >
          <Link04 /> Copy Link
        </CmdDropdown.Item>
        <CmdDropdown.Item
          key="create-answer"
          onClick={() => {
            const chatId = row.original.id;

            if (!chatId) {
              throw new Error('Chat id is not available');
            }

            createAnswer(chatId);
          }}
        >
          {row.original.answer ? (
            <>
              <BookmarkCheck /> View Answer
            </>
          ) : (
            <>
              <PlusCircle /> Create Answer
            </>
          )}
        </CmdDropdown.Item>
      </CmdDropdown.Content>
    </CmdDropdown.Menu>
  );
};

type ChatsTableProps = {
  timeFilterRange: DateFilter;
};

const ChatsTable: React.FC<ChatsTableProps> = ({ timeFilterRange }) => {
  const [sorting, setSorting] = useState<CmdSortingState>([]);
  const { isDemoData } = useAnalyticsContext();
  const { user } = useAuth();

  const {
    dispatch: {
      tags: { reload: reloadTags },
    },
  } = useAppContext();

  const [
    {
      selectedChat,
      isLoading: isTableLoading,
      chatTableData,
      tagFilter,
      userFilter,
      searchFilter,
      highlightFilter,
      pagination: { totalItems, currentPage },
      handleFetchChatAnalytics,
      closeChat,
      isXrayOpen,
    },
    dispatch,
  ] = useChatTable({
    timeFilterRange,
  });

  useEffect(() => {
    if (isDemoData) {
      dispatch({ type: 'SET_READY' });
    }
  }, [isTableLoading, isDemoData]);

  useEffect(() => {
    const createdSorting = sorting.find((sort) => sort.id === 'created');
    const numMessagesSorting = sorting.find((sort) => sort.id === 'num_messages');

    if (createdSorting) {
      dispatch({ type: 'SET_SORT_METHOD', payload: createdSorting.desc ? SORT_METHOD.NEWEST : SORT_METHOD.OLDEST });
    }
    if (numMessagesSorting) {
      dispatch({
        type: 'SET_SORT_METHOD',
        payload: numMessagesSorting.desc ? SORT_METHOD.MOST_MESSAGES : SORT_METHOD.FEWEST_MESSAGES,
      });
    }
  }, [sorting]);

  const columns: CmdColumnDef<ChatTableItem, any>[] = [
    {
      header: 'Chat topic',
      accessorKey: 'chat',
      enableGlobalFilter: true,
      cell: ({ cell, row }) => (
        <div
          role="button"
          onClick={() => dispatch({ type: 'SELECT_CHAT', payload: row.original.id || null })}
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <CmdTypography.Body
            variant="primary"
            style={{
              display: 'inline-block',
              maxWidth: '600px',
            }}
          >
            {cell.renderValue()}
          </CmdTypography.Body>
        </div>
      ),
    },
    {
      accessorKey: 'num_messages',
      enableGlobalFilter: false,
      cell: ({ cell }) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{cell.getValue()}</div>
      ),
      header: ({ column }) => <CmdSortableColumn column={column} title="Messages" />,
    },
    {
      header: 'Highlights',
      accessorKey: 'highlights',
      enableGlobalFilter: false,
      cell: ({ cell }) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            {(() => {
              const highlights = cell.getValue() as HIGHLIGHT_TYPE[];
              const extraHighlightsList: string[] = [];
              const highlightsComponent = highlights
                .map((highlight, index) => {
                  let message = '';
                  switch (highlight) {
                    case HIGHLIGHT_TYPE.FALLBACK:
                      message = 'Fallback triggered';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <AlertHexagon color={CB_COLORS.red500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }
                    case HIGHLIGHT_TYPE.ERROR:
                      message = 'Error message shown';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <AlertTriangle color={CB_COLORS.red500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }

                    case HIGHLIGHT_TYPE.NEGATIVE_FEEDBACK:
                      message = 'Negative feedback';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <ThumbsDown color={CB_COLORS.red500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }
                    case HIGHLIGHT_TYPE.POSITIVE_FEEDBACK:
                      message = 'Positive feedback';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <ThumbsUp color={CB_COLORS.green500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }
                    case HIGHLIGHT_TYPE.NUDGE:
                      message = 'Nudge suggested';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <Compass03 color={CB_COLORS.blue500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }
                    case HIGHLIGHT_TYPE.ACTION:
                      message = 'Action suggested';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <Zap color={CB_COLORS.blue500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }
                    case HIGHLIGHT_TYPE.PAGE:
                      message = 'Page or link suggested';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <Link01 color={CB_COLORS.blue500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }
                    case HIGHLIGHT_TYPE.CHAT_HANDOFF:
                      message = 'Chat handoff triggered';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <MessageSquare01 color={CB_COLORS.blue500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }
                    case HIGHLIGHT_TYPE.WORKFLOW:
                      message = 'Workflow triggered';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <Route color={CB_COLORS.blue500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }
                    case HIGHLIGHT_TYPE.API:
                      message = 'API referenced';
                      if (highlights.length > 4 && index > 2) {
                        extraHighlightsList.push(message);
                        return null;
                      } else {
                        return (
                          <CmdTooltip key={index} message={message}>
                            <CmdPill style={{ ...(index !== 0 && { marginLeft: '-4px' }) }}>
                              <CodeBrowser color={CB_COLORS.blue500} />
                            </CmdPill>
                          </CmdTooltip>
                        );
                      }
                    default:
                      return null;
                  }
                })
                .filter((comp) => !!comp);
              if (extraHighlightsList.length > 0) {
                highlightsComponent.push(
                  <CmdTooltip
                    message={extraHighlightsList.map((item, idx) => (
                      <span key={idx}>
                        • {item}
                        <br />
                      </span>
                    ))}
                  >
                    <CmdPill key={'extras'} style={{ marginLeft: '-4px' }}>
                      +{extraHighlightsList.length}
                    </CmdPill>
                  </CmdTooltip>,
                );
              }
              return highlightsComponent;
            })()}
          </div>
        );
      },
    },
    {
      header: 'User id',
      accessorKey: 'end_user',
      enableGlobalFilter: true,
      cell: ({ cell }) => cell.renderValue(),
    },
    {
      accessorKey: 'created',
      enableGlobalFilter: false,
      cell: ({ cell }) => formatRelativeTime(cell.getValue() as string),
      header: ({ column }) => <CmdSortableColumn column={column} title="Date" />,
    },
  ];

  if (hasRequiredRole(user, 'contributor')) {
    columns.push({
      header: '',
      accessorKey: 'options',
      enableGlobalFilter: false,
      width: 40,
      cell: ({ row }) => {
        if (!isDemoData) {
          return <ChatRowActions row={row} currentPage={currentPage} />;
        }
        return null;
      },
    });
  }

  return (
    <>
      <CmdCard style={{ padding: '0' }}>
        <CmdCard.Content style={{ padding: '0' }}>
          <CmdDataTable
            isLoading={isTableLoading}
            columns={columns}
            data={chatTableData}
            enableRowSelection
            manualSorting
            manualPagination
            manualFiltering
            renderFallbackValue={`-`}
            state={{
              sorting,
              pagination: {
                pageIndex: currentPage,
                pageSize: PAGE_SIZE,
              },
              globalFilter: searchFilter,
              rowSelection: {
                [selectedChat?.id || '']: true,
              },
            }}
            getRowId={(row) => row.id || ''}
            onSortingChange={setSorting}
            onPaginationChange={(updater) => {
              if (typeof updater === 'function') {
                const newState = updater({
                  pageSize: PAGE_SIZE,
                  pageIndex: currentPage,
                });

                dispatch({ type: 'SET_CURRENT_PAGE', payload: newState.pageIndex });
              } else {
                dispatch({ type: 'SET_CURRENT_PAGE', payload: updater.pageIndex });
              }
            }}
            pageCount={Math.ceil(totalItems / PAGE_SIZE)}
            rowCount={totalItems}
            onGlobalFilterChange={(value) => {
              dispatch({ type: 'FILTER_BY_TERM', payload: value });
            }}
            shouldDebounceFilter={false}
            emptyResultChildren={<CmdTableNoResultCta onClick={handleFetchChatAnalytics} />}
            toolBarChildren={
              <Filters
                tagFilter={tagFilter}
                setTagFilter={(payload) => {
                  dispatch({ type: 'FILTER_BY_TAG', payload });
                }}
                userFilter={userFilter}
                setUserFilter={(payload) => dispatch({ type: 'FILTER_BY_USER', payload })}
                highlightFilter={highlightFilter}
                setHighlightFilter={(payload) => dispatch({ type: 'FILTER_BY_HIGHLIGHT', payload })}
              />
            }
          />
        </CmdCard.Content>
      </CmdCard>

      <Preview
        selectedChat={selectedChat}
        isXrayOpen={isXrayOpen}
        setTags={(tags: string[]) => {
          dispatch({
            type: 'SET_CHAT_TAGS',
            payload: {
              chat_id: selectedChat?.id as string,
              tags,
              callback: reloadTags,
            },
          });
          setTimeout(() => {
            reloadTags();
          }, 200);
        }}
        closeChat={closeChat}
        createAnswer={createAnswer}
        copyChatLink={(chatId, isXrayOpen) => copyChatLink(chatId, currentPage, isXrayOpen)}
        previousChat={() => {
          dispatch({
            type: 'SELECT_PREVIOUS_CHAT',
          });
        }}
        nextChat={() => {
          dispatch({
            type: 'SELECT_NEXT_CHAT',
          });
        }}
      />
    </>
  );
};

export default ChatsTable;
