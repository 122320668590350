/** @jsx jsx  */
import { jsx, css } from '@emotion/core';
import React, { Fragment, useEffect, useRef } from 'react';
import Logger from '@commandbar/internal/util/Logger';
import useTheme from 'shared/util/hooks/useTheme';
import { emptyGlobalStore } from 'shared/store/global-store';
import { getSentry } from '@commandbar/internal/util/sentry';
import { useStore } from 'shared/util/hooks/useStore';

import { CB_COLORS } from '@commandbar/design-system/colors';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';
import { useChatState } from '../../store/useChatState';
import { EnrichedHtml } from '../../../../shared/components/EnrichedHtml';

type BotMessageTextProps = {
  preview: boolean;
  isLoading: boolean;
  answer: string;
  isFinishedStreaming: boolean;
  onFinishStreaming: () => void;
  messageId: string;
  previewOverrides?: { useThemeV2: boolean };
};

export const BotMessageText: React.FC<BotMessageTextProps> = ({
  preview,
  isLoading,
  answer,
  isFinishedStreaming,
  onFinishStreaming,
  messageId,
  previewOverrides,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _ = !preview ? useStore() : emptyGlobalStore();
  const chatState = useChatState();
  const { theme } = useTheme();
  const answerStreamingIndex = React.useRef(0);

  const useThemesV2 = (preview && previewOverrides?.useThemeV2) || _.flags?.['release-themes-v2'];

  const selfClosingTags = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);

  const handleCodeCopyClick = (parent: Element) => async () => {
    const { children } = parent;
    const { innerText } = Array.from(children)[0] as HTMLElement;
    try {
      await navigator.clipboard.writeText(innerText);
    } catch (err) {
      getSentry()?.captureException(err);
      Logger.error('Failed to copy text to clipboard:', err);
    }
  };

  const isTable = (node: Node): boolean => {
    return node.nodeName.toLowerCase() === 'table';
  };

  const getStreamingNodes = (
    nodes: ChildNode[],
    textNodes: { content: string; stream: boolean; type: 'text' | 'block' }[],
  ) => {
    nodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent) {
          textNodes.push(
            ...node.textContent.split('').map(
              (char) =>
                ({
                  content: char,
                  stream: true,
                  type: 'text',
                } as { content: string; stream: boolean; type: 'text' }),
            ),
          );
        }
      } else if (
        isTable(node) ||
        node.nodeName.toLowerCase() === 'pre' ||
        node.nodeName.toLowerCase() === 'img' ||
        (node.nodeName.toLowerCase() === 'div' && (node as HTMLElement).classList.contains('codehilite'))
      ) {
        // Treat tables, pre elements, images, and div.codehilite as single streaming units
        textNodes.push({
          content: node instanceof HTMLElement ? node.outerHTML : '',
          stream: true,
          type: 'block',
        });
      } else {
        if (node.childNodes.length > 0) {
          textNodes.push({
            content: `<${node.nodeName.toLowerCase()}>`,
            stream: false,
            type: 'text',
          });
          getStreamingNodes(Array.from(node.childNodes), textNodes);
          textNodes.push({
            content: `</${node.nodeName.toLowerCase()}>`,
            stream: false,
            type: 'text',
          });
        } else {
          const nodeName = node.nodeName.toLowerCase();
          if (selfClosingTags.has(nodeName)) {
            const attributes = Array.from((node as Element).attributes)
              .map((attr) => `${attr.name}="${attr.value}"`)
              .join(' ');
            textNodes.push({
              content: `<${nodeName}${attributes ? ' ' + attributes : ''}>`,
              stream: false,
              type: 'text',
            });
          } else {
            textNodes.push({
              content: `<${node.nodeName.toLowerCase()}></${node.nodeName.toLowerCase()}>`,
              stream: false,
              type: 'text',
            });
          }
        }
      }
    });
    return textNodes;
  };
  const handleNode = (
    currParentNode: ChildNode | ParentNode,
    node: {
      content: string;
      stream: boolean;
      type: 'text' | 'block';
    },
    fadeIn?: boolean,
  ) => {
    const { content, stream, type } = node;
    if (stream) {
      let element: HTMLElement;
      if (type === 'block') {
        const container = document.createElement('div');
        container.innerHTML = content;
        element = container.firstChild as HTMLElement;
      } else {
        // For text content, create a span
        element = document.createElement('span');
        element.textContent = content;
      }

      if (fadeIn) {
        element.style.opacity = '0';
        element.style.transition = 'opacity 0.25s ease';
        currParentNode.appendChild(element);

        requestAnimationFrame(() => {
          element.style.opacity = '1';
        });
      } else {
        currParentNode.appendChild(element);
      }
    } else {
      // Handle non-streaming content
      if (content.startsWith('</')) {
        if (currParentNode.parentNode) {
          currParentNode = currParentNode.parentNode;
        }
      } else {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const node = doc.body.firstChild;
        if (node) {
          currParentNode.appendChild(node);
          if (!selfClosingTags.has(node.nodeName.toLowerCase())) {
            currParentNode = node;
          }
        }
      }
    }
    return currParentNode;
  };

  const lastMeasuredLength = useRef(0);
  const lastMeasureTime = useRef(Date.now());
  const nodesPerSecond = useRef(100);

  useEffect(() => {
    const answerDiv = document.getElementById('doc-based-ai-answer');
    const fakeAnswerDiv = document.getElementById('fake-container');
    const answerDivContainer = document.getElementById(`doc-based-ai-answer-container-${messageId}`);

    if (answerDivContainer && answerDiv && fakeAnswerDiv && useThemesV2) {
      let animationFrameId: number | null = null;

      const textNodes = getStreamingNodes(Array.from(fakeAnswerDiv.childNodes), []);
      answerDiv.innerHTML = '';

      const firstChild = document.createElement('span');
      answerDiv.appendChild(firstChild);
      let currParentNode: ChildNode | ParentNode = firstChild;
      for (let i = 0; i < Math.min(textNodes.length, answerStreamingIndex.current); i++) {
        currParentNode = handleNode(currParentNode, textNodes[i]);
      }

      answerDivContainer.style.height = `${answerDiv.offsetHeight}px`;

      let lastTimestamp = performance.now();
      const measureInterval = 500;

      const animate = (timestamp: number) => {
        const elapsed = timestamp - lastTimestamp;
        const availableNodes = textNodes.length - answerStreamingIndex.current;
        const nodesToProcess = Math.min(Math.floor((elapsed / 1000) * nodesPerSecond.current), availableNodes);

        if (nodesToProcess > 0) {
          lastTimestamp = timestamp;

          const currentTime = Date.now();
          if (currentTime - lastMeasureTime.current >= measureInterval) {
            const timeDiff = (currentTime - lastMeasureTime.current) / 1000;
            const lengthDiff = textNodes.length - lastMeasuredLength.current;

            if (timeDiff > 0 && lengthDiff > 0) {
              const inputRate = lengthDiff / timeDiff;
              const targetNodesPerSecond = Math.max(30, Math.min(300, inputRate));
              nodesPerSecond.current = nodesPerSecond.current * 0.7 + targetNodesPerSecond * 0.3;
            }

            lastMeasuredLength.current = textNodes.length;
            lastMeasureTime.current = currentTime;
          }

          for (let i = 0; i < nodesToProcess; i++) {
            const node = textNodes[answerStreamingIndex.current];
            currParentNode = handleNode(currParentNode, node, true);
            answerStreamingIndex.current += 1;
          }

          answerDivContainer.style.height = `${answerDiv.offsetHeight}px`;

          if (answerStreamingIndex.current >= textNodes.length && !isLoading) {
            onFinishStreaming();
            answerDivContainer.style.height = 'unset';
            animationFrameId && cancelAnimationFrame(animationFrameId);
            return;
          }
        }

        animationFrameId = requestAnimationFrame(animate);
      };

      // Start animation loop
      animationFrameId = requestAnimationFrame(animate);

      return () => {
        onFinishStreaming();
        answerDivContainer.style.height = 'unset';
        animationFrameId && cancelAnimationFrame(animationFrameId);
      };
    }
    // for themes v1, we finish streaming as soon as we finish loading the answer
    else if (!useThemesV2 && !isLoading) {
      onFinishStreaming();
    }
  }, [answer, isLoading]);

  useEffect(() => {
    // Attach a Copy button to each code block after we're done streaming
    if (!isLoading && isFinishedStreaming) {
      const highlights = document.querySelectorAll('div.codehilite');

      highlights.forEach((div) => {
        if (div.querySelector('button')) {
          return;
        }
        // create the copy button
        const copy = document.createElement('button');
        copy.innerHTML = 'Copy';
        // add the event listener to each click
        copy.addEventListener('click', handleCodeCopyClick(div));
        // append the copy button to each code block
        div.append(copy);
      });
    }
  }, [isLoading, isFinishedStreaming]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      id={`doc-based-ai-answer-container-${messageId}`}
      onClick={(e) => {
        // eslint-disable-next-line commandbar/no-event-target
        if (e.target instanceof HTMLElement) {
          if (e.target.matches('a')) {
            Track.copilot.linkClicked(chatState.chatID, messageId, (e.target as HTMLAnchorElement).href);
          }
        }
      }}
      style={{
        borderRadius: '0px 16px 16px 16px',
        maxWidth: '100%',
        wordWrap: 'break-word',
      }}
      css={css`
        & p {
          margin: 0 0 16px 0;
        }
        & p:last-child {
          margin: 0;
        }
        & pre {
          margin: 0;
          overflow: auto;
        }
        & .codehilite {
          margin: 0 0 16px 0;
          overflow: auto;
          position: relative;
        }
        b {
          ${useThemesV2 && `font-weight: var(--font-weight-bold)`}
        }
        strong {
          ${useThemesV2 && `font-weight: var(--font-weight-bold)`}
        }
        table {
          border-collapse: collapse;
          width: 100%;
        }
        th,
        td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }
        th {
          background-color: #f2f2f2;
        }
        & blockquote {
          border-left: 4px solid #ddd;
          margin: 0;
          padding: 8px 16px;
        }
        & {
          ol,
          ul {
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            padding-inline-start: 20px;
          }
          ul {
            list-style: disc;
          }

          ol {
            list-style: decimal;
          }

          li {
            display: list-item;
          }

          ul ul,
          ol ul {
            list-style: circle;
          }

          ul li,
          ol li {
            margin-bottom: 8px;
          }

          ol ol ul,
          ol ul ul,
          ul ol ul,
          ul ul ul {
            list-style: square;
          }

          ol ul,
          ul ol,
          ul ul,
          ol ol {
            margin-top: 8px;
            margin-block-start: 0;
            margin-block-end: 0;
          }

          li > ul,
          li > ol {
            margin-top: 8px;
          }

          img,
          table {
            max-width: calc(100% - 32px);
            height: auto;
            border-radius: 4px;
            margin: 0 0 16px 0;
          }
        }
        & p code {
          white-space: pre-wrap;
        }
        & .codehilite button {
          color: white;
          box-sizing: border-box;
          cursor: pointer;
          user-select: none;
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(0, 0, 0, 0);
          padding: 4px 8px;
          position: absolute;
          top: 3px;
          right: 3px;
          display: none;
          border-radius: 4px;
          transition: all ${theme.main.transitionTime};
        }

        & .codehilite:hover button {
          display: block;
        }
        a {
          color: ${useThemesV2 ? 'var(--content-link)' : CB_COLORS.blue500};
        }

        a:hover {
          color: ${useThemesV2 ? 'var(--content-link-hover)' : CB_COLORS.blue500};
        }

        a:visited {
          color: ${useThemesV2 ? 'var(--content-link-visited)' : '#551A8B'};
        }
        ${useThemesV2 &&
        `
          & img,
          & table,
          & .codehilite,
          & pre {
            opacity: inherit;
            transition: inherit;
          }
        `}
      `}
    >
      {isLoading || !isFinishedStreaming ? (
        <Fragment>
          <div id="doc-based-ai-answer" dangerouslySetInnerHTML={useThemesV2 ? undefined : { __html: answer }} />
          {useThemesV2 && (
            <div id="fake-container" dangerouslySetInnerHTML={{ __html: answer }} style={{ display: 'none' }} />
          )}
        </Fragment>
      ) : (
        <EnrichedHtml preview={preview} html={answer}></EnrichedHtml>
      )}
    </div>
  );
};

export default BotMessageText;
