import { IEndUserStoreData } from '@cb/types/entities/endUser';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { createFallbackHmac } from './helpers';

export const _getRemoteData = (endpoint: string, hmac?: string) => {
  return axiosInstance.get(endpoint, {
    headers: {
      'X-USER-AUTHORIZATION': !!hmac ? hmac : createFallbackHmac(),
    },
    credentials: 'omit',
  });
};

export const _patchRemoteData = (
  endpoint: string,
  updated: IEndUserStoreData[keyof IEndUserStoreData],
  store: 'checklist_interactions' | 'nudges_interactions' | 'hotkeys',
  hmac?: string,
) => {
  return axiosInstance.patch(
    endpoint,
    { [store]: updated },
    {
      credentials: 'omit',
      headers: {
        'X-USER-AUTHORIZATION': !!hmac ? hmac : createFallbackHmac(),
      },
    },
  );
};
