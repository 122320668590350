import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { AnalyticsSurveys, type AnalyticsSurveysDetailData } from '@commandbar/internal/middleware/analytics/surveys';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { getSurveyDetailsDemoData } from '../../analyticDemoData';
import { useCSVDownload } from '../../useCSVDownload';
import useSurveyDetails from './useSurveyDetails';

export const useAnalyticSurveyDetail = ({ id, goalEventName }: { id: string; goalEventName?: string }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();
  const [data, setData] = useState<AnalyticsSurveysDetailData | null>(null);

  const location = useLocation();
  const { surveyDetails, fetchData: fetchSurveyDetailsWithResponses } = useSurveyDetails({
    nudgeId: id,
    listFilter: 'most_recent',
    timeFilterRange,
  });

  const { downloadCsv, isDownloading } = useCSVDownload({
    name: 'surveys',
    download: () =>
      AnalyticsSurveys.downloadDetail(id, {
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        show_unpublished: 'false',
        include_admin_data: includeAdminData.toString(),
      }),
  });

  const { downloadCsv: downloadCsvResponses, isDownloading: isDownloadingResponses } = useCSVDownload({
    name: 'survey_responses',
    download: () =>
      AnalyticsSurveys.downloadDetailResponses(id, {
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        show_unpublished: 'false',
        include_admin_data: includeAdminData.toString(),
      }),
  });

  const { downloadCsv: downloadCsvViews, isDownloading: isDownloadingViews } = useCSVDownload({
    name: 'survey_views',
    download: () =>
      AnalyticsSurveys.downloadDetailViews(id, {
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        show_unpublished: 'false',
        include_admin_data: includeAdminData.toString(),
      }),
  });

  const fetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    AnalyticsSurveys.readDetail(id, {
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      show_unpublished: 'false',
      include_admin_data: includeAdminData.toString(),
      goal_event_name: goalEventName,
    })
      .then(setData)
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      })
      .finally(() => setIsLoading(false));

    fetchSurveyDetailsWithResponses();
  }, [fetchSurveyDetailsWithResponses, goalEventName, id, includeAdminData, timeFilterRange]);

  useEffect(() => {
    if (isDemoData) {
      setData(getSurveyDetailsDemoData(location.pathname.includes('nps')));
    } else {
      fetchData();
    }
  }, [fetchData, isDemoData, location.pathname]);

  return {
    isLoading,
    fetchData,
    data,
    error,
    downloadCsv,
    downloadCsvResponses,
    isDownloading: isDownloadingResponses || isDownloading || isDownloadingViews,
    surveyDetails,
    downloadCsvViews,
  };
};
