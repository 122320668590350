/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import React, { type CSSProperties, forwardRef } from 'react';

import { BindThemeV2ForNudge } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import Z from '@commandbar/internal/client/Z';
import type { ITheme } from '@commandbar/internal/client/theme';
import type { INudgeModalStepType, INudgePopoverStepType, INudgeType } from '@commandbar/internal/middleware/types';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { DraftFooter } from 'shared/components/admin-facing/DraftFooter';
import { isEditorOpen } from 'shared/store/util/editorUtils';
import { useAction } from 'shared/util/hooks/useAction';
import useDraggable from 'shared/util/hooks/useDraggable';
import { useMobileExperience } from 'shared/util/hooks/useMobileExperience';
import { useStore } from 'shared/util/hooks/useStore';
import { useLinkClickHandler } from '../../hooks/useLinkClickHandler';
import { dismissNudge } from '../../store/actions';
import { getNudgeService, isNudgeDismissible, isNudgeSnoozable } from '../../store/selectors';
import { getNudgeStep } from '@commandbar/internal/util/nudges';
import CloseButton from '../CloseButton';
import ContentContainer from '../ContentContainer';
import { RenderMode } from '../RenderNudge';
import { SurveyResponseProvider } from '../SurveyResponseProvider';
import { Layout } from './Layout';
import {
  useAnimatedWidget,
  AnimatedWidget,
} from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';

const getStyles = (theme: ITheme, autoWidth: boolean): Record<string, CSSProperties> => ({
  container: {
    width: autoWidth ? 'auto' : theme.nudges.width,
    display: 'flex',
    flexDirection: 'column',
    background: theme.nudges.background,
    borderRadius: theme.nudges.borderRadius,
    color: theme.nudges.color,
    zIndex: Z.Z_COMMANDBAR - 2,
    boxShadow: theme.nudges.boxShadow,
    fontFamily: theme.nudges.fontFamily,
    transition: 'opacity 0.15s ease-in-out',
    pointerEvents: 'all',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    padding: `16px 16px ${theme.nudges.gap}`,
  },
  title: {
    fontFamily: theme.nudges.titleFontFamily,
    fontSize: theme.nudges.titleFontSize,
    fontWeight: theme.nudges.titleFontWeight,
    lineHeight: theme.nudges.titleLineHeight,
    color: theme.nudges.titleColor,
    zIndex: Z.Z_COMMANDBAR - 1,
    cursor: 'auto',
  },
  content: {
    fontFamily: theme.nudges.contentFontFamily,
    color: theme.nudges.contentColor,
    fontSize: theme.nudges.contentFontSize,
    fontWeight: theme.nudges.contentFontWeight,
    lineHeight: theme.nudges.contentLineHeight,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.nudges.padding,
    gap: theme.nudges.gap,
  },
  dragZone: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: parseInt(theme.nudges.titleFontSize, 10) + parseInt(theme.nudges.padding, 10) * 1.5,
    padding: theme.nudges.padding,
    borderTopLeftRadius: theme.nudges.borderRadius,
    borderTopRightRadius: theme.nudges.borderRadius,
    userSelect: 'none',
    cursor: 'move',
  },
  ctaButton: {
    fontFamily: theme.nudges.ctaFontFamily,
    padding: theme.nudges.ctaPadding,
    background: theme.nudges.ctaBackground,
    color: theme.nudges.ctaColor,
    border: theme.nudges.ctaBorder,
    boxShadow: theme.nudges.ctaBoxShadow,
    textShadow: theme.nudges.ctaTextShadow,
    borderRadius: theme.nudges.ctaBorderRadius,
    fontSize: theme.nudges.ctaFontSize,
    lineHeight: theme.nudges.ctaLineHeight,
    fontWeight: theme.nudges.ctaFontWeight,
  },
  ctaSecondaryButton: {
    fontFamily: theme.nudges.ctaSecondaryFontFamily,
    padding: theme.nudges.ctaSecondaryPadding,
    background: theme.nudges.ctaSecondaryBackground,
    color: theme.nudges.ctaSecondaryColor,
    border: theme.nudges.ctaSecondaryBorder,
    boxShadow: theme.nudges.ctaSecondaryBoxShadow,
    textShadow: theme.nudges.ctaSecondaryTextShadow,
    borderRadius: theme.nudges.ctaSecondaryBorderRadius,
    fontSize: theme.nudges.ctaSecondaryFontSize,
    lineHeight: theme.nudges.ctaSecondaryLineHeight,
    fontWeight: theme.nudges.ctaSecondaryFontWeight,
  },
  snoozeButton: {
    background: theme.nudges.snoozeButtonBackground,
    fontFamily: theme.nudges.snoozeButtonFontFamily,
    color: theme.nudges.snoozeButtonColor,
    border: theme.nudges.snoozeButtonBorder,
    textShadow: theme.nudges.snoozeButtonTextShadow,
    fontSize: theme.nudges.snoozeButtonFontSize,
    lineHeight: theme.nudges.snoozeButtonLineHeight,
    fontWeight: theme.nudges.snoozeButtonFontWeight,
    width: theme.nudges.snoozeButtonWidth,
  },
  closeButtonOverrides: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  stepCount: {
    fontFamily: theme.nudges.stepCountFontFamily,
    color: theme.nudges.stepCountColor,
    fontSize: theme.nudges.stepCountFontSize,
    fontWeight: theme.nudges.stepCountFontWeight,
  },
});

type PopoverProps = {
  renderMode: RenderMode;
  nudge: INudgeType;
  stepIndex: number;
  center?: boolean;
  stepCount?: string;
  preventRepositionOnEditorOpen?: boolean;
};

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  ({ renderMode, nudge, stepIndex, center, stepCount, preventRepositionOnEditorOpen }: PopoverProps, popoverRef) => {
    const _ = useStore();
    const { theme }: { theme: ITheme } = useTheme();
    const { isMobileDevice, mobileStyles } = useMobileExperience();
    const step = getNudgeStep(nudge, stepIndex) as INudgePopoverStepType | INudgeModalStepType;
    const styles = getStyles(
      theme,
      !isMobileDevice && !!step?.content.some((block) => block?.type === 'survey_rating' && block.meta.options === 10),
    );
    const getService = (id: INudgeType['id']) => getNudgeService(_, id);
    const { onExit } = useAnimatedWidget();

    const dismissAction = useAction(dismissNudge);
    const dismiss = (nudge: INudgeType, renderMode: RenderMode) => {
      onExit(() => dismissAction(nudge, renderMode));
    };

    const handleLinkClick = useLinkClickHandler(() => dismiss(nudge, renderMode));
    const service = renderMode !== RenderMode.MOCK ? getService(nudge.id) : undefined;

    const editorWidth = Number(LocalStorage.get('width', '770'));
    const editorOpen =
      Number(LocalStorage.get('editor.open', false)) &&
      isEditorOpen() &&
      !isStudioPreview() &&
      !preventRepositionOnEditorOpen;

    if (step) {
      const className = (() => {
        const formFactor = step.form_factor;

        if (!isStudioPreview() && formFactor.type === 'popover') {
          if (formFactor.position.includes('right')) {
            return 'commandbar-popover-nudge-right';
          } else if (formFactor.position === 'center') {
            return 'commandbar-popover-nudge-center';
          }
        }

        return '';
      })();

      const editorAdjustment: Record<'open' | 'closed', Record<string, React.CSSProperties>> = {
        open: {
          right: { right: `${editorWidth + 16}px` },
          center: {
            right: `${(editorWidth + 16) / 2}px`,
          },
        },
        closed: {
          right: { right: '0' },
          center: { right: undefined },
          standaloneEditor: {
            top: 'calc(50vh - 51px)',
            right: '50%',
            transform: 'translate(50%, -50%)',
            position: 'absolute',
          },
        },
      };

      const isRightPositioned = step.form_factor.type === 'popover' && step.form_factor.position.includes('right');
      const isCenterPositioned =
        (step.form_factor.type === 'popover' && step.form_factor.position === 'center') ||
        step.form_factor.type === 'modal';

      const editorAdjustmentStyle = !_.showWidgetTableau
        ? editorAdjustment[editorOpen ? 'open' : 'closed'][
            isStudioPreview() ? 'standaloneEditor' : isCenterPositioned ? 'center' : isRightPositioned ? 'right' : ''
          ]
        : undefined;

      return (
        <SurveyResponseProvider service={service} step={step}>
          {_.flags?.['release-themes-v2'] ? (
            <Layout
              ref={popoverRef}
              nudge={nudge}
              step={step}
              renderMode={renderMode}
              service={service}
              handleLinkClick={handleLinkClick}
              className={className}
              stepIndex={stepIndex}
              styles={styles}
              center={center}
              stepCount={stepCount}
              editorAdjustmentStyle={editorAdjustmentStyle}
            />
          ) : (
            <div
              data-testid={`commandbar-popover-${nudge.id}-${String(step.id)}${
                renderMode === RenderMode.MOCK ? '-mock' : ''
              }`}
              key={step.id}
              ref={popoverRef}
              className={className}
              aria-labelledby="commandbar-nudge-title"
              style={
                isMobileDevice
                  ? mobileStyles.nudges.popover.container
                  : {
                      maxHeight: '90vh',
                      maxWidth: '90vw',
                      position: 'relative',
                      ...(center && {
                        top: '50vh',
                        transform: 'translate(0, -50%)',
                      }),
                      ...styles.container,
                      ...editorAdjustmentStyle,
                    }
              }
            >
              <div style={styles.header}>
                <div data-draggable="drag-zone" data-testid="drag-zone" style={styles.dragZone} />
                <span id="commandbar-nudge-title" style={styles.title} role="heading" aria-level={3}>
                  {step.title}
                </span>
                {isNudgeDismissible(nudge) && <CloseButton onInteraction={() => dismiss(nudge, renderMode)} />}
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                data-testid="commandbar-nudge-popover-body"
                style={{
                  ...styles.body,
                  overflow: 'auto',
                }}
              >
                <ContentContainer
                  step={step}
                  service={service}
                  markdownStyles={styles.content}
                  primaryButtonStyles={styles.ctaButton}
                  secondaryButtonStyles={styles.ctaSecondaryButton}
                  snoozeButtonStyles={styles.snoozeButton}
                  stepCountStyles={styles.stepCount}
                  stepCount={stepCount}
                  renderMode={renderMode}
                  snoozable={isNudgeSnoozable(nudge)}
                  snoozeLabel={nudge.snooze_label}
                  handleContentLinkClick={handleLinkClick}
                  stepIndex={stepIndex}
                  snoozable_on_all_steps={nudge.snoozable_on_all_steps}
                />
              </div>
              {!nudge.is_live && renderMode === RenderMode.MOCK && (
                <DraftFooter details={{ type: 'nudge', nudge: nudge, stepIndex }} />
              )}
            </div>
          )}
        </SurveyResponseProvider>
      );
    }

    return null;
  },
);

export const DraggablePopover = (props: React.ComponentProps<typeof Popover>) => {
  const [ref, setRef] = React.useState<HTMLElement | null>(null);

  useDraggable(ref);

  const step = getNudgeStep(props.nudge, props.stepIndex);
  const position = step?.form_factor.type === 'popover' ? step.form_factor.position : undefined;

  return (
    <BindThemeV2ForNudge nudge={props.nudge}>
      <AnimatedWidget widget="popover" isMounted enterDelay={1} position={position}>
        <Popover ref={setRef} {...props} />
      </AnimatedWidget>
    </BindThemeV2ForNudge>
  );
};
