import { cmdToast } from '@commandbar/design-system/cmd';
import { DryRunData, IntegrationSpec } from './types';
import { HelpDocsIntegration } from '@commandbar/internal/middleware/helpDocsIntegration';
import * as Sentry from '@sentry/react';

const INTEG_ERROR_MESSAGE =
  'Something went wrong. Please check the integration configuration. If this issue persists, please reach out to us at support@command.ai.';

export const onIntegrationError = (
  err: string | Sentry.Exception,
  integrationType?: string | undefined,
  error_message?: string,
) => {
  cmdToast.error(error_message ?? INTEG_ERROR_MESSAGE);
  Sentry.withScope((scope) => {
    scope.setTags({
      product: 'admin:integrations',
      feature: 'admin:integrations::integration-setup',
      'integration-type': integrationType,
    });
    typeof err === 'string' ? Sentry.captureMessage(err) : Sentry.captureException(err);
  });
};

/* Filter out all the unselected options from a dry run data field */
export const filterDryRunData = (selectedData: { value: unknown }[], allData: DryRunData | undefined) => {
  const selected_values = selectedData.map((item) => item.value);
  return allData?.filter((data) => selected_values.includes(data.value));
};

/* Updates the integration sources to just include the ones that match the selected integration */
export const updateIntegrations = async (selectedIntegration: IntegrationSpec | undefined) => {
  if (selectedIntegration) {
    HelpDocsIntegration.list().then((integrations) => {
      selectedIntegration.sources = integrations.filter((i) => i.type === selectedIntegration.slug);
    });
  }
};
