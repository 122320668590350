import React, { type FC, type CSSProperties, type MouseEventHandler } from 'react';

import { BindThemeV2ForNudge, useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { DraftFooter } from '@commandbar/commandbar/shared/components/admin-facing/DraftFooter';
import { PopoverClose } from '@commandbar/commandbar/shared/components/popover';
import { useMobileExperience } from '@commandbar/commandbar/shared/util/hooks/useMobileExperience';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import { XClose } from '@commandbar/design-system/icons/react';
import StyledNudgeStepCounter from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeStepCounter';
import StyledNudgeTitleBlock from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeTitleBlock';
import StyledTooltip from '@commandbar/internal/client/themesV2/components/shared/StyledTooltip';
import type { INudgeTooltipStepType, INudgeType } from '@commandbar/internal/middleware/types';
import { getNudgeService, isNudgeSnoozable } from '../../store/selectors';
import { Actions } from '../Actions';
import Media from '../ContentContainer';
import { LayoutTemplate } from '../LayoutTemplate';
import { RenderMode } from '../RenderNudge';
import { getFirstMarkdownBlock } from '../utils';
import { TestID } from './constants';
import useStyles from './styles';

interface LayoutProps {
  nudge: INudgeType;
  step: INudgeTooltipStepType;
  renderMode: RenderMode;
  handleLinkClick: MouseEventHandler<HTMLElement>;
  stepIndex: number;
  stepCount?: string;
  isVisible?: boolean;
}

export const Layout: FC<LayoutProps> = ({
  nudge,
  step,
  renderMode,
  handleLinkClick,
  stepIndex,
  stepCount,
  isVisible = true,
}) => {
  const _ = useStore();
  const themeV2 = useThemeV2Context();
  const styles = useStyles();
  const nudgeService = renderMode !== RenderMode.MOCK ? getNudgeService(_, nudge.id) : undefined;
  const { isMobileDevice } = useMobileExperience();

  const close = (
    <PopoverClose data-testid={TestID.Close}>
      <XClose />
    </PopoverClose>
  );

  const header = (
    <StyledNudgeTitleBlock
      title={step.title}
      content={getFirstMarkdownBlock(step.content)}
      handleContentLinkClick={handleLinkClick}
      dismissible
    />
  );

  const media = (
    <Media
      step={step}
      stepIndex={stepIndex}
      stepCount={stepCount}
      service={nudgeService}
      markdownStyles={styles.content as CSSProperties}
      primaryButtonStyles={styles.ctaButton as CSSProperties}
      secondaryButtonStyles={styles.ctaSecondaryButton as CSSProperties}
      snoozeButtonStyles={styles.snoozeButton as CSSProperties}
      stepCountStyles={styles.stepCount as CSSProperties}
      renderMode={renderMode}
      snoozable={isNudgeSnoozable(nudge)}
      snoozeLabel={nudge.snooze_label}
      snoozable_on_all_steps={nudge.snoozable_on_all_steps}
      handleContentLinkClick={handleLinkClick}
    />
  );

  const stepCounter = stepCount ? <StyledNudgeStepCounter stepCount={stepCount} /> : null;

  const actions = (
    <Actions
      step={step}
      stepIndex={stepIndex}
      renderMode={renderMode}
      snooze={{
        enabled: isNudgeSnoozable(nudge),
        label: nudge.snooze_label,
        enabledOnAllSteps: nudge.snoozable_on_all_steps,
        duration: nudge.snooze_duration,
      }}
      service={nudgeService}
      stepCount={stepCount}
    />
  );

  const footer =
    !nudge.is_live && renderMode === RenderMode.MOCK ? (
      <DraftFooter details={{ type: 'nudge', nudge, stepIndex }} />
    ) : null;

  return (
    <BindThemeV2ForNudge nudge={nudge}>
      <StyledTooltip
        variant={step.content.find((block) => block.type === 'survey_rating') ? 'survey' : 'default'}
        key={step.id}
        themeV2={themeV2}
        style={{
          pointerEvents: isVisible ? 'auto' : 'none',
          ...(step.form_factor.layout === 'horizontal' && !isMobileDevice
            ? {
                width: 'auto',
                maxWidth: 'calc(var(--tooltip-max-width) * 2)',
              }
            : {}),
        }}
        arrow={_.flags?.['release-nudge-arrows']}
      >
        <LayoutTemplate
          layout={isMobileDevice ? 'classic' : step.form_factor.layout}
          headerAndMarkdown={header}
          close={close}
          media={media}
          footer={footer}
          actions={actions}
          stepCounter={stepCounter}
          columnWidth="var(--tooltip-max-width)"
        />
      </StyledTooltip>
    </BindThemeV2ForNudge>
  );
};
