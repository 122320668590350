import { InfoCircle } from '@commandbar/design-system/icons/react';
import styled from '@emotion/styled';
import { useAppContext } from 'editor/src/AppStateContext';
import React from 'react';
import { Form } from '@commandbar/design-system/components/antd';
import { CmdSwitch } from '@commandbar/design-system/cmd';

const SettingBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InlineRow = styled(Form.Item)`
  margin: 0 24px 8px 0;
  .ant-row {
    display: flex;
    justify-content: space-between;
    flex-flow: initial;
  }

  .ant-form-horizontal .ant-form-item-control {
    flex: 0;
  }
  .ant-form-item-control {
    flex-grow: 0;
  }
`;

export const AISettings = () => {
  const {
    organization,
    dispatch: {
      organization: { updateSetting },
    },
    flags,
  } = useAppContext();

  return (
    <SettingBox style={{ width: '100%' }}>
      <Form layout="horizontal">
        <InlineRow
          colon={false}
          label="Enable Copilot"
          name="copilot_enabled"
          tooltip={{
            icon: <InfoCircle height={16} />,
            title: 'AI chat will be enabled for your organization.',
          }}
        >
          <CmdSwitch
            checked={!!organization?.copilot_enabled}
            onCheckedChange={(value) => {
              updateSetting({
                copilot_enabled: value,
                helphub_suggested_queries_enabled: value,
                helphub_continuations_enabled: value,
              });
            }}
          />
        </InlineRow>

        <InlineRow
          colon={false}
          name="helphub_suggested_queries_enabled"
          label="Initial query suggestions"
          tooltip={{
            icon: <InfoCircle height={16} />,
            title:
              'Show query suggestions at the start of chats. Suggestions will be automatically generated, or you can customize them in Copilot > Settings > Interactions > Suggested queries.',
          }}
        >
          <CmdSwitch
            checked={!!organization?.helphub_suggested_queries_enabled}
            disabled={!organization?.copilot_enabled}
            onCheckedChange={(value) => {
              updateSetting({ helphub_suggested_queries_enabled: value });
            }}
          />
        </InlineRow>
        <InlineRow
          colon={false}
          label="Follow-up query suggestions"
          name="helphub_continuations_enabled"
          tooltip={{
            icon: <InfoCircle height={16} />,
            title: 'Suggest relevant follow-up queries during chats.',
          }}
        >
          <CmdSwitch
            checked={!!organization?.helphub_continuations_enabled}
            disabled={!organization?.copilot_enabled}
            onCheckedChange={(value) => {
              updateSetting({ helphub_continuations_enabled: value });
            }}
          />
        </InlineRow>
        {flags['release-themes-v2'] && flags['release-chat-history'] && (
          <InlineRow
            colon={false}
            label="Chat History"
            name="copilot_chat_history_enabled"
            tooltip={{
              icon: <InfoCircle height={16} />,
              title: 'End users will be able to view all of their previous copilot chats',
            }}
          >
            <CmdSwitch
              checked={!!organization?.copilot_chat_history_enabled}
              disabled={!organization?.copilot_enabled || !organization.force_end_user_identity_verification}
              tooltip={
                !organization.force_end_user_identity_verification
                  ? 'Force identity verification required to show chat history'
                  : undefined
              }
              onCheckedChange={(value) => {
                updateSetting({ copilot_chat_history_enabled: value });
              }}
            />
          </InlineRow>
        )}
        <InlineRow
          colon={false}
          label="Expand 'Read more' by default"
          name="copilot_expand_read_more_by_default"
          tooltip={{
            icon: <InfoCircle height={16} />,
            title: 'Cited sources from AI messages will be expanded by default.',
          }}
        >
          <CmdSwitch
            checked={!!organization?.copilot_expand_read_more_by_default}
            disabled={!organization?.copilot_enabled}
            onCheckedChange={(value) => {
              updateSetting({ copilot_expand_read_more_by_default: value });
            }}
          />
        </InlineRow>
      </Form>
    </SettingBox>
  );
};

export default AISettings;
