/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useRef, useEffect, useState } from 'react';
import LoadingIndicator from 'shared/components/LoadingIndicator';
import { useStyles } from '../../helphub/components/useStyles';
import { Send03, RefreshCw01, Attachment01 } from '@commandbar/design-system/icons/react';
import { CB_COLORS } from '@commandbar/design-system/colors';
import StyledInputContainer from '@commandbar/internal/client/themesV2/components/shared/StyledInputContainer';
import StyledCopilotInputButtons from '@commandbar/internal/client/themesV2/components/copilot/StyledCopilotInputButtons';
import StyledTertiaryIconButton from '@commandbar/internal/client/themesV2/components/shared/StyledTertiaryIconButton';
import { useStore } from '@commandbar/commandbar/shared/util/hooks/useStore';
import StyledTextArea from '@commandbar/internal/client/themesV2/components/shared/StyledTextArea';
import { emptyGlobalStore } from '@commandbar/commandbar/shared/store/global-store';
import { useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { interpolate } from '@commandbar/commandbar/shared/util/Interpolate';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import Logger from '@commandbar/internal/util/Logger';
import StyledCopilotAttachment from '@commandbar/internal/client/themesV2/components/copilot/StyledCopilotAttachment';
import { useChatState } from '../store/useChatState';
import { IAIMessageType, IUserAttachmentType } from '@commandbar/internal/middleware/types';
import StyledSecondaryIconButton from '@commandbar/internal/client/themesV2/components/shared/StyledSecondaryIconButton';
import { Chat } from '@commandbar/internal/middleware/chat';
import { BsStopCircleFill } from 'react-icons/bs';
import useResizeObserver from '@commandbar/commandbar/shared/util/hooks/useResizeObserver2';

type MessageInputProps = {
  preview: boolean;
  isLoading: boolean;
  resetChat: () => void;
  onSubmit: (query: string, attachments?: IUserAttachmentType[]) => void;
  isChatHistoryPreview: boolean;
  streamingBotMessage?: IAIMessageType;
  previewOverrides?: { useThemeV2: boolean; messageInputPlaceholder: string };
};

const MessageInput = React.forwardRef<HTMLDivElement, MessageInputProps>((props, ref) => {
  const { preview, isLoading, onSubmit, isChatHistoryPreview, streamingBotMessage, previewOverrides } = props;
  const { chatID } = useChatState();
  const styles = useStyles(preview);

  const chatInput = useRef<HTMLTextAreaElement>(null);
  const attachmentUploader = useRef<HTMLInputElement>(null);

  const [attachment, setAttachment] = React.useState<File>();
  const [attachmentURL, setAttachmentURL] = React.useState<string>();
  const [query, setQuery] = React.useState('');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const _ = !preview ? useStore() : emptyGlobalStore();

  const themeV2 = useThemeV2Context();

  const { ref: inputButtonsRef, width: inputButtonsWidth } = useResizeObserver();
  const { ref: attachmentRef, height: attachmentHeight } = useResizeObserver();

  useEffect(() => {
    // Don't pull focus off of Copilot settings' inputs and re-focus when
    // the input is no longer disabled
    if (!_.helpHub.previewedCopilotSettings) {
      chatInput.current?.focus();
    }
  }, []);

  useEffect(() => {
    const uploadImage = async (image: File) => {
      try {
        const formData = new FormData();
        formData.append('attachment', image);
        const response = await axiosInstance.post(`ml/chats/${chatID}/upload`, formData);
        const url = response.data.object_url;
        setAttachmentURL(url);
        if (chatInput.current) {
          chatInput.current.focus();
          chatInput.current.style.height = `calc(${chatInput.current.style.height} + 48px)`;
        }
      } catch (error) {
        Logger.error('Attachment upload failed', error);
        setAttachment(undefined);
      }
    };
    if (attachment) {
      uploadImage(attachment);
    } else {
      setAttachmentURL(undefined);
    }
  }, [attachment]);

  const handleSubmit = () => {
    onSubmit(
      query,
      attachmentURL
        ? [{ type: 'image_url', media_preview_url: attachmentURL, title: 'Photo', description: attachment?.name }]
        : undefined,
    );
    setQuery('');
    setAttachment(undefined);
    if (chatInput.current) {
      chatInput.current.style.height = 'unset';
      chatInput.current.focus();
    }
  };

  const handleStopAnswerGeneration = () => {
    if (streamingBotMessage && streamingBotMessage.uuid) {
      Chat.abortAnswerGeneration(chatID, streamingBotMessage.uuid);
    }
  };

  const [shouldSubmitAfterAbort, setShouldSubmitAfterAbort] = useState(false);

  useEffect(() => {
    const waitUntilNotLoading = async () => {
      if (shouldSubmitAfterAbort && !isLoading) {
        handleSubmit();
        setShouldSubmitAfterAbort(false); // Reset after handling submit
      }
    };

    waitUntilNotLoading();
  }, [isLoading, shouldSubmitAfterAbort]);

  const showAttachmentUploader = _.flags?.['release-copilot-attachments'] && !attachment;
  const disabled = preview || isChatHistoryPreview;

  return (preview && previewOverrides?.useThemeV2) || _.flags?.['release-themes-v2'] ? (
    <StyledInputContainer ref={ref}>
      <StyledTextArea
        themeV2={themeV2}
        style={{
          paddingRight: (inputButtonsWidth ?? 0) + 8,
          paddingTop: `calc(${
            attachmentHeight ?? '0'
          }px + var(--form-control-padding-x) + var(--form-control-height) / 2 - 12px)`,
          maxHeight: '192px',
          cursor: disabled ? 'not-allowed' : 'auto',
          minHeight: 'calc(var(--form-control-height) + 2 * var(--form-control-padding-x))',
        }}
        rows={1}
        inputMode="search"
        disabled={disabled}
        value={query ?? ''}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (!!query.trim().length) {
              if (isLoading) {
                handleStopAnswerGeneration();
              }
              setShouldSubmitAfterAbort(true);
            }
          }
        }}
        onChange={(e) => {
          setQuery(e.currentTarget.value);
          // this makes it so our input resizes properly when we delete some of the query
          e.currentTarget.style.height = '1px';
          e.currentTarget.style.height = e.currentTarget.scrollHeight + 2 + 'px';
        }}
        data-testid="helphub-input"
        placeholder={
          preview
            ? previewOverrides?.messageInputPlaceholder
            : interpolate(_.localizedMessages['copilot.chat-input-placeholder'], _, true, false)
        }
        ref={chatInput}
      />

      <StyledCopilotAttachment
        themeV2={themeV2}
        attachment={attachmentURL}
        onDelete={() => {
          setAttachment(undefined);
          if (chatInput.current) {
            // this makes it so our input resizes properly when we delete the attachment
            chatInput.current.style.height = '1px';
            chatInput.current.style.height = chatInput.current.scrollHeight + 2 - (attachmentHeight ?? 0) + 'px';
          }
        }}
        ref={attachmentRef}
      />

      <StyledCopilotInputButtons ref={inputButtonsRef}>
        {showAttachmentUploader && (
          <StyledTertiaryIconButton
            themeV2={themeV2}
            disabled={disabled}
            onClick={() => attachmentUploader.current?.click()}
          >
            <Attachment01 style={{ transform: 'rotate(-45deg)' }} />
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              ref={attachmentUploader}
              onChange={(e) => setAttachment(e.target.files?.[0])}
              style={{ display: 'none' }}
            />
          </StyledTertiaryIconButton>
        )}
        {!isLoading && (
          <StyledTertiaryIconButton
            themeV2={themeV2}
            disabled={disabled || !query.trim().length}
            style={{ ...(!query.trim().length && { opacity: '0.6' }) }}
            onClick={handleSubmit}
          >
            <Send03 />
          </StyledTertiaryIconButton>
        )}
        {isLoading && (
          <StyledSecondaryIconButton themeV2={themeV2} disabled={disabled} onClick={handleStopAnswerGeneration}>
            <BsStopCircleFill />
          </StyledSecondaryIconButton>
        )}
      </StyledCopilotInputButtons>
    </StyledInputContainer>
  ) : (
    <div css={styles.chatTextAreaContainer}>
      <div style={styles.chatTextAreaWrapper}>
        <textarea
          inputMode="search"
          disabled={disabled}
          suppressContentEditableWarning={true}
          ref={chatInput}
          placeholder={interpolate(styles.strings.chatInputPlaceholder, _, true, false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !isLoading && !!query.trim().length) {
              e.preventDefault();
              handleSubmit();
            }
          }}
          onChange={(e) => {
            setQuery(e.currentTarget.value);
            e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
          }}
          css={styles.chatTextArea}
          rows={1}
          value={query ?? ''}
        />

        <div
          style={{
            display: 'flex',
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
          css={css`
            & button {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 32px;
              height: 32px;
              background: none;
              border: none;
              color: ${CB_COLORS.neutral600};
              cursor: pointer;
            }
          `}
        >
          <button disabled={disabled} onClick={() => props.resetChat()}>
            <RefreshCw01 width={16} height={16} />
          </button>

          {isLoading ? (
            <div
              style={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <LoadingIndicator size={16} isLoading />
            </div>
          ) : (
            <button
              disabled={disabled}
              onClick={() => {
                if (query && !!query.trim().length) {
                  handleSubmit();
                }
              }}
            >
              <Send03
                width={16}
                height={16}
                style={{
                  ...(!query.length && { opacity: '0.6' }),
                }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default MessageInput;
