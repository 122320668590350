import { Fragment } from 'react';
import { Spin, Result } from '@commandbar/design-system/components/antd';
import * as Organization from '@commandbar/internal/middleware/organization';
import { useReportEvent } from '../../../../hooks/useEventReporting';
import { CmdButton, CmdInput, CmdLabel, cmdToast } from '@commandbar/design-system/cmd';
import { ISegmentWebhookIntegrationProps } from './SegmentWebhookIntegrationPage';
import { useAppContext } from 'editor/src/AppStateContext';
import * as axiosInstance from '@commandbar/internal/middleware/network';
import { Copy02 } from '@commandbar/design-system/icons/react';

interface SegmentWebhookEntryStepProps {
  handleNext: Function;
  handlePrev: Function;
}

export const segmentWebhookDetailsDisplay = (webhookEndpoint: string, shared_secret: string) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%' }}>
      <CmdLabel>Webhook URL</CmdLabel>
      <CmdInput
        fullWidth
        suffixElement={
          <CmdButton
            size="sm"
            style={{ padding: '0px' }}
            variant="ghost"
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(webhookEndpoint);
                cmdToast.success('URL copied to clipboard!');
              } catch (e) {
                cmdToast.error('Unable to copy URL to clipboard. Please try again');
              }
            }}
          >
            <Copy02 />
          </CmdButton>
        }
        value={webhookEndpoint}
      />
      <CmdLabel style={{ marginTop: '8px' }}>Shared Secret</CmdLabel>

      <CmdInput
        fullWidth
        suffixElement={
          <CmdButton
            size="sm"
            style={{ padding: '0px' }}
            variant="ghost"
            onClick={async () => {
              try {
                await navigator.clipboard.writeText(shared_secret);
                cmdToast.success('Shared secret copied to clipboard!');
              } catch (e) {
                cmdToast.error('Unable to copy secret to clipboard. Please try again');
              }
            }}
          >
            <Copy02 />
          </CmdButton>
        }
        value={shared_secret}
      />
    </div>
  );
};

export const SegmentWebhookEntryStep = ({
  handleNext,
  setSegmentWebhookConfig,
  segmentWebhookConfig,
  loading,
}: SegmentWebhookEntryStepProps & ISegmentWebhookIntegrationProps) => {
  const { organization } = useAppContext();

  const { reportEvent } = useReportEvent();

  const apiURL = process.env.REACT_APP_API_URL || 'https://api.commandbar.com';
  const webhookEndpoint = `${apiURL}/api/${organization.id}/segment/`;

  const removeConfiguration = async () => {
    const { segment_webhook, ...otherIntegrations } = await (
      await Organization.readInternal(`${organization.id}`)
    ).integrations;

    await Organization.updateInternal({
      id: `${organization.id}`,
      integrations: otherIntegrations,
    });
    setSegmentWebhookConfig(undefined);
  };

  return (
    <div>
      <Spin spinning={loading} tip="Checking for previous configuration...">
        {segmentWebhookConfig ? (
          <Result
            status="success"
            title="You have enabled Command AI as a Segment Webhook Destination"
            extra={
              <Fragment>
                {segmentWebhookDetailsDisplay(webhookEndpoint, segmentWebhookConfig.shared_secret)}
                <CmdButton
                  variant="destructive"
                  key="remove"
                  onClick={() => {
                    removeConfiguration();
                    reportEvent('segment integration interacted with', {
                      segment: true,
                      highlight: true,
                      slack: true,
                      payloadMessage: 'Reset Credentials',
                    });
                  }}
                >
                  Remove existing integration
                </CmdButton>
              </Fragment>
            }
          />
        ) : (
          <Fragment>
            <p>
              Start by creating a new Webhook (Actions) Destination in your Segment account. You will be able to
              configure the destination after you have created it. Click 'Continue' to active the webhook on
              CommandBar's end and retrieve the URL and shared secret you need to finish the setup within Segment.
            </p>
            <CmdButton
              onClick={async () => {
                const response = await axiosInstance.post('integrations/segment-webhook/activate/');

                if (response.data?.shared_secret) {
                  setSegmentWebhookConfig({ shared_secret: response.data.shared_secret });
                  handleNext();
                } else {
                  cmdToast.error('Unable to activate webhook. Please try again');
                }
              }}
            >
              Continue
            </CmdButton>
          </Fragment>
        )}
      </Spin>
    </div>
  );
};
