/** @jsx jsx */
import React, { CSSProperties, RefCallback, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import useTheme from 'shared/util/hooks/useTheme';
import { jsx } from '@emotion/core';
import positionTooltip, {
  BEACON_INSET,
  TPin,
  findScrollParent,
  getElementCenter,
  calculateNewScrollPosition,
  isElementOffScreen,
  scrollToElement,
} from './helpers';
import { toPx } from '@commandbar/internal/client/theme';
import Z from '@commandbar/internal/client/Z';
import CloseButton from '../CloseButton';
import { DraftFooter } from 'shared/components/admin-facing/DraftFooter';
import type { ITheme } from '@commandbar/internal/client/theme';
import ContentContainer from '../ContentContainer';
import type { INudgePinStepType, INudgeStepContentButtonBlockType } from '@commandbar/internal/middleware/types';
import { isStudioPreview } from '@commandbar/internal/util/location';
import LocalStorage from '@commandbar/internal/util/LocalStorage';
import { useAction } from 'shared/util/hooks/useAction';
import { RenderMode } from '../RenderNudge';
import { getNudgeService, getPinAnchors, isNudgeDismissible, isNudgeSnoozable } from '../../store/selectors';
import { dismissNudge, dismissNudgeTemporarily } from '../../store/actions';
import { useMobileExperience } from 'shared/util/hooks/useMobileExperience';
import { hideMask, showMask } from 'shared/util/hooks/useMask';
import { useStore } from 'shared/util/hooks/useStore';
import { getSentry } from '@commandbar/internal/util/sentry';
import { NudgeContext } from '../../store/nudgeMachine';

import StyledNudgeBeacon from '@commandbar/internal/client/themesV2/components/nudge/StyledNudgeBeacon';
import { useLinkClickHandler } from '../../hooks/useLinkClickHandler';
import { BindThemeV2ForNudge, useThemeV2Context } from '@commandbar/commandbar/shared/components/ThemeV2Context';
import { useMergeRefs } from '@commandbar/internal/hooks/useMergeRefs';
import { Layout } from './Layout';
import {
  useAnimatedWidget,
  AnimatedWidget,
} from '@commandbar/internal/client/themesV2/components/animations/AnimatedWidget';
import { SurveyResponseProvider } from '../SurveyResponseProvider';

const BEACON_SIZE = 16;

export const getStyles = (theme: ITheme, autoWidth: boolean): Record<string, CSSProperties> => ({
  pin: {
    position: 'absolute',
    display: 'flex',
    gap: '16px',
    pointerEvents: 'none',
    zIndex: Z.Z_NUDGE,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: autoWidth ? 'auto' : theme.nudgePin.width,
    boxShadow: theme.nudgePin.boxShadow,
    borderRadius: theme.nudgePin.borderRadius,
    backgroundColor: theme.nudgePin.background,
    fontFamily: theme.nudgePin.fontFamily,
    color: theme.nudgePin.color,
    zIndex: Z.Z_NUDGE - 1,
    pointerEvents: 'auto',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.nudgePin.padding,
    gap: theme.nudgePin.gap,
  },
  header: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px' },
  title: {
    fontFamily: theme.nudgePin.titleFontFamily,
    fontWeight: theme.nudgePin.titleFontWeight,
    fontSize: theme.nudgePin.titleFontSize,
    lineHeight: theme.nudgePin.titleLineHeight,
    color: theme.nudgePin.titleColor,
  },
  content: {
    fontFamily: theme.nudgePin.contentFontFamily,
    fontWeight: theme.nudgePin.contentFontWeight,
    fontSize: theme.nudgePin.contentFontSize,
    lineHeight: theme.nudgePin.contentLineHeight,
    color: theme.nudgePin.contentColor,
  },
  ctaButton: {
    padding: theme.nudgePin.ctaPadding,
    background: theme.nudgePin.ctaBackground,
    color: theme.nudgePin.ctaColor,
    border: theme.nudgePin.ctaBorder,
    boxShadow: theme.nudgePin.ctaBoxShadow,
    textShadow: theme.nudgePin.ctaTextShadow,
    borderRadius: theme.nudgePin.ctaBorderRadius,
    fontFamily: theme.nudgePin.ctaFontFamily,
    fontSize: theme.nudgePin.ctaFontSize,
    lineHeight: theme.nudgePin.ctaLineHeight,
    fontWeight: theme.nudgePin.ctaFontWeight,
  },
  ctaSecondaryButton: {
    fontFamily: theme.nudgePin.ctaSecondaryFontFamily,
    padding: theme.nudgePin.ctaSecondaryPadding,
    background: theme.nudgePin.ctaSecondaryBackground,
    color: theme.nudgePin.ctaSecondaryColor,
    border: theme.nudgePin.ctaSecondaryBorder,
    boxShadow: theme.nudgePin.ctaSecondaryBoxShadow,
    textShadow: theme.nudgePin.ctaSecondaryTextShadow,
    borderRadius: theme.nudgePin.ctaSecondaryBorderRadius,
    fontSize: theme.nudgePin.ctaSecondaryFontSize,
    lineHeight: theme.nudgePin.ctaSecondaryLineHeight,
    fontWeight: theme.nudgePin.ctaSecondaryFontWeight,
  },
  snoozeButton: {
    background: theme.nudgePin.snoozeButtonBackground,
    fontFamily: theme.nudgePin.snoozeButtonFontFamily,
    color: theme.nudgePin.snoozeButtonColor,
    border: theme.nudgePin.snoozeButtonBorder,
    textShadow: theme.nudgePin.snoozeButtonTextShadow,
    fontSize: theme.nudgePin.snoozeButtonFontSize,
    lineHeight: theme.nudgePin.snoozeButtonLineHeight,
    fontWeight: theme.nudgePin.snoozeButtonFontWeight,
    width: theme.nudgePin.snoozeButtonWidth,
  },
  closeButtonOverrides: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  beaconOuter: {
    position: 'relative',
    borderRadius: '100%',
    width: toPx(BEACON_SIZE),
    height: toPx(BEACON_SIZE),
    backgroundColor: theme.nudgePin.beaconColor,
    zIndex: Z.Z_NUDGE,
  },
  beaconInner: {
    borderRadius: '100%',
    width: toPx(BEACON_SIZE),
    height: toPx(BEACON_SIZE),
    backgroundColor: theme.nudgePin.beaconColor,
    position: 'absolute',
    animationDuration: '1.5s',
    animationTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationDirection: 'normal',
    animationFillMode: 'none',
    animationPlayState: 'running',
  },
  stepCount: {
    color: theme.nudgePin.stepCountColor,
    fontFamily: theme.nudgePin.stepCountFontFamily,
    fontSize: theme.nudgePin.stepCountFontSize,
    fontWeight: theme.nudgePin.stepCountFontWeight,
  },
});

export interface BeaconProps {
  isOpen: boolean;
  onClick: () => void;
  styles: Record<string, CSSProperties>;
  renderUnAnchoredMock: boolean;
}

const Beacon = ({ isOpen, onClick, styles, renderUnAnchoredMock }: BeaconProps) => {
  const { theme } = useTheme();
  const _ = useStore();
  const themeV2 = useThemeV2Context();

  return _.flags?.['release-themes-v2'] ? (
    <StyledNudgeBeacon
      themeV2={themeV2}
      onClick={onClick}
      style={{
        right: renderUnAnchoredMock ? '20px' : `-${BEACON_INSET}px`,
        bottom: renderUnAnchoredMock ? '20px' : `-${BEACON_INSET}px`,
        cursor: isOpen ? 'auto' : 'pointer',
        pointerEvents: isOpen ? 'none' : 'all',
      }}
      data-testid="commandbar-nudge-pin-beacon"
    />
  ) : (
    <button
      type="button"
      data-testid="commandbar-nudge-pin-beacon"
      style={{
        ...styles.beaconOuter,
        top: BEACON_INSET,
        left: BEACON_INSET,
        cursor: isOpen ? 'auto' : 'pointer',
        pointerEvents: isOpen ? 'none' : 'all',
        // unstyled button
        display: 'flex',
        border: 'none',
        padding: 0,
      }}
      onClick={onClick}
      disabled={isOpen}
    >
      <div
        style={styles.beaconInner}
        css={{
          animationName: 'ping',
          '@keyframes ping': {
            '75%, to': {
              transform: `scale(${theme.nudgePin.beaconPulseScale})`,
              opacity: 0,
            },
          },
        }}
      />
    </button>
  );
};

interface ContentProps {
  styles: Record<string, React.CSSProperties>;
  pin: TPin;
  isOpen: boolean;
  service: ReturnType<typeof getNudgeService>;
  stepCount: string | undefined;
  renderMode: RenderMode;
  dismissNudge: () => void;
  stepIndex: number;
  onMockAction: (action: INudgeStepContentButtonBlockType['meta']) => void;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ styles, pin, isOpen, service, stepCount, renderMode, dismissNudge, stepIndex, onMockAction }, ref) => {
    const _ = useStore();
    const handleLinkClick = useLinkClickHandler(dismissNudge);
    const contentStyles: CSSProperties = pin.targetEl
      ? {
          ...styles.container,
          visibility: isOpen ? 'visible' : 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,

          transform: undefined,
        }
      : {
          ...styles.container,
          visibility: isOpen ? 'visible' : 'hidden',
          top: `${BEACON_SIZE}px`,
          left: `${BEACON_INSET}px`,
        };

    return (
      <SurveyResponseProvider service={service} step={pin.step}>
        {_.flags?.['release-themes-v2'] ? (
          <Layout
            ref={ref}
            nudge={pin.nudge}
            step={pin.step}
            handleLinkClick={handleLinkClick}
            isOpen={isOpen}
            dismissNudge={dismissNudge}
            stepCount={stepCount}
            service={service}
            styles={styles}
            renderMode={renderMode}
            stepIndex={stepIndex}
          />
        ) : (
          <div
            data-testid="commandbar-nudge-pin-content"
            ref={ref}
            style={contentStyles}
            aria-labelledby="commandbar-nudge-title"
          >
            <div style={styles.body}>
              <div style={styles.header}>
                <span id="commandbar-nudge-title" style={styles.title} role="heading" aria-level={3}>
                  {pin.step.title}
                </span>
                {isNudgeDismissible(pin.nudge) && <CloseButton onInteraction={dismissNudge} />}
              </div>
              <ContentContainer
                step={pin.step}
                service={service}
                markdownStyles={styles.content}
                primaryButtonStyles={styles.ctaButton}
                secondaryButtonStyles={styles.ctaSecondaryButton}
                snoozeButtonStyles={styles.snoozeButton}
                stepCountStyles={styles.stepCount}
                stepCount={stepCount}
                renderMode={renderMode}
                snoozable={isNudgeSnoozable(pin.nudge)}
                snoozeLabel={pin.nudge.snooze_label}
                onMockAction={onMockAction}
                handleContentLinkClick={handleLinkClick}
                stepIndex={stepIndex}
                snoozable_on_all_steps={pin.nudge.snoozable_on_all_steps}
              />
            </div>
            {!pin.nudge.is_live && renderMode === RenderMode.MOCK && (
              <DraftFooter details={{ type: 'nudge', nudge: pin.nudge, stepIndex }} />
            )}
          </div>
        )}
      </SurveyResponseProvider>
    );
  },
);

interface PinPositioningProps {
  pin: TPin;
  isOpen: boolean;
  dismissNudge: () => void;
  beaconRef: React.RefObject<HTMLDivElement>;
  contentRef: React.RefObject<HTMLDivElement>;
  isAnimatedWidget: boolean;
}

const usePinPositioning = ({
  pin,
  isOpen,
  dismissNudge,
  beaconRef,
  contentRef,
  isAnimatedWidget,
}: PinPositioningProps) => {
  useEffect(() => {
    if (beaconRef.current && contentRef.current) {
      const cleanupMutationObserver = positionTooltip(
        pin,
        beaconRef.current,
        contentRef.current,
        isOpen,
        dismissNudge,
        isAnimatedWidget,
      );

      return () => {
        if (cleanupMutationObserver) cleanupMutationObserver();
      };
    }
  }, [beaconRef, contentRef, dismissNudge, isOpen, pin]);
};

interface PinTargetClickHandlerProps {
  pin: TPin;
  onClick: () => void;
}

const usePinTargetClickHandler = ({ pin, onClick }: PinTargetClickHandlerProps) => {
  const handlePinTargetClick = useCallback(
    (event: MouseEvent) => {
      /* eslint-disable commandbar/no-event-target */
      const target = event.target;
      if (!(target instanceof Node)) return;

      if (pin.step.form_factor.advance_trigger) {
        if (pin.advanceTriggerEl?.contains(target)) {
          onClick();
        }
      } else if (pin.targetEl?.contains(target)) {
        onClick();
      }
    },
    [onClick, pin.advanceTriggerEl, pin.targetEl],
  );

  useEffect(() => {
    document.addEventListener('click', handlePinTargetClick, true);

    return () => {
      document.removeEventListener('click', handlePinTargetClick, true);
    };
  }, [handlePinTargetClick]);
};

interface ScrollToTargetElProps {
  pin: TPin;
}

/**
 * Scrolls to the target element if it is off screen and the pin is open by default.
 * Element scrolled will be the targetEl's closest scrollable parent, or the window if none is found.
 */
const useScrollToTargetEl = ({ pin }: ScrollToTargetElProps) => {
  useEffect(() => {
    try {
      const targetEl = pin.targetEl;
      if (!targetEl) return;

      const targetElRect = targetEl.getBoundingClientRect();

      const parentScrollable = findScrollParent(targetEl, pin.nudge);
      if (pin.isOpenByDefault && isElementOffScreen(targetElRect, parentScrollable)) {
        const elementCenter = getElementCenter(targetElRect);
        const scrollContainer = parentScrollable || document.documentElement || window;

        const newScrollPosition = calculateNewScrollPosition(elementCenter, scrollContainer, {
          isScrollableContainer: !!parentScrollable,
        });

        const scrollToOptions: ScrollToOptions = {
          behavior: 'smooth',
          left: newScrollPosition.x,
          top: newScrollPosition.y,
        };

        scrollToElement(parentScrollable || window, scrollToOptions);
      }
    } catch (error) {
      getSentry()?.captureException(error);
    }
  }, []);
};

interface MaskingProps {
  pin: TPin;
}

const useMasking = ({ pin }: MaskingProps) => {
  useEffect(() => {
    if (pin.isShowingMask && pin.targetEl) {
      showMask(pin.targetEl);
    }

    return () => {
      hideMask();
    };
  }, [pin]);
};

export interface PinProps {
  pin: TPin;
  renderMode: RenderMode;
  stepIndex: number;
  stepCount?: string;
  anchorOverride?: string;
  style?: React.CSSProperties;
}

const usePin = (pin: TPin, renderMode: RenderMode, anchorOverride?: string) => {
  const _ = useStore();
  const [context, setContext] = useState<NudgeContext>();

  const service = renderMode !== RenderMode.MOCK ? getNudgeService(_, pin.nudge.id) : undefined;

  useEffect(() => {
    if (service) {
      const unsub = service?.subscribe(({ context }) => {
        setContext(context);
      });
      return () => {
        unsub?.unsubscribe();
      };
    }
  }, [service]);

  const nudge = service && context ? context.nudge : pin.nudge;
  const step = service && context ? (context.nudge.steps[context.stepIndex] as INudgePinStepType) : pin.step;

  const { targetEl, advanceTriggerEl } = getPinAnchors(_, { step, anchorOverride });

  const steps = useMemo(
    () =>
      nudge.steps.map((_step, index) => {
        if (step.id === _step.id) {
          return {
            ..._step,
            index,
          };
        }

        return {
          ...step,
          index,
        };
      }),
    [step, nudge.steps],
  );

  const newNudge = useMemo(() => ({ ...nudge, steps: steps }), [steps, nudge]);

  const newPin = { ...pin, nudge: newNudge, step, targetEl, advanceTriggerEl } as TPin;

  return { pin: newPin, service };
};

const Pin = ({ pin: _pin, stepCount, stepIndex, renderMode, anchorOverride, style }: PinProps) => {
  const { theme }: { theme: ITheme } = useTheme();
  const _ = useStore();

  const { pin, service } = usePin(_pin, renderMode, anchorOverride);

  const [isOpen, setIsOpen] = React.useState<boolean>(pin.isOpenByDefault);
  const { onExit, onEnter, isAnimatedWidget } = useAnimatedWidget();

  const dismiss = useAction(dismissNudge);
  const dismissTemporarily = useAction(dismissNudgeTemporarily);

  const handleDismissNudge = () => {
    onExit(() => {
      if (_.extension.recorder.enabled) {
        dismissTemporarily({ nudge: pin.nudge, renderMode, stepIndex, anchorOverride });
      } else {
        dismiss(pin.nudge, renderMode);
      }
    });
  };

  const handleNudgeAction = (isPinClick?: boolean) => {
    onExit(() => {
      if (_.extension.recorder.enabled) {
        dismissTemporarily({ nudge: pin.nudge, renderMode, stepIndex, anchorOverride });
      } else {
        service?.send({ type: 'ADVANCE', isPinClick });
      }
    });
  };

  const { isMobileDevice, mobileStyles } = useMobileExperience();
  const styles = getStyles(
    theme,
    !isMobileDevice && pin.step.content.some((block) => block?.type === 'survey_rating' && block.meta.options === 10),
  );

  const beaconRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [{ width: contentWidth, height: contentHeight }, setContentDimensions] = useState({ width: 0, height: 0 });

  const measuredContentRef: RefCallback<HTMLDivElement> = useCallback(
    (node) => {
      if (node !== null) {
        const { width, height } = node.getBoundingClientRect();
        setContentDimensions({ width, height });
      }
    },
    [pin.step],
  );

  const mergedContentRef = useMergeRefs(contentRef, measuredContentRef);

  usePinPositioning({
    pin,
    beaconRef,
    contentRef,
    isOpen,
    dismissNudge: handleDismissNudge,
    isAnimatedWidget,
  });
  usePinTargetClickHandler({
    pin,
    onClick: () => {
      handleNudgeAction(true);
    },
  });
  useScrollToTargetEl({ pin });
  useMasking({ pin });

  const renderUnAnchoredMock = renderMode === RenderMode.MOCK && (!pin.targetEl || isStudioPreview());
  const editorWidth = Number(LocalStorage.get('width', '770'));

  // we should never render a pin if there is no targetEl and we are not in mock mode
  if (!(pin.targetEl || renderUnAnchoredMock)) {
    return null;
  }

  return (
    <div
      data-testid={`commandbar-pin-${pin.nudge.id}-${String(pin.step.id)}${
        renderMode === RenderMode.MOCK ? '-mock' : ''
      }`}
      ref={beaconRef}
      key={pin.step.title}
      className={`commandbar-nudge-pin ${renderUnAnchoredMock ? 'commandbar-unanchored-nudge-pin' : ''}`}
      style={{
        ...styles.pin,
        position: 'absolute',
        width: 'max-content',
        ...(isStudioPreview()
          ? {
              top: `calc(50vh - ${BEACON_SIZE}px - ${
                contentHeight === 0 ? contentHeight : contentHeight / 2
              }px - 51px)`,
              right: `calc(50% - ${BEACON_SIZE}px + ${contentWidth === 0 ? contentWidth : contentWidth / 2}px)`,
              transform: 'translate(50%, -50%)',
            }
          : {
              top: renderUnAnchoredMock
                ? `calc(50vh - ${BEACON_SIZE}px - ${contentHeight === 0 ? contentHeight : contentHeight / 2}px)`
                : 0,
              left: _.isEditorVisible
                ? `calc((100vw - ${editorWidth}px) / 2 + ${BEACON_SIZE}px - ${
                    editorWidth * 1.5 + contentWidth === 0 ? contentWidth : contentWidth / 2
                  }px)`
                : `calc(50% + ${BEACON_SIZE}px - ${
                    editorWidth * 1.5 + contentWidth === 0 ? contentWidth : contentWidth / 2
                  }px)`,
              transform: 'translate(-50%, -50%)',
            }),
        ...(renderUnAnchoredMock
          ? {}
          : {
              left: 0,
              visibility: 'hidden',
              opacity: 0,
            }),
        ...(isMobileDevice ? mobileStyles.nudges.pin.container : {}),
        ...style,
      }}
    >
      <Beacon
        isOpen={isOpen}
        onClick={() => {
          setIsOpen(true);
          onEnter();
        }}
        styles={styles}
        renderUnAnchoredMock={renderUnAnchoredMock}
      />
      <Content
        ref={mergedContentRef}
        styles={styles}
        pin={pin}
        isOpen={isOpen}
        service={service}
        stepCount={stepCount}
        renderMode={renderMode}
        dismissNudge={() => handleDismissNudge()}
        stepIndex={stepIndex}
        onMockAction={() => handleNudgeAction()}
      />
    </div>
  );
};

const PinContainer = (props: React.ComponentProps<typeof Pin>) => {
  return (
    <BindThemeV2ForNudge nudge={props.pin.nudge}>
      <AnimatedWidget widget="popover" keepMounted isOpenByDefault={props.pin.isOpenByDefault}>
        <Pin {...props} />
      </AnimatedWidget>
    </BindThemeV2ForNudge>
  );
};

export default PinContainer;
