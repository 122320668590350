import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';

import {
  AnalyticsProductTours,
  type AnalyticsProductToursDetailData,
} from '@commandbar/internal/middleware/analytics/productTours';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { getProductTourDetailsDemoData } from '../../analyticDemoData';
import useSurveyDetails from '../surveys/useSurveyDetails';
import { useCSVDownload } from '../../useCSVDownload';

export const useAnalyticProductTourDetails = ({ id, goalEventName }: { id: string; goalEventName?: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();
  const [data, setData] = useState<AnalyticsProductToursDetailData | null>(null);
  const [error, setError] = useState(false);

  const { surveyDetails, fetchData: fetchSurveyDetailsWithResponses } = useSurveyDetails({
    nudgeId: id,
    listFilter: 'most_recent',
    timeFilterRange,
  });

  const { downloadCsv, isDownloading } = useCSVDownload({
    name: 'product_tour',
    download: () =>
      AnalyticsProductTours.downloadDetail(id, {
        start_date: timeFilterRange.start_date,
        end_date: timeFilterRange.end_date,
        include_admin_data: includeAdminData.toString(),
      }),
  });

  const fetchData = useCallback(() => {
    setIsLoading(true);
    setError(false);
    AnalyticsProductTours.readDetail(id, {
      start_date: timeFilterRange.start_date,
      end_date: timeFilterRange.end_date,
      include_admin_data: includeAdminData.toString(),
      goal_event_name: goalEventName,
    })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(true);
      })
      .finally(() => setIsLoading(false));
    fetchSurveyDetailsWithResponses();
  }, [goalEventName, id, includeAdminData, timeFilterRange, fetchSurveyDetailsWithResponses]);

  useEffect(() => {
    if (isDemoData) {
      setData(getProductTourDetailsDemoData());
      setIsLoading(false);
    } else {
      fetchData();
    }
  }, [fetchData, isDemoData]);

  return {
    isLoading,
    data,
    error,
    surveyDetails,
    fetchData,
    downloadCsv,
    isDownloading,
  };
};
