import Available from './Available';
import { CBStore } from 'shared/store/global-store';
import { currentStepAndIndex } from './steps/selectors';
import { Step } from './steps/step-utils';
import { IPlaceholderType } from '@commandbar/internal/middleware/types';
import isEqual from 'lodash/isEqual';
import { runExecuteStepActionWithSideEffects } from './steps/actions';

import * as Reporting from 'shared/services/analytics/Reporting';
import { findDefaultFocusOptionIndex, isOption } from './selectors';
import { initBaseStep } from './steps/step-utils/BaseStep';
import { StepType } from './steps/step-utils/Step';
import { extractSlashFilter } from 'shared/store/helpers';

import { DashboardType } from './steps/step-utils/DashboardStep';
import { ICommandCategoryType } from '@commandbar/internal/middleware/types';
import { Option } from './options/option-utils';

import { getNextFocusedIndex, getFocusableChildrenAtIndex, getContextSettings } from './selectors';
import {
  fallbackGroup,
  initOptionGroupFromCommandCategory,
  initOptionGroupFromRecordCategory,
  OptionGroup,
} from './options/option-utils/OptionGroup';
import a11y from 'shared/util/a11y';
import { CommandCategory } from '@commandbar/internal/middleware/commandCategory';
import { createSearchFilter } from '../components/select/SearchTabs';
import slugify from '@commandbar/internal/util/slugify';
import { ISearchFilter } from 'shared/store/global-store';
import { DEFAULT_PLACEHOLDER } from '../components/select/input/placeholderHelpers';

import { ref } from 'valtio';
import { isStudioPreview } from '@commandbar/internal/util/location';
import { chooseOption } from './options/actions';
import { OpenedEventTrigger } from '@commandbar/commandbar/shared/services/analytics/EventHandler';
import { Track } from '@commandbar/commandbar/shared/services/analytics/v2/track';

export type InputEvent = { action: string };
export type StepUpdater = (steps: Step[], useFulfill?: boolean) => void;

export * from './steps/actions';
export * from './generate-options/actions';
export * from './options/actions';

////////////////////////////////////////////////////////////////////////
////////// Transition Functions
////////////////////////////////////////////////////////////////////////

const completeStep = (_: CBStore, step: Step): void => {
  if (step.type === 'execute') {
    runExecuteStepActionWithSideEffects(_, step);
  }
  step.completed = true;
};

// NOTE: This was previously called "fulfill"
export const completeNextStepAndSubsequentExecuteSteps = (_: CBStore): CBStore => {
  const firstIncompleteIndex = _.spotlight.steps.findIndex((step) => !step.completed);
  if (firstIncompleteIndex !== -1) {
    completeStep(_, _.spotlight.steps[firstIncompleteIndex]);

    // Complete subsequent 'execute' steps
    for (let i = firstIncompleteIndex + 1; i < _.spotlight.steps.length; i++) {
      const thisStep = _.spotlight.steps[i];
      if (!thisStep.completed) {
        if (thisStep.type === 'execute') {
          completeStep(_, thisStep);
        } else {
          break;
        }
      } else {
        // Note: it's important to let the loop pass if the step is completed, because you could have mid-steps being completed because of `pre-select` and resource options. I.e., if you have a sequence of [base, select, select, execute], you could have scenarios of [{ base, complete: true}, { select, complete: false}, { select, complete: true}, { execute, complete: false}]. Once the first select is completed, you'd want to continue through and run the execute step.
      }
    }
  }

  return _;
};

export const rebase = (_: CBStore): void => {
  const { currentStep } = currentStepAndIndex(_);

  if (currentStep === undefined) {
    // HACK: prevent closing the bar on command exec when using standalone editor
    if (!isStudioPreview()) {
      _.spotlight.visible = false;
    }
    _.spotlight.steps = [initBaseStep(null)];
    _.spotlight.initialOptions = ref(Available.available(_));
  } else {
    _.spotlight.visible = true;
    _.spotlight.initialOptions = ref(Available.available(_));
  }
};

export const fulfillAndRebase = (_: CBStore) => {
  rebase(completeNextStepAndSubsequentExecuteSteps(_));
};

export const updateSteps = (_: CBStore, updatedSteps: Step[], useFulfill = true) => {
  _.spotlight.steps = updatedSteps;

  if (useFulfill) {
    rebase(completeNextStepAndSubsequentExecuteSteps(_));
  }
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export const handleInputChange = (_: CBStore, newInput: string, event?: InputEvent) => {
  if (event?.action === 'input-blur' || event?.action === 'menu-close') {
    return;
  }
  const { currentStep } = currentStepAndIndex(_);
  // By default, set-value is triggered when enter is pressed in the bar
  // In multiselect scenarios, we don't want to change the text or update the options when enter is pressed
  // Explanation: https://www.loom.com/share/648a4f1550f44309a80a30f3fc95af8e
  if (currentStep?.type === StepType.MultiSelect && event?.action === 'set-value') {
    return;
  }

  // Reporting
  reportDeadendIfClosed(_, newInput, event);
  reportDeadendIfBackspaced(_, newInput);

  _.spotlight.reportKeystrokeDebouncer(() => reportKeystroke(_, newInput));

  _.spotlight.rawInput = newInput;

  let cleaninput = newInput;

  //handle slash filters
  if (!!_.organization?.slash_filters_enabled && currentStep?.type === StepType.Base) {
    const inputParts = extractSlashFilter(newInput);
    cleaninput = inputParts.inputText;
  }

  if (_.spotlight.inputText) {
    _.spotlight.previousInputText = _.spotlight.inputText;
  }
  _.spotlight.inputText = cleaninput.trim();

  if (!_.spotlight.visible && newInput !== '') {
    setVisible(_, true);
  }
};

export const reportDeadendIfBackspaced = (_: CBStore, newInput: string) => {
  if (newInput.length === 0 && _.spotlight.isBackspacing && _.spotlight.cachedInput.length > 0) {
    Reporting.deadend(_.spotlight.cachedInput, 'Backspaced', _);
    _.spotlight.cachedInput = '';
  }
};

export const reportDeadendIfClosed = (_: CBStore, newInput: string, event?: InputEvent) => {
  const didInputClear = newInput.length === 0 && _.spotlight.inputText.length > 0;
  const didMenuClose = event?.action === 'menu-close';

  if (didInputClear && didMenuClose) {
    Reporting.deadend(_.spotlight.inputText, 'Resetting search', _);
  }
};

export const reportKeystroke = (_: CBStore, newInput: string) => {
  Reporting.searchInputChange(newInput, _);
  Track.spotlight.searchInput(newInput);
};

export const reset = (_: CBStore) => {
  _.spotlight.steps = [initBaseStep(null)];
  _.spotlight.initialOptions = ref(Available.available(_));
};

export const resetFocusedIndex = (_: CBStore) => {
  _.spotlight.focusedIndex = findDefaultFocusOptionIndex(_);
  _.spotlight.focusableChildIndex = -1;
};

export const rollback = (_: CBStore) => {
  const { currentStep, currentStepIndex } = currentStepAndIndex(_);

  if (currentStep === undefined) {
    rebase(_);
    return;
  }

  // Get previous step
  let steps = _.spotlight.steps.map((step: Step, index: number) => {
    // Undo current step
    if (index === currentStepIndex) {
      step.selected = null;
      return step;
    }
    // Set previous step to not complete
    if (index === currentStepIndex - 1) {
      if (step.type === StepType.LongTextInput) {
        step.completed = false;
        return step;
      } else {
        if (step.type === StepType.Select) {
          step.argument.auto_choose = false;
        }

        step.completed = false;
        step.selected = null;
        return step;
      }
    }

    return step;
  });

  // If the previous step was a BaseStep, then reset to empty state
  // With Object search we can have multiple BaseSteps in a row, causing strange behavior unless we reset
  if (steps[currentStepIndex - 1]?.type === StepType.Base) {
    steps = [initBaseStep(null)];
  }

  _.spotlight.steps = steps;
  rebase(_);
};

export const setLogo = (_: CBStore, logo: string | undefined) => {
  _.logo = logo ?? '';
};

export const setVisible = (_: CBStore, value: boolean) => {
  if (value) {
    // Reset preivous input on new session; hidden -> visible
    if (_.spotlight.visible === false) _.spotlight.previousInputText = '';

    _.spotlight.visible = true;
  } else {
    _.spotlight.visible = false;
    _.spotlight.steps = [initBaseStep(null)];
  }
};

export const toggleGroupExpansion = (_: CBStore, header: string) => {
  const expanded = _.spotlight.expandedGroupKeys;
  const index = expanded.findIndex((_header: string) => _header === header);
  if (index > -1) {
    expanded.splice(index, 1);
  } else {
    expanded.push(header);
  }
};

export const changeGroupExpanded = (_: CBStore, header: string, isExpanded: boolean) => {
  const expanded = _.spotlight.expandedGroupKeys;
  const index = expanded.findIndex((_header: string) => _header === header);
  if (isExpanded) {
    if (index === -1) {
      // Add to expanded list
      expanded.push(header);
    }
  } else {
    if (index > -1) {
      // Remove from expanded list
      expanded.splice(index, 1);
    }
  }
};

export const setInputText = (_: CBStore, value: string) => {
  if (_.spotlight.inputText) {
    _.spotlight.previousInputText = _.spotlight.inputText;
  }
  _.spotlight.inputText = value;
};

export const toggleTestMode = (_: CBStore) => {
  _.testMode = !_.testMode;
};

export const setPlaceholders = (_: CBStore, placeholders: IPlaceholderType[]) => {
  // without checking for changes, redundant loading requests (e.g., logging into editor)
  //  change the active placeholder, which looks choppy
  const hasChanged = !isEqual(_.spotlight.placeholders, placeholders);

  if (hasChanged) {
    _.spotlight.placeholders = placeholders;
  }
};

/**
 * FIXME: the actions above use Store. The actions below use State
 */

type KeyEvent = { key: string; preventDefault: VoidFunction; stopPropagation: VoidFunction };
export interface OpenBarOptions {
  startingInput?: string;
  categoryFilterID?: number | string;
}

export const clearInput = (_: CBStore) => {
  if (_.spotlight.inputText) {
    _.spotlight.previousInputText = _.spotlight.inputText;
  }
  _.spotlight.inputText = '';
  _.spotlight.rawInput = '';
  _.spotlight.refContainer?.current?.focus();
};

export const closeBarAndReset = (_: CBStore) => {
  // Report the end of the search. Do it before resetting state
  Reporting.endSearch(_.spotlight.inputText, _);
  Track.spotlight.closed(_.spotlight.inputText);

  // @ts-expect-error: FIXME dashboard type definition
  if (_.spotlight.dashboard?.type?.name === 'SubmissionForm') {
    setDashboard(_, undefined);
    return;
  }

  // Close the bar and clean up
  setVisible(_, false);
  setDashboard(_, undefined);
  _.spotlight.searchFilter = undefined;
  // Reset focus to the top
  resetFocusedIndex(_);

  // Input value does not blink when command bar closes
  setTimeout(() => {
    _.spotlight.previousInputText = '';
    _.spotlight.inputText = '';
    _.spotlight.rawInput = '';
  }, 100);
};

export const handleKeyDown = (_: CBStore, e: KeyEvent) => {
  if (e.key === 'Backspace') {
    if (!_.spotlight.isBackspacing) {
      _.spotlight.isBackspacing = true;
      _.spotlight.cachedInput = _.spotlight.inputText;
    }
  } else {
    // Reset any dead end backspace tracking
    if (_.spotlight.isBackspacing) _.spotlight.isBackspacing = false;
    if (_.spotlight.cachedInput) _.spotlight.cachedInput = '';
  }
};

export const openBarWithOptionalText = (_: CBStore, trigger: OpenedEventTrigger, meta?: OpenBarOptions) => {
  if (!_.products.includes('spotlight')) {
    return;
  }

  // Make command bar visible, reset dashboard
  setDashboard(_, undefined);
  setVisible(_, true);
  resetFocusedIndex(_);

  // If there is text passed in, set the text in the bar
  if (meta?.startingInput) {
    handleInputChange(_, meta.startingInput);
  }

  // Set the search filter for the category
  if (meta?.categoryFilterID) {
    let groupToFilter: OptionGroup | undefined = undefined;

    if (typeof meta.categoryFilterID === 'number') {
      const categoryToFilter = _.spotlight.categories.find((cat) => cat.id === meta.categoryFilterID);
      if (categoryToFilter) {
        groupToFilter = initOptionGroupFromCommandCategory(categoryToFilter, _);
      }
    } else if (typeof meta.categoryFilterID === 'string') {
      //FIXME: currentGroups does included recordGroups already, but only after the first open as they are not counted as active before
      const recordGroups = Object.entries(getContextSettings(_)).map(([k, v]) => {
        const group = initOptionGroupFromRecordCategory(k, _, v);
        if (!!!group.slash_filter_keyword) {
          group.slash_filter_keyword = slugify(group.name);
        }
        return group;
      });

      const allGroups = [..._.spotlight.currentGroups, ...recordGroups];

      groupToFilter = allGroups.find((group) => meta.categoryFilterID === group.slash_filter_keyword);

      if (!groupToFilter) {
        groupToFilter = allGroups.find((group) => {
          return meta.categoryFilterID === group.name;
        });
      }
    }
    if (!!groupToFilter) {
      const filter = createSearchFilter(groupToFilter);
      setSearchFilter(_, filter);
    }
  }

  // Report the new search
  Reporting.startSearch(
    trigger,
    _.spotlight.placeholders.length > 0 ? _.spotlight.placeholders?.[0].text : DEFAULT_PLACEHOLDER,
  );
  Track.spotlight.opened({
    type: trigger,
  });
};

export const setSearchFilter = (_: CBStore, filter: ISearchFilter | undefined) => {
  if (!!filter) {
    _.spotlight.searchFilter = filter;
    changeGroupExpanded(_, _.spotlight.searchFilter.slug, true);
  } else {
    _.spotlight.searchFilter = undefined;
    _.spotlight.expandedGroupKeys = [];
  }
};

export const selectCurrentOption = (_: CBStore, event?: React.KeyboardEvent | React.MouseEvent) => {
  const currentOption = _.spotlight.sortedOptions[_.spotlight.focusedIndex];
  if (isOption(currentOption)) {
    const isFallback = currentOption.groupKey === fallbackGroup().key;
    selectOption(_, currentOption, isFallback, event);
  }
};

export const selectOption = (
  _: CBStore,
  opt: Option,
  preserveInputText?: boolean,
  event?: React.KeyboardEvent | React.MouseEvent,
) => {
  const { inputText } = _.spotlight;
  const { currentStep, currentStepIndex } = currentStepAndIndex(_);
  const { isDisabled } = opt.optionDisabled;

  if (!isDisabled) {
    _.spotlight.searchFilter = undefined;
    chooseOption(_, opt, undefined, undefined, false, event);

    Track.spotlight.searchResultClicked(opt, inputText);

    if (currentStep?.type !== StepType.MultiSelect) {
      handleInputChange(_, '');

      const { steps } = _.spotlight; // The steps get updated after a

      /**
       * Hack to interpolate value from the previous inputText into the next step.
       * Here we are calling the Engine.handleInputChange twice so as to force refresh
       * the state of the inputText.
       *
       * Additionally, we want to interpolate inputText only when in the base state
       * Without the hack, the value for the inputText is incorrectly interpolated.
       * See https://app.clickup.com/t/2zpxqag
       */
      if (steps.length > 1 && currentStepIndex === 0 && preserveInputText) {
        handleInputChange(_, inputText);
      }
    }
  } else {
    // TODO fix this hack; we want to set errorIndex to the index of `opt` but we don't have that index here.
    // This assumes that the option which was selected has index focusedIndex.
    _.spotlight.errorIndex = _.spotlight.focusedIndex;
    _.spotlight.errorTimestamp = Date.now();
  }
};

export const setDashboard = (_: CBStore, v: DashboardType | undefined) => {
  _.spotlight.dashboard = v;
};

export const setLoading = (_: CBStore, v: string, isLoading: boolean) => {
  _.spotlight.loadingByKey[v] = isLoading;
};

export const toggleBar = (_: CBStore, trigger: OpenedEventTrigger): boolean => {
  if (_.active) {
    if (_.spotlight.visible) {
      closeBarAndReset(_);
      return false;
    } else {
      openBarWithOptionalText(_, trigger);
      return true;
    }
  }
  return false;
};

export const toggleBarFromLauncher = (_: CBStore) => toggleBar(_, 'launcher');

/**
 * a11y focus functions
 * Note: "child" is the terminology given to the focusable elements within a row ("See more", editable shortcut, etc.)
 */
// a11y: reset focus to input and remove focus on child
export const resetChildFocus = (_: CBStore) => {
  _.spotlight.focusableChildIndex = -1;
  const inputEl = document.getElementById(a11y.commandbarInputId);
  if (inputEl) {
    inputEl.focus();
  }
};

export const changeFocus = (_: CBStore, direction: 'up' | 'down') => {
  _.spotlight.focusedIndex = getNextFocusedIndex(_, direction);
  resetChildFocus(_);
};

export const focusOutOfList = (_: CBStore) => {
  // Set index to -1 to indicate focus has moved out of the list.
  _.spotlight.focusedIndex = -1;
  _.spotlight.focusableChildIndex = -1;
};

// a11y: refocus on currently active child
export const maintainChildFocus = (_: CBStore) => {
  const focusableChildren = getFocusableChildrenAtIndex(_.spotlight.focusedIndex);

  if (focusableChildren && _.spotlight.focusableChildIndex < focusableChildren.length) {
    const el = focusableChildren.item(_.spotlight.focusableChildIndex);
    if (el && el instanceof HTMLElement) {
      el.focus();
    }
  }
};

// a11y: cycle through focus on an elements children (if any)
const tryChildFocus = (_: CBStore, direction: 'up' | 'down'): boolean => {
  const focusableChildren = getFocusableChildrenAtIndex(_.spotlight.focusedIndex);

  if (!focusableChildren) {
    return false;
  }

  const hasNextChild = focusableChildren.length > _.spotlight.focusableChildIndex + 1;
  const hasPrevChild = _.spotlight.focusableChildIndex > 0;

  if (direction === 'down' && hasNextChild) {
    _.spotlight.focusableChildIndex++;
  } else if (direction === 'up' && hasPrevChild) {
    _.spotlight.focusableChildIndex--;
  } else {
    resetChildFocus(_);
    return false;
  }

  const el = focusableChildren.item(_.spotlight.focusableChildIndex);
  if (el && el instanceof HTMLElement) {
    el.focus();
    return true;
  }
  return false;
};

// a11y: tab nav should cycle through all focusable elements instead of just options
export const changeFocusWithTab = (_: CBStore, direction: 'up' | 'down' | 'tab'): boolean => {
  // Returns true iff should break out of list, allowing focus to move elsewhere.
  if (_.spotlight.sortedOptions.length === 0) {
    return true;
  }

  if (direction === 'tab') {
    focusOutOfList(_);
    return true;
  }

  if (tryChildFocus(_, direction)) {
    // If already moving through children, continue to.
    return false;
  }

  // Break out of the option list if we're at the beginning or end
  // This allows focus on footer elements (if we're at the end), or tab elements (at the beginning)
  const next_index = getNextFocusedIndex(_, direction, true);
  if (direction === 'down') {
    if (next_index < _.spotlight.focusedIndex) {
      focusOutOfList(_);
      return true;
    }
  } else if (next_index > _.spotlight.focusedIndex) {
    focusOutOfList(_);
    return true;
  }

  _.spotlight.focusedIndex = next_index;
  tryChildFocus(_, 'down'); // If there is a child jump right to it.

  return false;
};

export const setCategories = (_: CBStore, categories: ICommandCategoryType[]) => {
  _.spotlight.serverCategories = categories;
};

export const addCategory = (_: CBStore, categoryName: string) => {
  const existingCategoryIdx = _.spotlight.localCategories.findIndex((category) => category.name === categoryName);
  if (existingCategoryIdx === -1) {
    const id = -(_.spotlight.localCategories.length + 1);
    _.spotlight.localCategories.push(
      CommandCategory.decode({
        id,
        organization: '',
        name: categoryName,
        slash_filter_keyword: slugify(categoryName),
      }),
    );
    return id;
  }

  return _.spotlight.localCategories[existingCategoryIdx].id;
};

export const setCategoryConfig = (_: CBStore, categoryId: number, config: Partial<ICommandCategoryType>) => {
  _.spotlight.categoryConfig[categoryId] ||= {};
  _.spotlight.categoryConfig[categoryId] = { ..._.spotlight.categoryConfig[categoryId], ...config };
};
