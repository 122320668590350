import styled from '@emotion/styled';

import ChatsChart from './ChatsChart';
import ChatsTableOld from './ChatsTableOld';
import Layout from '../../components/Layout';

import { useEffect, useState } from 'react';
import { AnalyticsCopilot, AnalyticsCopilotData } from '@commandbar/internal/middleware/analytics/copilot';
import * as Sentry from '@sentry/react';

import StatCard from '../../components/StatCard';
import { useAnalyticsContext } from 'commandbar.com/src/components/analytics/AnalyticsContext';
import { useCSVDownload } from '../../useCSVDownload';
import { getCopilotDemoData } from '../../analyticDemoData';

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1148px) {
    width: 100%;
    flex-direction: row;

    * {
      min-width: 0 !important;
    }
  }
`;

const AnalyticsCopilotDashboardOld = () => {
  const [data, setData] = useState<AnalyticsCopilotData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isDemoData, includeAdminData, timeFilterRange } = useAnalyticsContext();

  const { downloadCsv: downloadCsvOverview, isDownloading: isDownloadingOverview } = useCSVDownload({
    name: `copilot-overview`,
    download: () => AnalyticsCopilot.download('overview', timeFilterRange),
  });

  const { downloadCsv: downloadCsvHistory, isDownloading: isDownloadingHistory } = useCSVDownload({
    name: `copilot-history`,
    download: () => AnalyticsCopilot.download('history', timeFilterRange),
  });

  useEffect(() => {
    let ignore = false;

    if (isDemoData) {
      setData(getCopilotDemoData(timeFilterRange));
      setIsLoading(false);
    } else {
      const fetchAnalyticsCopilot = () => {
        setIsLoading(true);

        AnalyticsCopilot.read({ ...timeFilterRange, include_admin_data: includeAdminData.toString() })
          .then((res) => {
            if (!ignore) {
              setData(res);
              setIsLoading(false);
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            console.error(error);
          })
          .finally(() => {
            if (!ignore) {
              setIsLoading(false);
            }
          });
      };

      fetchAnalyticsCopilot();
    }

    return () => {
      ignore = true;
    };
  }, [timeFilterRange, isDemoData, includeAdminData]);

  return (
    <Layout
      title="Copilot"
      disableDemoData={isLoading}
      isDownloading={isDownloadingOverview || isDownloadingHistory}
      onDownloadCSV={[
        {
          label: 'Overview',
          action: downloadCsvOverview,
        },
        {
          label: 'Chat history',
          action: downloadCsvHistory,
        },
      ]}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
          <ChatsChart chartData={data?.opens_bar_chart} totalOpens={data?.total_opens} isLoading={isLoading} />

          <StyledCardContainer>
            <StatCard
              title="Success rate"
              subtitle="How often Copilot can answer questions"
              format="percentage"
              metric={data?.success_rate?.metric}
              rate={data?.success_rate?.percent_change}
              isLoading={isLoading}
            />
            <StatCard
              title="Unique users"
              subtitle="Total unique users who have used copilot"
              format="number"
              metric={data?.unique_users?.metric}
              rate={data?.unique_users?.percent_change}
              isLoading={isLoading}
            />
          </StyledCardContainer>
        </div>

        <ChatsTableOld timeFilterRange={timeFilterRange} />
      </div>
    </Layout>
  );
};

export default AnalyticsCopilotDashboardOld;
